<template>
  <PreLoader v-if="isAllProvidersLoading || isTranslationLoading" />
  <div>
    <DashBoard />
    <div class="container">
      <div class="row mx-auto justify-content-center">
        <div class="col-12 col-lg-10">
          <div class="dashboard-all">
            <h5 class="dashboard-title ty_h3" style="font-size: 22px">
              {{ translations?.dashboard_menu?.MyInbox ?? "My Inbox" }}
            </h5>
            <div class="container">
              <div
                class="d-flex justify-content-around justify-content-md-start pt-3"
              >
                <div
                  class="col-5 col-md-2 messages-selector mr-2"
                  :class="isSelected == 1 ? 'active-filter-messages' : ''"
                  @click="select(1)"
                >
                  {{ translations?.my_inbox?.Incoming ?? "Incoming" }}
                </div>
                <div
                  class="col-5 col-md-2 messages-selector mr-2"
                  :class="isSelected == 2 ? 'active-filter-messages' : ''"
                  @click="select(2)"
                >
                  {{ translations?.my_inbox?.Compose ?? "Compose" }}
                </div>
              </div>
              <hr />
              <div v-if="isSelected == 1">
                <p class="mb-2">
                  {{ translations?.my_inbox?.RefineResults ?? "Refine Result" }}
                </p>
                <div
                  class="date-field py-3 d-flex justify-content-center flex-wrap"
                >
                  <div class="col-6 date-option-inbox-sms">
                    <label class="form-label" for="from_date">{{
                      translations?.reports?.From ?? "From"
                    }}</label>
                    <div
                      style="
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                      "
                      class="mb-2"
                    >
                      <div class="mr-1" style="flex: 1">
                        <select v-model="dayOption_From" id="day" class="input">
                          <option value="" disabled selected>
                            {{ translations?.registration?.Day }}
                          </option>
                          <option
                            v-for="dayOption_From in days_From"
                            :key="dayOption_From"
                            :value="dayOption_From"
                          >
                            {{
                              dayOption_From
                                ? dayOption_From
                                : translations?.registration?.Day
                            }}
                          </option>
                        </select>
                      </div>
                      <div class="mr-1" style="flex: 1">
                        <select
                          v-model="monthOption_From"
                          id="month"
                          class="input"
                        >
                          <option value="" disabled selected>
                            {{ translations?.registration?.Month }}
                          </option>
                          <option
                            v-for="monthOption_From in months_From"
                            :key="monthOption_From"
                            :value="monthOption_From"
                          >
                            {{
                              monthOption_From
                                ? monthOption_From
                                : translations?.registration?.Month
                            }}
                          </option>
                        </select>
                      </div>
                      <div class="mr-1" style="flex: 1">
                        <select
                          v-model="yearOption_From"
                          id="year"
                          class="input"
                        >
                          <option value="" disabled selected>
                            {{ translations?.registration?.Year }}
                          </option>
                          <option
                            v-for="yearOption_From in years_From"
                            :key="yearOption_From"
                            :value="yearOption_From"
                          >
                            {{
                              yearOption_From
                                ? yearOption_From
                                : translations?.registration?.Year
                            }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 date-option-inbox-sms">
                    <label class="form-label" for="from_date">{{
                      translations?.reports?.To ?? "To"
                    }}</label>
                    <div
                      style="
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                      "
                      class="mb-2"
                    >
                      <div class="mr-1" style="flex: 1">
                        <select v-model="dayOption_To" id="day" class="input">
                          <option value="" disabled selected>
                            {{ translations?.registration?.Day }}
                          </option>
                          <option
                            v-for="dayOption_To in days_To"
                            :key="dayOption_To"
                            :value="dayOption_To"
                          >
                            {{
                              dayOption_To
                                ? dayOption_To
                                : translations?.registration?.Day
                            }}
                          </option>
                        </select>
                      </div>
                      <div class="mr-1" style="flex: 1">
                        <select
                          v-model="monthOption_To"
                          id="month"
                          class="input"
                        >
                          <option value="" disabled selected>
                            {{ translations?.registration?.Month }}
                          </option>
                          <option
                            v-for="monthOption_To in months_To"
                            :key="monthOption_To"
                            :value="monthOption_To"
                          >
                            {{
                              monthOption_To
                                ? monthOption_To
                                : translations?.registration?.Month
                            }}
                          </option>
                        </select>
                      </div>
                      <div class="mr-1" style="flex: 1">
                        <select v-model="yearOption_To" id="year" class="input">
                          <option value="" disabled selected>
                            {{ translations?.registration?.Year }}
                          </option>
                          <option
                            v-for="yearOption_To in years_To"
                            :key="yearOption_To"
                            :value="yearOption_To"
                          >
                            {{
                              yearOption_To
                                ? yearOption_To
                                : translations?.registration?.Year
                            }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="messages-type_searchBtn-wrapper">
                  <div class="col-12 col-lg-4">
                    <label class="form-label mt-2" for="to">{{
                      translations?.reports?.To ?? "To"
                    }}</label>
                    <select v-model="type" id="day" class="input">
                      <option
                        v-for="(value, label) in statusOptions"
                        :key="value"
                        :value="value"
                      >
                        {{ translations?.my_inbox?.[label] ?? label }}
                      </option>
                    </select>
                  </div>
                  <div class="col-12 col-lg-4 ml-1 mr-1">
                    <label class="form-label mt-2" for="receveid_sent">{{
                      translations?.my_inbox?.Receveid_Sent ?? "Receveid/Sent"
                    }}</label>
                    <select v-model="received_sent" id="day" class="input">
                      <option
                        v-for="(value, label) in typeMessages"
                        :key="value"
                        :value="value"
                      >
                        {{ translations?.my_inbox?.[label] ?? label }}
                      </option>
                    </select>
                  </div>
                  <div class="col-12 col-lg-4">
                    <button
                      class="dashboard-button-searchMessages"
                      @click="searchMessages"
                    >
                      {{ translations?.my_inbox?.Search ?? "Search" }}
                    </button>
                  </div>
                </div>
                <div
                  class="card-messages-container"
                  v-if="messages.length > 0 && !isSearchingResult"
                >
                  <div
                    class="transaction my-4"
                    v-for="message in messages"
                    :key="message.id"
                  >
                    <div class="card-messages-mail">
                      <div class="col-12 text-left transaction-time">
                        {{ message.created_at.slice(8, 10) }}/{{
                          message.created_at.slice(5, 7)
                        }}/{{ message.created_at.slice(0, 4) }} -
                        {{ message.created_at.slice(11, 19) }}
                      </div>
                      <div class="col-12 text-left transaction-type">
                        {{ message.subject }}
                      </div>
                      <div class="col-12 pt-2 text-left">
                        {{ message.body }}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="d-flex align-items-center justify-content-center pt-3"
                  v-else-if="isSearchingResult"
                >
                  <MainLoader />
                </div>
                <div
                  class="error-message-no-sms-found"
                  v-if="messages.length == 0 && errorFoundSms"
                >
                  {{
                    translations?.my_inbox?.No_sms_found ?? "No message found"
                  }}
                </div>
              </div>
              <!--START COMPOSE MESSAGES AREA-->
              <div v-if="isSelected == 2">
                <form @submit.prevent>
                  <div class="col-12 col-md-4 col-lg-3 my-md-2 pt-2 pb-4">
                    <label class="form-label">{{
                      translations?.my_inbox?.Select_recipient ??
                      "Select recipient"
                    }}</label>
                    <select
                      class="input"
                      name="recipient"
                      id="recipient"
                      v-model="selectedRecipient"
                    >
                      <option
                        v-for="recipient in recipients"
                        :key="recipient.code"
                        :value="[recipient.code]"
                      >
                        {{ recipient.name }}
                      </option>
                    </select>
                  </div>
                  <div class="col-12 mb-md-2">
                    <textarea
                      class="text-area-sendMessage"
                      name="message"
                      id="message"
                      cols="30"
                      rows="10"
                      :maxlength="maxLengthMessage"
                      v-model="message"
                    ></textarea>
                  </div>
                </form>
                <div class="col-12 col-md-9 mt-3 mt-md-0">
                  <div class="available-text">
                    {{ availableText }}
                  </div>
                </div>
                <div class="sendMessage-btn-container">
                  <button
                    @click="sendMessage"
                    class="dashboard-button-sendMessages pt-2"
                  >
                    {{ translations?.my_inbox?.sendMessages ?? "Send Message" }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DashBoard from "./DashBoard.vue";
import PreLoader from "@/components/global/PreLoader.vue";
import MainLoader from "@/components/global/MainLoader.vue";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
export default {
  name: "MyInboxPage",
  components: {
    DashBoard,
    PreLoader,
    MainLoader,
  },
  data() {
    const currentDate = new Date();
    const currentDay = currentDate.getDate();
    const currentYear = new Date().getFullYear();
    return {
      checkDeviceType: "",
      isSearchingResult: false,
      /*Date_to*/
      yearOption_To: "",
      monthOption_To: "",
      dayOption_To: "",
      days_To: Array.from({ length: currentDay }, (_, index) =>
        (index + 1).toString().padStart(2, "0")
      ),
      months_To: Array.from({ length: 12 }, (_, index) =>
        (index + 1).toString().padStart(2, "0")
      ),
      years_To: Array.from({ length: 6 }, (_, index) => currentYear - index),
      /*Date_from*/
      yearOption_From: "",
      monthOption_From: "",
      dayOption_From: "",
      days_From: Array.from({ length: 31 }, (_, index) =>
        (index + 1).toString().padStart(2, "0")
      ),
      months_From: Array.from({ length: 12 }, (_, index) =>
        (index + 1).toString().padStart(2, "0")
      ),
      years_From: Array.from({ length: 6 }, (_, index) => currentYear - index),
      //*//
      from_date: "",
      to_date: "",
      type: null,
      received_sent: null,
      trashed: "false",
      // COMPOSE:
      statusOptions: {
        All: "null",
        Read: "true",
        Unread: "false",
      },
      typeMessages: {
        All: "null",
        Incoming: "false",
        Outcoming: "true",
      },
      isSelected: "1",
      maxLengthMessage: 2000,
      message: "",
      selectedRecipient: ["02"], // default messenger-group
      subject: "Request from Player dashboard",
    };
  },
  methods: {
    select(v) {
      this.isSelected = v;
    },
    sendMessage() {
      const composedMessage = {
        recipient: this.selectedRecipient,
        subject: this.subject,
        body: this.message,
      };
      this.$store.dispatch("messages/sendMessage", composedMessage);
    },
    searchMessages() {
      this.from_date = `${this.yearOption_From}-${this.monthOption_From}-${this.dayOption_From}`;
      this.to_date = `${this.yearOption_To}-${this.monthOption_To}-${this.dayOption_To}`;
      const dateFormatRegex = /^\d{4}-\d{2}-\d{2}$/;
      if (
        !dateFormatRegex.test(this.from_date) ||
        !dateFormatRegex.test(this.to_date)
      ) {
        Swal.fire({
          icon: "error",
          text:
            this.translations?.body?.Error_must_fill_all_date_fields ??
            "Must fill date fields!",
        });
        return;
      } else {
        this.isSearchingResult = true;
        let type = null;
        let received = null;
        if (this.type == "true") {
          type = 1;
        } else if (this.type == "false") {
          type = 0;
        }
        if (this.received_sent == "true") {
          received = 1;
        } else if (this.received_sent == "false") {
          received = 0;
        }
        const parameters = {
          from: `?date_from=${this.from_date} 00:00:00`,
          to: `&date_to=${this.to_date} 23:59:59`,
          type: this.type != null && this.type != "null" ? `&read=${type}` : "",
          received:
            this.received_sent != null && this.received_sent != "null"
              ? `&sent=${received}`
              : "",
        };
        const parametersPath = Object.values(parameters).join("");
        this.$store
          .dispatch("messages/getMessages", parametersPath)
          .then(() => {
            this.isSearchingResult = false;
          })
          .catch((error) => {
            console.error("Error during message retrieval:", error);
            this.isSearchingResult = false;
          });
      }
    },
  },
  watch: {
    resetField(value) {
      if (value) {
        this.message = "";
        this.$store.commit("messages/resetMessage", false);
      }
    },
  },
  computed: {
    ...mapGetters("translations", ["isTranslationLoading", "translations"]),
    ...mapGetters("messages", [
      "recipients",
      "resetField",
      "errorFoundSms",
      `messages`,
    ]),
    ...mapGetters("casinoGames", ["isAllProvidersLoading"]),
    availableText() {
      const available = this.maxLengthMessage - this.message.length;
      return `${available} ${
        this.translations?.my_inbox?.available_characters ?? "characters"
      }`;
    },
  },
  created() {},
  mounted() {
    this.$store.dispatch("messages/getRecipients");
  },
};
</script>

<style></style>
