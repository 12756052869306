// { player_id } /unusedCampaigns
import Swal from 'sweetalert2';
export default {
    namespaced: true,
    state() {
        return {
            path: {
                promotionsList: 'cms/pages?type=promotion&campaign_visible=1&language=',
                page: 'cms/pages/',
                pageDetail: 'cms/pages/',
                campaignUnused: 'campaigns/players/',
                deleteCampaign: 'campaigns/players/',
                forcedCampaign: 'campaigns/',
                cashBack: 'campaigns/'
            },
            promotionsList: {},
            promotion: {},
            campaignUnusedList: {},
            id: null,
            isPromotionsLoading: true,
            isPromoIdLoading: true,
            bonusCodeCopied: '',
            bonusBoxVisible: false,
            cashBackList: {},
            cashBackActiveList: {},
        };
    },
    mutations: {
        setSkinPromotions(state, payload) {
            if (state.id) {
                state.promotion = payload.data;
            } else {
                state.promotionsList = payload.data;
            }
            state.isPromotionsLoading = false;
            state.isPromoIdLoading = false;
        },
        setSkinCashBack(state, payload) {
            state.cashBackList = payload.data.data
            console.log(state.cashBackList)
        },
        setSkinCashBackActive(state, payload) {
            state.cashBackActiveList = payload.data.data
            console.log(state.cashBackActiveList)
        },
        openPromotion(state, payload) {
            state.promotion = payload;
            state.isPromoIdLoading = false;
        },
        setCampaignUnused(state, payload) {

            state.campaignUnusedList = payload.data;
            state.isPromotionsLoading = false

        },
        setLoadingPromo(state, value) {
            state.isPromotionsLoading = value
        },
        setBonusCodeCopied(state, value) {
            state.bonusCodeCopied = value
        },
        setBonusBoxVisible(state, value) {
            // console.warn(value)
            state.bonusBoxVisible = value
        }
    },
    actions: {
        async forcedCampaigns({ state, getters, commit }, { campaign_id, player_id, game_id, provider_id }) {
            // console.log(getters.endpoint + state.path.forcedCampaign + campaign_id + "/players/" + player_id + "/ping");
            let body = {
                game_id: game_id,
                provider_id: provider_id
            }
            // console.log(body)
            const responseData = await getters.axios
                .post(getters.endpoint + state.path.forcedCampaign + campaign_id + "/players/" + player_id + "/ping", body, getters.auth)
                .then((response) => {
                    return response;
                })
                .catch((err) => {
                    console.log(err);
                    return;
                });
            commit("setBonusBoxVisible", responseData.data.useBonus)
            // console.log('FORCED___', responseData.data);
        },
        async callCashback({ state, getters, commit }, player_id) {

            let action_id = 9;
            let language = localStorage.getItem('language') ?? 'en';
            if (language == 'ca' || language == 'au') {
                language = 'en';
            }
            const responseData = await getters.axios
                .get(getters.endpoint + state.path.cashBack + "/players/" + player_id +
                    "/unusedCampaigns?language=" + language + "&action_id=" + action_id +
                    '&campaign_visible=1',
                    getters.auth)
                .then((response) => {
                    return response;
                })
                .catch((err) => {
                    console.log(err);
                    return;
                });


            commit('setSkinCashBack', responseData)
            // console.log('FORCED___', responseData.data);
        },
        async callCashbackActiveForPlayer({ state, getters, commit }, player_id) {
            let action_id = 9
            const responseData = await getters.axios
                .get(getters.endpoint + state.path.cashBack + "players/" + player_id +
                    "/readAll" + "?action_id=" + action_id
                    ,
                    getters.auth)
                .then((response) => {
                    return response;
                })
                .catch((err) => {
                    console.log(err);
                    return;
                });

            console.log(responseData);
            commit('setSkinCashBackActive', responseData)
            // console.log('FORCED___', responseData.data);
        },
        async activeCashback({ state, getters, commit }, body) {
            try {
                console.log(commit);

                const response = await getters.axios.post(
                    getters.endpoint + state.path.cashBack,
                    body,
                    getters.auth
                );

                if (response.status === 200) {
                    window.location.reload();
                } else {
                    console.log(`Unexpected response status: ${response.status}`);
                }

                console.log(response);
            } catch (err) {
                console.error(err);
            }
        },
        async callSkinPromotions({ state, getters, commit }, promotionId) {
            commit('setLoadingPromo', true)
            // state.isPromotionsLoading = true;
            let language = localStorage.getItem('language') ?? 'en';
            if (language == 'ca' || language == 'au') {
                language = 'en';
            }

            let config = {
                path: state.path.promotionsList + language,
            };
            if (promotionId) {
                config = {
                    path: state.path.page + promotionId,
                };
                state.id = promotionId;
            }

            const responseData = await getters.axios
                .get(getters.endpoint + config.path, getters.auth)
                .then((response) => {
                    return response.data;
                })
                .catch((err) => {
                    console.log(err);
                    return;
                });
            commit('setSkinPromotions', responseData);

        },
        async findPromotion({ state, getters, commit }, promo) {
            // state.isPromoIdLoading = true;
            state.id = promo.id;
            const promotion = getters.promotionsList.find(
                (promotion) => promotion.id == promo.id
            );
            await commit('openPromotion', promotion);
        },
        clearPromotion({ state }) {
            state.id = null;
            state.promotionsList = {};
            state.promotion = {};
        },
        async callUnusedCampaign({ state, getters, commit }, player_id) {
            state.isPromotionsLoading = true
            let language = getters.language ?? 'en';
            const responseData = await getters.axios
                .get(getters.endpoint + state.path.campaignUnused + player_id + "/unusedCampaigns?language=" + language + "&is_deposit=1&campaign_visible=1", getters.auth)
                .then((response) => {
                    return response.data;
                })
                .catch((err) => {
                    console.log(err);
                    return;
                });
            commit('setCampaignUnused', responseData);
        },
        saveBonusCode({ commit }, bonus_code) {
            commit("setBonusCodeCopied", bonus_code)
        },
        async deleteCampaign({ state, getters }, user_id) {
            try {
                const confirmResult = await Swal.fire({
                    title: getters.translations.promotion.are_you_sure ?? 'Are you sure?',
                    text: getters.translations.promotion.text_alert_before_delete ?? 'You are about to delete your offer. This action cannot be undone.',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: getters.translations.promotion.confirm_delete ?? 'Yes, delete it',
                    cancelButtonText: getters.translations.promotion.cancel_delete ?? 'No, come back',
                    confirmButtonColor: 'red'
                });

                if (confirmResult.isConfirmed) {
                    const deletePath = state.path.deleteCampaign + user_id;
                    await getters.axios.delete(getters.endpoint + deletePath, getters.auth);

                    window.location.reload();
                }
            } catch (error) {
                console.error("Errore durante l'eliminazione della campagna:", error);
            }
        },
        async deleteCashback({ state, getters }, { body }) {
           
            console.log(body.campaign_id)
            try {
                const confirmResult = await Swal.fire({
                    title: getters.translations.promotion.are_you_sure ?? 'Are you sure?',
                    text: getters.translations.promotion.text_alert_before_delete ?? 'You are about to delete your offer. This action cannot be undone.',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: getters.translations.promotion.confirm_delete ?? 'Yes, delete it',
                    cancelButtonText: getters.translations.promotion.cancel_delete ?? 'No, come back',
                    confirmButtonColor: 'red'
                });

                if (confirmResult.isConfirmed) {
                    const deletePath = state.path.deleteCampaign + body.player_id+"?campaign_id="+body.campaign_id;
                    await getters.axios.delete(getters.endpoint + deletePath, getters.auth);

                    window.location.reload();
                }
            } catch (error) {
                console.error("Error:", error);
            }
        },
    },
    getters: {
        endpoint(_, _1, _2, rootGetters) {
            return rootGetters['api/endpoint'];
        },
        axios(_, _1, _2, rootGetters) {
            return rootGetters['api/axios'];
        },
        auth(_, _1, _2, rootGetters) {
            return rootGetters['api/auth'];
        },
        language(_, _1, _2, rootGetters) {
            return rootGetters['translations/language_selected'];
        },
        translations(_, _1, _2, rootGetters) {
            return rootGetters['translations/translations'];
        },
        isTranslationLoading(_, _1, _2, rootGetters) {
            return rootGetters['translations/isTranslationLoading'];
        },
        promotionsList(state) {
            return state.promotionsList;
        },
        promotion(state) {
            return state.promotion;
        },
        isPromotionsLoading(state) {
            return state.isPromotionsLoading;
        },
        isPromoIdLoading(state) {
            return state.isPromoIdLoading;
        },
        campaignUnusedList(state) {
            return state.campaignUnusedList;
        },
        bonusCodeCopied(state) {
            return state.bonusCodeCopied
        },
        playerCampaign(_, _1, _2, rootGetters) {
            return rootGetters["player/playerCampaign"];
        },
        bonusBoxVisible(state) {
            return state.bonusBoxVisible;
        },
        cashBackList(state) {
            return state.cashBackList
        },
        cashBackActiveList(state) {
            return state.cashBackActiveList
        }
    },
};
