<template>
  <transition name="fade">
    <PreLoader v-if="isTimer" />
  </transition>
  <div class="container">
    <div class="login-container">
      <h2 class="ty_h3">Admin Login</h2>
      <form @submit.prevent="redirectToAdminPage">
        <label for="username ty_bd">Username:</label>
        <input type="text" id="username" v-model="nickname" required />

        <label for="password ty_bd">Password:</label>
        <input type="password" id="password" v-model="password" required />

        <button type="submit ty_bd">Login</button>
      </form>
    </div>
  </div>
</template>

<script>
import PreLoader from "@/components/global/PreLoader.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    PreLoader,
  },
  data() {
    return {
      language_selected: localStorage.getItem("language"),
      nickname: "",
      password: "",
      admin_nickname: "BetHappy-admin-2023",
      admin_password: "BetHappy2024@admin!!",
      isTimer: true,
    };
  },
  created() {
    let language = localStorage.getItem("language");
    this.$router.push({ params: { lang: language } });
    this.setTimer();
  },
  computed: {
    ...mapGetters("translations", ["isTranslationLoading", "translations"]),
    ...mapGetters("casinoGames", ["allSkinProviders", "isAllProvidersLoading"]),
  },
  methods: {
    setTimer() {
      setTimeout(() => {
        this.isTimer = false;
      }, 2000);
    },
    redirectToAdminPage() {
      if (
        this.nickname === this.admin_nickname &&
        this.password === this.admin_password
      ) {
        sessionStorage.setItem("adminNickname", this.admin_nickname);
        sessionStorage.setItem("adminPassword", this.admin_password);
        this.$store.dispatch("translations/callTranslations").then(() => {
          alert("You will be redirected to the home page shortly");
          window.location.href = "/";
        });
      } else {
        alert("Incorrect credentials");
        this.nickname = "";
        this.password = "";
      }
    },
  },
};
</script>

<style scoped>
.container {
  padding-top: 150px;
  min-height: 70vh;
}
.login-container {
  max-width: 300px;
  margin: auto;
  padding: 20px;
  border: 2px solid #ccc;
  border-radius: 5px 0px 5px 0px;
  color: white;
}

form {
  display: flex;
  flex-direction: column;
}

label {
  margin-bottom: 8px;
}

input {
  padding: 8px;
  background: white;
  margin-bottom: 16px;
}

button {
  padding: 10px;
  background: radial-gradient(
      78.33% 78.33% at 47.27% 0%,
      rgba(108, 215, 81, 0.75) 0%,
      rgba(170, 234, 131, 0) 100%
    ),
    linear-gradient(360deg, #0b5a00 0%, #149102 100%) !important;
  color: #fff;
  border: none;
  border-radius: 5px 0px 5px 0px;
  cursor: pointer;
}
</style>
