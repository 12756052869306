<template>
  <transition name="route" mode="out-in">
    <PreLoader v-if="isAllProvidersLoading || isTranslationLoading || isTimer" />
  </transition>
  <div class="Main-Container mt-4">
    <div class="pt-30">
      <div class="container pt-30">
        <div
          class="d-flex justify-content-space-around align-items-center flex-column my-3"
        >
          <h2 class="col-12 text-center ty_h2">
            {{ translations?.payment_methods?.payment_methods ?? "Payment Methods" }}
          </h2>
          <div class="row flex-row w-100 mt-5 justify-content-left">
            <div
              v-for="method in paymentMethod"
              :key="method?.id"
              class="col-12 col-lg-4 col-md-6 col-sm-12"
            >
              <div class="payment-card">
                <div class="thumb-container">
                  <img
                    :src="`/images/paymentMethods/${method.thumb}.png`"
                    :alt="method.name"
                    class="method-thumb"
                  />
                </div>
                <div
                  class="mx-0 mb-2 text-center"
                  v-if="method?.id == 1 || method?.id == 2 || method?.id == 3"
                >
                  <p class="mb-0 ty_h5" style="color: white">
                    {{ translations?.payment_methods?.credit_cards_psp ?? "Credit Card" }}
                  </p>
                </div>
                <div class="information-payment">
                  <div class="head-payment-information">
                    <h5 class="ty_h5 tr-95">
                      {{
                        translations?.payment_methods?.deposit_limits ?? "Deposit Limits"
                      }}
                    </h5>
                  </div>
                  <div class="d-flex justify-content-around text-center">
                    <p class="ty_bd">
                      {{ translations?.payment_methods?.min ?? "Min" }}:
                      {{ method?.minDeposit }}€
                    </p>
                    <p class="ty_bd">
                      {{ translations?.payment_methods?.max ?? "Max" }}:
                      {{ method?.maxDeposit }}€
                    </p>
                  </div>
                  <div
                    class="head-payment-information"
                    v-if="method?.maxWithdrawal !== 0 && method?.minWithdrawal !== 0"
                  >
                    <h5 class="ty_h5 tr-95">
                      {{
                        translations?.payment_methods?.withdrawal_limits ??
                        "Withdrawal Limits"
                      }}
                    </h5>
                  </div>
                  <div
                    class="d-flex justify-content-around text-center"
                    v-if="method?.maxWithdrawal !== 0 && method?.minWithdrawal !== 0"
                  >
                    <p class="ty_bd">
                      {{ translations?.payment_methods?.min ?? "Min" }}:
                      {{ method?.minWithdrawal }}€
                    </p>
                    <p class="ty_bd">
                      {{ translations?.payment_methods?.max ?? "Max" }}:
                      {{ method?.maxWithdrawal }}€
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <h2 class="ty_h2 my-5" style="color: white">
            {{ translations?.payment_methods?.supported_crypto ?? "Supported Crypto" }}
          </h2>
          <div class="row flex-row w-100 justify-content-left">
            <div
              v-for="method in cryptoMethod"
              :key="method?.id"
              class="col-12 col-lg-4 col-md-6 col-sm-12"
            >
              <div class="payment-card">
                <div class="thumb-container">
                  <img
                    :src="`/images/paymentMethods/${method.thumb}.png`"
                    :alt="method.name"
                    class="method-thumb"
                  />
                </div>
                <div class="information-payment">
                  <div class="head-payment-information">
                    <h5 class="ty_h5 tr-95">
                      {{
                        translations?.payment_methods?.deposit_limits ?? "Deposit Limits"
                      }}
                    </h5>
                  </div>
                  <div class="d-flex justify-content-around text-center">
                    <p class="ty_bd">
                      {{ translations?.payment_methods?.min ?? "Min" }}:
                      {{ method?.minDeposit }}€
                    </p>
                    <p class="ty_bd">
                      {{ translations?.payment_methods?.max ?? "Max" }}:
                      {{ method?.maxDeposit }}€
                    </p>
                  </div>
                  <div
                    class="head-payment-information"
                    v-if="method?.maxWithdrawal !== 0 && method?.minWithdrawal !== 0"
                  >
                    <h5 class="ty_h5 tr-95">
                      {{
                        translations?.payment_methods?.withdrawal_limits ??
                        "Withdrawal Limits"
                      }}
                    </h5>
                  </div>
                  <div
                    class="d-flex justify-content-around text-center"
                    v-if="method?.maxWithdrawal !== 0 && method?.minWithdrawal !== 0"
                  >
                    <p class="ty_bd">
                      {{ translations?.payment_methods?.min ?? "Min" }}:
                      {{ method?.minWithdrawal }}€
                    </p>
                    <p class="ty_bd">
                      {{ translations?.payment_methods?.max ?? "Max" }}:
                      {{ method?.maxWithdrawal }}€
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PreLoader from "@/components/global/PreLoader.vue";
export default {
  name: "banking-page",
  components: {
    PreLoader,
  },
  data() {
    return {
      lang: localStorage.getItem("language"),
      isMethodLoaded: false,
      slug: "banking",
      isTimer: true,
      isLogged: localStorage.getItem("X19fYWNjZXNzX19f") ?? false,
      paymentMethod: [
        {
          id: 1,
          thumb: "psp",
          name: "PSP1",
          minDeposit: 10.0,
          maxDeposit: 5000.0,
          minWithdrawal: 0,
          maxWithdrawal: 0,
        },
        {
          id: 2,
          thumb: "psp",
          name: "PSP2",
          minDeposit: 10.0,
          maxDeposit: 2500.0,
          minWithdrawal: 0,
          maxWithdrawal: 0,
        },
        {
          id: 3,
          thumb: "psp",
          name: "PSP3",
          minDeposit: 10.0,
          maxDeposit: 5000.0,
          minWithdrawal: 0,
          maxWithdrawal: 0,
        },
        {
          id: 4,
          thumb: "mastercard1",
          name: "mastercard",
          minDeposit: 10.0,
          maxDeposit: 1000.0,
          minWithdrawal: 20,
          maxWithdrawal: 500,
        },
        {
          id: 5,
          thumb: "visa1",
          name: "visa",
          minDeposit: 5.0,
          maxDeposit: 500.0,
          minWithdrawal: 10,
          maxWithdrawal: 300,
        },
      ],
      cryptoMethod: [
        {
          id: 1,
          thumb: "bitcoin1",
          name: "BTC",
          minDeposit: 10.0,
          maxDeposit: 100000,
          minWithdrawal: 0,
          maxWithdrawal: 0,
        },
        {
          id: 2,
          thumb: "etherum1",
          name: "ETH",
          minDeposit: 10.0,
          maxDeposit: 100000,
          minWithdrawal: 0,
          maxWithdrawal: 0,
        },
        {
          id: 3,
          thumb: "ltc",
          name: "LTC",
          minDeposit: 10.0,
          maxDeposit: 100000,
          minWithdrawal: 0,
          maxWithdrawal: 0,
        },
        {
          id: 4,
          thumb: "bch",
          name: "BCH",
          minDeposit: 10.0,
          maxDeposit: 100000,
          minWithdrawal: 0,
          maxWithdrawal: 0,
        },
        {
          id: 5,
          thumb: "dash",
          name: "DASH",
          minDeposit: 10.0,
          maxDeposit: 100000,
          minWithdrawal: 0,
          maxWithdrawal: 0,
        },
      ],
    };
  },
  computed: {
    ...mapGetters("translations", ["translations", "isTranslationLoading"]),
    ...mapGetters("skinBanners", ["isLoadingBanners"]),
    ...mapGetters("player", ["playerInfo"]),
    ...mapGetters("payments", [
      "depositPaymentMethods",
      "withdrawalPaymentMethods",
      "withdrawPaymentMethods",
    ]),
    ...mapGetters("payments", ["depositPaymentMethodsFiltered"]),
  },
  created() {
    this.$router.push({ params: { lang: this.lang } });
  },
  mounted() {
    setTimeout(() => {
      this.isTimer = false;
    }, 1000);
  },
  beforeUnmount() {
    this.$store.dispatch("casinoGames/clearFilter");
  },
  methods: {
    async fetchMethod() {
      await this.$store.dispatch("payments/callPaymentMethods");
    },
  },
};
</script>

<style scoped></style>

<!--   // Deposit Methods Section 
<div
    //     class="d-flex justify-content-space-around align-items-center flex-column my-3"
    //     v-if="depositPaymentMethods?.length > 0"
    //   >
    //     <h2 class="col-12 text-center ty_h2">Deposit Methods</h2>
    //     <div class="row w-100">
    //       <div
    //         v-for="method in depositPaymentMethods"
    //         :key="method?.id"
    //         class="col-12 col-lg-4 col-md-4"
    //       >
    //         <div class="payment-card">
    //           <p>{{ method?.name }}</p>
    //         </div>
    //       </div>
    //     </div>
    //   </div>

    //    Withdrawal Methods Section 
<div
    //     class="d-flexjustify-content-space-around align-items-center flex-column my-3"
    //     v-if="withdrawPaymentMethods?.length > 0"
    //   >
    //     <h2 class="col-12 text-center ty_h2">Withdrawal Methods</h2>
    //     <div class="row w-100">
    //       <div
    //         v-for="method in withdrawPaymentMethods"
    //         :key="method?.id"
    //         class="col-12 col-lg-4 col-md-4"
    //       >
    //         <div class="payment-card">
    //           <p>{{ method?.name }}</p>
    //         </div>
    //       </div>
    //     </div>
    //   </div> -->
