import { createStore } from "vuex";
import apiAccess2 from "@/router/apiAccess2";
import CasinoGames from "./modules/main/CasinoGames.js";
import OpenMenuLogin from "./modules/OpenMenuLogin.js";
import Promotion from "./modules/main/Promotion.js";
import registrationAndRecovery from "./modules/main/registrationAndRecovery.js";
import authPlayer from "./modules/main/authPlayer.js";
import playGame from "./modules/main/playGame.js";
import skinTranslations from './modules/main/skinTranslations.js';
import skinSettings from "./modules/main/skinSettings.js";
import paymentsMethods from "./modules/main/paymentsMethods.js";
import reports from "./modules/main/reports.js";
import playerMessages from "./modules/main/playerMessages.js";
import skinBanners from "./modules/main/skinBanners.js";
import Notifications from "./modules/main/Notifications.js";

const store = createStore({
    modules: {
        api: apiAccess2,
        casinoGames: CasinoGames,
        openMenuLogin: OpenMenuLogin,
        promotion: Promotion,
        registration: registrationAndRecovery,
        player: authPlayer,
        play: playGame,
        translations: skinTranslations,
        skinSettings: skinSettings,
        payments: paymentsMethods,
        reports: reports,
        messages: playerMessages,
        skinBanners: skinBanners,
        Notifications: Notifications
    },
    state() {
        return {
            prefilled_nickname: null,
            prefilled_password: null,
        };
    },
    getters: {
        prefilled_nickname(state) {
            return state.prefilled_nickname;
        },
        prefilled_password(state) {
            return state.prefilled_password;
        },
    },
    mutations: {
        setPrefilledLoginValue(state, payload) {
            state.prefilled_nickname = payload.nickname;
            state.prefilled_password = payload.password;
        },
    },
    actions: {
        setPrefilledLoginValue(context, payload) {
            context.commit("setPrefilledLoginValue", payload);
        },
    },
});

export default store;
