<template>
  <!-- :touchDrag="bannerToDisplay.length > 1 ? true : false" -->
  <div class="wrapper" id="AllSectionPreview">
    <carousel :items-to-show="1" :items-to-scroll="1" :wrap-around="bannerToDisplay.length > 1 ? true : false"
      :transition="300" :mouse-drag="bannerToDisplay.length > 1 ? true : false" :touch-drag="false"
      :autoplay="bannerToDisplay.length > 1 ? 8000 : ''" :pause-autoplay-on-hover="true" class="Carousel-HomePage"
      id="carouselBanner" :style="{
        cursor: bannerToDisplay.length > 1 ? 'grab !important' : 'inherit !important',
      }">
      <slide v-for="banner in bannerToDisplay.filter((banner) =>
        isMobile ? banner.medias.some((media) => media.is_mobile === 1) : true
      )" :key="banner.id" class="slider-wrapper">
        <div class="inner" @click="openPopupSignup">
          <img :src="isMobile
            ? banner.medias.find((media) => media.is_mobile === 1)?.full_url ||
            defaultBanner_mobile
            : banner.medias[0]?.full_url || defaultBanner
            " alt="" />
          <div class="text-hover-carousel" v-if="0">
            <h3 class="title-carousel-hover my-1" v-if="banner?.options?.html && !isMobile">
              {{ banner?.options?.html }}
            </h3>
            <h3 class="title-carousel-hover my-1" v-if="banner?.options?.html_mobile && isMobile">
              {{ banner?.options?.html_mobile }}
            </h3>
            <div class="container-btn-hovered-btn my-1">
              <a :href="banner?.options?.button_url" class="btn button-text style1" v-if="
                banner?.options?.button_text && !isMobile && banner?.options?.button_url
              ">
                {{ banner.options.button_text }}
              </a>
              <a :href="banner?.options?.button_url_mobile" class="btn button-text style1" v-if="
                banner?.options?.button_text_mobile &&
                isMobile &&
                banner?.options?.button_url_mobile
              ">
                {{ banner.options.button_text_mobile }}
              </a>
              <a :href="banner?.options?.button_url_1" class="btn button-text style2" v-if="
                banner?.options?.button_text_1 &&
                !isMobile &&
                banner?.options?.button_url_1
              ">
                {{ banner.options.button_text_1 }}
              </a>
              <a :href="banner?.options?.button_url_mobile_1" class="btn button-text style2" v-if="
                banner?.options?.button_text_mobile_1 &&
                isMobile &&
                banner?.options?.button_url_mobile_1
              ">
                {{ banner.options.button_text_mobile_1 }}
              </a>
            </div>
          </div>
        </div>
      </slide>

      <template #addons>
        <pagination v-if="0" class="carousel__pagination" />
        <navigation v-if="bannerToDisplay.length > 1" />
      </template>
    </carousel>
  </div>
</template>

<script>
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import { mapGetters } from "vuex";

export default {
  name: "CarouselVue",
  props: ["slugSection"],
  components: { Carousel, Slide, Pagination, Navigation },
  data() {
    return {
      carousels: {
        homepage: {
          itemsToShow: 1,
          itemsToScroll: 1,
          wrapAround: false,
          transition: 300,
          showPagination: false,
          imagePath: [
            "/images/banner-section/banner2.jpg",
            "/images/banner-section/second-banner-home.png",
          ],
        },
        livecasino: {
          itemsToShow: 1,
          itemsToScroll: 1,
          wrapAround: false,
          transition: 300,
          showPagination: false,
          imagePath: [
            "/images/banner-section/queen.jpg",
            "/images/banner-section/second-banner-live.jpg",
          ],
        },
        promotion: {
          itemsToShow: 1,
          itemsToScroll: 1,
          wrapAround: false,
          transition: 300,
          showPagination: false,
          imagePath: ["/images/banner-section/promotion_banner.jpg"],
        },
      },
      isTouchDragEnabled: true,
      isScrolling: false,
      isResizing: false,
      isMobile: window.innerWidth <= 768,
      isMorethenOne: false,
      isLogged: localStorage.getItem("X19fYWNjZXNzX19f") ?? false,
    };
  },
  computed: {
    ...mapGetters("skinBanners", [
      "bannerToDisplay",
      "isLoadingBanners",
      "defaultBanner_mobile",
      "defaultBanner",
    ]),
  },
  watch: {
    isScrolling() {
      this.updateTouchDrag();
    },
  },
  methods: {
    openPopupSignup() {
      let signUpButtonDesk = document.getElementById("signUp-btn-desk");
      let signUpButtonMob = document.getElementById("signUp-btn-mob");

      if (
        !this.isLogged &&
        (this.$route.name === "LiveCasinoPage" || this.$route.name === "HomePage")
      ) {
        if (signUpButtonDesk) {
          signUpButtonDesk.click();
        } else {
          signUpButtonMob.click();
        }
      } else {
        return;
      }
    },
    isInformationNotNull(banner) {
      return (
        banner?.title ||
        banner?.subtitle ||
        banner?.options?.button_text ||
        banner?.options?.button_text_1 ||
        banner?.options?.button_text ||
        banner?.options?.button_text_1
      );
    },
    handleScroll() {
      this.isScrolling = true;
      setTimeout(() => {
        this.isScrolling = false;
      }, 2000);
    },
    handleResize() {
      this.isResizing = true;
      setTimeout(() => {
        this.isMobile = window.innerWidth <= 768;
        this.isResizing = false;
      }, 200);
    },
    updateTouchDrag() {
      if (this.isMobile) {
        this.isTouchDragEnabled = false;
      } else if (this.currentCarouselLength >= 2) {
        this.isTouchDragEnabled = true;
      } else if (this.currentCarouselLength == 1 && !this.isMobile) {
        this.isTouchDragEnabled = false;
      }
    },
  },
  created() {
    this.updateTouchDrag();
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("resize", this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style scoped>
@media (min-width: 992px) {
  .wrapper {
    padding: 0px 0px;
  }
}

@media screen and (max-width: 991px) and (min-width: 600px) {
  .wrapper {
    padding: 0px 5px;
  }
}

@media (max-width:768px) {
  .wrapper {
    position: relative;
    width: 100%;
    max-height: 100vh !important;
    margin-top: 90px;
  }

  .wrapper .slider-wrapper {
    max-height: 100% !important;
    max-width: 2048px;
    /* border-radius: 16px !important; */
  }

  img {
    object-fit: cover;
    width: 100%;
    aspect-ratio: unset !important;
  }
}

@media screen and (max-width: 599px) {
  .wrapper {
    padding: 0px 5px;
  }

  .slider-wrapper .inner img {
    max-height: 100% !important;
    object-fit: cover !important;
  }
}

.wrapper {
  position: relative;
  width: 100%;
  /* max-height: 100vh; */
  max-height: 23rem;
  margin-top: 90px;
}

.wrapper .slider-wrapper {
  /* max-height: 100vh; */
  max-height: 23rem;
  max-width: 2048px;
  /* border-radius: 16px !important; */
}

img {
  object-fit: cover;
  width: 100%;
  aspect-ratio: 16/4;
  /*
  min-height: calc(100vh - 80px); */
}

input {
  display: none;
}

.inner {
  width: 100%;
  height: 100%;
  line-height: 0;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  float: left;
  position: relative;
}

.inner:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

.slider-prev-next-control .prev,
.slider-prev-next-control .next {
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #fff;
  opacity: 0.7;
  cursor: pointer;
  z-index: 1;
  text-align: center;
  font-size: 24px;
  line-height: 40px;
  color: #333;
  transition: 0.3s all;
}

.slider-prev-next-control .prev {
  left: 10px;
}

.slider-prev-next-control .next {
  right: 10px;
}

.slider-prev-next-control .prev:hover,
.slider-prev-next-control .next:hover {
  opacity: 1;
  transition: 0.3s all;
}

.arrow-slider-control-svg {
  width: 35px;
  height: 35px;
  object-fit: fill;
  transition: 0.3s all;
  text-align: center;
  font-size: 24px;
  line-height: 40px;
  position: absolute;
  left: -1px;
  top: -3px;
}

.slider-dot-control {
  position: absolute;
  width: 100%;
  bottom: 0;
  text-align: center;
}

.slider-dot-control label {
  cursor: pointer;
  border-radius: 50px;
  display: inline-block;
  width: 15px;
  height: 15px;
  background: #bbb;
  -webkit-transition: all 0.1s;
  -moz-transition: all 0.1s;
  transition: all 0.1s;
  margin: 0px 5px;
}

.slider-dot-control label:hover {
  background: #b3afaf;
}

/* Info Box */
/* .text-hover-carousel { //old
  position: absolute;
  line-height: 20px;
  opacity: 1;
  top: 15px;
  left: 50%;
  max-width: 650px;
  transform: translateX(-50%);
  color: white;
  text-align: center;
  display: flex;
  flex-direction: column;
} */

.text-hover-carousel {
  position: absolute;
  line-height: 20px;
  opacity: 1;
  top: 0%;
  left: 50%;
  max-width: 650px;
  height: 70%;
  transform: translateX(-50%);
  color: white;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

/* Slider Styling */
.slider-wrapper {
  width: 100%;
  overflow: hidden;
  box-shadow: 0px 0px 5px 1px #203d5d;
  background: transparent;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-transition: all 500ms ease-out;
  -moz-transition: all 500ms ease-out;
  transition: all 500ms ease-out;
}

.slider-wrapper .inner {
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-transition: all 800ms cubic-bezier(0.77, 0, 0.175, 1);
  -moz-transition: all 800ms cubic-bezier(0.77, 0, 0.175, 1);
  transition: all 800ms cubic-bezier(0.77, 0, 0.175, 1);
}

.slider-wrapper .inner img {
  max-height: 23rem;
  height: 100%;
  object-fit: cover;
}

.title-carousel-hover {
  font-size: clamp(20px, 4vw, 60px);
}

.sub-title-carousel-hover {
  font-size: clamp(8px, 4vw, 16px);
}

/*---- TABLET ----*/
@media only screen and (max-width: 850px) and (min-width: 451px) {
  .slider-wrapper {
    border-radius: 0;
  }
}

/*---- MOBILE----*/
@media only screen and (max-width: 450px) {
  .slider-wrapper {
    border-radius: 0;
  }

  .slider-wrapper .info {
    opacity: 0;
  }
}
</style>

<style>
.Caroseul-HomePage {
  position: relative;
  width: 100%;
  height: 100vh;
  /* max-height: 90vh;
  height: calc(9 / 16 * 100vw - 0px); */
}

.carousel__pagination {
  display: flex;
  justify-content: center;
  list-style: none;
  position: relative;
  line-height: 0;
  margin: -40px 0 0;
  width: 100%;
}

.carousel__icon {
  display: block;
  width: 33px !important;
  height: 33px !important;
  -o-object-fit: contain !important;
  object-fit: contain !important;
  fill: white !important;
  background: radial-gradient(78.33% 78.33% at 47.27% 0%,
      rgba(108, 215, 81, 0.75) 0%,
      rgba(170, 234, 131, 0) 100%),
    linear-gradient(360deg, #0b5a00 0%, #149102 100%) !important;
  border-radius: 50% !important;
  opacity: 0.8;
  /* box-shadow: 0px 1px 0px 0px #00000091; */
}

.carousel__pagination-item li {
  margin: 0px 5px;
}

.carousel__pagination-button:hover::after,
.carousel__pagination-button--active::after {
  background-color: white;
  border: 3px solid transparent;
}

.carousel__pagination-button::after {
  display: flex;
  justify-content: center;
  text-align: center;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: lightgrey;
}

.carousel__pagination-button {
  display: block;
  border: 0;
  margin: 0;
  cursor: pointer;
  background: transparent;
}

.carousel__pagination-button--active {
  display: flex;
  justify-content: center;
  text-align: center;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  text-align: center;
  position: relative;
  background: cornflowerblue;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border-image: cornflowerblue;
}

.carousel__pagination-button:hover::after,
.carousel__pagination-button--active::after {
  background-color: white !important;
}

.carousel__prev,
.carousel__next {
  width: 40px;
}

.carousel__icon {
  width: 40px;
  height: 40px;
  object-fit: contain;
  fill: black;
  background: white;
  border-radius: 50%;
  opacity: 0.8;
}

#carouselBanner .carousel__viewport {
  border-radius: 0px !important;
}

ol.carousel__track {
  margin-bottom: 0px !important;
}
</style>
