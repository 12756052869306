import router from "@/router";
export default {
  namespaced: true,
  state() {
    return {
      paths: [
        { name: "header", path: "cms/translation/header/" },
        { name: "footer", path: "cms/translation/footer/" },
        { name: "registration", path: "cms/translation/registration/" },
        { name: "login", path: "cms/translation/login/" },
        { name: "buttons", path: "cms/translation/buttons/" },
        { name: "casino", path: "cms/translation/casino/" },
        { name: "live_casino", path: "cms/translation/live_casino/" },
        { name: "promotion", path: "cms/translation/promotion/" },
        { name: "about_us", path: "cms/translation/about_us/" },
        { name: "payment_methods", path: "cms/translation/payment_methods/" },
        {
          name: "affiliate_program",
          path: "cms/translation/affiliate_program/",
        },
        { name: "myprofile", path: "cms/translation/myprofile/" },
        { name: "reports", path: "cms/translation/reports/" },
        { name: "account_balance", path: "cms/translation/accountBalance/" },
        { name: "my_inbox", path: "cms/translation/myInbox/" },
        { name: "dashboard_menu", path: "cms/translation/dashboardMenu/" },
      ],
      languages: {
        path: "cms/translation/languages",
      },
      translations: {
        path: "cms/translation/",
      },
      skinLanguages: [],
      language_selected: "",
      // isTranslationLoading: null,
      isTranslationLoading: false,
      adminUserName: "",
      adminPassword: "",
    };
  },
  mutations: {
    setSkinLanguages(state, payload) {
      payload.push({
        id: 1,
        name: "Australian",
        code: "au",
        deleted_at: null,
      });
      payload.push({
        id: 1,
        name: "Canadian",
        code: "ca",
        deleted_at: null,
      });
      state.skinLanguages = payload;
    },
    getLanguageFromStorage(state) {
      let defaultLanguage = "en";
      let language = localStorage.getItem("language");
      const pathLanguage = router.currentRoute["_value"].redirectedFrom;
      if (pathLanguage != undefined) {
        const checkLanguageParam = pathLanguage.fullPath.split("/");

        const languageFound = state.skinLanguages.find(
          (lang) => lang.code == checkLanguageParam[1]
        );
        if (languageFound != undefined) {
          defaultLanguage = checkLanguageParam[1];
          language = checkLanguageParam[1];
          const newRoute = pathLanguage.fullPath.slice(3);
          router.replace(newRoute);
        }
      }
      if (language) {
        state.language_selected = language;
      } else {
        localStorage.setItem("language", language);
        state.language_selected = defaultLanguage;
      }
    },
    setAllTranslations(state, payload) {
      state.allTranslations = payload;
      state.isTranslationLoading = false;
    },
    setLanguageToStorage(state, payload) {
      state.isTranslationLoading = true;
      if (payload) {
        localStorage.setItem("language", payload);
      } else {
        localStorage.setItem("language", state.language_selected);
      }
      state.isTranslationLoading = false;
    },
    setAdminUsername(state, value) {
      state.adminUserName = value;
      // console.log(state.adminUserName)
    },
    setAdminPassword(state, value) {
      state.adminPassword = value;
      // console.log(state.adminPassword)
    },
  },
  actions: {
    async getSkinLanguages({ state, getters, commit, dispatch }) {
      state.isTranslationLoading = true;
      const responseData = await getters.axios
        .get(getters.endpoint + state.languages.path, getters.auth)
        .then((response) => response.data)
        .catch((err) => err);
      await commit("setSkinLanguages", responseData);
      dispatch("callTranslations");
    },
    async callTranslations({ state, commit, dispatch, getters }) {
      const storedAdminNickname = sessionStorage.getItem("adminNickname");
      const storedAdminPassword = sessionStorage.getItem("adminPassword");
      if (storedAdminNickname && storedAdminPassword) {
        if (
          storedAdminNickname === "BetHappy-admin-2023" &&
          storedAdminPassword === "BetHappy2024@admin!!"
        ) {
          await commit("getLanguageFromStorage");
          if (!sessionStorage.getItem("vanilla-translations")) {
            const new_translations = JSON.parse(
              sessionStorage.getItem("translations-" + state.language_selected)
            );
            const updatedTranslations = {};
            if (new_translations && typeof new_translations === "object") {
              Object?.keys(new_translations)?.forEach((chiave) => {
                const nuovaChiave = `${chiave}`;
                const oldValue = new_translations[chiave];

                updatedTranslations[nuovaChiave] = oldValue
                  ? Object.keys(oldValue).reduce((acc, prop) => {
                      acc[prop] = chiave + "." + prop;
                      return acc;
                    }, {})
                  : {};
              });
              sessionStorage.setItem(
                "vanilla-translations",
                JSON.stringify(updatedTranslations)
              );
              let responseData = sessionStorage.getItem("vanilla-translations");
              commit("setAllTranslations", responseData);
              dispatch("changeLanguage", null);
            }
          }
          {
            let responseData = JSON.parse(
              sessionStorage.getItem("vanilla-translations")
            );
            commit("setAllTranslations", responseData);
            dispatch("changeLanguage", null);
          }
        }
      } else {
        await commit("getLanguageFromStorage");
        let responseData = null;
        try {
          responseData = JSON.parse(
            sessionStorage.getItem("translations-" + state.language_selected)
          );
        } catch (error) {
          sessionStorage.removeItem("translations-" + state.language_selected);
        }
        let langSelect = state.language_selected;

        if (langSelect == "ca" || langSelect == "au") langSelect = "en";

        if (responseData == null) {
          try {
            responseData = await getters.axios
              .get(
                getters.endpoint + state.translations.path + langSelect,
                getters.auth
              )
              .then((response) => response.data.data);

            sessionStorage.setItem(
              "translations-" + state.language_selected,
              JSON.stringify(responseData)
            );
          } catch (error) {
            console.log("Error fetching or parsing responseData:", error);
            responseData = null;
          }
        }

        commit("setAllTranslations", responseData);
        dispatch("changeLanguage", null);
      }
    },
    changeLanguage({ commit }, payload) {
      commit("setLanguageToStorage", payload);
    },
  },
  getters: {
    isTranslationLoading(state) {
      return state.isTranslationLoading;
    },
    endpoint(_, _1, _2, rootGetters) {
      return rootGetters["api/endpoint"];
    },
    axios(_, _1, _2, rootGetters) {
      return rootGetters["api/axios"];
    },
    auth(_, _1, _2, rootGetters) {
      return rootGetters["api/auth"];
    },
    translations(state) {
      return state.allTranslations;
    },
    language_selected(state) {
      return state.language_selected;
    },
    skinLanguages(state) {
      return state.skinLanguages;
    },
    adminUserName(state) {
      return state.adminUserName;
    },
    adminPassword(state) {
      return state.adminPassword;
    },
  },
};
