
export default {
    namespaced: true,
    state() {
        return {
            path: {
                banners: "cms/banners",
            },
            bannerToDisplay: [],
            isLoadingBanners: true,
            defaultBanner: "/images/banner-section/banner2.jpg",
            defaultBanner_mobile: "/images/banner-section/banner2_mobile.jpg",
        }
    },
    getters: {
        endpoint(_, _1, _2, rootGetters) {
            return rootGetters["api/endpoint"];
        },
        axios(_, _1, _2, rootGetters) {
            return rootGetters["api/axios"];
        },
        auth(_, _1, _2, rootGetters) {
            return rootGetters["api/auth"];
        },
        language(_, _1, _2, rootGetters) {
            return rootGetters["translations/language_selected"];
        },
        isLoadingBanners(state) {
            return state.isLoadingBanners;
        },
        tokenAuth(_, _1, _2, rootGetters) {
            return rootGetters['player/tokenAuth'];
        },
        playerInfo(_, _1, _2, rootGetters) {
            return rootGetters['player/playerInfo'];
        },
        bannerToDisplay(state) {
            return state.bannerToDisplay;
        },
        defaultBanner(state) {
            return state.defaultBanner;
        },
        defaultBanner_mobile(state) {
            return state.defaultBanner_mobile;
        }
    },
    mutations: {
        setBannerToDisplay(state, payload) {
            state.bannerToDisplay = payload
            state.isLoadingBanners = false;
        }
    },
    actions: {
        async callBanners({ state, getters, commit }, { slug, playerID, tag }) {
            let lang = localStorage.getItem('language') ?? 'en';
            state.isLoadingBanners = true;
            let addParam = '?';
            console.log(slug);

            if (lang == 'ca' || lang == 'au')
                lang = 'en';

            addParam += 'language=' + lang;
            let userPath = playerID ? '&user_id=' + playerID : '';
            addParam += userPath;

            let isMobile = 0;
            if (window.innerWidth <= 600)  
              isMobile = 1;

            addParam += '&is_mobile=' + isMobile;

            if (tag != null)
                addParam += "&tag[" + tag + "]=" + tag;


            const responseData = await getters.axios
                .get(getters.endpoint + state.path.banners + addParam, getters.tokenAuth)
                .then((response) => response.data.data)
                .catch((err) => err);

            const filteredBanners = responseData.filter(banner => banner.medias.length > 0);
            if (filteredBanners.length === 0) {
                const defaultBanner = [
                    {
                        medias: [
                            { full_url: getters.defaultBanner },
                            { full_url: getters.defaultBanner_mobile }
                        ]
                    }
                ];
                commit('setBannerToDisplay', defaultBanner);
            } else {
                commit('setBannerToDisplay', filteredBanners);
            }
        }
    },
}