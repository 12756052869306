<template>
  <transition name="route" mode="out-in">
    <PreLoader v-if="isPromotionsLoading || isTranslationLoading || isLoadingBanners" />
  </transition>
  <div>
    <Carousel :slugSection="slug" />
    <div class="container">
      <div class="promotion-detail-wrapper">
        <div class="btn-wrapper-comeBack">
          <button class="ty_bd backToHome" @click="redirectToPromotionPage">
            {{ translations?.buttons?.BackButton }}
          </button>
        </div>
        <h4 class="ty_h4 promotion-detail">
          {{ translations?.promotion?.PromotionDetail }}
        </h4>
        <div class="row">
          <div class="col-12">
            <div class="promotion-title ty_h5">
              {{ promotionsDetail?.name }}
            </div>
            <div class="promotion-period mt-3 ty_bd">
              <p v-if="promotionsDetail?.campaign?.valid_from">
                Start: {{ formatDate(promotionsDetail.campaign.valid_from) }}
              </p>
              <p v-if="promotionsDetail?.campaign?.expires_at">
                End: {{ formatDate(promotionsDetail.campaign.expires_at) }}
              </p>
            </div>
          </div>
          <div class="col-12 my-3">
            <span
              class="promotion-content ty_bd"
              v-html="promotionsDetail?.content"
            ></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PreLoader from "@/components/global/PreLoader.vue";
import Carousel from "@/components/ui/Carousel.vue";
import { mapGetters } from "vuex";
export default {
  name: "PromotionPage",
  components: {
    Carousel,
    PreLoader,
  },
  data() {
    return {
      allIsLoading: true,
      promotionsDetail: [],
      slug: "promotion",
      isLogged: localStorage.getItem("X19fYWNjZXNzX19f") ?? false,
    };
  },
  computed: {
    ...mapGetters("promotion", [
      "promotionsList",
      "promotion",
      "isPromotionsLoading",
      "isPromoIdLoading",
    ]),
    ...mapGetters("translations", ["translations", "isTranslationLoading"]),
    ...mapGetters("player", ["playerInfo"]),
    ...mapGetters("skinBanners", ["isLoadingBanners", "defaultBanner_mobile"]),
  },
  created() {
    let lang = localStorage.getItem("language");
    this.$router.push({ params: { lang: lang } });
    const promoSlug = this.$route.params.promotionId;
    this.$router.push({ params: { lang: lang } });
    this.$store.dispatch("promotion/callSkinPromotions", promoSlug).then(() => {
      this.promotionsDetail = this.promotion;
    });
    if (
      (this.playerInfo?.id == null || this.playerInfo?.id == undefined) &&
      this.isLogged
    ) {
      this.$store.dispatch("player/callUserInformation").then(() => {
        this.$store.dispatch("skinBanners/callBanners", {
          slug: this.slug,
          playerID: this.playerInfo?.id,
          tag: "promotions",
        });
      });
    } else if (this.isLogged && this.playerInfo && this.playerInfo?.id) {
      this.$store.dispatch("skinBanners/callBanners", {
        slug: this.slug,
        playerID: this.playerInfo?.id,
        tag: "promotions",
      });
    } else if (!this.isLogged) {
      this.$store.dispatch("skinBanners/callBanners", {
        slug: this.slug,
        playerID: "",
        tag: "promotions",
      });
    }
  },
  methods: {
    formatDate(dateString) {
      let date = new Date(dateString);
      let options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      };
      let formattedDate = date.toLocaleString("en-US", options);
      return formattedDate;
    },
    redirectToPromotionPage() {
      this.$store.dispatch("promotion/clearPromotion");
      this.$router.push({ name: "PromotionPage" });
    },
  },
};
</script>
