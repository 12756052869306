<template>
  <div class="dropDownMenuVuetify text-center">
    <v-menu>
      <template v-slot:activator="{ props }">
        <v-btn color="primary" v-bind="props">
          <img
            :src="`/images/flagicon/round-icon/flag-${language_selected}.png`"
            :alt="language_selected"
            class="flag-icon"
            style="width: 30px; height: 30px; object-fit: cover"
          />
        </v-btn>
      </template>
      <div class="pt-1"></div>
      <v-list>
        <v-list-item
          v-for="lang in filteredLanguages"
          :key="lang.id"
          :value="lang.code"
          @click="changeLanguage(lang.code)"
        >
          <v-row class="language-row">
            <v-list-item-avatar>
              <img
                loading="eager"
                :src="`/images/flagicon/round-icon/flag-${lang.code}.png`"
                :alt="lang.name"
                class="flag-icon"
              />
            </v-list-item-avatar>
          </v-row>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      lang: "",
      language_selected: localStorage.getItem("language") ?? "en",
      iconsLoaded: false,
      supportedLanguages: [
        { id: 1, name: "English", code: "en" },
        { id: 2, name: "Italiano", code: "it" },
      ],
    };
  },
  computed: {
    filteredLanguages() {
      return this.skinLanguages.filter(
        (lang) => lang.code !== this.language_selected
      );
    },
    ...mapGetters("translations", ["skinLanguages"]),
    ...mapGetters("player", ["playerInfoLoading", "playerInfo", "isLoading"]),
  },
  methods: {
    changeLanguage(newLanguage) {
      if (this.playerInfo?.id != null) {
        let body = {
          language: newLanguage,
          dob: this.playerInfo?.information?.dob,
        };
        let playerId = this.playerInfo.id;
        this.$store.dispatch("registration/updatePlayer", {
          body,
          playerId,
        });
      }
      if (newLanguage != this.language_selected) {
        this.$store.dispatch("translations/changeLanguage", newLanguage);
        const currentPath = this.$route.path;
        const pathName = this.$route.name;
        const langRegex = /\/[^/]+\//;

        let newLanguagePath;
        if (pathName === "HomePage") {
          newLanguagePath = "/" + newLanguage;
        } else {
          newLanguagePath = `/${newLanguage}${currentPath.replace(
            langRegex,
            "/"
          )}`;
        }
        if (this.$route.name !== "game-page") {
          this.$router.push(newLanguagePath);
        }
        setTimeout(() => {
          location.reload();
        }, 400);
      }
    },
  },
  mounted() {},
};
</script>

<style scoped>
.flag-icon {
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 50%;
}

.language-row {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
}

.dropDownMenuVuetify .bg-primary {
  /* background: rgb(25, 39, 63) !important; */
  background: transparent !important;
  margin-bottom: 20px;
  padding: 10px;
}

.v-menu > .v-overlay__content > .v-card,
.v-menu > .v-overlay__content > .v-sheet,
.v-menu > .v-overlay__content > .v-list {
  background: #132437 !important;
  border-radius: 16px;
  overflow: auto;
  height: 100%;
  transition: 0.3s all;
  box-shadow: 0px 5px 5px -3px var(--v-shadow-key-umbra-opacity, rgba(0, 0, 0, 0.2));
}
.v-menu > .v-overlay__content > .v-list .v-list-item:hover {
  background: #3d5d20 !important;
  transition: 0.3s all;
}

.v-menu > .v-overlay__content {
  padding-top: 3px !important;
}

.dropDownMenuVuetify .bg-primary {
  background: transparent !important;
  margin-bottom: 0px !important;
  padding: 0px 15px;
  border-radius: 999px;
  min-height: 40px;
}
.v-btn--active > .v-btn__overlay,
.v-btn[aria-haspopup="menu"][aria-expanded="true"] > .v-btn__overlay {
  opacity: 0 !important;
}

.bg-primary {
  background-color: transparent !important;
  color: transparent !important;
  box-shadow: none !important;
}

.v-row {
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 auto;
  margin: 0px !important;
}

.lang-name {
  color: white;
  font-weight: 500;
  line-height: 1.25;
}
</style>
