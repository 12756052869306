// import config from "/config.json";
// import VueCookies from "vue-cookies";
import { io } from 'socket.io-client';
import Swal from "sweetalert2";
export default {
  namespaced: true,
  state() {
    return {
      path: {
        login: "auth/login",
        logout: "auth/logout",
        password_recovery: "auth/password/recovery",
        information: "me?kyc=1&marketing=1&riskyCountry=1",
        edit_information: "me",
        documents: "me/documents",
        messages: "me/messages/",
        balance: "me/balance",
        reset_password: "auth/password/reset",
        check_token_status: "auth/status/",
      },
      token: null,
      kyc_token: null,
      temp_token: null,
      isTempTokenValid: false,
      errorMessage: false,
      basicKYCLevel: "BasicAmlLevel",
      selfieKYCLevel: "ID_SELFIE_2NDID_POA",
      playerInfo: {},
      playerDocuments: {},
      playerBalance: {},
      playerSurvey: {},
      playerStatistic: {},
      playerCampaign: {},
      isLoading: true,
      isLoggedCorrect: false,
      isCheckingToken: false,
      isPlayerLogged: false,
      playerInfoLoading: false,
      isSocketOn: false,
      errorLogin: true,
      playerAml: {},
      status_reason: "Requested by user",
      selectedDocumentToUpload: { value: null, default: "passport" },
      tokenChekResponse: {},
      newsletterOptOut: "",
      isTokenInvalidOptOut: null,
    };
  },
  getters: {
    token(state) {
      return state.token;
    },
    temp_token(state) {
      return state.temp_token;
    },
    kyc_token(state) {
      return state.kyc_token;
    },
    isTempTokenValid(state) {
      return state.isTempTokenValid;
    },
    translations(_, _1, _2, rootGetters) {
      return rootGetters["translations/translations"];
    },
    skinId(_, _1, _2, rootGetters) {
      return rootGetters['skinSettings/skinId'];
    },
    tokenAuth(_, getters) {
      return {
        headers: {
          authorization: getters.auth.headers.authorization,
          "user-token": sessionStorage.getItem("user-token"),
        },
      };
    },
    tempTokenAuth(_, getters) {
      return {
        headers: {
          authorization: getters.auth.headers.authorization,
          token: sessionStorage.getItem("user-token"),
        },
      };
    },
    isAuthenticated(state) {
      return !!state.token;
    },
    isLoggedCorrect(state) {
      return state.isLoggedCorrect;
    },
    endpoint(_, _1, _2, rootGetters) {
      return rootGetters["api/endpoint"];
    },
    axios(_, _1, _2, rootGetters) {
      return rootGetters["api/axios"];
    },
    auth(_, _1, _2, rootGetters) {
      return rootGetters["api/auth"];
    },
    swal(_, _1, _2, rootGetters) {
      return rootGetters.swal;
    },
    balance(state) {
      return state.playerBalance;
    },
    playerInfo(state) {
      return state.playerInfo;
    },
    playerInfoLoading(state) {
      return state.playerInfoLoading;
    },
    playerCampaign(state) {
      return state.playerCampaign;
    },
    statisticLimits(state) {
      return state.statisticLimits;
    },
    newsletterOptOut(state) {
      return state.newsletterOptOut;
    },
    statisticExclusions(state) {
      return state.statisticExclusions;
    },
    playerSurvey(state) {
      return state.playerSurvey;
    },
    playerDocuments(state) {
      return state.playerDocuments;
    },
    selectedDocumentToUpload(state) {
      return state.selectedDocumentToUpload.value;
    },
    defaultSelectedDocumentToUpload(state) {
      return state.selectedDocumentToUpload.default;
    },
    isPlayerLoading(state) {
      return state.isLoading;
    },
    isCheckingToken(state) {
      return state.isCheckingToken;
    },
    basicKYCLevel(state) {
      return state.basicKYCLevel;
    },
    selfieKYCLevel(state) {
      return state.selfieKYCLevel;
    },
    amlLevel(state) {
      return state.playerAml;
    },
    errorMessage(state) {
      return state.errorMessage;
    },
    errorLogin(state) {
      return state.errorLogin;
    },
    tokenChekResponse(state) {
      return state.tokenChekResponse;
    },
    isTokenInvalidOptOut(state) {
      return state.isTokenInvalidOptOut;
    },
    isSocketOn(state) {
      return state.isSocketOn;
    }
  },
  mutations: {
    playerLoadingCompleted(state) {
      state.isLoading = false;
    },
    loginPlayer(state, payload) {
      localStorage.setItem(payload.access, payload.codecToken);
      // payload access è X19fYWNjZXNzX19f
      state.token = decodeURIComponent(window.atob(payload.codecToken));
    },
    setIsLoggedCorrect(state, value) {
      state.isLoggedCorrect = value;
    },
    logoutPlayer(state, payload) {
      const access = window.btoa(encodeURIComponent("___access___"));
      localStorage.removeItem(access);
      sessionStorage.removeItem("user-token");
      state.token = payload;
      setTimeout(() => {
        window.location.href = "/";
      }, 1500);
    },
    setTokenOnLoad(state) {
      const access = window.btoa(encodeURIComponent("___access___"));
      if (
        localStorage.getItem(access) != null &&
        localStorage.getItem(access) != undefined
      ) {
        const decodecToken = decodeURIComponent(
          window.atob(localStorage.getItem(access))
        );

        state.token = decodecToken;
      }
    },
    setTempToken(state, payload) {
      state.temp_token = payload;
    },
    checkTempToken(state, payload) {
      state.isCheckingToken = false;
      state.isTempTokenValid = payload;
    },
    setUserInformation(state, payload) {
      state.playerInfo = payload.data;
      state.playerBalance = payload.data.balance;
      state.isLoading = false;
      state.playerInfoLoading = true;


    },
    setPlayerBalance(state, payload) {
      state.playerInfo.balance = payload;
    },
    setKycToken(state, payload) {
      state.kyc_token = payload.token;
    },
    setStatisticLimits(state, payload) {
      state.statisticLimits = payload.data;
    },
    setStatisticExclusions(state, payload) {
      state.statisticExclusions = payload.data;
    },
    setUserDocuments(state, payload) {
      state.playerDocuments = payload;
      if (Object.keys(payload.id_card).length > 0) {
        state.playerDocuments["id_card"] = [payload.id_card];
      }
      if (Object.keys(payload.passport).length > 0) {
        state.playerDocuments["passport"] = [payload.passport];
      }
      if (Object.keys(payload.driver_license).length > 0) {
        state.playerDocuments["driver_license"] = [payload.driver_license];
      }
    },
    setUserSurvey(state, payload) {
      state.playerSurvey = payload;
    },
    setUserAml(state, payload) {
      state.playerAml = payload;
    },
    changePlayerPassword(payload) {
      if (payload.status === 401) {
        alert("Your session is expire!");
        location.reload("/");
      } else if (payload.status === 422) {
        alert("Current password doesn't match!");
      } else if (payload.status === 200) {
        alert("Password changed successfully!");
      }
    },
    sendDocuments(_, payload) {
      if (payload) {
        return;
      }
      window.location.reload();
    },
    messageHasBeenRed(_, payload) {
      if (payload.data == "") {
        return;
      }
    },
    setErrorLogin(state, value) {
      state.errorLogin = value;
    },
    setTokenCheckResponse(state, value) {
      state.tokenChekResponse = value;
    },
    setIsMessageHasBeenRed(_, payload) {
      if (payload.data == "") {
        return;
      }
    },
    checkOptOutTokenCommit(state, payload) {
      state.optOutToken = payload;
    },
    newsletterOptOutCommit(state, payload) {
      state.newsletterOptOut = payload;
      console.log(state.newsletterOptOut);
    },
    setIsTokenInvalidOptOut(state, value) {
      state.isTokenInvalidOptOut = value;
    },
    setIsSocketOn(state, value) {
      state.isSocketOn = value;
    },
    setCampaign(state, payload) {
      // console.log('PAYLOAD______', payload);
      state.playerCampaign = payload;

      //   if (state.playerCampaign.status !== 'partial')
      //   location.reload();
    },
  },
  actions: {
    async callingAboutUser({ dispatch }) {
      await dispatch("callUserInformation");
      dispatch("callPlayerBalance", true);
    },
    async websocketLogin({ dispatch, getters, commit }) {

      let endpoint = process.env.VUE_APP_WEBSOCKETURL ?? "ws://localhost:4000";
      const socket = io(endpoint);
      socket.on('connect_error', (error) => {
        console.error(`Socket connection error: ${error.message}`);
        commit('setIsSocketOn', false)
        // dispatch('syncMessage');
        return;
      });

      socket.on('connect', () => {
        commit('setIsSocketOn', true)

        // Store the client socket ID by playerId and join a room by skin refID (if it doesn't exist already) on the webSocket server
        socket.emit('create', { playerId: getters.playerInfo.id, refId: process.env.VUE_APP_REFID });
        const interval = 10000;
        setTimeout(() => {
          if (getters.isAuthenticated) {
            dispatch('callMessage');
          }
        }, interval);
      });

      socket.on('message', (data) => {

        let message = data;
        console.log(message);
        if (message.type == "campaignReached") {
          let confirmButtonTextAlert = getters.translations.buttons.refresh_balance ?? 'Refresh Balance';
          let denyButtonTextAlert = getters.translations.buttons.return_website ?? 'Return to website';

          let divElement = '<div style="white-space: pre-line">';
          Swal.fire({
            title: data.subject ? data.subject : "",
            confirmButtonText: confirmButtonTextAlert,
            showDenyButton: true,
            denyButtonText: denyButtonTextAlert,
            html: `${divElement}${message.body}</div>`,
            imageUrl: message.image ? message.image : undefined,
            imageWidth: 400,
            imageHeight: 200,
            imageAlt: message.subject,
          }).then((result) => {

            dispatch("player/messageHasBeenRed", message.id, {
              root: true,
            }).then(() => {
            });

            if (result.isConfirmed)
              location.reload();
            else
              location.href = "/";



            if (message.type !== "realityCheck") {
              dispatch("player/syncMessage", {}, { root: true });
            }
          });

        }
        else {
          Swal.fire({
            icon: "warning",
            text: data.data.text,
          });
        }


      });

      socket.on('campaign', (data) => {

        commit('setCampaign', data);
      });
    },

    async login({ state, getters, dispatch, commit }, payload) {
      let body = {
        username: payload.username,
        password: payload.password,
      };
      const responseData = await getters.axios
        .post(getters.endpoint + state.path.login, body, getters.auth)
        .then((response) => {
          return response.data;
        })
        .catch((err) => err);

      if (Object.prototype.hasOwnProperty.call(responseData, "token")) {
        sessionStorage.setItem("user-token", responseData.token);
        //Controllo se è stato creato un token dopo login avvenuto con successo
        commit("setErrorLogin", false);
        commit("setIsLoggedCorrect", true);
        await commit("setTokenOnLoad", responseData.token);
        await dispatch("encodeLogin", responseData.token);
        // await dispatch("callUserInformation");
      } else {
        commit("setErrorLogin", true);
      }
    },

    async logout({ commit, state, getters, dispatch }) {
      let config = {
        path: state.path.logout,
      };
      const responseData = await getters.axios
        .delete(getters.endpoint + config.path, getters.tokenAuth)
        .then((response) => {
          return response.data;
        })
        .catch((err) => {
          console.log(err);
          return;
        });
      commit("logoutPlayer", responseData);
      console.log(dispatch);
      // dispatch("alert/logAlert", responseData, { root: true });
    },

    async setTokenOnLoad({ state, getters, commit, dispatch }) {
      state.isLoading = true;
      dispatch("callPlayerBalance", false);
      commit("setTokenOnLoad");
      if (getters.isAuthenticated) {
        await dispatch("callingAboutUser");
      } else {
        commit("playerLoadingCompleted");
      }
    },

    encodeLogin({ commit }, payload) {
      const access = window.btoa(encodeURIComponent("___access___"));
      const codecToken = window.btoa(encodeURIComponent(payload));
      commit("loginPlayer", { access, codecToken });
    },

    async callUserInformation({ commit, state, getters, dispatch }) {
      const responseData = await getters.axios
        .get(getters.endpoint + state.path.information, getters.tokenAuth)
        .then((response) => {
          return response.data;
        })
        .catch((err) => {
          console.log(err);
          commit("logoutPlayer", "");
          return;
        });
      commit("setUserInformation", responseData);
      dispatch("syncMessage");
    },

    async callPlayerBalance({ state, getters, commit }, status) {
      const config = {
        path: state.path.balance,
      };
      const makeCallBalance = async () => {
        const responseData = await getters.axios
          .get(getters.endpoint + config.path, getters.tokenAuth)
          .then((response) => response.data.data)
          .catch((err) => err);
        commit("setPlayerBalance", responseData);
      };
      const startToCallBalance = setInterval(makeCallBalance, 5000);
      if (!status) {
        clearInterval(startToCallBalance);
      }
    },

    async callUserDocuments({ commit, state, getters }) {
      state.path.documents = "me/all-documents";
      let config = {
        path: state.path.documents,
      };

      const responseData = await getters.axios
        .get(getters.endpoint + config.path, getters.tokenAuth)
        .then((response) => response.data.data)
        .catch((err) => {
          console.log(err);
          return;
        });
      commit("setUserDocuments", responseData);
    },

    async changePlayerPassword({ commit, state, getters }, payload) {
      let config = {
        path: state.path.edit_information,
      };
      let body = {
        password_current: payload.current,
        password: payload.new,
        password_confirmation: payload.confirm,
      };
      const responseData = await getters.axios
        .put(getters.endpoint + config.path, body, getters.tokenAuth)
        .then((response) => {
          return response;
        })
        .catch((err) => err.response);
      commit("changePlayerPassword", responseData);
      if (responseData.status === 401) {
        alert("Something went wrong!");
        location.reload();
      } else if (responseData.status === 422) {
        alert("Current password doesn't match");
      } else if (responseData.status === 200) {
        alert("Password updated successfully");
        location.reload();
      }
    },

    async playerRemoveChanges({ commit, state, getters }, id) {
      let config = {
        path: state.path.removeChanges + "" + id,
      };

      await getters.axios
        .delete(getters.endpoint + config.path, getters.tokenAuth)
        .then((response) => response)
        .catch((err) => err);

      return commit;
    },

    async checkTempToken({ state, getters, commit, dispatch }, payload) {
      state.isCheckingToken = true;
      let config = {
        path: state.path.check_token_status,
      };
      const responseData = await getters.axios
        .get(getters.endpoint + config.path + payload, getters.auth)
        .then((response) => response.data)
        .catch((err) => err);
      commit("checkTempToken", responseData.is_valid);
      if (responseData.is_valid) {
        dispatch("setTempToken", payload);
      }
      return responseData;
    },

    setTempToken(context, payload) {
      context.commit("setTempToken", payload);
    },

    async changeForgotPassword({ commit, state, getters }, payload) {
      let config = {
        path: state.path.reset_password,
      };
      let body = {
        email: payload.email,
        password: payload.new,
        password_confirmation: payload.confirm,
        token: getters.temp_token,
      };
      const responseData = await getters.axios
        .put(getters.endpoint + config.path, body, getters.auth)
        .then((response) => {
          return response.status;
        })
        .catch((err) => err.response);
      // commit('changeForgotPassword', responseData);
      let alertText = {
        message:
          getters.translations?.login?.passwordChanged ?? "Password Change",
        path: "/login",
      };
      if (responseData != 204) {
        alertText.message =
          getters.translations?.login?.errorContactSupport ??
          "Something went wrong.Please contact our support.";
      }
      // commit('alert/simple_alert', alertText, { root: true });
      console.log(commit);
      alert(alertText.message);
    },

    async sendDocuments({ state, getters, commit }, { formData, contentType }) {
      state.path.documents = "me/documents";
      const config = {
        path: state.path.documents,
      };
      const { formContent } = contentType;
      const headers = {
        authorization: getters.auth.headers.authorization,
        "user-token": getters.token,
        formContent,
      };
      const responseData = await getters.axios
        .post(getters.endpoint + config.path, formData, { headers })
        .then((response) => {
          if (response.status === 200) {
            return response;
          }
        })
        .catch((err) => {
          err = "Something went wrong!";
          Swal.fire({
            icon: "error",
            text:
              this.translations?.body?.Error_doc_something_went_wrong ?? err,
          });
        });
      let message = null;
      if (
        responseData &&
        Object.prototype.hasOwnProperty.call(responseData, "response")
      ) {
        const obj = responseData.response.data;
        message = obj[Object.keys(obj)[0]];
      } else {
        message = "Document uploaded";
        Swal.fire({
          icon: "success",
          text: this.translations?.body?.Success_doc_uploaded ?? message,
        });
      }
      commit("sendDocuments", responseData);
    },

    async checkTokenToValidateEMail({ state, getters, commit }, token) {
      const responseData = await getters.axios.get(
        getters.endpoint + state.path.check_token_status + token,
        getters.tokenAuth
      );
      await commit("setTokenCheckResponse", responseData.data);
    },

    async callMessage({ state, getters, dispatch }) {
      const pathSuffix = "readone";
      let openSweet = document.getElementsByClassName("swal2-container").length; // check if any SweetAlert popup is open

      if (!openSweet) {
        // lock popup if another one is displayed
        const responseData = await getters.axios.get(
          getters.endpoint + state.path.messages + pathSuffix,
          getters.tokenAuth
        );



        if (Object.prototype.hasOwnProperty.call(responseData, "data")) {
          if (responseData.data.length > 0) {
            const imageSrc = responseData.data[0].image;

            const imageStyle = imageSrc
              ? `
        max-width: 400px;
        max-height: 200px;
        object-fit: cover;
      `
              : "";

            const imageElement = imageSrc
              ? `<img src="https://storage.googleapis.com/stage-camasy-images/${imageSrc}" style="${imageStyle}" alt="Message Image">`
              : "";

            const swalResult = await Swal.fire({
              html: `
        <div class="d-flex justify-content-center align-items-center w-100 flex-column"> ${imageElement}</div>   
        <p class="text-left">${responseData.data[0].body}</p>
        <p class="text-left"><strong>${getters?.translations?.body?.alert_msg_sent_at ?? "Message sent at:"
                }</strong> ${responseData.data[0].created_at}</p>
  
      `,
              icon: "info",
              showDenyButton: true,
              denyButtonColor: "#a78300",
              confirmButtonColor: "#149102",
              confirmButtonText:
                getters?.translations?.body?.confirm_message_sent ?? "OK",
              denyButtonText:
                getters?.translations?.body?.show_later_sent_message ??
                "Show me later",
              allowOutsideClick: false,
            });

            if (swalResult.isConfirmed) {
              dispatch("player/messageHasBeenRed", responseData.data[0].id, {
                root: true,
              });
            } else if (swalResult.isDenied) {
              dispatch("showAgainMessages");
            }
          }
        }
      }
    },
    syncMessage({ dispatch }) {
      setInterval(function () {
        dispatch("callMessage");
      }, 5000);
    },
    showAgainMessages({ dispatch }) {
      let countdown = 300000; //5 min

      function runCountdown() {
        if (countdown === 0) {
          dispatch("callMessage");
        } else {
          countdown--;
          setTimeout(runCountdown, 1000);
        }
      }
      runCountdown();
    },
    async messageHasBeenRed({ state, getters, commit }, payload) {
      const responseData = await getters.axios
        .get(
          getters.endpoint + state.path.messages + payload,
          getters.tokenAuth
        )
        .then((response) => response)
        .catch((err) => err);
      commit("setIsMessageHasBeenRed", responseData);
    },
    async editPlayerLimits({ commit, state, getters }, payload) {
      let config = {
        path: state.path.edit_information,
      };

      let body = {
        max_daily_deposit: payload.max_daily_deposit,
        max_weekly_deposit: payload.max_weekly_deposit,
        max_monthly_deposit: payload.max_monthly_deposit,
        max_daily_bet: payload.max_daily_bet,
        max_daily_loss: payload.max_daily_loss,
      };

      let type = [
        "max_daily_deposit",
        "max_weekly_deposit",
        "max_monthly_deposit",
      ];
      let i, old, field;
      for (i = 0; i < type.length; i++) {
        field = type[i];
        if (field != "max_daily_deposit")
          old =
            state.playerInfo.betLimits[field] != null
              ? state.playerInfo.betLimits[field]
              : 0;
        else
          old = state.playerInfo.limits[field]
            ? state.playerInfo.limits[field]
            : 0;
        console.log(old);
        body.field = field;

        await getters.axios
          .put(getters.endpoint + config.path, body, getters.tokenAuth)
          .then((response) => {
            return response;
          });
        return;
      }

      console.log(commit("setPlayerLimits"));
    },
    async supportNotification({ commit, getters }, payload) {
      let config = {
        path: "notifications/messages/custom_email",
      };
      let body = {
        emails: payload.emails,
        body: payload.content_body,
        subject: payload.subject,
        ref_id: payload.ref_id,
      };
      await getters.axios
        .post(getters.endpoint + config.path, body, getters.tokenAuth)
        .then((response) => {
          return response.data;
        })
        .catch((err) => {
          console.log(commit);
          console.log(err);
          return;
        });
    },
    async playerSelfExclusion({ commit, state, getters }, payload) {
      let time = payload.time;

      if (payload.status_reason) state.status_reason = payload.status_reason;

      let config = {
        path: state.path.edit_information,
      };
      let body = {
        statistic: true,
        status_reason: state.status_reason,
        status_expires_at: time,
      };

      const responseData = await getters.axios
        .put(getters.endpoint + config.path, body, getters.tokenAuth)
        .then((response) => {
          return response;
        })
        .catch((err) => {
          console.log(err);
          return;
        });

      commit("setExclusion", { responseData, time });
    },
    async checkOptOutToken({ state, getters, commit }, payload) {
      let config = {
        path: "notifications/newsletter/token/opt-out",
      };

      const responseData = await getters.axios
        .get(getters.endpoint + config.path, {
          params: payload,
        })
        .then((response) => {
          state.optOutToken = response.data.is_valid;
          return response.data.is_valid;
        })
        .catch((err) => {
          commit("setIsTokenInvalidOptOut", true);
          console.log(err);
          Swal.fire({
            icon: "error",
            text:
              getters.translations?.body?.error_check_if_token_is_still_valid ??
              "Check if you link is still valid, and try again.",
            showConfirmButton: false,
          }).then(() => {
            window.location.href = "/";
          });
        });
      commit("setIsTokenInvalidOptOut", false);
      commit("checkOptOutTokenCommit", responseData);
    },
    async newsletterOptOutAction({ state, getters, commit }, payload) {
      console.log(state);
      let config = {
        path: "notifications/newsletter/opt-out",
      };

      const responseData = await getters.axios
        .post(getters.endpoint + config.path, payload)
        .then((response) => {
          return response.data.message;
        })
        .catch(
          (err) => err.response.data.message
          // Swal.fire({
          //   icon: "warning",
          //   text:
          //     getters.translations?.body?.error_check_if_token_is_still_valid ??
          //     "Check if you link is still valid, and try again.",
          //   showConfirmButton: false,
          // }).then(() => {
          //   window.location.href = "/";
          // })
        );
      commit("newsletterOptOutCommit", responseData);
    },
  },
};
