<template>
  <div class="preloader">
    <img src="/images/logo.png" alt="" loading="eager" />
  </div>
</template>

<script>
export default {
  name: "PreLoader",
  components: {},

  data() {
    return {};
  },
  mounted() {},
  created() {},
};
</script>
