import { createRouter, createWebHistory } from "vue-router";

import HomePage from "../views/HomePage.vue";
import CasinoPage from "../views/CasinoPage.vue";
import LiveCasinoPage from "../views/LiveCasinoPage.vue";
import PromotionPage from "../views/PromotionPage.vue";
import PromotionDetail from "../views/PromotionDetail.vue";
import GamePage from "../views/GamePage.vue";
import DashBoardPage from "../views/DashBoardPages/DashBoard.vue";
import Profile from "../views/DashBoardPages/Profile.vue";
import AccountBalance from "../views/DashBoardPages/AccountBalance.vue";
import Reports from "../views/DashBoardPages/Reports.vue";
import MyInbox from "../views/DashBoardPages/MyInbox.vue";
import MyBonus from "../views/DashBoardPages/MyBonus.vue";
import RecoveryPasswordPage from "../views/RecoveryPasswordPage.vue";
import PasswordResetPage from "../views/PasswordResetPage.vue";
import StaticPage from "../views/StaticPage.vue";
import Page404Error from "../views/Page404Error.vue";
import TransactionStatus from "../views/TransactionStatus.vue";
import BankingPage from "../views/BankingPage";
import SupportPage from "../views/SupportPage.vue";
import VerifyEmail from "../views/VerifyEmail.vue";
import UnsubscribeNewsLetters from "../views/UnsubscribeNewsLetters.vue";
import LoginPageAdmin from "../views/LoginPageAdmin.vue";
//
const skinLanguages = ["de", "en", "pt", "tr", "es", "fr", "ar", "au", "ca"];
const defaultLang = "en";

const baseRoute = {
  path: "/:lang?/",
  beforeEnter: validateLanguage,
};

function validateLanguage(to, _, next) {
  const lang = to.params.lang || defaultLang;
  const playerLogged = localStorage.getItem("X19fYWNjZXNzX19f") != null;
  let pageAccess = to.meta.restrictedPage;
  switch (pageAccess) {
    case true:
      if (playerLogged) {
        next();
      } else {
        const pathCercato = to.path.split(`/${lang}/`)[1];
        const modifiedPath = `/${lang}/${pathCercato}`;
        to.path = modifiedPath;
        if (to.meta.restrictedPage) {
          //salvo nel sessionStorage il path cercato
          sessionStorage.setItem("savedPath", to.fullPath);
          window.location.href = `/${lang}/`;
        }
        break;
      }
      break;
    case false:
      if (playerLogged) {
        next();
      } else {
        next();
      }
      break;
    default:
      next();
  }
}

const routes = [
  {
    // path: "/:lang?",
    ...baseRoute,
    name: "HomePage",
    component: HomePage,
  },
  {
    ...baseRoute,
    path: "/:lang?/casino",
    name: "CasinoPage",
    component: CasinoPage,
  },
  {
    ...baseRoute,
    path: "/:lang?/live-casino",
    name: "LiveCasinoPage",
    component: LiveCasinoPage,
  },
  {
    ...baseRoute,
    path: "/:lang?/promotion",
    name: "PromotionPage",
    component: PromotionPage,
  },
  {
    path: "/:lang?/promotion/:promotionId",
    name: "PromotionDetail",
    component: PromotionDetail,
  },
  {
    ...baseRoute,
    path: "/game/play/:gameId/:providerId",
    props: true,
    name: "game-page",
    component: GamePage,
  },
  {
    ...baseRoute,
    path: "/:lang?/recover-password",
    name: "recover-password",
    component: RecoveryPasswordPage,
  },
  {
    ...baseRoute,
    path: "/:lang?/dashboard",
    name: "dashboard",
    component: DashBoardPage,
    meta: { restrictedPage: true },
  },
  {
    ...baseRoute,
    path: "/:lang?/profile",
    name: "profile",
    component: Profile,
    beforeEnter: validateLanguage,
    meta: { restrictedPage: true },
  },
  {
    ...baseRoute,
    path: "/:lang?/account-balance",
    name: "account-balance",
    component: AccountBalance,
    beforeEnter: validateLanguage,
    meta: { restrictedPage: true },
  },
  {
    ...baseRoute,
    path: "/:lang?/my-inbox",
    name: "my-inbox",
    component: MyInbox,
    beforeEnter: validateLanguage,
    meta: { restrictedPage: true },
  },
  {
    ...baseRoute,
    path: "/:lang?/reports",
    name: "reports",
    component: Reports,
    beforeEnter: validateLanguage,
    meta: { restrictedPage: true },
  },
  {
    ...baseRoute,
    path: "/:lang?/my-bonus",
    name: "my-bonus",
    component: MyBonus,
    beforeEnter: validateLanguage,
    meta: { restrictedPage: true },
  },
  {
    ...baseRoute,
    path: "/:lang?/:page",
    name: "static-page",
    component: StaticPage,
  },
  {
    ...baseRoute,
    path: "/:lang?/banking",
    name: "banking-page",
    component: BankingPage,
  },
  {
    ...baseRoute,
    path: "/:lang?/password/reset/:temp_token",
    name: "password-reset",
    component: PasswordResetPage,
  },
  {
    ...baseRoute,
    path: "/:lang?/support",
    name: "contact-page",
    component: SupportPage,
  },
  {
    ...baseRoute,
    path: "/:lang?/transactions/:transactionId",
    name: "transactionStatus",
    component: TransactionStatus,
  },
  {
    path: "/:lang?/verify/:token",
    name: "verify-email",
    component: VerifyEmail,
  },
  {
    path: "/:lang?/unsubscribe/:token",
    name: "unsubscribe-page",
    component: UnsubscribeNewsLetters,
  },
  {
    path: "/:lang?/bethappy2024_admin_access_page_Xr4Enhfd45w",
    name: "login-admin-page",
    component: LoginPageAdmin,
  },
  {
    ...baseRoute,
    path: "/:lang?/:path?",
    name: "Page404Error",
    component: Page404Error,
    beforeEnter: (to, from, next) => {
      if (skinLanguages.includes(to.params.path) && !to.params.lang) {
        next({ name: "HomePage", params: { lang: to.params.path } });
      } else {
        next();
      }
    },
  },
  {
    path: "/:NotValid(.*)",
    redirect: { name: "Page404Error" },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  mode: "history",
  scrollBehavior() {
    return { top: 0 };
  },
});

export default router;
// {
//   ...baseRoute,
//   path: '/:lang?/profile',
//   name: 'profile',
//   component: Profile
// },
// {
//   ...baseRoute,
//   path: '/:lang?/account-balance',
//   name: 'account-balance',
//   component: AccountBalance
// },
// {
//   ...baseRoute,
//   path: '/:lang?/reports',
//   name: 'reports',
//   component: Reports
// },
// {
//   ...baseRoute,
//   path: '/:lang?/my-inbox',
//   name: 'my-inbox',
//   component: MyInbox
// },
