<template>
  <PreLoader
    v-if="
      (isAllProvidersLoading ||
        isTranslationLoading ||
        !playerInfoLoading ||
        !allInformationIsLoading) &&
      isTimer
    "
  />
  <DashBoard />
  <div class="container">
    <div class="row mx-auto justify-content-center">
      <div class="col-12 col-lg-10">
        <div class="dashboard-all">
          <div
            v-if="!playerInfo.is_verified"
            class="not-verified d-flex align-items-center"
          >
            <svg
              class="icon me-2"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0" />
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <g id="SVGRepo_iconCarrier">
                <path
                  d="M6.30928 9C8.59494 5 9.96832 3 12 3C14.3107 3 15.7699 5.58716 18.6883 10.7615L19.0519 11.4063C21.4771 15.7061 22.6897 17.856 21.5937 19.428C20.4978 21 17.7864 21 12.3637 21H11.6363C6.21356 21 3.50217 21 2.40626 19.428C1.45498 18.0635 2.24306 16.2635 4.05373 13"
                  stroke-width="1.5"
                  stroke-linecap="round"
                />
                <path d="M12 8V13" stroke-width="1.5" stroke-linecap="round" />
                <circle cx="12" cy="16" r="1" />
              </g>
            </svg>
            <span>{{
              translations?.myprofile?.Account_not_yet_verified ??
              "Account not yet verified"
            }}</span>
          </div>
          <div class="row mx-0">
            <div class="col-12 col-lg-6 mb-3">
              <h2 class="dashboard-title ty_h3">
                {{ translations?.myprofile?.BasicInfo ?? "Basic Info" }}
              </h2>
              <div class="mb-2">
                <span class="field ty_bs">
                  {{ translations?.myprofile?.Username ?? "Username" }}:</span
                >
                <span class="value ty_text100-medium">{{ playerInfo?.username }}</span>
              </div>
              <!-- <div class="mb-2">
                <span class="field ty_bs">
                  {{ translations?.myprofile?.Email ?? "Email" }}:</span
                >
                <span class="value ty_text100-medium">{{ playerInfo?.email }}</span>
              </div> -->
              <div class="mb-2">
                <span class="field ty_bs">
                  {{ translations?.myprofile?.MobileNumber ?? "Mobile number" }}:</span
                >
                <span class="value ty_text100-medium">{{ playerInfo?.mobile }}</span>
              </div>
              <div class="mb-2">
                <span class="field ty_bs">
                  {{ translations?.myprofile?.Name ?? "Name" }}:</span
                >
                <span class="value ty_text100-medium">{{ playerInfo?.name }}</span>
              </div>
              <div class="mb-2">
                <span class="field ty_bs">
                  {{ translations?.myprofile?.Gender ?? "Gender" }}:</span
                >
                <span class="value ty_text100-medium">{{
                  playerInfo?.information?.gender
                }}</span>
              </div>
              <div class="mb-2">
                <span class="field ty_bs">
                  {{ translations?.myprofile?.DateOfBirth ?? "Date of Birth" }}:</span
                >
                <span class="value ty_text100-medium">{{
                  playerInfo?.information?.dob
                }}</span>
              </div>
              <div
                class="mb-2 d-flex justify-content-between align-items-end flex-row flex-wrap w-100"
              >
                <div v-if="playerInfo?.email_verified_at">
                  <span class="field ty_bs"
                    >{{ translations?.myprofile?.Email ?? "Email" }}:</span
                  >
                  <span
                    class="value ty_text100-medium"
                    style="font-size: clamp(12px, 5vw, 16px)"
                    >{{ playerInfo?.email }}
                    <span class="verified_account-container"
                      ><img
                        title="Account verified"
                        class="verified_account"
                        src="../../../public/images/verify.png"
                      /> </span
                  ></span>
                </div>
                <div class="my-1" v-else>
                  <label class="field ty_bs mb-1"
                    >{{ translations?.myprofile?.Email ?? "Email" }}:</label
                  >
                  <input
                    class="dashboard-input"
                    type="text"
                    v-model="playerEmail"
                    :placeholder="playerInfo?.email"
                  />
                </div>
                <button
                  class="btn my-1 verify-email-btn"
                  @click="verifyMail"
                  :disabled="isTimerMail"
                  v-if="!playerInfo?.email_verified_at"
                >
                  {{ translations?.myprofile?.verify_mail_btn ?? "Verify email" }}
                </button>
              </div>
              <small v-if="isTimerMail">
                {{
                  translations?.myprofile?.you_can_try_new_request ??
                  " You can try with a new request in"
                }}
                {{ countdownMail }} {{ translations?.myprofile?.sec ?? "sec" }}
              </small>
            </div>
            <div class="col-12 col-lg-6 mb-3">
              <h2 class="dashboard-title ty_h3">
                {{ translations?.myprofile?.ResidencyInfo ?? "Residency info" }}
              </h2>
              <div class="mb-2">
                <span class="field ty_bs">
                  {{
                    translations?.myprofile?.CountryOfResidence ?? "Country of Residence"
                  }}:</span
                >
                <span class="value ty_text100-medium">{{
                  playerInfo?.information?.country
                }}</span>
              </div>
              <div class="mb-2" v-if="playerInfo?.information?.birth_country">
                <span class="field ty_bs">
                  {{
                    translations?.myprofile?.StateOfResidence ?? "State of Residence"
                  }}:</span
                >
                <span class="value ty_text100-medium">{{
                  playerInfo?.information?.birth_country
                }}</span>
              </div>
              <div class="mb-2">
                <span class="field ty_bs">
                  {{
                    translations?.myprofile?.CityOfResidence ?? "City of Residence"
                  }}:</span
                >
                <span class="value ty_text100-medium">{{
                  playerInfo?.information?.city
                }}</span>
              </div>
              <div class="mb-2">
                <span class="field ty_bs">
                  {{ translations?.myprofile?.Address ?? "Address" }}:</span
                >
                <span class="value ty_text100-medium">{{
                  `${playerInfo?.information?.address_line_1 ?? ""} ${
                    playerInfo?.information?.address_line_2 ?? ""
                  }`
                }}</span>
              </div>
              <div class="mb-2">
                <span class="field ty_bs">
                  {{ translations?.myprofile?.Player_ID ?? "Player ID" }}:</span
                >
                <span class="value ty_text100-medium">{{ playerInfo?.id }}</span>
              </div>
              <div class="mb-2">
                <span class="field ty_bs">
                  {{ translations?.myprofile?.PostalCode ?? "Postal Code" }}:</span
                >
                <span class="value ty_text100-medium">{{
                  playerInfo?.information?.postal_code
                }}</span>
              </div>
            </div>
          </div>
          <div class="row mx-0">
            <div class="logout-btn-container">
              <button
                @click="logoutPlayer"
                class="dashboard-button-logout position-relative"
                style="left: 0px; padding: 5px 20px; transform: translate(0%, 0%)"
              >
                <svg
                  class="logout-dashboard-icon"
                  viewBox="-4 2 28 28"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>logout</title>
                  <path
                    d="M0 9.875v12.219c0 1.125 0.469 2.125 1.219 2.906 0.75 0.75 1.719 1.156 2.844 1.156h6.125v-2.531h-6.125c-0.844 0-1.5-0.688-1.5-1.531v-12.219c0-0.844 0.656-1.5 1.5-1.5h6.125v-2.563h-6.125c-1.125 0-2.094 0.438-2.844 1.188-0.75 0.781-1.219 1.75-1.219 2.875zM6.719 13.563v4.875c0 0.563 0.5 1.031 1.063 1.031h5.656v3.844c0 0.344 0.188 0.625 0.5 0.781 0.125 0.031 0.25 0.031 0.313 0.031 0.219 0 0.406-0.063 0.563-0.219l7.344-7.344c0.344-0.281 0.313-0.844 0-1.156l-7.344-7.313c-0.438-0.469-1.375-0.188-1.375 0.563v3.875h-5.656c-0.563 0-1.063 0.469-1.063 1.031z"
                  ></path>
                </svg>
                <span class="ml-1">{{ translations?.buttons?.logout ?? "Logout" }}</span>
              </button>
            </div>
          </div>
          <hr class="dashboard-line" />
          <div class="row mx-0">
            <div class="col-12 col-lg-6 mb-3">
              <h2 class="dashboard-title ty_h3">
                {{ translations?.myprofile?.UpdatePassword ?? "Update Password" }}
              </h2>
              <form class="pe-lg-5" @submit.prevent="saveNewPassword">
                <div class="mb-2">
                  <label class="d-block" for="password">
                    {{
                      translations?.myprofile?.CurrentPassword ?? "Current Password"
                    }}</label
                  >
                  <input
                    class="dashboard-input"
                    id="password"
                    v-model="current_password"
                    type="password"
                  />
                </div>
                <div class="mb-2">
                  <label class="d-block" for="new_password">
                    {{ translations?.myprofile?.NewPassword ?? "New Password" }}</label
                  >
                  <input
                    class="dashboard-input"
                    id="new_password"
                    v-model.trim="new_password"
                    type="password"
                  />
                </div>
                <div class="mb-2">
                  <label class="d-block" for="confirm_password">
                    {{
                      translations?.myprofile?.ConfirmPassword ?? "Confirm Password"
                    }}</label
                  >
                  <input
                    class="dashboard-input"
                    id="confirm_password"
                    v-model.trim="confirm_password"
                    type="password"
                  />
                </div>
                <button class="dashboard-button-submit login-btn mt-3">
                  {{ translations?.myprofile?.Save ?? "Save" }}
                </button>
              </form>
            </div>
            <div class="col-12 col-lg-6 mb-3">
              <h2 class="dashboard-title ty_h3">
                {{ translations?.myprofile?.SuspendMyAccount ?? "Suspend my Account" }}
              </h2>
              <p class="ty_text100-medium">
                {{
                  translations?.myprofile?.Please_contact_our_support_team ??
                  "If you need assistance please contact our support team:"
                }}
                <a
                  href="mailto:support@bethappy.com"
                  class="dashboard-link text-decoration-none"
                  >support@bethappy.com</a
                >
              </p>
            </div>
          </div>
          <hr class="dashboard-line" />
          <div
            class="row mx-0"
            v-if="
              (!playerDocuments?.id_card?.length && !playerDocuments?.passport?.length) ||
              playerDocuments?.id_card?.length ||
              playerDocuments?.passport?.length
            "
          >
            <div class="col-12 col-lg-6 mb-3">
              <h2 class="dashboard-title ty_h3 mb-2">
                {{ translations?.myprofile?.Identification ?? "Identification" }}
              </h2>
              <p class="fw-bold mb-2">
                {{
                  translations?.myprofile?.UploadyourDocuments ?? "Upload your Documents"
                }}
              </p>
              <!-- TODO poi decommentare in caso di bisogno 
                <p
                class="error-doc-expired ty_text100-bold mb-2"
                v-if="
                  (playerDocuments?.id_card?.length ||
                    playerDocuments?.passport?.length) &&
                  playerInfo?.has_expired_documents
                "
              >
                {{
                  translations?.myprofile?.doc_expired ??
                  "Your document has expired, please upload a new valid one!"
                }}
              </p> -->
              <form class="pe-lg-5" @submit.prevent="uploadDocument">
                <div class="mb-2">
                  <label class="d-block" for="document_type">
                    {{ translations?.myprofile?.DocumentType ?? "Document Type" }}</label
                  >
                  <select
                    id="document_type"
                    class="form-select dashboard-select"
                    v-model="selectedType"
                  >
                    <option class="ty_text100-medium" value="id_card">
                      {{ translations?.myprofile?.id_card_option ?? " Id Card" }}
                    </option>
                    <option class="ty_text100-medium" value="passport">
                      {{ translations?.myprofile?.passport_option ?? "Passport" }}
                    </option>
                  </select>
                </div>
                <div class="mb-2">
                  <label class="d-block" for="numberkyc">
                    {{
                      translations?.myprofile?.IdentificationNumber ??
                      "Identification Number"
                    }}</label
                  >
                  <input
                    class="dashboard-input"
                    id="numberkyc"
                    v-model="idn"
                    type="text"
                  />
                </div>
                <label class="d-block" for="expirekyc">
                  {{
                    translations?.myprofile?.ExpirationDate ?? "Expiration Date"
                  }}</label
                >
                <div
                  style="
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                  "
                  class="mb-2"
                >
                  <div class="mr-1" style="flex: 1">
                    <select v-model="dayOption" id="day" class="input">
                      <option value="" disabled selected>
                        {{ translations?.registration?.Day }}
                      </option>
                      <option
                        v-for="dayOption in days"
                        :key="dayOption"
                        :value="dayOption"
                      >
                        {{ dayOption ? dayOption : translations?.registration?.Day }}
                      </option>
                    </select>
                  </div>
                  <div class="mr-1" style="flex: 1">
                    <select v-model="monthOption" id="month" class="input">
                      <option value="" disabled selected>
                        {{ translations?.registration?.Month }}
                      </option>
                      <option
                        v-for="monthOption in months"
                        :key="monthOption"
                        :value="monthOption"
                      >
                        {{
                          monthOption ? monthOption : translations?.registration?.Month
                        }}
                      </option>
                    </select>
                  </div>
                  <div class="mr-1" style="flex: 1">
                    <select v-model="yearOption" id="year" class="input">
                      <option value="" disabled selected>
                        {{ translations?.registration?.Year }}
                      </option>
                      <option
                        v-for="yearOption in years"
                        :key="yearOption"
                        :value="yearOption"
                      >
                        {{ yearOption ? yearOption : translations?.registration?.Year }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="mb-2">
                  <label class="d-block" for="front_file">
                    {{ translations?.myprofile?.FrontSide ?? "Front Side" }}</label
                  >
                  <input
                    @change="uploadFrontSide"
                    ref="front_file"
                    class="dashboard-input"
                    id="front_file"
                    type="file"
                    accept="image/*, .pdf"
                  />
                </div>
                <div class="mb-2">
                  <label class="d-block" for="back_file">
                    {{ translations?.myprofile?.BackSide ?? "Back Side" }}</label
                  >
                  <input
                    @change="uploadBackSide"
                    ref="back_file"
                    class="dashboard-input"
                    id="back_file"
                    type="file"
                    accept="image/*, .pdf"
                  />
                </div>
                <button class="dashboard-button-submit login-btn mt-3" @click="sendFile">
                  {{ translations?.myprofile?.Upload ?? "Upload" }}
                </button>
              </form>
            </div>
            <div class="col-12 col-lg-6 mb-3">
              <h2 class="dashboard-title ty_h3 mb-2" style="font-size: 20px">
                {{
                  translations?.myprofile?.last_doc_uploaded ??
                  "Latest documents uploaded"
                }}
              </h2>
              <div class="document-container">
                <div
                  class="document-type"
                  v-if="playerDocuments && playerDocuments?.id_card"
                >
                  <div v-for="idCard in playerDocuments?.id_card[0]" :key="idCard?.id">
                    <div class="document-info">
                      <div class="d-flex justify-content-between">
                        <div class="ty_bd d-flex flex-column flex-1-class">
                          <p class="ty_bd fwBold">
                            {{ translations?.myprofile?.doc_type ?? "Type" }}:
                          </p>
                          <p class="ty_bd">{{ idCard?.type }}</p>
                        </div>
                        <div class="ty_bd d-flex flex-column flex-1-class">
                          <p class="ty_bd fwBold">
                            {{ translations?.myprofile?.id_number ?? "ID Number" }}:
                          </p>
                          <p class="ty_bd">{{ idCard?.idn }}</p>
                        </div>
                      </div>
                      <div class="d-flex justify-content-between">
                        <div class="d-flex flex-column flex-1-class">
                          <p class="ty_bd fwBold">
                            {{ translations?.myprofile?.created_at ?? "Created At" }}:
                          </p>
                          <p class="ty_bd">{{ idCard?.created_at }}</p>
                        </div>
                        <div class="d-flex flex-column flex-1-class">
                          <p class="ty_bd fwBold">
                            {{ translations?.myprofile?.expires_at ?? "Expires At" }}:
                          </p>
                          <p class="ty_bd">{{ idCard?.expires_at }}</p>
                        </div>
                      </div>
                      <div class="d-flex justify-content-start my-5">
                        <div
                          v-for="media in idCard?.media"
                          :key="media?.id"
                          class="document-media"
                        >
                          <img
                            :src="media?.full_url"
                            alt="ID Card Image"
                            class="doc-img"
                          />
                        </div>
                      </div>
                      <p class="ty_bd pt-2 mb-1">
                        <b class="ty_bd fwBold"
                          >{{ translations?.myprofile?.is_verified ?? "Is Verified" }}:</b
                        >
                        {{ idCard?.is_verified ? "Yes" : "No" }}
                      </p>
                    </div>
                    <hr class="dashboard-line" />
                  </div>
                </div>

                <div
                  class="document-type"
                  v-if="playerDocuments && playerDocuments?.passport"
                >
                  <div
                    v-for="passport in playerDocuments?.passport[0]"
                    :key="passport?.id"
                  >
                    <div class="document-info">
                      <div class="d-flex justify-content-between">
                        <div class="ty_bd d-flex flex-column flex-1-class">
                          <p class="ty_bd fwBold">
                            {{ translations?.myprofile?.doc_type ?? "Type" }}:
                          </p>
                          <p class="ty_bd">{{ passport?.type }}</p>
                        </div>
                        <div class="ty_bd d-flex flex-column flex-1-class">
                          <p class="ty_bd fwBold">
                            {{ translations?.myprofile?.id_number ?? "ID Number" }}:
                          </p>
                          <p class="ty_bd">{{ passport?.idn }}</p>
                        </div>
                      </div>
                      <div class="d-flex justify-content-between">
                        <div class="ty_bd d-flex flex-column flex-1-class">
                          <p class="ty_bd fwBold">
                            {{ translations?.myprofile?.created_at ?? "Created At" }}:
                          </p>
                          <p class="ty_bd">{{ passport?.created_at }}</p>
                        </div>
                        <div class="ty_bd d-flex flex-column flex-1-class">
                          <p class="ty_bd fwBold">
                            {{ translations?.myprofile?.expires_at ?? "Expires At" }}:
                          </p>
                          <p class="ty_bd">{{ passport?.expires_at }}</p>
                        </div>
                      </div>
                      <div class="d-flex justify-content-start my-5">
                        <div
                          v-for="media in passport?.media"
                          :key="media?.id"
                          class="document-media"
                        >
                          <img
                            :src="media?.full_url"
                            alt="Passport Image"
                            class="doc-img"
                          />
                        </div>
                      </div>
                      <p class="ty_bd pt-2 mb-1">
                        <b class="ty_bd fwBold"
                          >{{ translations?.myprofile?.is_verified ?? "Is Verified" }}:
                        </b>
                        {{ passport?.is_verified ? "Yes" : "No" }}
                      </p>
                      <hr class="dashboard-line" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import DashBoard from "./DashBoard.vue";
import { mapGetters } from "vuex";
import PreLoader from "@/components/global/PreLoader.vue";
export default {
  name: "ProfilePage",
  components: {
    DashBoard,
    PreLoader,
  },
  data() {
    const currentYear = new Date().getFullYear();
    const startYear = currentYear;
    const endYear = currentYear + 50;
    return {
      isTimer: true,
      playerEmail: "",
      isTimerMail: false,
      countdownMail: 0,
      IDList: ["id_card", "passport", "driver_license"],
      isFrontFileAccepted: null,
      isFrontFileTooLarge: false,
      isBackFileAccepted: null,
      isBackFileTooLarge: false,
      idn: "",
      front_file: null,
      back_file: null,
      selectedType: "",
      expires_at: "",
      current_password: "",
      new_password: "",
      confirm_password: "",
      yearOption: "",
      monthOption: "",
      dayOption: "",
      days: Array.from({ length: 31 }, (_, index) =>
        (index + 1).toString().padStart(2, "0")
      ),
      months: Array.from({ length: 12 }, (_, index) =>
        (index + 1).toString().padStart(2, "0")
      ),
      years: Array.from(
        { length: endYear - startYear + 1 },
        (_, index) => startYear + index
      ),
    };
  },
  methods: {
    startTimerMail() {
      const now = new Date();
      this.expiresAt = new Date(now.getTime() + 60 * 1000); // 60 seconds in milliseconds
      this.isTimerMail = true;

      // Save values in localStorage
      localStorage.setItem("expires_at", this.expiresAt.toISOString());
      localStorage.setItem("is_timer_on", "true");

      // Start the countdown
      this.updateCountdownMail();
    },
    updateCountdownMail() {
      this.countdownMail = Math.max(0, Math.round((this.expiresAt - new Date()) / 1000));

      if (this.countdownMail > 0) {
        setTimeout(this.updateCountdownMail, 1000);
      } else {
        // Timer has reached 0, reset values
        this.isTimerMail = false;
        localStorage.removeItem("expires_at");
        localStorage.removeItem("is_timer_on");
      }
    },
    async verifyMail() {
      this.startTimerMail();
      let playerId = this.playerInfo?.user_id;
      // alert(this.playerEmail);
      // return;
      let body = {
        email: this.playerEmail,
      };

      try {
        await this.$store.dispatch("registration/updatePlayer", { body, playerId });
        await this.$store.dispatch("registration/sendVerificationCodeAgain", playerId);
        await this.$store.dispatch("registration/verifyMail", playerId, { root: true });
      } catch (error) {
        console.error(error);
        this.showError = true;
        setTimeout(() => {
          this.showError = false;
        }, 5000);
      }
    },
    async logoutPlayer() {
      this.$store.dispatch("player/logout");
    },
    async saveNewPassword() {
      const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

      if (this.new_password !== this.confirm_password) {
        Swal.fire({
          icon: "error",
          text:
            this.translations?.body?.Error_psw_does_not_match ??
            "Password fields don't match!",
        });
        return;
      } else if (
        this.current_password == "" ||
        this.new_password == "" ||
        this.confirm_password == ""
      ) {
        Swal.fire({
          icon: "error",
          text:
            this.translations?.body?.Error_must_fill_all_fields ??
            "Must fill all fields!",
        });
        return;
      } else if (!passwordRegex.test(this.new_password)) {
        Swal.fire({
          icon: "error",
          text:
            this.translations?.body?.password_error_during_reset ??
            "Your password should include a minimum of one uppercase letter, one lowercase letter, one number, and one special character.",
        });
        return;
      } else {
        this.$store.dispatch("player/changePlayerPassword", {
          current: this.current_password,
          new: this.new_password,
          confirm: this.confirm_password,
        });
      }
    },
    uploadFrontSide() {
      const input = this.$refs.front_file;
      if (input.files.length > 0) {
        const file = input.files[0];
        this.front_file = {
          name: file.name,
          type: file.type,
          size: file.size,
          file: file,
        };
      }
    },

    uploadBackSide() {
      const input = this.$refs.back_file;
      if (input.files.length > 0) {
        const file = input.files[0];
        this.back_file = {
          name: file.name,
          type: file.type,
          size: file.size,
          file: file,
        };
      }
    },

    async sendFile() {
      const formData = new FormData();
      this.expires_at = `${this.yearOption}-${this.monthOption}-${this.dayOption}`;

      if (this.front_file && this.front_file.file) {
        formData.append("front_file", this.front_file.file);

        if (this.front_file.size > 20 * 1024 * 1024) {
          Swal.fire({
            icon: "error",
            text:
              this.translations?.body?.Error_max_size_exceed_Front_Side ??
              "Your front-side file is too large. Maximum size for 20MB!",
          });
          return;
        }
      } else {
        Swal.fire({
          icon: "error",
          text:
            this.translations?.body?.Error_Front_Side_Missing ??
            "The Front File is missing!",
        });
        return;
      }

      if (this.back_file && this.back_file.file) {
        formData.append("back_file", this.back_file.file);

        if (this.back_file.size > 20 * 1024 * 1024) {
          Swal.fire({
            icon: "error",
            text:
              this.translations?.body?.Error_max_size_exceed_Back_Side ??
              "Your back-side file is too large. Maximum size for 20MB!",
          });
          return;
        }
      } else {
        Swal.fire({
          icon: "error",
          text:
            this.translations?.body?.Error_Back_Side_Missing ??
            "The Back File is missing!",
        });
        return;
      }

      formData.append("expires_at", this.expires_at);
      formData.append("idn", this.idn);
      formData.append("type", this.selectedType);

      const contentType = { "Content-Type": "multipart/form-data" };
      this.$store.dispatch("player/sendDocuments", { formData, contentType });
    },
  },
  created() {
    this.$store.dispatch("player/callUserDocuments").then(
      setTimeout(() => {
        this.playerEmail = this.playerInfo?.email;
        this.isTimer = false;
      }, 2000)
    );
  },
  mounted() {},
  computed: {
    ...mapGetters("player", ["playerInfo", "playerInfoLoading", "playerDocuments"]),
    ...mapGetters("translations", ["translations", "isTranslationLoading"]),
    ...mapGetters("skinSettings", ["allInformationIsLoading"]),
    ...mapGetters("casinoGames", ["isAllProvidersLoading"]),
  },
  watch: {
    playerInfo(val) {
      console.log(val);
    },
  },
};
</script>

<style lang="scss" scoped>
.document-container {
  height: 100%;
  max-height: 400px;
  overflow-y: auto;

  .document-media {
    margin-right: 10px;
  }

  .doc-img {
    width: 100%;
    max-width: 150px;
    height: 100%;
    border-radius: 16px;
    object-fit: contain;
  }
  .document-info {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }
  .flex-1-class {
    flex: 1;
    justify-content: start;
    line-height: 25px !important;

    p.ty_bd {
      line-height: 25px !important;
      margin-bottom: 5px !important;
      font-size: 16px !important;
    }

    p.fwBold {
      font-weight: 600 !important;
    }
  }
}
</style>
