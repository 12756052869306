<template>
  <transition name="route" mode="out-in">
    <PreLoader v-if="loading" />
  </transition>
  <div class="container position-relative" style="padding-top: 120px">
    <div class="col-12 col-lg-12 col-md-12 col-sm-12 position-relative">
      <div class="content-holder position-realtive">
        <div class="container-fluid page-box py-4">
          <div class="col-md-2"></div>
          <div class="col-md-8 mx-auto">
            <div class="col-md-12">
              <h3 class="ty_h3">
                {{ translations?.about_us?.contact_us ?? "CONTACT US" }}
              </h3>
              <p class="ty_bd">
                {{
                  translations?.about_us?.contact_our_team ?? "Contact our team"
                }}
              </p>
            </div>
            <form class="contact-form" @submit.prevent="submitForm">
              <div class="form-group">
                <label class="ty_bd my-2">
                  {{
                    translations?.about_us?.name_field ??
                    "Name (first and last)"
                  }}</label
                >
                <input
                  v-model="name"
                  class="form-control"
                  type="text"
                  :placeholder="translations?.about_us?.name_field ?? 'Name'"
                />
              </div>
              <div class="form-group">
                <label class="ty_bd my-2">
                  {{ translations?.about_us?.email_field ?? "Email" }}</label
                >
                <input
                  v-model="email"
                  class="form-control"
                  type="email"
                  :placeholder="translations?.about_us?.email_field ?? 'Email'"
                />
              </div>
              <div class="form-group">
                <label class="ty_bd my-2">
                  {{
                    translations?.about_us?.phone_field ?? "Phone Number"
                  }}</label
                >
                <input
                  v-model="phoneNumber"
                  class="form-control"
                  type="tel"
                  :placeholder="
                    translations?.about_us?.phone_place_holder ??
                    'Example +391234569875'
                  "
                />
              </div>
              <div class="form-group">
                <label class="ty_bd my-2">
                  {{
                    translations?.about_us?.message_field ?? "Message"
                  }}</label
                >
                <textarea
                  v-model="message"
                  class="form-control"
                  name="comment"
                  form="usrform"
                  :placeholder="
                    translations?.about_us?.message_place_holder ??
                    'Enter text here...'
                  "
                ></textarea>
                <div class="char-count ty_bd">
                  {{ charCount }}
                  {{
                    translations?.about_us?.char_used ?? "/400 characters used"
                  }}
                </div>
              </div>
              <div class="form-group pt-3">
                <button
                  id="contactsubmit"
                  type="submit"
                  class="btn btn-primary btn-block"
                >
                  {{ translations?.about_us?.send_form_button ?? "Send" }}
                </button>
              </div>
            </form>
          </div>
          <div class="col-md-2"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PreLoader from "@/components/global/PreLoader.vue";
import Swal from "sweetalert2";
import { mapGetters } from "vuex";

export default {
  components: {
    PreLoader,
  },
  data() {
    return {
      loading: true,
      name: "",
      email: "",
      phoneNumber: "",
      message: "",
      charCount: 0,
    };
  },
  computed: {
    ...mapGetters("translations", ["isTranslationLoading", "translations"]),
    ...mapGetters("casinoGames", ["isAllProvidersLoading"]),
  },
  watch: {
    message(newValue) {
      this.charCount = newValue.length;
    },
  },
  methods: {
    async submitForm() {
      if (this.isValid()) {
        this.$store
          .dispatch("Notifications/sendFormContact", {
            name: this.name,
            email: this.email,
            telephone: this.phoneNumber,
            message_body: this.message,
          })
          .then(() => {
            this.showSuccessAlert().then(() => {
              this.clearFields();
            });
          });
      } else {
        await this.showErrorAlert();
      }
    },
    clearFields() {
      this.name = "";
      this.email = "";
      this.phoneNumber = "";
      this.message = "";
      this.charCount = 0;
    },
    isValid() {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const phoneRegex = /^\+\d{1,4}\d{3,}$/;

      if (this.name === "") {
        this.showErrorAlert("Name is required");
        return false;
      }

      if (!emailRegex.test(this.email.trim())) {
        this.showErrorAlert("Invalid email format");
        return false;
      }

      if (!phoneRegex.test(this.phoneNumber.trim())) {
        this.showErrorAlert("Invalid phone number format");
        return false;
      }

      if (this.message === "") {
        this.showErrorAlert("Message is required");
        return false;
      }

      if (this.charCount > 400) {
        this.showErrorAlert("Message should not exceed 400 characters");
        return false;
      }

      return true;
    },
    async showSuccessAlert() {
      await Swal.fire({
        icon: "success",
        title: "Success",
        text: "Form submitted successfully!",
      });
    },
    async showErrorAlert(errorMessage) {
      if (errorMessage) {
        await Swal.fire({
          icon: "error",
          text: errorMessage,
        });
      }
    },
  },
  created() {
    setTimeout(() => {
      this.loading = false;
    }, 1500);
  },
};
</script>

<style scoped>
.ty_bd {
  color: white;
}
.ty_h3 {
  color: white;
}
.contact-form {
  background-color: #203d5d;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.contact-form h3 {
  color: #333;
}

.contact-form label {
  display: block;
  margin: 10px 0 5px;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

textarea {
  min-height: 200px;
}

.contact-form button {
  width: 100%;
  padding: 10px;
  background-color: #16ca31;
  color: #fff;
  border: none;
  border-radius: 5px 0px 5px 0px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.contact-form button:hover {
  background-color: #17b02e;
}
</style>
