<template>
  <header class="header" ref="header">
    <div class="side-left-menu" :class="{ active: isMenuOpen }">
      <div class="image-container-side-menu-left">
        <div class="cross-container">
          <div class="close-left-menu" @click="toggleMenu">
            <img src="/images/left-menu_img/close.svg" alt="" />
          </div>
        </div>
        <div class="inner">
          <img :src="`/images/left-menu_img/_${lang}.png`" alt="" />
        </div>
        <div class="sign-up-btn-container" v-if="!isLogged">
          <button
            id="signUp-btn-desk"
            @click="toggleModalRegistration"
            class="sing-up-btn"
          >
            {{ translations?.buttons?.SignUp }}
          </button>
        </div>
        <div class="logout-btn-container" v-else>
          <button @click="logoutPlayer" class="dashboard-button-logout">
            <svg
              class="logout-dashboard-icon"
              viewBox="-4 2 28 28"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>{{ translations?.buttons?.logout ?? "Logout" }}</title>
              <path
                d="M0 9.875v12.219c0 1.125 0.469 2.125 1.219 2.906 0.75 0.75 1.719 1.156 2.844 1.156h6.125v-2.531h-6.125c-0.844 0-1.5-0.688-1.5-1.531v-12.219c0-0.844 0.656-1.5 1.5-1.5h6.125v-2.563h-6.125c-1.125 0-2.094 0.438-2.844 1.188-0.75 0.781-1.219 1.75-1.219 2.875zM6.719 13.563v4.875c0 0.563 0.5 1.031 1.063 1.031h5.656v3.844c0 0.344 0.188 0.625 0.5 0.781 0.125 0.031 0.25 0.031 0.313 0.031 0.219 0 0.406-0.063 0.563-0.219l7.344-7.344c0.344-0.281 0.313-0.844 0-1.156l-7.344-7.313c-0.438-0.469-1.375-0.188-1.375 0.563v3.875h-5.656c-0.563 0-1.063 0.469-1.063 1.031z"
              ></path>
            </svg>
            <span class="ml-1">{{ translations?.buttons?.logout ?? "Logout" }}</span>
          </button>
        </div>
      </div>
      <div class="ul-container-meu-left">
        <ul>
          <!-- eslint-disable -->
          <div
            class="nav-item-menu-left nav-link-menu-left position-relative"
            @click="showSection('homepage')"
            style="color: white; border-bottom: 0px !important"
          >
            <span class="arrow-dropdown-container"
              ><img
                :class="{
                  inverse: isSubMenuOpenCasino,
                }"
                class="arrow-dropdown" alt="dropdown"
                src="../../../public/images/arrow-indicator.png"
              />
            </span>
            <span
              :class="{
                'router-link-active router-link-exact-active': $route.name === 'HomePage',
              }"
            >
              {{ translations?.header?.Home ?? "Home" }}</span
            >
            <ul class="side-nav-menu-left custom-mt" v-if="isSubMenuOpenCasino">
              <!-- <li class="nav-item-menu-left mb-0">
                <span
                  class="nav-link-menu-left skin-sections-options"
                  @click="gotoPage('homePage')"
                  >All</span
                >
              </li> -->
              <li
                class="nav-item-menu-left mb-0"
                v-if="
                  allSkinSectionLeftMenuCasino && allSkinSectionLeftMenuCasino.length > 1
                "
                v-for="casinoSection in allSkinSectionLeftMenuCasino"
                :key="casinoSection.id"
              >
                <span
                  class="nav-link-menu-left skin-sections-options"
                  @click="navigateAndCloseMenu(casinoSection.id, 'home')"
                >
                  {{
                    translations?.casino?.[replaceSpaces(casinoSection.button_text)] ??
                    casinoSection.button_text
                  }}
                </span>
              </li>
            </ul>
          </div>
          <!-- eslint-disable -->
          <!-- eslint-disable -->
          <div
            class="nav-item-menu-left nav-link-menu-left position-relative"
            @click="showSection('live-casino')"
            style="color: white; border-bottom: 0px !important"
          >
            <span class="arrow-dropdown-container"
              ><img
                :class="{
                  inverse: isSubMenuOpenLiveCasino,
                }"
                class="arrow-dropdown" alt="dropdown"
                src="../../../public/images/arrow-indicator.png"
              />
            </span>
            <span
              :class="{
                'router-link-active router-link-exact-active':
                  $route.name === 'LiveCasinoPage',
              }"
            >
              {{ translations?.header?.LiveCasino ?? "Live casino" }}</span
            >
            <ul class="side-nav-menu-left custom-mt" v-if="isSubMenuOpenLiveCasino">
              <!-- <li class="nav-item-menu-left mb-0">
                <span
                  class="nav-link-menu-left skin-sections-options"
                  @click="gotoPage('livePage')"
                  >All</span
                >
              </li> -->
              <li
                class="nav-item-menu-left mb-0"
                v-if="
                  allSkinSectionLeftMenuLiveCasino &&
                  allSkinSectionLeftMenuLiveCasino.length > 1
                "
                v-for="livecasinoSection in allSkinSectionLeftMenuLiveCasino"
                :key="livecasinoSection.id"
              >
                <span
                  class="nav-link-menu-left skin-sections-options"
                  @click="navigateAndCloseMenu(livecasinoSection.id, 'live')"
                >
                  {{
                    translations?.casino?.[
                      replaceSpaces(livecasinoSection.button_text)
                    ] ?? livecasinoSection.button_text
                  }}
                </span>
              </li>
            </ul>
          </div>
          <!-- eslint-disable -->
          <li
            class="nav-item-menu-left"
            v-for="item in menuItemsSideMenuLeft"
            :key="item.navigation_link"
          >
            <!--TODO impostare classe active in caso di route uguale-->
            <router-link :to="item.path" class="nav-link-menu-left" @click="closeMenu">
              {{ translations?.header?.[item.navigation_link] ?? item.navigation_link }}
            </router-link>
          </li>
        </ul>
      </div>
    </div>
    <div class="container">
      <nav class="navbar">
        <div class="logo-hamburger-wrapper">
          <div class="hamburger" @click="toggleMenu" v-if="0">
            <span class="bar bar1"></span>
            <span class="bar bar2"></span>
            <span class="bar bar3"></span>
          </div>
          <div class="logo">
            <img loading="lazy" alt="logo" src="/images/logo.png" @click="redirectToHome" />
          </div>
          <div class="nav-item-wrapper-bg" v-if="!isBottomBarVersion">
            <ul class="nav-menu">
              <li class="nav-item nav-link hamburger-mobile" @click="toggleMenu">
                <span class="bar bar1"></span>
                <span class="bar bar2"></span>
                <span class="bar bar3"></span>
              </li>
              <li
                class="nav-item"
                v-for="item in menuItemsDesktop"
                :key="item.navigation_link"
              >
                <router-link :to="item.path" class="nav-link" @click="closeMenu">
                  {{
                    translations?.header?.[item.navigation_link] ?? item.navigation_link
                  }}
                </router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="nav-link-picker-language-wrapper">
          <!-- <div v-if="!isBottomBarVersion">
            <ul class="nav-menu">
              <li
                class="nav-item nav-link hamburger-mobile"
                @click="toggleMenu"
              >
                <span class="bar bar1"></span>
                <span class="bar bar2"></span>
                <span class="bar bar3"></span>
              </li>
              <li
                class="nav-item"
                v-for="item in menuItemsDesktop"
                :key="item.navigation_link"
              >
                <router-link
                  :to="item.path"
                  class="nav-link"
                  @click="closeMenu"
                >
                  {{
                    translations?.header?.[item.navigation_link] ??
                    item.navigation_link
                  }}
                </router-link>
              </li>
            </ul>
          </div> -->
          <div class="nav-item mx-2" v-if="isLogged == false">
            <div class="wrapper-btn-sing-in_up">
              <button
                id="signUp-btn-mob"
                class="sing-up-btn"
                @click="toggleModalRegistration"
              >
                {{ translations?.buttons?.SignUp }}
              </button>
              <button class="login-btn" @click="toggleMenuLogin">
                {{ translations?.buttons?.Login }}
              </button>
            </div>
          </div>
          <div class="nav-item mr-3" v-else-if="isLogged">
            <div class="wrapper-btn-sing-in_up">
              <button class="button-eyes" @click="setShowBalance">
                <img
                  class="icon-svg"
                  v-if="showBalance !== ''"
                  src="/images/svg-sticky-nav/eyeOpen.svg"
                  alt=""
                />
                <img
                  class="icon-svg"
                  v-else
                  src="/images/svg-sticky-nav/eyeClose.svg"
                  alt=""
                />
              </button>
            </div>
            <PlayerSummary v-if="showBalance !== '' && isLogged" />
          </div>
          <div class="nav-item" :class="{ 'ml-1': !isLogged, 'mx-1': isLogged }">
            <selectPickerLanguage />
          </div>
        </div>
      </nav>
    </div>
  </header>
  <!--BottomBar-->
  <div
    v-show="isBottomBarVersion && this.$route.name !== 'game-page'"
    class="nav-menu-mobile"
  >
    <li class="nav-item">
      <div class="nav-link-with-icon" @click="toggleMenu" style="cursor: pointer">
        <img class="icon-svg" src="/images/svg-sticky-nav/menu.svg" alt="" />
        <div class="nav-link">
          {{ translations?.header?.menu_btn_mobile ?? "Menu" }}
        </div>
      </div>
    </li>
    <li class="nav-item" v-for="item in menuItemsMobile" :key="item.navigation_link">
      <router-link :to="item.path" class="nav-link" @click="closeMenu">
        <div class="nav-link-with-icon">
          <img class="icon-svg" :src="item.icon" alt="" />
          <div class="nav-link">
            {{ translations?.header?.[item.navigation_link] ?? item.navigation_link }}
          </div>
        </div>
      </router-link>
    </li>
    <li class="nav-item" v-if="!isLogged">
      <div class="nav-link-with-icon" @click="toggleMenuLogin">
        <img class="icon-svg" src="/images/svg-sticky-nav/login.svg" alt="" />
        <div class="nav-link">{{ translations?.buttons?.Login }}</div>
      </div>
    </li>
    <li class="nav-item" v-else>
      <div
        class="nav-link-with-icon"
        style="cursor: pointer"
        @click="
          $router.push({
            name: 'profile',
            params: { lang: lang },
          })
        "
      >
        <img class="icon-svg" src="/images/svg-sticky-nav/dashboard.svg" alt="" />
        <div class="nav-link">
          {{ translations?.header?.menu_btn_dashboard ?? "Dashboard" }}
        </div>
      </div>
    </li>
  </div>
  <LoginMenu />
  <RegistrationForm />
</template>

<script>
import { mapGetters } from "vuex";
import SelectPickerLanguage from "@/components/ui/SelectPickerLanguage.vue";
import PlayerSummary from "@/components/ui/DashBoardUiElement/PlayerSummary.vue";
import LoginMenu from "../ui/LoginMenu.vue";
import RegistrationForm from "../ui/RegistrationForm.vue";
export default {
  name: "TheHeader",
  components: {
    SelectPickerLanguage,
    LoginMenu,
    RegistrationForm,
    PlayerSummary,
  },
  data() {
    return {
      isMenuOpen: false,
      isSubMenuOpen: false,
      isSubMenuOpenCasino: false,
      isSubMenuOpenLiveCasino: false,
      lang: localStorage.getItem("language") ?? "en",
      showBalance: localStorage.getItem("showBalance") ?? "",
      isLogged: localStorage.getItem("X19fYWNjZXNzX19f") ?? false,
      isBottomBarVersion: window.innerWidth <= 992,
      menuItemsDesktop: [
        {
          navigation_link: "Home",
          path: `/${localStorage.getItem("language")}`,
        },
        // {
        //   navigation_link: "Casino",
        //   path: `/${localStorage.getItem("language")}/casino`,
        // },
        {
          navigation_link: "LiveCasino",
          path: `/${localStorage.getItem("language")}/live-casino`,
        },
        {
          navigation_link: "Promotions",
          path: `/${localStorage.getItem("language")}/promotion`,
        },
        {
          navigation_link: "banking",
          path: `/${localStorage.getItem("language")}/banking`,
        },
        {
          navigation_link: "support",
          path: `/${localStorage.getItem("language")}/support`,
        },
      ],
      menuItemsMobile: [
        // {
        //   navigation_link: "Casino",
        //   path: `/${localStorage.getItem("language")}/casino`,
        //   icon: "/images/svg-sticky-nav/casino.svg",
        // },
        {
          navigation_link: "Home",
          path: `/${localStorage.getItem("language")}/`,
          icon: "/images/svg-sticky-nav/casino.svg",
        },
        {
          navigation_link: "LiveCasino",
          path: `/${localStorage.getItem("language")}/live-casino`,
          icon: "/images/svg-sticky-nav/live-casino.svg",
        },
      ],
      menuItemsSideMenuLeft: [
        /*  {
          navigation_link: "Home",
          path: `/${localStorage.getItem("language")}/`,
        },
        {
          navigation_link: "LiveCasino",
          path: `/${localStorage.getItem("language")}/live-casino`,
        },*/
        {
          navigation_link: "Promotions",
          path: `/${localStorage.getItem("language")}/promotion`,
        },
        {
          navigation_link: "banking",
          path: `/${localStorage.getItem("language")}/banking`,
        },
        {
          navigation_link: "support",
          path: `/${localStorage.getItem("language")}/support`,
        },
      ],
    };
  },
  methods: {
    gotoPage(page) {
      if (page == "homePage") {
        if (this.isMenuOpen) {
          this.toggleMenu();
        }
        this.$router.push(`/${localStorage.getItem("language")}/`);
      } else if (page == "livePage") {
        if (this.isMenuOpen) {
          this.toggleMenu();
        }
        this.$router.push(`/${localStorage.getItem("language")}/live-casino`);
      }
    },
    showSection(slug) {
      let targetState = "";
      let targetStoreProperty = "";

      switch (slug) {
        case "homepage":
          targetState = "isSubMenuOpenCasino";
          targetStoreProperty = "allSkinSectionLeftMenuCasino";
          break;
        case "live-casino":
          targetState = "isSubMenuOpenLiveCasino";
          targetStoreProperty = "allSkinSectionLeftMenuLiveCasino";
          break;
        default:
          return;
      }

      if (!this[targetState]) {
        if (this[targetStoreProperty] === null) {
          this.$store
            .dispatch("casinoGames/callSkinSectionsFromLeftMenu", slug)
            .then(() => {
              this[targetState] = true;
            });
        } else {
          this[targetState] = true;
        }
      } else {
        this[targetState] = false;
      }
    },
    navigateAndCloseMenu(sectionID, path) {
      let language = localStorage.getItem("language");
      let routeName = path === "home" ? "HomePage" : "LiveCasinoPage";
      let targetPath = path === "home" ? `/${language}/` : `/${language}/live-casino`;

      if (this.$route.name !== routeName) {
        this.$router.push(targetPath).then(() => {
          let interval = setInterval(() => {
            let section = document.getElementById(sectionID);
            if (section) {
              clearInterval(interval);
              section.scrollIntoView({
                behavior: "smooth",
                block: "center",
                inline: "center",
              });
              section.click();
              if (this.isMenuOpen) {
                this.toggleMenu();
              }
            }
          }, 100);
        });
      } else {
        let section = document.getElementById(sectionID);
        if (section) {
          section.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "center",
          });
          setTimeout(() => {
            section.click();
          }, 500);
        }
        this.toggleMenu();
      }
    },
    replaceSpaces(text) {
      return text.replace(/\s/g, "").toLowerCase();
    },
    handleResize() {
      this.isBottomBarVersion = window.innerWidth <= 992;
    },
    setShowBalance() {
      if (localStorage.getItem("showBalance")) {
        this.showBalance = "";
        localStorage.removeItem("showBalance");
      } else {
        localStorage.setItem("showBalance", true);
        this.showBalance = localStorage.getItem("showBalance");
      }
    },
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
      this.isSubMenuOpenCasino = false;
      this.isSubMenuOpenLiveCasino = false;
      this.setNormalBackground();
      if (window.scrollY === 0 && this.isMenuOpen == false) {
        this.setTransparentBackground();
      }
    },
    toggleMenuLogin() {
      this.$store.dispatch(
        "openMenuLogin/setStateLoginMenu",
        !this.$store.getters["openMenuLogin/isLoginMenuOpen"]
      );
    },
    toggleModalRegistration() {
      if (this.isMenuOpen) {
        this.toggleMenu();
      }
      this.$store.dispatch(
        "openMenuLogin/setStateModalRegistration",
        !this.$store.getters["openMenuLogin/isModalOpen"]
      );
      if (this.isModalOpen && window.innerWidth >= 768) {
        // Se la modale è appena stata aperta, disabilita lo scroll
        document.documentElement.style.overflow = "hidden";
        document.documentElement.style.paddingRight = "11px";
      } else {
        // Se la modale è stata chiusa, riabilita lo scroll
        document.documentElement.style.overflow = "";
        document.documentElement.style.paddingRight = "0px";
      }
    },
    closeMenu() {
      this.isMenuOpen = false;
    },
    redirectToHome() {
      this.closeMenu();
      if (this.$route.name == "HomePage") {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      } else {
        window.location.href = "/";
      }
    },
    toggleSubMenu() {
      this.isSubMenuOpen = !this.isSubMenuOpen;
      if (this.isSubMenuOpen == true && window.scrollY === 0) {
        this.setNormalBackground();
      } else if (
        this.isSubMenuOpen == false &&
        window.scrollY === 0 &&
        window.innerWidth > 992
      ) {
        this.setTransparentBackground();
      }
    },
    setTransparentBackground() {
      if (this.$refs.header) {
        this.$refs.header.style.background =
          "linear-gradient(0deg, transparent, rgba(0, 0, 0, 0.8))";
        this.$refs.header.style.paddingBottom = "0px";
      }
    },

    setNormalBackground() {
      if (this.$refs.header) {
        // this.$refs.header.style.background = "#1f2e41";
        this.$refs.header.style.background = "#132437";
        //dato che hanno tolto tutto il margine
        //e padding della bara delle sezioni lo metto qua
        //in modo che non sia attacato alla nav dell'header
        //quando il casinoCatgeoryMenu è sticky
        this.$refs.header.style.paddingBottom = "10px";
      }
    },
    handleScroll() {
      if (
        window.scrollY === 0 &&
        this.isMenuOpen == false &&
        this.isSubMenuOpen == false
      ) {
        this.setTransparentBackground();
      } else {
        this.setNormalBackground();
      }
    },
    async logoutPlayer() {
      this.$store.dispatch("player/logout");
    },
  },
  created() {
    this.lang = localStorage.getItem("language");
  },
  computed: {
    ...mapGetters("openMenuLogin", ["isLoginMenuOpen"]),
    ...mapGetters("openMenuLogin", ["isModalOpen"]),
    ...mapGetters("translations", ["translations", "isTranslationLoading"]),
    ...mapGetters("casinoGames", [
      "allSkinSectionLeftMenuCasino",
      "allSkinSectionLeftMenuLiveCasino",
    ]),
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    window.addEventListener("DOMContentLoaded", this.handleScroll);
    window.addEventListener("scroll", this.handleScroll);
  },
  watch: {
    $route() {
      //nascondo popup informazioni
      this.showBalance = "";
      localStorage.removeItem("showBalance");
      if (
        (this.isSubMenuOpen && this.isMenuOpen) ||
        this.isSubMenuOpen ||
        this.isMenuOpen
      ) {
        this.closeMenu();
        this.toggleSubMenu();
      }
      this.handleScroll();
    },
  },
};
</script>
