<template>
  <PreLoader
    v-if="isAllProvidersLoading || isTranslationLoading || !allInformationIsLoading"
  />
  <div>
    <DashBoard />
    <div class="container">
      <div class="row mx-auto justify-content-center">
        <div class="col-12 col-lg-10">
          <div class="dashboard-all">
            <h2 class="dashboard-title ty_h3">
              {{ translations?.dashboard_menu?.MyBonus ?? "My Bonus" }}
            </h2>
            <hr class="dashboard-line" />
            <div
              class="pt-3 d-flex align-items-center justify-content-center"
              v-if="isPromotionsLoading"
            >
              <MainLoader />
            </div>
            <div v-else-if="!isPromotionsLoading">
              <div
                class="d-flex flex-column w-100 align-items-center justify-content-center"
                v-if="campaignUnusedList.length > 0 && bonus.length == 0"
              >
                <h3 id="text-header-bonus" class="text-center ty_h3">
                  {{
                    translations.promotion.bonuses_that_can_be_actived ??
                    "Bonuses that can be activated"
                  }}
                </h3>
                <div class="row w-100">
                  <div
                    class="col-md-12 col-lg-6 col-sm-12 col-12 mb-4"
                    v-for="(campaignUnused, index) in campaignUnusedList"
                    :key="index"
                  >
                    <div class="card">
                      <span class="gift-icon">
                        <img src="/images/giftIcon.png" alt="Promotion Image" />
                      </span>
                      <div class="card-body">
                        <h5 class="card-title-unused text-uppercase ty_h5">
                          {{ removeTags(campaignUnused.name) }}
                        </h5>
                        <div class="information-campaign">
                          <div class="promo-infos">
                            <p class="ty_bd">
                              <strong>
                                {{
                                  translations.promotion.amount_added ?? "Amount added"
                                }}:</strong
                              >
                              {{ parseFloat(campaignUnused.amount).toFixed(2) }}
                              {{ playerInfo?.currency?.symbol ?? "" }}
                            </p>

                            <div class="makeDeposit-Line">
                              <span class="ty_bd">
                                <strong
                                  >{{ translations.promotion.type ?? "Type" }}:</strong
                                >
                                {{ campaignUnused.action.name }}
                              </span>
                              <span
                                class="ty_bd"
                                v-show="
                                  campaignUnused.action.name
                                    .toLowerCase()
                                    .includes('deposit') && campaignUnused.is_valid
                                "
                              >
                                <a
                                  href="#"
                                  class="ty_bd"
                                  @click.prevent="
                                    redirectDepositPage(campaignUnused.code)
                                  "
                                >
                                  {{
                                    translations.promotion.make_deposit ?? "Make Deposit"
                                  }}
                                </a>
                              </span>
                            </div>
                            <p class="ty_bd">
                              <strong
                                >{{
                                  translations.promotion.bonus_code ?? "Bonus Code"
                                }}:</strong
                              >
                              <b class="b-code-promo" id="bonus-code-card-2">{{
                                campaignUnused.code
                              }}</b>
                              <span class="copy-code" @click="copyBonusCode"
                                ><img
                                  title="Copy"
                                  class="copy-code"
                                  src="../../../public/images/copy.png"
                              /></span>
                            </p>

                            <p class="ty_bd">
                              <strong
                                >{{
                                  translations.promotion.wagering ?? "Wagering"
                                }}:</strong
                              >
                              {{ campaignUnused.wagering }}
                            </p>
                            <p class="mb-0 ty_bd" v-if="!campaignUnused.is_valid">
                              <strong
                                >{{
                                  translations.promotion.valid_from ?? "Valid From"
                                }}:</strong
                              >
                              {{ campaignUnused.valid_from }}
                            </p>
                          </div>
                          <button
                            v-if="
                              campaignUnused.description !== null &&
                              typeof campaignUnused.description !== 'undefined'
                            "
                            class="show-tc-campaign"
                            @click="showTAndCModal(campaignUnused.description, index)"
                          >
                            {{
                              selectedCampaignIndex === index
                                ? translations.promotion.tcHide ?? "T&C (Hide)"
                                : translations.promotion.tc ?? "T&C"
                            }}
                          </button>
                          <p
                            class="tc-description-campaign ty_bd"
                            v-if="isTAndCModalVisible && selectedCampaignIndex === index"
                            v-html="termsAndConditions"
                          ></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-center" v-if="bonus.campaign">
                <div class="row w-100">
                  <div id="card-promotion-active" class="col-md-12 col-lg-12 mb-4 pt-5">
                    <div class="card">
                      <span class="gift-icon">
                        <img src="/images/giftIcon.png" alt="Promotion Image" />
                      </span>
                      <div class="card-body">
                        <h5 class="card-title text-uppercase ty_h5">
                          {{ bonus.campaign.name ?? "" }}
                        </h5>
                        <div class="information-campaign">
                          <p class="ty_bd">
                            <strong
                              >{{
                                translations.promotion.amount_added ??
                                " Bonus amount up to"
                              }}:</strong
                            >{{ parseFloat(bonus.amount).toFixed(2) }}
                            {{ playerInfo?.currency?.symbol ?? "" }}
                          </p>

                          <div class="makeDeposit-Line">
                            <span class="ty_bd">
                              <strong
                                >{{ translations.promotion.type ?? "Type" }}:</strong
                              >
                              {{ bonus.campaign.action.name }}
                            </span>
                          </div>
                          <p class="ty_bd">
                            <strong
                              >{{
                                translations.promotion.bonus_code ?? "Bonus Code"
                              }}:</strong
                            >
                            <b class="b-code-promo" id="bonus-code-card">{{
                              bonus.campaign.code
                            }}</b>
                            <span class="copy-code" @click="copyBonusCode"
                              ><img
                                title="Copy"
                                class="copy-code"
                                src="../../../public/images/copy.png"
                            /></span>
                          </p>

                          <p class="ty_bd">
                            <strong
                              >{{
                                translations.promotion.wagering ?? "Wagering"
                              }}:</strong
                            >
                            {{ bonus.campaign.wagering }}
                          </p>

                          <p class="ty_bd" v-if="bonus.freespins > 0">
                            <strong
                              >{{
                                translations.promotion.freespins ?? "Freespins"
                              }}:</strong
                            >
                            {{ bonus.freespins }}
                          </p>

                          <p class="ty_bd" v-if="bonus.freespins_counter > 0">
                            <strong
                              >{{
                                translations.promotion.freespins_used ?? "Freespins used"
                              }}:</strong
                            >
                            {{ bonus.freespins_counter }}
                          </p>
                          <p
                            class="ty_bd"
                            v-if="bonus.freespins > 0 && bonus.campaign.max_bet > 0"
                          >
                            <strong
                              >{{
                                translations.promotion.bet_per_freespin ??
                                "Bet per Freespin"
                              }}:</strong
                            >
                            {{ bonus.campaign.max_bet }}
                            {{ playerInfo?.currency?.symbol ?? "" }}
                          </p>

                          <div class="container-boxturnover">
                            <div class="boxturnover">
                              <p class="mb-0 ty_bd">
                                <b id="pls-note-b"
                                  >{{
                                    translations.promotion.please_note ?? "PLEASE NOTE"
                                  }}:</b
                                >
                                {{
                                  translations.promotion.turnover_requirement ??
                                  "The turnover requirement has not been met yet!"
                                }}
                              </p>
                              <p class="mb-0 ty_bd">
                                {{ translations.promotion.wager ?? "Wager" }}:
                                <b>
                                  {{ bonus.target }}
                                  {{ playerInfo?.currency?.symbol ?? "" }}</b
                                >
                              </p>
                              <p class="mb-0 ty_bd">
                                {{
                                  translations.promotion.missing_wager ?? "Missing Wager"
                                }}
                                :
                                <b>
                                  {{ bonus.countdown ?? "" }}
                                  {{ playerInfo?.currency?.symbol ?? "" }}</b
                                >
                              </p>
                            </div>
                          </div>

                          <button
                            v-if="
                              bonus.campaign.description !== null &&
                              typeof bonus.campaign.description !== 'undefined'
                            "
                            class="show-tc-campaign"
                            @click="showTAndCModal(bonus.campaign.description, index)"
                          >
                            {{
                              selectedCampaignIndex === index
                                ? translations.promotion.tcHide ?? "T&C (Hide)"
                                : translations.promotion.tc ?? "T&C"
                            }}
                          </button>
                          <p
                            class="tc-description-campaign ty_bd"
                            v-if="isTAndCModalVisible && selectedCampaignIndex === index"
                            v-html="termsAndConditions"
                          ></p>
                          <p class="delete-promotion" @click="deleteCampaign">
                            {{
                              translations.promotion.delete_promotion ??
                              "Cancel this promotion"
                            }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="campaignUnusedList.length == 0 && bonus.length == 0">
                <p class="text-center no-campaign-available ty_bd">
                  {{
                    translations.promotion.no_campaign_available ??
                    "No campaign available!"
                  }}
                </p>
              </div>
            </div>
            <div v-if="!isPromotionsLoading">
              <!-- <div v-if="cashBackList.length > 0"> -->
              <h2 class="dashboard-title ty_h3 mt-4">
                {{ translations?.dashboard_menu?.MyCashabck ?? "My Cashback" }}
              </h2>
              <hr class="dashboard-line" />
              <h3
                id=""
                class="text-center ty_h3"
                style="padding-bottom: 35px"
                v-if="cashBackActiveList.length == 0 && cashBackList.length > 0"
              >
                {{
                  translations?.promotion?.cashback_that_can_be_actived ??
                  "Cashback that can be activated"
                }}
              </h3>
              <h3
                id=""
                class="text-center ty_h3"
                style="padding-bottom: 35px"
                v-else-if="cashBackActiveList.length > 0 && cashBackList.length == 0"
              >
                {{
                  translations?.promotion?.cashback_active_players ?? "Cashback active"
                }}
              </h3>

              <!-- </div> -->
              <div
                class="d-flex align-items-center justify-content-center"
                v-if="cashBackList.length !== 0 && cashBackActiveList.length == 0"
              >
                <div class="row w-100">
                  <div
                    class="col-md-12 col-lg-6 col-sm-12 col-12 mb-4"
                    v-for="(cashback, index) in cashBackList"
                    :key="index"
                  >
                    <div class="card">
                      <span class="cashback-icon">
                        <img src="/images/cashback.png" alt="Cashback" />
                      </span>
                      <div class="card-body">
                        <div v-html="cashback.name" class="cashbacktitle card-title-unused text-uppercase">
                        </div>
                        <div class="information-cashback">
                          <div class="cashback-info">
                            <p class="ty_bd">
                              <strong
                                >{{
                                  translations.promotion.amount_added ??
                                  "Bonus amount up to"
                                }}:</strong
                              >
                              {{ parseFloat(cashback.amount).toFixed(2) }}
                              {{ cashback.currency.symbol }}
                            </p>
                            <div
                              class="d-flex justify-content-between align-items-center w-100"
                              style="margin-bottom: 15px"
                            >
                              <p class="ty_bd mb-0">
                                <strong
                                  >{{ translations.promotion.type ?? "Type" }}:</strong
                                >
                                Cashback
                              </p>
                              <span class="ty_bd">
                                <a
                                  href="#"
                                  class="ty_bd"
                                  @click.prevent="activateCashback(cashback)"
                                >
                                  {{
                                    translations.promotion.activateCashback ?? "Active"
                                  }}
                                </a>
                              </span>
                            </div>

                            <p class="ty_bd">
                              <strong
                                >{{
                                  translations?.promotion?.cashback_code ??
                                  "Cashback Code"
                                }}:</strong
                              >
                              <b class="b-code-promo">{{ cashback.code }}</b>
                            </p>
                            <p class="ty_bd text-capitalize">
                              <strong
                                >{{
                                  translations?.promotion?.repeat_frequency ??
                                  "Repeat frequency"
                                }}:</strong
                              >
                              {{ cashback.repeat_frequency }}
                            </p>
                            <p class="ty_bd text-capitalize">
                              <strong
                                >{{
                                  translations?.promotion?.cashback_percentage ??
                                  "Cashback Percentage"
                                }}:</strong
                              >
                              {{ cashback.percentage }}%
                            </p>
                            <p class="ty_bd text-capitalize">
                              <strong
                                >{{
                                  translations?.promotion?.expires_at_cashback ??
                                  "Expires At"
                                }}:</strong
                              >
                              {{ cashback.expires_at }}
                            </p>
                            <button
                              v-if="
                                cashback.description !== null &&
                                typeof cashback.description !== 'undefined'
                              "
                              class="show-tc-campaign"
                              @click="showTAndCModalCashback(cashback.description, index)"
                            >
                              {{
                                selectedCashBackIndex === index
                                  ? translations.promotion.tcHide ?? "T&C (Hide)"
                                  : translations.promotion.tc ?? "T&C"
                              }}
                            </button>
                            <p
                              class="tc-description-campaign ty_bd"
                              v-if="
                                isTcAndCModalCashBackVisible &&
                                selectedCashBackIndex === index
                              "
                              v-html="termsAndConditionsCashback"
                            ></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="d-flex justify-content-center"
                v-else-if="cashBackActiveList && cashBackActiveList.length !== 0"
              >
                <div class="row w-100">
                  <div
                    id="card-promotion-active"
                    class="col-md-12 col-lg-12 mb-4 pt-5"
                    v-for="(cashBackActiveList, index) in cashBackActiveList"
                    :key="index"
                  >
                    <div class="card">
                      <span class="gift-icon">
                        <img src="/images/cashback.png" alt="Cashback" />
                      </span>
                      <div class="card-body">
                        <h5 class="card-title text-uppercase ty_h5">
                          {{ cashBackActiveList?.campaign?.name ?? "" }}
                        </h5>
                        <div class="information-campaign">
                          <p class="ty_bd">
                            <strong
                              >{{
                                translations.promotion.amount_added ??
                                "Bonus amount up to"
                              }}:</strong
                            >
                            {{ parseFloat(cashBackActiveList?.amount).toFixed(2) }}
                            {{ playerInfo?.currency?.symbol ?? "" }}
                          </p>
                          <p class="ty_bd text-capitalize">
                            <strong>{{ translations.promotion.type ?? "Type" }}:</strong>
                            {{ cashBackActiveList?.campaign?.action?.name }}
                          </p>
                          <p class="ty_bd">
                            <strong
                              >{{
                                translations.promotion.bonus_code ?? "Bonus Code"
                              }}:</strong
                            >
                            <b class="b-code-promo" id="bonus-code-card">{{
                              cashBackActiveList?.campaign?.code
                            }}</b>
                          </p>

                          <p class="ty_bd">
                            <strong
                              >{{
                                translations.promotion.repeat_frequency ??
                                "Repeat Frequency"
                              }}:</strong
                            >
                            {{ cashBackActiveList?.campaign?.repeat_frequency }}
                          </p>

                          <p class="ty_bd">
                            <strong
                              >{{
                                translations.promotion.cashback_percentage ??
                                "CashBack Percentage"
                              }}:</strong
                            >
                            {{ cashBackActiveList?.campaign?.percentage }}%
                          </p>

                          <p class="ty_bd">
                            <strong
                              >{{
                                translations.promotion.cashback_expiresAt ?? "Expires At"
                              }}:</strong
                            >
                            {{ cashBackActiveList?.expires_at }}
                          </p>
                          <button
                            v-if="
                              cashBackActiveList.campaign?.description !== null &&
                              typeof cashBackActiveList?.campaign?.description !==
                                'undefined'
                            "
                            class="show-tc-campaign"
                            @click="
                              showTAndCModalCashback(
                                cashBackActiveList?.campaign?.description,
                                index
                              )
                            "
                          >
                            {{
                              selectedCashBackIndex === index
                                ? translations.promotion.tcHide ?? "T&C (Hide)"
                                : translations.promotion.tc ?? "T&C"
                            }}
                          </button>
                          <p
                            class="tc-description-campaign ty_bd"
                            v-if="
                              isTcAndCModalCashBackVisible &&
                              selectedCashBackIndex === index
                            "
                            v-html="termsAndConditionsCashback"
                          ></p>
                          <p
                            class="delete-promotion"
                            @click="deleteCashabck(cashBackActiveList)"
                          >
                            {{
                              translations.promotion.delete_promotion ??
                              "Cancel this promotion"
                            }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else-if="cashBackList.length == 0 && cashBackActiveList.length == 0">
                <p class="text-center no-campaign-available ty_bd">
                  {{
                    translations.promotion.no_cashback_available ??
                    "No cashabck available!"
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DashBoard from "./DashBoard.vue";
import MainLoader from "@/components/global/MainLoader.vue";
import { mapGetters } from "vuex";
import PreLoader from "@/components/global/PreLoader.vue";
export default {
  name: "my-bonus",
  components: {
    DashBoard,
    MainLoader,
    PreLoader,
  },
  data() {
    return {
      isTAndCModalVisible: false,
      isTcAndCModalCashBackVisible: false,
      termsAndConditions: null,
      termsAndConditionsCashback: null,
      selectedCampaignIndex: -1,
      selectedCashBackIndex: -1,
      language_selected: localStorage.getItem("language"),
      bonus: [],
      campaignUnused: [],
    };
  },
  methods: {
    removeTags(html) {
      return html.replace(/(<([^>]+)>)/gi, "");
    },
    copyBonusCode() {
      var copyText =
        document.getElementById("bonus-code-card") ??
        document.getElementById("bonus-code-card-2");
      const tempTextArea = document.createElement("textarea");
      tempTextArea.value = copyText.innerText;
      document.body.appendChild(tempTextArea);
      tempTextArea.select();
      document.execCommand("copy");
      document.body.removeChild(tempTextArea);
    },
    redirectDepositPage(bonus_code) {
      //   this.$store.dispatch("promotion/saveBonusCode", bonus_code);
      this.$router.push({
        name: "account-balance",
        params: { lang: this.language_selected },
        query: { tab: "deposit", code: bonus_code },
      });
    },
    activateCashback(cashback) {
      let body = {
        player_id: this.playerInfo?.id,
        campaign_code: cashback.code,
        action_id: cashback.action.id,
        force_campaign: true,
      };
      console.log(body);
      this.$store.dispatch("promotion/activeCashback", body);
    },
    deleteCampaign() {
      this.$store.dispatch(
        "promotion/deleteCampaign",
        this.playerInfo.bonus.player.user_id
      );
    },
    deleteCashabck(cashBackInfo) {
      console.log(this.cashBackActiveList);
      let body = {
        player_id: this.playerInfo?.id,
        campaign_id: cashBackInfo?.campaign_id,
      };
      console.log(body);

      this.$store.dispatch("promotion/deleteCashback", { body });
    },
    showTAndCModal(description, index) {
      if (index === this.selectedCampaignIndex) {
        // If the clicked campaign is already open, close it
        this.isTAndCModalVisible = false;
        this.selectedCampaignIndex = -1;
      } else {
        // If a different campaign is clicked, open its T&C modal
        this.isTAndCModalVisible = true;
        this.termsAndConditions = description;
        this.selectedCampaignIndex = index;
      }
    },
    showTAndCModalCashback(description, index) {
      if (index === this.selectedCashBackIndex) {
        // If the clicked campaign is already open, close it
        this.isTcAndCModalCashBackVisible = false;
        this.selectedCashBackIndex = -1;
      } else {
        // If a different campaign is clicked, open its T&C modal
        this.isTcAndCModalCashBackVisible = true;
        this.termsAndConditionsCashback = description;
        this.selectedCashBackIndex = index;
      }
    },
  },
  computed: {
    ...mapGetters("promotion", [
      "promotionsList",
      "promotion",
      "isPromotionsLoading",
      "isPromoIdLoading",
      "campaignUnusedList",
      "cashBackList",
      "cashBackActiveList",
    ]),
    ...mapGetters("player", [
      "token",
      "isAuthenticated",
      "isPlayerLoading",
      "playerInfo",
    ]),
    ...mapGetters("translations", ["isTranslationLoading", "translations"]),
    ...mapGetters("skinSettings", ["allInformationIsLoading"]),
  },
  created() {
    const callPromotion = () => {
     this.$store.dispatch("promotion/callCashback", this.playerInfo?.id);
     this.$store.dispatch("promotion/callCashbackActiveForPlayer", this.playerInfo?.id);
      this.$store
        .dispatch("promotion/callUnusedCampaign", this.playerInfo?.id)
        .then(() => {
          if (this.playerInfo && this.playerInfo?.bonus) {
            this.bonus = this.playerInfo?.bonus;
          }
        });
    };
    if (this.playerInfo?.id == null || this.playerInfo?.id == undefined) {
      this.$store.dispatch("player/callUserInformation").then(callPromotion);
    } else {
      callPromotion();
    }

    this.$router.push({ params: { lang: localStorage.getItem("language") } });
  },
  mounted() {},
};
</script>

<style scoped>
.ty_bd {
  font-size: clamp(14px, 3vw, 17px) !important;
}

#text-header-bonus {
  font-size: clamp(20px, 3vw, 24px) !important;
  padding-bottom: 35px;
}

.card-title-unused h5 {
  font-size: clamp(18px, 3vw, 20px) !important;
}

.boxturnover {
  border: 2px solid #e74c3c;
  border-radius: 10px;
  padding: 15px;
  color: #e74c3c;
  background-color: #f9ebec;
  margin-bottom: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

#pls-note-b {
  color: #e74c3c;
}

.boxturnover b {
  color: #c0392b; /* Colore del testo in grassetto */
}
.card-img-top {
  max-width: 100%;
  object-fit: contain;
  max-height: 150px;
}
.card {
  border: 3px solid rgb(151 146 145 / 20%);
  border-radius: 15px;
  box-shadow: 0 1rem 2rem rgb(0 0 0 / 10%);
  position: relative;
  height: 100%;
}

.promo-infos {
  min-height: 180px;
  margin-bottom: 15px;
}

.card-title {
  font-size: 15px;
  text-align: center;
  min-height: 100px;
  padding: 10px 0px;
  white-space: pre-wrap;
  width: 100%;
  line-height: 1.5rem;
}

.card-title-unused {
  font-size: 15px;
  text-align: center;
  min-height: 100px;
  padding: 10px 0px;
  white-space: pre-wrap;
  width: 80%;
  line-height: 1.5rem;
}

.card-body {
  padding: 0.5rem 1rem;
}
.makeDeposit-Line {
  margin-bottom: 15px;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

p {
  margin-bottom: 15px;
  text-align: left !important;
  font-family: "Poppins", sans-serif;
}

a {
  font-weight: 600;
  color: #fdbd1a;
  font-size: 12px;
  transition: 0.3s all;
  text-decoration: none;
}

a:hover {
  color: #ffa800;
  text-decoration: underline;
}

h5 {
  margin-bottom: 0px;
}

.information-campaing {
  padding: 20px 5px;
}

.gift-icon {
  position: absolute;
  top: -5%;
  right: 0%;
}

.cashback-icon {
  position: absolute;
  top: -5%;
  right: 0%;
}

.cashback-icon img {
  max-width: 60px;
  max-height: 60px;
  -o-object-fit: contain;
  object-fit: contain;
}
 
.gift-icon img {
  max-width: 70px;
  max-height: 70px;
  -o-object-fit: contain;
  object-fit: contain;
}

.no-campaign-available {
  line-height: 1.44;
  font-size: 20px;
  font-weight: 600;
  opacity: 0.8;
}

.show-tc-campaign {
  background: transparent;
  border: none;
  text-decoration: none;
  color: green;
  transition: 0.4s all;
  margin-bottom: 5px;
  font-weight: 600;
}
.show-tc-campaign:hover {
  text-decoration: underline;
}

.tc-description-campaign {
  margin: 10px 0px;
  color: rgb(90, 90, 90);
  font-weight: 500;
  line-height: 1.4rem;
  transition: 0.4s all;
}

.delete-promotion {
  font-weight: 600;
  font-size: 16px;
  line-height: 1.2rem;
  margin: 10px 0px;
  color: rgb(220, 44, 44);
  text-align: center !important;
  cursor: pointer;
}

@media (max-width: 576px) {
  .card-title {
    text-align: left;
    padding: 10px 5px;
    min-height: 70px;
    width: 80%;
  }

  .card-title-unused {
    text-align: left;
    padding: 10px 5px;
    min-height: 70px;
    width: 80%;
  }
}

.b-code-promo {
  color: orange !important;
}

.copy-code {
  max-height: 15px;
  max-width: 15px;
  margin-left: 5px;
  object-fit: contain;
  cursor: pointer;
}

.cashback-info {
  min-height: 180px;
  margin-bottom: 15px;
}
</style>
