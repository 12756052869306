<template>
  <section id="PromotionSection">
    <!--  
    <div class="PromotionCategory">
      <div class="container-max">
        <div class="menu">
          <a   class="item">
            <div>All promotions</div>
          </a>
          <a   class="item">
            <div>New Players</div>
          </a>
          <a  class="item">
            <div>Deposits</div>
          </a>
        </div>
      </div>
    </div> -->
    <PromotionCard />
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import PromotionCard from "./PromotionCard.vue";
export default {
  name: "promotionSections",
  components: { PromotionCard },
  computed: {
    ...mapGetters("translations", ["translations", "isTranslationLoading"]),
  },
  methods: {},
  mounted() {},
  created() {},
};
</script>
