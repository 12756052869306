<template>
  <div :style="{ opacity: isModalOpen ? 1 : 0 }">
    <RegistrationForm />
  </div>
  <section class="gamePage-detail">
    <div
      v-if="!isDesktop && !demoNotAvailable && currentGameName != null"
      class="mobile-frame-container"
    >
      <div class="game-background-mobile" :style="backgroundImage"></div>
      <div class="game-background-overlap-mobile"></div>
      <div ref="gameFrame">
        <transition name="fade">
          <div v-if="isFullscreen" class="disable-fullscreen">
            <button class="btn" @click="allowFullscreen2" style="z-index: 99999">
              <img
                src="/images/fullscreen.svg"
                alt=""
                class="button-options-fullscreen-come-back"
              />
            </button>
          </div>
        </transition>
        <div class="over-style-page">
          <div
            class="d-flex justify-content-center align-items-center w-100 mobile-view-bonus"
            v-if="playerCampaign?.id && isSocketOn && bonusBoxVisible"
          >
            <div class="boxbonus">
              <div class="col-12 p-0 py-2 m-0 row boxopen flex-column" v-if="openBonus">
                <div class="openBox position-relative" @click="seebonus">
                  <img
                    loading="eager"
                    title="Close Bonus"
                    class="icon-svg-eye"
                    src="/images/svg-sticky-nav/eyeClose.svg"
                    alt=""
                  />
                </div>
                <div
                  class="col-5 col-lg-2 d-flex justify-content-between align-items-center w-100 information-turnover"
                >
                  <p
                    v-if="
                      parseInt(playerCampaign?.type) == 1 ||
                      parseInt(playerCampaign?.freespins_counter) >=
                        parseInt(playerCampaign?.freespins)
                    "
                    class="mb-0"
                  >
                    <b>{{ translations.casino.turnover ?? "Turnover" }}:</b>
                    {{ playerInfo?.currency?.symbol ?? "" }}
                    {{ parseFloat(playerCampaign?.target).toFixed(2) }}
                  </p>
                  <p v-if="parseFloat(playerCampaign?.counter) > 0" class="mb-0">
                    <b>{{ translations.casino.counter ?? "Counter" }}:</b>
                    {{ playerInfo?.currency?.symbol ?? "" }}
                    {{ parseFloat(playerCampaign?.counter).toFixed(2) }}
                  </p>
                  <p v-if="parseInt(playerCampaign?.type) != 1" class="mb-0">
                    <b>{{ translations.casino.freespins ?? "Freespins" }}</b
                    >: {{ playerCampaign?.freespins_counter }}/{{
                      playerCampaign?.freespins
                    }}
                  </p>
                  <p class="mb-0">
                    <b>{{ translations.casino.max_bet_bonus ?? "Max bet bonus" }}</b
                    >:
                    {{ playerInfo?.currency?.symbol ?? "" }}
                    {{ playerCampaign?.max_bet ?? "" }}
                  </p>
                  <p class="mb-0" v-if="parseInt(playerCampaign?.type) != 1">
                    <b>{{ translations.casino.max_bet_bonus ?? "Max bet freespins" }}</b
                    >:
                    {{ playerInfo?.currency?.symbol ?? "" }}
                    {{ playerCampaign?.max_freespins_bet ?? "" }}
                  </p>
                </div>
                <div
                  class="d-flex justify-content-center align-items-center container-mobile w-100"
                  v-if="parseFloat(playerCampaign?.target) > 0"
                >
                  <div class="skill-main">
                    <div class="skill-wrrap">
                      <div class="skill-bar">
                        <div
                          class="skill-per"
                          :per="playerInfo?.bonus?.counter_percentage + '%'"
                          :style="`width: ${playerCampaign?.counter_percentage}%;`"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="d-flex justify-content-center align-items-center"
                v-else-if="!openBonus"
                @click="seebonus"
              >
                <img
                  loading="eager"
                  title="Show Bonus"
                  class="icon-svg-eye"
                  src="/images/svg-sticky-nav/eyeOpen.svg"
                  alt=""
                  style="top: 0"
                />
              </div>
            </div>
          </div>
          <iframe
            frameborder="0"
            class="game-frame mobile"
            :src="currentGamePath"
            scrolling="yes"
            :style="containerStyle"
          ></iframe>
        </div>
      </div>
    </div>
    <div v-else>
      <transition name="fade">
        <div class="full-container" v-if="!isGameLoading">
          <div
            class="game-background"
            :style="{ backgroundImage: 'url(' + backgroundUrl + ')' }"
          ></div>
          <div class="game-background-overlap"></div>
          <div
            class="d-flex justify-content-center align-items-center w-100 row-column"
            style="min-height: 100vh"
          >
            <div
              class="game"
              :class="isLogged || promotionsList.length === 0 ? 'extend-iframe' : ''"
              v-if="!isGameLoading"
            >
              <div class="row d-flex justify-content-center align-items-center">
                <div v-if="!demoNotAvailable">
                  <div v-if="isDesktop" ref="gameFrame">
                    <transition name="fade">
                      <div v-if="isFullscreen" class="disable-fullscreen">
                        <button
                          class="btn"
                          @click="allowFullscreen2"
                          style="z-index: 99999"
                        >
                          <img
                            class="button-options-fullscreen-come-back"
                            src="/images/fullscreen.svg"
                            alt=""
                          />
                        </button>
                      </div>
                    </transition>
                    <div
                      class="wrapper-header-btn-game-page mb-2 align-items-center"
                      :class="
                        !isLogged && promotionsList.length !== 0
                          ? 'show-bar-name'
                          : 'show-bar-name-no-logged'
                      "
                    >
                      <div
                        class="d-flex justify-content-center align-items-center"
                        style="width: 100%"
                      >
                        <div id="test1" class="game-title">{{ currentGameName }}</div>
                      </div>
                      <button class="btn" @click="goGamesPage">
                        <img
                          src="/images/leftArrow.svg"
                          alt=""
                          class="button-options-fullscreen-come-back"
                        />
                      </button>
                      <button class="btn" @click="allowFullscreen2" v-if="isDesktop">
                        <img
                          src="/images/fullscreen.svg"
                          alt=""
                          class="button-options-fullscreen-come-back"
                        />
                      </button>
                    </div>
                    <div
                      class="d-flex justify-content-center align-items-center w-100 position-relative"
                      v-if="playerCampaign?.id && isSocketOn && bonusBoxVisible"
                    >
                      <div class="boxbonus">
                        <div
                          class="col-12 p-0 py-2 m-0 row boxopen flex-column"
                          v-if="openBonus"
                        >
                          <div class="openBox position-relative" @click="seebonus">
                            <img
                              loading="eager"
                              title="Close Bonus"
                              class="icon-svg-eye"
                              src="/images/svg-sticky-nav/eyeClose.svg"
                              alt=""
                            />
                          </div>
                          <div
                            class="col-5 col-lg-2 d-flex justify-content-between align-items-center w-100 information-turnover"
                          >
                            <p
                              v-if="
                                parseInt(playerCampaign?.type) == 1 ||
                                parseInt(playerCampaign?.freespins_counter) >=
                                  parseInt(playerCampaign?.freespins)
                              "
                              class="mb-0"
                            >
                              <b>{{ translations.casino.turnover ?? "Turnover" }}:</b>
                              {{ playerInfo?.currency?.symbol ?? "" }}
                              {{ parseFloat(playerCampaign?.target).toFixed(2) }}
                            </p>
                            <p
                              class="mb-0"
                              v-if="parseFloat(playerCampaign?.counter) > 0"
                            >
                              <b>{{ translations.casino.counter ?? "Counter" }}:</b>
                              {{ playerInfo?.currency?.symbol ?? "" }}
                              {{ parseFloat(playerCampaign?.counter).toFixed(2) }}
                            </p>
                            <p v-if="parseInt(playerCampaign?.type) != 1" class="mb-0">
                              <b>{{ translations.casino.freespins ?? "Freespins" }}</b
                              >: {{ playerCampaign?.freespins_counter }}/{{
                                playerCampaign?.freespins
                              }}
                            </p>
                            <p class="mb-0">
                              <b>{{
                                translations.casino.max_bet_bonus ?? "Max bet bonus"
                              }}</b
                              >:
                              {{ playerInfo?.currency?.symbol ?? "" }}
                              {{ playerCampaign?.max_bet ?? "" }}
                            </p>
                            <p class="mb-0" v-if="parseInt(playerCampaign?.type) != 1">
                              <b>{{
                                translations.casino.max_bet_bonus ?? "Max bet freespins"
                              }}</b
                              >:
                              {{ playerInfo?.currency?.symbol ?? "" }}
                              {{ playerCampaign?.max_freespins_bet ?? "" }}
                            </p>
                          </div>
                          <div
                            class="d-flex justify-content-center align-items-center container-mobile w-100"
                            v-if="parseFloat(playerCampaign?.target) > 0"
                          >
                            <div class="skill-main">
                              <div class="skill-wrrap">
                                <div class="skill-bar">
                                  <div
                                    class="skill-per"
                                    :per="playerCampaign?.counter_percentage + '%'"
                                    :style="`width: ${playerCampaign?.counter_percentage}%;`"
                                  ></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="d-flex justify-content-center align-items-center"
                          v-else-if="isSocketOn && !openBonus"
                          @click="seebonus"
                        >
                          <img
                            loading="eager"
                            title="Show Bonus"
                            class="icon-svg-eye"
                            src="/images/svg-sticky-nav/eyeOpen.svg"
                            alt=""
                            style="top: 0"
                          />
                        </div>
                      </div>
                    </div>
                    <!-- bonusBox -->
                    <iframe
                      class="game-frame"
                      :src="currentGamePath"
                      :class="isFullscreen ? 'fullscreen' : ''"
                      scrolling="no"
                    ></iframe>
                  </div>
                </div>
                <div v-else class="error-demoNotavailable-recharge-account">
                  <div class="DemogameNotAvailable">
                    {{ demoNotAvailableMessage }}
                  </div>
                  <div class="container" v-if="isBalanceInsufficient">
                    <div class="row d-flex justify-content-center">
                      <div class="recharge-btn-wrapper">
                        <button
                          class="btn redirect-to-deposit"
                          @click="redirectToDeposit"
                        >
                          {{ translations?.buttons?.make_a_deposit ?? "MAKE A DEPOSIT" }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="isDesktop && !isLogged && promotionsList.length !== 0"
              class="comand-promo-wrapper"
            >
              <div
                class="promotion-container-game-page d-flex flex-column justify-content-start align-items-center w-100"
              >
                <div class="wrapper-header-btn-game-page flex-class show-bar-name-2 mb-2">
                  <div style="width: 100%">
                    <div id="test2" class="game-title">{{ currentGameName }}</div>
                  </div>
                  <button class="btn" @click="goGamesPage">
                    <img
                      src="/images/leftArrow.svg"
                      alt=""
                      class="button-options-fullscreen-come-back"
                    />
                  </button>
                  <button class="btn" @click="allowFullscreen2" v-if="isDesktop">
                    <img
                      src="/images/fullscreen.svg"
                      alt=""
                      class="button-options-fullscreen-come-back"
                    />
                  </button>
                </div>
                <div
                  class="game-modal-banner-campaign"
                  v-if="!isLogged && promotionsList.length !== 0"
                >
                  <div v-for="(promotion, index) in promotionsList" :key="index">
                    <div class="container-promotion-game-page" v-if="index === 0">
                      <img
                        class="promotion-thumb-game-modal"
                        :src="promotion?.media?.full_url"
                        alt=""
                      />
                      <h3 class="game-modal-banner-title text-center ty_h5 mt-1 mb-0">
                        {{ promotion.name }}
                      </h3>
                      <div
                        class="game-modal-vhtml-body ty_bd mb-0 text-center"
                        v-html="promotion?.content"
                      ></div>
                      <button
                        class="game-modal-button-register-now"
                        @click="showModalRegistration"
                      >
                        {{
                          translations?.buttons?.register_now_game_modal ?? "Register Now"
                        }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import RegistrationForm from "@/components/ui/RegistrationForm.vue";
export default {
  components: {
    RegistrationForm,
  },
  data() {
    return {
      backgroundUrl: null,
      isFullscreen: false,
      dataGame: null,
      isDesktop: window.innerWidth > 767,
      isLogged: localStorage.getItem("X19fYWNjZXNzX19f") ?? false,
      maxHeight: window.innerHeight,
      mode: localStorage.getItem("game-mode"),
      openBonus: true,
    };
  },

  computed: {
    containerStyle() {
      return {
        maxHeight: `${this.maxHeight}px`,
        minHeight: `${this.maxHeight}px`,
        overflow: "hidden",
      };
    },
    ...mapGetters("casinoGames", [
      "allSkinGames",
      "game_detail",
      "isAllSkinGamesLoading",
      "allSkinGames_loaded",
    ]),
    ...mapGetters("device", ["isDesktop", "isMobile"]),
    ...mapGetters("play", [
      "currentGameName",
      "currentGamePath",
      "isGameLoading",
      "isForReal",
      "demoNotAvailable",
      "demoNotAvailableMessage",
      "currentGameId",
      "currentProviderId",
      "isBalanceInsufficient",
    ]),
    ...mapGetters("openMenuLogin", ["isModalOpen"]),
    ...mapGetters("translations", ["translations"]),
    ...mapGetters("player", [
      "playerInfoLoading",
      "playerInfo",
      "playerBalance",
      "playerCampaign",
      "isSocketOn",
    ]),
    ...mapGetters("promotion", ["promotionsList", "promotion", "bonusBoxVisible"]),
    backgroundImage() {
      return `background-image: url('${this.backgroundUrl}')`;
    },
  },
  watch: {
    // isAllSkinGamesLoading(value) {
    //   if (!value || value === null) {
    //     this.launchGame();
    //   }
    // },
    isForReal(value) {
      return value;
    },
    isMobile(value) {
      console.log(value);
    },
  },
  methods: {
    seebonus() {
      this.openBonus = !this.openBonus;
    },
    handleResize() {
      this.isDesktop = window.innerWidth > 767;
    },
    handleResize2() {
      this.maxHeight = window.innerHeight;
    },
    launchGame() {
      const skinGames = this.game_detail;
      // console.log("SKIN_GAMES___", skinGames);
      let gameId = skinGames.id;
      let providerId = this.$route.params.providerId;
      //controllo che skinGames non sia ull/undefined e che sia un array
      if (skinGames) {
        if (
          this.mode === "real" &&
          (this.playerInfo?.currency?.code == null ||
            this.playerInfo?.currency?.code == undefined)
        ) {
          this.$store.dispatch("player/callingAboutUser").then(() => {
            this.$store.dispatch("play/play", skinGames).then(() => {
              this.$store
                .dispatch("promotion/forcedCampaigns", {
                  campaign_id: this.playerInfo?.bonus?.campaign_id,
                  player_id: this.playerInfo?.user_id,
                  game_id: gameId,
                  provider_id: providerId,
                })
                .then(() => {
                  // console.log(this.playerCampaign);
                });
            });
          });
        } else {
          this.$store.dispatch("play/play", skinGames).then(() => {
            if (this.mode === "real") {
              this.$store
                .dispatch("promotion/forcedCampaigns", {
                  campaign_id: this.playerInfo?.bonus?.campaign_id,
                  player_id: this.playerInfo?.user_id,
                  game_id: gameId,
                  provider_id: providerId,
                })
                .then(() => {
                  // console.log(this.playerCampaign);
                });
            }
          });
        }
        this.backgroundUrl =
          "https://storage.googleapis.com/stage-camasy-images/" + skinGames.thumb;
      } else {
        console.log("error");
      }
    },
    async checkPlayerBalance() {
      this.$store.dispatch(
        "player/callPlayerBalance",
        sessionStorage.getItem("user-token")
      );
    },
    allowFullscreen2() {
      this.isFullscreen = !this.isFullscreen;
      const iframeElement = this.$refs.gameFrame;

      if (this.isFullscreen) {
        if (iframeElement.requestFullscreen) {
          iframeElement.requestFullscreen();
        } else if (iframeElement.mozRequestFullScreen) {
          iframeElement.mozRequestFullScreen();
        } else if (iframeElement.webkitRequestFullscreen) {
          iframeElement.webkitRequestFullscreen();
        } else if (iframeElement.msRequestFullscreen) {
          iframeElement.msRequestFullscreen();
        }
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        }
      }
    },
    goGamesPage() {
      let route = sessionStorage.getItem("from");
      if (route) {
        sessionStorage.removeItem("from");
        window.location.href = route;
      } else {
        sessionStorage.removeItem("from");
        window.location.href = "/";
      }
    },
    redirectToDeposit() {
      window.location.href = `/${localStorage.getItem("language")}/account-balance`;
    },
    run() {
      let gameId = this.$route.params.gameId;
      let providerId = this.$route.params.providerId;
      this.$store
        .dispatch("casinoGames/launchGamePage", {
          game_id: gameId,
          provider_id: providerId,
        })
        .then(() => {
          this.launchGame();
        });
    },
    showModalRegistration() {
      this.$store.dispatch("openMenuLogin/setStateModalRegistration", true);
    },
  },
  created() {
    this.$store.dispatch("promotion/callSkinPromotions").then(() => {
      if (this.promotionsList == undefined) {
        this.promotionsList = this.promotions.length > 0 ? [this.promotions[0]] : [];
      }
    });
    this.run();
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    window.addEventListener("resize", this.handleResize2);
  },
  beforeUnmount() {
    this.$store.dispatch("play/stopGame");
    localStorage.removeItem("game-mode");
    sessionStorage.removeItem("from");
  },
};
</script>

<style scoped>
.skill-main {
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.skill-main .skill-wrrap {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.skill-main .skill-wrrap .skill-name {
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;
}
.skill-main .skill-wrrap .skill-bar {
  height: 20px;
  background-color: #064c6b;
  border-radius: 8px;
  box-shadow: 0px -3px 4.4px 0px rgba(0, 0, 0, 0.45) inset,
    0px 1.786px 1.786px 0px rgba(0, 0, 0, 0.25), 0px 4px 4.9px 0px rgba(0, 0, 0, 0.25),
    0px 3px 10px -5px rgba(255, 255, 255, 0.44) inset,
    0px -2px 4px 0px rgba(0, 0, 0, 0.25);
}
.skill-main .skill-wrrap .skill-per {
  height: 20px;
  background-color: #ffd700;
  border-radius: 8px;
  width: 0;
  transition: 1s linear;
  position: relative;
  box-shadow: 0px -3px 4.4px 0px rgba(0, 0, 0, 0.45) inset,
    0px 1.786px 1.786px 0px rgba(0, 0, 0, 0.25), 0px 4px 4.9px 0px rgba(0, 0, 0, 0.25),
    0px 3px 10px -5px rgba(255, 255, 255, 0.44) inset,
    0px -2px 4px 0px rgba(0, 0, 0, 0.25);
}
.skill-main .skill-wrrap .skill-per:before {
  content: attr(per);
  position: absolute;
  padding: 4px 6px;
  background-color: #064c6b;
  border: 1px solid #00000047;
  color: #fff;
  font-size: 11px;
  font-weight: bold;
  border-radius: 4px;
  top: -3px;
  right: -5px;
  transform: translateX(50%);
  border-radius: 21px;
  box-shadow: 0px -3px 4.4px 0px rgba(0, 0, 0, 0.45) inset,
    0px 1.786px 1.786px 0px rgba(0, 0, 0, 0.25), 0px 4px 4.9px 0px rgba(0, 0, 0, 0.25),
    0px 3px 10px -5px rgba(255, 255, 255, 0.44) inset,
    0px -2px 4px 0px rgba(0, 0, 0, 0.25);
}
.boxopen,
.boxbonus {
  width: 100%;
  background-color: #00283a;
  color: white;
  border-bottom: 1px solid #ffffff4f;
  border-radius: 5px 5px 0px 0px;
  box-shadow: 0px -3px 4.4px 0px rgba(0, 0, 0, 0.45) inset,
    0px 1.786px 1.786px 0px rgba(0, 0, 0, 0.25), 0px 4px 4.9px 0px rgba(0, 0, 0, 0.25),
    0px 3px 10px -5px rgba(255, 255, 255, 0.44) inset,
    0px -2px 4px 0px rgba(0, 0, 0, 0.25);
}

.boxopen p {
  transition: 0.3s all;
  color: white;
}

.box .boxbonus p {
  color: #fff;
  font-size: 14px;
}
.boxbonus .bonus {
  background-color: var(--dark-orange);
  border-color: var(--dark-orange);
  color: #111;
  font-size: 0.8rem;
  font-weight: 600;
  padding: 0 0.5rem 0 0.5rem;
  width: fit-content;
  border-radius: 3px;
}
.boxbonusmobile {
  z-index: 99999999;
  top: 0;
  position: fixed;
}

.information-turnover b {
  text-transform: capitalize !important;
  color: white !important;
}

@media only screen and (max-width: 769px) {
  .boxbonus p {
    font-size: 12px;
  }

  .information-turnover {
    flex-direction: row;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
  }

  .container-mobile {
    justify-content: center !important;
    width: 100%;
    margin: 5px 0px !important;
  }

  .mode-type {
    flex-direction: row;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 5px;
  }

  .wrapper-skill-main {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0px;
  }
}
.openBox {
  position: relative;
  z-index: 9999;
  top: 0px;
  right: 0px;
  display: flex;
  justify-content: end;
  align-items: center;
}
/* 
.openBox img {
  width: 18px;
  height: 12px;
  margin-left: 12px;
  margin-right: 12px;
  margin-bottom: 5px;
  margin-top: 5px;
} */

.gift-icon {
  padding: 0px 10px;
}

.openBox .arrowopen {
  transform: rotate(180deg);
}
.openBox .openBonus {
  transition: all 2s ease-in-out;
}

.mobile-view-bonus {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
}

.mobile-view-bonus-show {
  position: fixed;
  top: 0px;
  left: 0;
  z-index: 99999999;
  background: #00283a;
  box-shadow: 0px -3px 4.4px 0px rgba(0, 0, 0, 0.45) inset,
    0px 1.786px 1.786px 0px rgba(0, 0, 0, 0.25), 0px 4px 4.9px 0px rgba(0, 0, 0, 0.25),
    0px 3px 10px -5px rgba(255, 255, 255, 0.44) inset,
    0px -2px 4px 0px rgba(0, 0, 0, 0.25);
}

.icon-svg-eye {
  padding: 3px;
  max-width: 35px;
  max-height: 30px;
  position: relative;
  -o-object-fit: contain;
  object-fit: contain;
  top: -5px;
  cursor: pointer;
}

p {
  font-size: 13px !important;
}

.py-2 {
  padding-top: 2px !important;
  padding-bottom: 8px !important;
}
</style>
