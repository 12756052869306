<template>
  <div class="box-cont" @click="exitAdminMode">
    <img src="../../../public/images/quit.png" />
  </div>
</template>

<script>
import Swal from "sweetalert2";

export default {
  data() {
    return {};
  },
  methods: {
    exitAdminMode() {
      Swal.fire({
        title: "Are you sure you want to exit Admin Mode?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, exit!",
        cancelButtonText: "Cancel",
      }).then((result) => {
        if (result.isConfirmed) {
          sessionStorage.removeItem("adminNickname");
          sessionStorage.removeItem("adminPassword");
          sessionStorage.removeItem("vanilla-translations");
          window.location.href = "/";
        }
      });
    },
  },
};
</script>

<style scoped>
.box-cont {
  position: fixed;
  padding: 20px 20px;
  top: 115px;
  right: 0;
  background: #fff;
  border-radius: 0;
  box-shadow: var(--light-orange);
  z-index: 200;
  transition: var(--medium-trans);
  border: 2px solid transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
}
.box-cont img {
  width: 20px;
  height: 20px;
}
.box-cont:hover {
  box-shadow: var(--dark-orange);
  cursor: pointer;
  border-color: rgb(171 160 160);
}

p {
  font-size: 14px;
  line-height: 1.75;
  color: white;
  white-space: nowrap;
}

.text-center {
  white-space: normal;
  text-align: center;
  margin: 0;
}
</style>
