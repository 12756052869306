import { createApp } from "vue";

import App from "./App.vue";
import router from "./router";

// import store from "./store/store.js";
import store from "./store/store";
import config from "../vue.config.js";
// Vuetify
import "vuetify/styles";
// import VScrollLock from "v-scroll-lock";
import vuetify from "../src/plugins/vuetify.js";

const app = createApp(App);
app.use(config);
// app.use(PDFPlugin);
app.use(router);
app.use(store);
app.use(vuetify);
// app.use(VScrollLock)

router.isReady().then(() => {
  app.mount("#app");
});
