<template>
  <transition name="route" mode="out-in">
    <PreLoader
      v-if="
        (isLoadingCardGamesBySections ||
          isAllProvidersLoading ||
          isTranslationLoading) &&
        !isFullViewActive
      "
    />
  </transition>
  <div class="Main-Container">
    <Carousel />
    <GameSection :games_providers="allSkinProviders" :slugSection="slug" />
    <div
      v-if="isLoadingCardGamesBySections"
      class="pt-5"
      style="min-height: 40vh"
    >
      <MainLoader />
    </div>
    <div v-if="!isFullViewActive" class="section-main-wrapper">
      <div v-for="section in allSkinSections" :key="section.id">
        <CategoryGames :section="section" />
      </div>
    </div>
    <div v-else class="full-game-shown">
      <FullCategoryGames :id="idFUllGameInthisSection" />
    </div>
  </div>
</template>

<script>
import Carousel from "@/components/ui/Carousel.vue";
import GameSection from "@/components/ui/GameSection.vue";
import CategoryGames from "@/components/ui/casinoPage/CategoryGames.vue";
// import FullCategoryGames from "@/components/ui/casinoPage/FullCategoryGames.vue";
import PreLoader from "@/components/global/PreLoader.vue";
import { mapGetters } from "vuex";
import { defineAsyncComponent } from "vue";
import MainLoader from "@/components/global/MainLoader.vue";
const FullCategoryGames = defineAsyncComponent(() =>
  import("@/components/ui/casinoPage/FullCategoryGames.vue")
);
export default {
  name: "CasinoPage",
  components: {
    Carousel,
    GameSection,
    CategoryGames,
    FullCategoryGames,
    PreLoader,
    MainLoader,
  },
  data() {
    return {
      lang: localStorage.getItem("language"),
      allIsLoading: true,
      slug: "homepage",
    };
  },
  computed: {
    ...mapGetters("casinoGames", [
      "isLoadingCardGames",
      "allSkinSections",
      "allSkinProviders",
      "isFullViewActive",
      "idFUllGameInthisSection",
      "isAllProvidersLoading",
      "isLoadingCardGamesBySections",
    ]),
    ...mapGetters("translations", ["translations", "isTranslationLoading"]),
  },
  created() {
    this.$router.push({ params: { lang: this.lang } });
    this.$store.dispatch("casinoGames/callSkinSections", this.slug);
    let filters = new Array();
    let filter = {};
    filter["label"] = "is_live";
    filter["value"] = 0;
    filters.push(filter);
    this.$store.dispatch("casinoGames/callSkinProviders", filters);
  },
  beforeUnmount() {
    this.$store.dispatch("casinoGames/removeSectionSavedLocalStorage");
    this.$store.dispatch("casinoGames/clearFilter");
  },
  methods: {},
};
</script>
