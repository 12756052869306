<template>
  <div class="container promotion-card-section">
    <div
      id="container-promotion"
      class="promotion-container row mx-0 flex-row"
      v-if="promotions && promotions.length > 0"
    >
      <div
        class="col-lg-4 col-md-6 col-sm-6 col-12"
        v-for="(promotion, index) in promotions"
        :key="index"
        :value="promotion?.slug"
      >
        <div class="card" @click="openPromo(promotion?.slug)">
          <img
            v-if="promotion?.media?.full_url"
            :src="promotion?.media?.full_url"
            class="card-img-top"
            alt="Promotion Image"
          />
          <img
            v-else
            src="../../../public/images/banner-section/promotionBannerDefault.png"
            alt=""
          />
          <div class="card-body">
            <h5 class="card-title">{{ promotion?.name }}</h5>
            <p v-if="promotion?.campaign?.code" class="card-text">
              {{ translations?.promotion?.promo_code ?? "Promo code" }}:
              {{ promotion?.campaign?.code }}
            </p>
            <div class="btn-wrapper">
              <button class="btn-grey text-capitalize" role="button">
                {{ translations?.buttons?.GetBonus }}
              </button>
            </div>

            <div class="terms-and-conditions" v-if="promotion?.campaign?.description">
              <div
                class="terms"
                v-html="promotion?.campaign?.description.toUpperCase()"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="no-promo-available" v-else>
      <p class="text-center ty_h5 no-promo-text">
        {{ translations?.promotion?.no_promo_available ?? "No promotion available" }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "promotionCard",
  data() {
    return {
      promotions: [],
    };
  },
  computed: {
    ...mapGetters("promotion", [
      "promotionsList",
      "promotion",
      "isPromotionsLoading",
      "isPromoIdLoading",
    ]),
    ...mapGetters("translations", ["translations", "isTranslationLoading"]),
    showAllPromotions() {
      return (
        this.$route.params.promotionId === "" ||
        this.$route.params.promotionId === undefined
      );
    },
    showPromoDetail() {
      return (
        this.$route.params.promotionId !== undefined &&
        this.$route.params.promotionId !== ""
      );
    },
  },
  mounted() {},
  created() {
    this.$store.dispatch("promotion/callSkinPromotions").then(() => {
      this.promotions = this.promotionsList;
    });
  },
  methods: {
    openPromo(slug) {
      const el = this.promotions.find((promo) => promo.slug === slug);
      if (el != null && !el.is_valid) return;
      this.$store.dispatch("promotion/findPromotion", { id: el.slug });
      const lang = localStorage.getItem("language");
      const location = `/${lang}/promotion/${slug}`;
      this.$router.push(location);
    },
  },
};
</script>
