export default {
	namespaced: true,
	state() {
		return {
			path: {
				callDeposit: 'payments/methods/deposit',
				callWithdraw: 'payments/methods/withdraw',
				makeDeposit: 'payments/deposit',
				makeWithdraw: 'payments/withdraw',
				payment_methods: 'cms/payment-methods',
				globank_last_used_card: 'payments/globank/player/last-used-card'
			},
			depositResponse: null,
			withdrawResponse: null,
			depositPaymentMethods: null,
			withdrawalPaymentMethods: null,
			withdrawPaymentMethods: null,
			depositPaymentMethodsFiltered: null,
			gbLastUsedCardResponse: null,
		};
	},
	mutations: {
		setDepositMethods(state, payload) {
			state.depositMethods = payload;
		},
		setWithdrawMethods(state, payload) {
			state.withdrawMethods = payload;
			state.isWithdrawLoading = false;
		},
		makeDeposit(state, payload) {
			state.depositResponse = payload;
		},
		makeWithdraw(state, payload) {
			state.withdrawResponse = payload;
		},
		setPaymentMethods(state, payload) {
			let deposit = [];
			let withdraw = [];
			payload.forEach((method) => {
				let id = method.id;
				let name = method.name;
				let depositMethod = method.meta.deposit;
				let withdrawMethod = method.meta.withdrawal;
				deposit.push({
					id,
					name,
					data: depositMethod,
				});
				withdraw.push({
					id,
					name,
					data: withdrawMethod,
				});
			});
			state.depositPaymentMethods = deposit;
		},
		setWithdrawPaymentMethods(state, payload) {
			let withdraw = [];
			payload.forEach((method) => {
				let id = method.id;
				let name = method.name;
				let withdrawal = method.withdrawal ?? [];
				let deposit = method.deposit ?? []
				withdraw.push({
					id,
					name,
					deposit,
					withdrawal
				});
			});
			state.withdrawPaymentMethods = withdraw;
		},
		setDepositPaymentMethods(state, payload) {
			let deposit = [];
			payload.forEach((method) => {
				let id = method.id;
				let name = method.name;
				let code = method.code;
				deposit.push({
					id,
					name,
					code,
				});
			});
			state.depositPaymentMethodsFiltered = deposit;
		},
		setGlobankResponse(state, payload) {
			state.gbLastUsedCardResponse = payload;
		}
	},
	actions: {
		async callPaymentMethods({ state, getters, commit }) {
			const responseData = await getters.axios
				.get(getters.endpoint + state.path.payment_methods, getters.tokenAuth)
				.then((response) => response.data.data)
				.catch((err) => err);
			commit('setPaymentMethods', responseData);
		},
		async getWithdrawPaymentMethods({ state, getters, commit }) {
			const responseData = await getters.axios
				.get(getters.endpoint + state.path.callWithdraw, getters.tokenAuth)
				.then((response) => response.data.data)
				.catch((err) => err)
			commit('setWithdrawPaymentMethods', responseData);
		},
		async getDepositPaymentMethods({ state, getters, commit }) {
			const responseData = await getters.axios
				.get(getters.endpoint + state.path.callDeposit, getters.tokenAuth)
				.then((response) => response.data.data)
				.catch((err) => err)
			commit('setDepositPaymentMethods', responseData);
		},
		async makeDeposit({ state, getters, commit }, payload) {
			const responseData = await getters.axios
				.post(
					getters.endpoint + state.path.makeDeposit,
					payload,
					getters.tokenAuth
				)
				.then((response) => response.data)
				.catch((err) => err.response.data);
			commit('makeDeposit', responseData);
		},
		async makeWithdraw({ state, getters, commit }, payload) {
			const responseData = await getters.axios
				.post(
					getters.endpoint + state.path.makeWithdraw,
					payload,
					getters.tokenAuth
				)
				.then((response) => response.data)
				.catch((err) => err.response.data);
			commit('makeWithdraw', responseData);
		},
		async callGlobankLastUsedCard({ state, getters, commit }) {
			const responseData = await getters.axios
				.get(
					getters.endpoint + state.path.globank_last_used_card,
					getters.tokenAuth
				)
				.then((response) => response.data)
				.catch((err) => err.response.data);

			commit('setGlobankResponse', responseData);
		}
	},
	getters: {
		endpoint(_, _1, _2, rootGetters) {
			return rootGetters['api/endpoint'];
		},
		axios(_, _1, _2, rootGetters) {
			return rootGetters['api/axios'];
		},
		auth(_, _1, _2, rootGetters) {
			return rootGetters['api/auth'];
		},
		token(_, _1, _2, rootGetters) {
			return rootGetters['player/token'];
		},
		tokenAuth(_, _1, _2, rootGetters) {
			return rootGetters['player/tokenAuth'];
		},
		depositPaymentMethods(state) {
			return state.depositPaymentMethods;
		},
		withdrawPaymentMethods(state) {
			return state.withdrawPaymentMethods;
		},
		depositPaymentMethodsFiltered(state) {
			return state.depositPaymentMethodsFiltered;
		},
		withdrawalPaymentMethods(state) {
			return state.withdrawalPaymentMethods;
		},
		depositResponse(state) {
			return state.depositResponse;
		},
		withdrawResponse(state) {
			return state.withdrawResponse;
		},
		gbLastUsedCardResponse(state) {
			return state.gbLastUsedCardResponse;
		}
	},
};
