<template>
  <div class="sign-popup-main" :class="{ 'active-modal': isModalOpen }">
    <div class="container signup-pu-wp">
      <div v-if="!isMobile" class="bg-image-reg"></div>
      <div :class="{ 'sticky-class-mobile': isMobile, 'w-100': !isMobile }">
        <div class="cross-container">
          <div class="close-left-menu" @click="toggleModalRegistration">
            <img src="/images/left-menu_img/close.svg" alt="" />
          </div>
        </div>
        <div class="logo-container">
          <div class="logo">
            <img src="/images/logo.png" alt="logo" />
          </div>
        </div>
        <!--step-->
        <div class="step-bar" v-if="isMobile">
          <div v-for="(step, index) in steps" :key="index" class="step">
            <div class="step-number" :class="{ active: currentStep === index + 1 }">
              {{ index + 1 }}
            </div>
            <div class="step-text">{{ step }}</div>
          </div>
        </div>
      </div>

      <div class="registration-form-left">
        <div v-if="isMobile" class="bg-image-reg"></div>
        <!--step-->
        <div class="step-bar" v-if="!isMobile">
          <div v-for="(step, index) in steps" :key="index" class="step">
            <div class="step-number" :class="{ active: currentStep === index + 1 }">
              {{ index + 1 }}
            </div>
            <div class="step-text">{{ step }}</div>
          </div>
        </div>
        <!--STEP UNO-->
        <div v-if="currentStep === 1">
          <div class="input-wrapper">
            <div class="welcome-wrapper">
              <div class="welcome-text">
                <h4 class="highlight-text">
                  {{ translations?.registration?.Welcome }}
                </h4>
                <p class="subtitle-welcome">
                  {{ translations?.registration?.JoinTheParty }}
                </p>
              </div>
              <div class="input">
                <i aria-hidden="true" class="fa fa-user"></i>
                <input
                  id="username"
                  class=""
                  type="text"
                  name=""
                  :placeholder="translations?.registration?.UsernamePlaceHolder"
                  v-model="username"
                />
              </div>
              <div class="input">
                <i aria-hidden="true" class="fa fa-unlock-alt"></i>
                <input
                  id="email"
                  class=""
                  type="email"
                  name=""
                  :placeholder="translations?.registration?.EmailPlaceHolder"
                  v-model="email"
                />
              </div>
              <div class="input">
                <i aria-hidden="true" class="fa fa-unlock-alt"></i>
                <input
                  id="password"
                  class=""
                  type="password"
                  name=""
                  :placeholder="translations?.registration?.PasswordPlaceHolder"
                  v-model="password"
                />
              </div>
              <div class="phone-number-input d-flex w-100" style="margin-bottom: 20px">
                <div class="currency-dropdown" style="width: 30%">
                  <transition name="fade">
                    <select
                      id="prefix-drop"
                      name="prefix"
                      v-model="selectedPrefix"
                      required
                      class="input"
                    >
                      <option value="" disabled>
                        {{
                          selectedPrefix === ""
                            ? translations?.registration?.ChoosePreFix
                            : translations?.registration?.ChoosePreFix
                        }}
                      </option>
                      <option
                        class="prefix-option"
                        v-for="prefix in sortedCountries"
                        :key="prefix.phone_prefix"
                        :value="prefix.phone_prefix"
                      >
                        {{ prefix.phone_prefix }}
                      </option>
                    </select>
                  </transition>
                </div>
                <div class="input-phone" style="width: 70%">
                  <input
                    id="phoneNumber"
                    type="text"
                    v-model="phoneNumber"
                    :placeholder="translations?.registration?.PhoneNumberPlaceHolder"
                  />
                </div>
              </div>
              <div class="wrapper-currency-affiliate-code d-flex w-100">
                <!-- <div class="currency-dropdown" style="width: 50%"> -->
                <div class="currency-dropdown" style="width: 100%">
                  <transition name="fade">
                    <select
                      id="curr-drop"
                      name="currency"
                      v-model="selectedCurrency"
                      required
                      class="input"
                    >
                      <option value="" disabled>
                        {{
                          selectedCurrency === ""
                            ? translations?.registration?.ChooseCurrency
                            : translations?.registration?.ChooseCurrency
                        }}
                      </option>
                      <option
                        class="currency-option"
                        v-for="currency in currencies"
                        :key="currency.id"
                        :value="currency.id"
                      >
                        {{ currency.name }}
                      </option>
                    </select>
                  </transition>
                </div>
                <div class="input affiliate_code" style="width: 50%" v-if="0">
                  <i aria-hidden="true" class="fa fa-user"></i>
                  <input
                    type="text"
                    name=""
                    :class="isBtagOnStore ? 'disabled_class_input' : ''"
                    :disabled="isBtagOnStore"
                    :placeholder="
                      !isBtagOnStore
                        ? translations?.registration?.AffiliateCodePlaceHolder
                        : affiliate_code
                    "
                    v-model="affiliate_code"
                  />
                </div>
              </div>
              <div class="custom-checkbox mt-1">
                <label for="customControlInline" class="custom-control-label">
                  <input
                    id="checkBox18Age"
                    type="checkbox"
                    class="custom-control-input customControlInline mr-2"
                  />
                  {{ translations?.registration?.im18Years_TC }}
                </label>
              </div>
            </div>
            <div class="button-container-register-form flex-wrap">
              <button
                class="m-1"
                id="prevFormBtn"
                @click="prevStep"
                :disabled="currentStep === 1"
              >
                {{ translations?.buttons?.BackButton }}
              </button>
              <button class="m-1" id="nextFormBtn" @click="checkFirstStep">
                {{ translations?.buttons?.NextButton }}
              </button>
            </div>
          </div>
        </div>
        <!--Secondo step-->
        <!-- cambiar ein step 2 -->
        <div v-if="currentStep === 2">
          <div class="welcome-wrapper">
            <div class="welcome-text">
              <h4 class="highlight-text">
                {{ translations?.registration?.UserInformation }}
              </h4>
              <p class="subtitle-welcome">
                {{ translations?.registration?.TellUsAboutYou }}
              </p>
            </div>
            <div class="input-wrapper w-100">
              <div class="d-flex w-100">
                <div class="input w-50 mr-1">
                  <i aria-hidden="true" class="fa fa-user"></i>
                  <input
                    class=""
                    type="text"
                    v-model="name"
                    :placeholder="translations?.registration?.NamePlaceHolder"
                  />
                </div>
                <div class="input w-50">
                  <i aria-hidden="true" class="fa fa-unlock-alt"></i>
                  <input
                    class=""
                    type="text"
                    v-model="surname"
                    :placeholder="translations?.registration?.SurnamePlaceHolder"
                  />
                </div>
              </div>
              <div class="wrapper-gender-dateOfBirthday d-flex w-100 mb-5">
                <div class="w-100" style="display: flex; flex-direction: column">
                  <p class="field-name mb-1 mr-1">
                    {{ translations?.registration?.DateOfBirthday }}
                  </p>
                  <div
                    style="
                      width: 100%;
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                    "
                  >
                    <div class="mr-1" style="flex: 1">
                      <select v-model="dayOfBirth" id="day" class="input">
                        <option value="" disabled selected>
                          {{ translations?.registration?.Day }}
                        </option>
                        <option
                          v-for="dayOption in days"
                          :key="dayOption"
                          :value="dayOption"
                        >
                          {{ dayOption }}
                        </option>
                      </select>
                    </div>
                    <div class="mr-1" style="flex: 1">
                      <select v-model="monthOfBirth" id="month" class="input">
                        <option value="" disabled selected>
                          {{ translations?.registration?.Month }}
                        </option>
                        <option
                          v-for="monthOption in months"
                          :key="monthOption"
                          :value="monthOption"
                        >
                          {{ monthOption }}
                        </option>
                      </select>
                    </div>
                    <div class="mr-1" style="flex: 1">
                      <select v-model="yearOfBirth" id="year" class="input">
                        <option value="" disabled selected>
                          {{ translations?.registration?.Year }}
                        </option>
                        <option
                          v-for="yearOption in years"
                          :key="yearOption"
                          :value="yearOption"
                        >
                          {{ yearOption }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <!--gender-->
                <div class="gender-dropdown w-100 position-realtive">
                  <p class="field-name mb-1 mr-1">
                    {{ translations?.registration?.Gender }}
                  </p>
                  <select
                    id="gender-drop"
                    name="gender"
                    v-model="selectedGender"
                    required
                    class="input"
                  >
                    <option value="" disabled>
                      {{
                        selectedGender === ""
                          ? translations?.registration?.ChooseGender
                          : translations?.registration?.ChooseGender
                      }}
                    </option>
                    <option
                      v-for="gender in genders"
                      :key="gender.value"
                      :value="gender.value"
                    >
                      {{ translations?.registration?.[gender.label] }}
                    </option>
                  </select>
                </div>
              </div>
              <!--PAESE E LUOGO DI NASCITA-->
              <div
                class="wrapper-countryBirthday-cityBirthday d-flex w-100 mb-2"
                v-if="0"
              >
                <!--BIRTHDAY COUNTRY PICKER-->
                <div class="countryBirthday-dropdown w-100 position-relative mr-1">
                  <p class="field-name mb-1 mr-1">
                    {{ translations?.registration?.BirthdayCountry }}
                  </p>
                  <!-- <select
                    id="birthCountry-drop"
                    name="birthdayCountry"
                    v-model="selectedCountryBirthday"
                    required
                    class="input"
                  >
                    <option value="" disabled>
                      {{
                        selectedCountryBirthday === ""
                          ? translations?.registration?.ChooseCountry
                          : translations?.registration?.ChooseCountry
                      }}
                    </option>
                    <option
                      v-for="countryBirthday in countriesAll"
                      :key="countryBirthday.id"
                      :value="countryBirthday.code"
                    >
                      {{ countryBirthday.name }}
                    </option>
                  </select> -->
                  <div
                    class="input position-relative"
                    id="birthCountry-drop"
                    v-click-outside="closeDropdown"
                  >
                    <input
                      type="text"
                      v-model="searchInput"
                      @click="toggleDropdown"
                      :placeholder="
                        searchInput === ''
                          ? translations?.registration?.ChooseCountry
                          : searchInput
                      "
                      class="input-field select-searchable"
                    />
                    <div
                      v-show="isDropdownOpen"
                      class="dropdown-options position-absolute"
                    >
                      <div
                        class="options-country"
                        v-for="countryBirthday in filteredCountries"
                        :key="countryBirthday.id"
                        @click="selectCountry(countryBirthday)"
                      >
                        {{ countryBirthday.name }}
                      </div>
                    </div>
                  </div>
                </div>
                <!--LEAVING COUNTRY PICKER-->
                <div class="birthdayCity-dropdown w-100 position-relative">
                  <p class="field-name mb-1 mr-1">
                    {{ translations?.registration?.BirthdayCity }}
                  </p>
                  <input
                    id="countryRes-drop"
                    name="birthcityField"
                    v-model="selectedCityBirthday"
                    required
                    class="input"
                    maxlength="20"
                  />
                </div>
              </div>
              <!--PAESE E LUOGO DI RESIDENZA-->
              <div
                class="wrapper-residenceCountry-ResidenceCity d-flex w-100"
                style="margin-bottom: 20px"
              >
                <!--RESIDENCE COUNTRY PICKER-->
                <div class="residenceCountry-dropdown w-100 position-relative mr-1">
                  <p class="field-name mb-1 mr-1">
                    {{ translations?.registration?.ResidenceCountry }}
                  </p>
                  <!-- <select
                    id="birthCity-drop"
                    name="CountryResidence"
                    v-model="selectedCountryResidence"
                    required
                    class="input"
                  >
                    <option value="" disabled>
                      {{
                        selectedCountryResidence === ""
                          ? translations?.registration?.ChooseCountry
                          : translations?.registration?.ChooseCountry
                      }}
                    </option>
                    <option
                      v-for="residenceCountry in countries"
                      :key="residenceCountry.id"
                      :value="residenceCountry.code"
                    >
                      {{ residenceCountry.name }}
                    </option>
                  </select> -->
                  <div
                    class="input position-relative"
                    id="birthCity-drop"
                    v-click-outside="closeDropdownResidenceCountry"
                  >
                    <input
                      type="text"
                      v-model="searchInputResidenceCountry"
                      @click="toggleDropdownResidenceCountry"
                      :placeholder="
                        searchInputResidenceCountry === ''
                          ? translations?.registration?.ChooseCountry
                          : searchInputResidenceCountry
                      "
                      class="input-field select-searchable"
                    />
                    <div
                      v-show="isDropdownOpenResidenceCountry"
                      class="dropdown-options position-absolute"
                    >
                      <div
                        class="options-country"
                        v-for="residenceCountry in filteredResidenceCountry"
                        :key="residenceCountry.id"
                        @click="selectResidenceCountry(residenceCountry)"
                      >
                        {{ residenceCountry.name }}
                      </div>
                    </div>
                  </div>
                </div>
                <!--RESIDENCE CITY PICKER-->
                <div class="residenceCity-dropdown position-relative w-100">
                  <p class="field-name mb-1 mr-1">
                    {{ translations?.registration?.ResidenceCity }}
                  </p>
                  <input
                    id="resCity-drop"
                    name="residenceCity"
                    v-model="selectedResidenceCity"
                    required
                    class="input"
                  />
                </div>
              </div>
              <p class="text-field-required">
                {{ translations?.registration?.AllFieldsRequired }}
              </p>
            </div>
          </div>
          <div class="button-container-register-form flex-wrap">
            <button class="m-1" id="prevFormBtn" @click="prevStep">
              {{ translations?.buttons?.BackButton }}
            </button>
            <button class="m-1" id="nextFormBtn" @click="checkSecondStep">
              {{ translations?.buttons?.NextButton }}
            </button>
          </div>
        </div>
        <!--Terzo Step  -->
        <div v-if="currentStep === 3">
          <div class="welcome-wrapper">
            <div class="welcome-text">
              <h4 class="highlight-text">
                {{ translations?.registration?.WeAreAlmostThere }}
              </h4>
              <p class="subtitle-welcome">
                {{ translations?.registration?.LatestInformation }}
              </p>
            </div>
          </div>
          <div class="d-flex w-100 position-relative">
            <div class="d-flex-flex-column w-50 mr-1">
              <p class="field-name mb-1 mr-1">
                {{ translations?.registration?.address_user ?? "Address" }}
              </p>
              <div class="input w-100">
                <input
                  class=""
                  type="text"
                  v-model="address_user"
                  placeholder="Address"
                  maxlength="20"
                />
              </div>
            </div>
            <div class="d-flex-flex-column w-50">
              <p class="field-name mb-1 mr-1">
                {{ translations?.registration?.street_number ?? "Street Number" }}
              </p>
              <div class="input w-100">
                <input
                  class=""
                  type="text"
                  v-model="civicNumberUser"
                  placeholder="Civic Number"
                  maxlength="20"
                />
              </div>
            </div>
          </div>
          <div class="d-flex w-100 position-relative">
            <div class="d-flex-flex-column w-50">
              <p class="field-name mb-1 mr-1">
                {{ translations?.registration?.postal_code ?? "Postal Code" }}
              </p>
              <div class="input w-100">
                <input
                  class=""
                  type="text"
                  v-model="zipCode"
                  placeholder="Postal Code"
                  maxlength="20"
                />
              </div>
            </div>
            <div class="d-flex flex-column w-50" v-if="0">
              <p class="field-name mb-1 mr-1">
                {{ translations?.registration?.choose_campaign ?? "Choose Campaign" }}
              </p>
              <select
                class="campaign-picker ml-1"
                name="campaign"
                id="campaign"
                v-model="campaign"
                @change="getCampaign"
              >
                <option value="null" disabled>
                  {{ translations.registration.Please_select ?? "Please Select" }}
                </option>
                <option value="none">
                  {{ translations.registration.No_promo ?? "No promo" }}
                </option>
                <option
                  v-for="campaign in campaigns"
                  :key="campaign.id"
                  :value="campaign.code"
                  :data-img="campaign.media_url"
                  :data-title="campaign.name"
                >
                  {{ campaign.name }}
                </option>
              </select>

              <div class="col-12 my-3 ml-1" v-if="showbonuscode">
                <input
                  type="text"
                  oninput="this.value = this.value.replace(/\s/g, '');"
                  :placeholder="translations.registration.bonus_code ?? 'Bonus code'"
                  class="input ml-1 mb-1"
                  v-model="bonus_code_campaign"
                />
                <small class="ty_bs field-name"
                  >{{
                    translations.registration.enter_code_help ??
                    "Enter the promotional code"
                  }}
                </small>
              </div>
            </div>
            <div v-if="campaignImage != ''" class="form-img-campaign form-group my-3">
              <img class="w-100" :src="campaignImage" alt="Campaign" />
              <div class="overlayImg p-3" v-if="campaignTitle != ''">
                <b>{{ this.campaignTitle }}</b>
              </div>
            </div>
          </div>
          <div class="custom-checkbox position-relative">
            <label for="customControlInline" class="custom-control-label ml-0">
              <input
                id="checkBoxNewsLetters"
                type="checkbox"
                class="custom-control-input customControlInline mr-2 check-news-letter"
              />
              {{
                translations?.registration?.accepts_new_letters ??
                "I agree to receive marketing communications and newsletters"
              }}
            </label>
          </div>
          <div class="button-container-register-form flex-wrap">
            <button class="m-1" id="prevFormBtn" @click="prevStep">
              {{ translations?.buttons?.BackButton }}
            </button>
            <button class="m-1" id="loginBTNslide" @click="checkThirdStep">
              {{ translations?.buttons?.SubmitRegistration }}
            </button>
          </div>
        </div>
      </div>
      <div class="registration-form-right" v-if="0">
        <div class="why-us">
          <h6 class="why-us-highlight">
            {{ translations?.registration?.WhyUs }}
          </h6>
          <ul class="features">
            <li class="features-item">
              <img
                src="/images/svgIconBanner/rocketIconBanner.svg"
                class="svg-icon-registration"
                alt=""
              />
              {{ translations?.registration?.FastPayout }}
            </li>
            <li class="features-item">
              <img
                src="/images/svgIconBanner/likeButtonBanner.svg"
                class="svg-icon-registration"
                alt=""
              />{{ translations?.registration?.QuickApproval }}
            </li>
            <li class="features-item">
              <img
                src="/images/svgIconBanner/support.svg"
                class="svg-icon-registration"
                alt=""
              />{{ translations?.registration?.LiveSupport }}
            </li>
            <li class="features-item">
              <img
                src="/images/svgIconBanner/aggregation.svg"
                class="svg-icon-registration"
                alt=""
              />{{ translations?.registration?.EasyAggregation }}
            </li>
          </ul>
          <div class="certificate-wrapper">
            <img src="/images/left-menu_img/secure.png" alt="" />
            <img
              src="/images/left-menu_img/18only.png"
              alt=""
              style="filter: brightness(100)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
import VueCookies from "vue-cookies";
export default {
  name: "Registration_form",
  components: {},
  data() {
    const currentYear = new Date().getFullYear();
    const startYear = currentYear - 18;
    const endYear = 1930;
    return {
      referralCode: "",
      isBtagOnStore: false,
      /*Check availability usr email */
      usernameAvailability: false,
      emailAvailability: false,
      isPasswordStrong: false,
      phoneNumberValidity: false,
      /****************/
      isMobile: false,
      currentStep: 1,
      // steps: ["Step 1", "Step 2", "Step 3"],
      steps: ["Step 1", "Step 2", "Step 3"],
      //First step
      username: "",
      email: "",
      password: "",
      selectedPrefix: "",
      phoneNumber: "",
      selectedCurrency: "",
      affiliate_code: "",
      currencyEmpty: true,
      //Second step
      name: "",
      surname: "",
      dateOfBirth: "",
      dayOfBirth: "",
      monthOfBirth: "",
      yearOfBirth: "",
      days: Array.from({ length: 31 }, (_, index) =>
        (index + 1).toString().padStart(2, "0")
      ),
      months: Array.from({ length: 12 }, (_, index) =>
        (index + 1).toString().padStart(2, "0")
      ),

      years: Array.from(
        { length: startYear - endYear + 1 },
        (_, index) => startYear - index
      ),
      years_doc_release: Array.from(
        { length: 15 },
        (_, index) => currentYear - 15 + index
      ).reverse(),
      years_doc_expire: Array.from({ length: 16 }, (_, index) => currentYear + index),
      isPrefixDropdownOpen: false,
      selectedGender: "",
      selectedCountryBirthday: "",
      selectedCityBirthday: "",
      selectedCountryResidence: "",
      selectedResidenceCity: "",
      genders: [
        { label: "Male", value: "M" },
        { label: "Female", value: "F" },
        { label: "Dontspecific", value: "GN" },
      ],
      //third step
      documentsType: [
        { id: 1, name: "ID-Card" },
        { id: 2, name: "Passport" },
      ],
      selectedDocumentType: "",
      documentNumber: "",
      releaseDateDoc: "",
      expireDateDoc: "",
      //check 3 step field
      address_user: "",
      civicNumberUser: "",
      zipCode: "",
      isDocumentTypeValid: false,
      isDocumentNumberValid: false,
      isReleaseDateDocValid: false,
      isExpireDateDocValid: false,
      dayOfRelease: "",
      monthOfRelease: "",
      yearOfRelease: "",
      dayOfExpire: "",
      monthOfExpire: "",
      yearOfExpire: "",
      //campaign
      bonus_code_campaign: "",
      campaign: "null",
      campaignImage: "",
      campaignTitle: "",
      showbonuscode: false,
      //newsLetters
      isNewsLettersAccepted: false,
      //serachbale select
      searchInput: "",
      isDropdownOpen: false,
      isDropdownOpenResidenceCountry: "",
      searchInputResidenceCountry: "",
    };
  },
  mounted() {
    this.getAndStoreReferralCode();
    window.addEventListener("resize", this.checkIsMobile);
  },
  created() {
    this.checkIsMobile();
    this.$store.dispatch("skinSettings/callCountriesAll");
  },
  methods: {
    //country birth
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    selectCountry(country) {
      this.searchInput = country.name;
      this.selectedCountryBirthday = country.code;
      this.isDropdownOpen = false;
      // console.log("********Country of Birthday********");
      // console.log(this.selectedCountryBirthday);
      // console.log("***********************************");
    },
    closeDropdown() {
      this.isDropdownOpen = false;
    },
    //residence country
    toggleDropdownResidenceCountry() {
      this.isDropdownOpenResidenceCountry = !this.isDropdownOpenResidenceCountry;
    },
    selectResidenceCountry(country) {
      this.searchInputResidenceCountry = country.name;
      this.selectedCountryResidence = country.code;
      this.isDropdownOpenResidenceCountry = false;
      // console.log("********Country of Residence********");
      // console.log(this.selectedCountryResidence);
      // console.log("***********************************");
    },
    closeDropdownResidenceCountry() {
      this.isDropdownOpenResidenceCountry = false;
    },
    getAndStoreReferralCode() {
      const isReferralLink = !!this.$route.query.btag;
      const doesReferralExist = VueCookies.get("btag");

      if (isReferralLink) {
        const expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + 30);
        VueCookies.set("btag", this.$route.query.btag, expirationDate.toUTCString());
        this.referralCode = this.$route.query.btag;
        this.affiliate_code = this.referralCode;
        this.isBtagOnStore = true;
        this.toggleModalRegistration();
      } else if (doesReferralExist) {
        this.referralCode = VueCookies.get("btag");
        this.affiliate_code = this.referralCode;
        this.isBtagOnStore = true;
      } else {
        this.isBtagOnStore = false;
        false;
      }
    },
    checkIsMobile() {
      this.isMobile = window.innerWidth <= 768;
    },
    nextStep() {
      if (this.currentStep < this.steps.length) {
        this.currentStep++;
      }
    },
    prevStep() {
      if (this.currentStep !== 1) {
        this.currentStep--;
      }
    },
    toggleModalRegistration() {
      this.$store.dispatch(
        "openMenuLogin/setStateModalRegistration",
        !this.$store.getters["openMenuLogin/isModalOpen"]
      );
      if (this.isModalOpen) {
        // Se la modale è appena stata aperta, disabilita lo scroll
        document.documentElement.style.overflow = "hidden";
        document.documentElement.style.paddingRight = "11px";
      } else {
        // Se la modale è stata chiusa, riabilita lo scroll
        document.documentElement.style.overflow = "";
        document.documentElement.style.paddingRight = "0px";
      }
    },
    toggleLoginMenu() {
      this.$store
        .dispatch(
          "openMenuLogin/setStateModalRegistration",
          !this.$store.getters["openMenuLogin/isModalOpen"]
        )
        .then(() => {
          //rimuovo il blocco dello scroll dovuto all'apertura della modale
          document.body.style.position = "";
          this.$store.dispatch(
            "openMenuLogin/setStateLoginMenu",
            !this.$store.getters["openMenuLogin/isLoginMenuOpen"]
          );
        });
    },
    togglePrefixDropdown() {
      this.isPrefixDropdownOpen = !this.isPrefixDropdownOpen;
    },
    selectPrefix(prefix) {
      this.selectedPrefix = prefix;
      this.isPrefixDropdownOpen = false;
    },
    /*CHECK FIRST STEP ALL VALID */
    async checkFirstStep() {
      await this.checkUsername();
      if (!this.usernameAvailability) {
        return;
      }
      await this.checkEmail().then(() => {
        if (!this.emailAvailability) {
          // console.log("pippo");
          return;
        }
      });
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      const isEmailValid = emailRegex.test(this.email);
      if (!this.emailAvailability && !isEmailValid) {
        // this.currentStep = 1;
        return;
      }
      await this.checkPasswordComplexity();
      if (!this.isPasswordStrong) {
        return;
      }
      await this.checkPhoneNumber();
      if (!this.phoneNumberValidity) {
        return;
      }

      await this.checkCurrencyField();
      if (this.currencyEmpty) {
        return;
      }

      if (!this.isCheckboxChecked()) {
        Swal.fire({
          icon: "error",
          text:
            this.translations?.body?.Error_must_accept_TandC ??
            "You must accept our Terms and Conditions to continue.",
        });
        return;
      }

      if (isEmailValid && this.emailAvailability) {
        this.nextStep();
      }
    },

    /*CHECK SECOND STEP FIELDS*/
    async checkSecondStep() {
      if (!this.isValidName()) {
        return;
      }

      if (!this.isValidSurname()) {
        return;
      }

      if (!this.isUserOldEnough()) {
        return;
      }

      if (!this.isValidSelectedGender()) {
        return;
      }

      // if (!this.isValidSelectedCountryBirthday()) {
      //   return;
      // }

      // if (!this.isValidSelectedCityBirthday()) {
      //   return;
      // }

      if (!this.isValidSelectedCountryResidence()) {
        return;
      }

      if (!this.isValidSelectedResidenceCity()) {
        return;
      }

      this.nextStep();
      this.getCountryPromos();
      // this.saveRegistration();
    },

    /*CHECK THIRD STEP*/
    async checkThirdStep() {
      this.isCheckboxCheckedNewsLetters();
      if (!this.isValidAddress()) {
        return;
      }

      if (!this.isValidCivicNumber()) {
        return;
      }

      if (!this.isValidPostalCode()) {
        return;
      }

      this.saveRegistration();
    },

    async checkCurrencyField() {
      if (!this.selectedCurrency) {
        Swal.fire({
          icon: "error",
          text:
            this.translations?.body?.Error_currency_not_choose ??
            "You must choose one currency.",
        });
        return;
      } else {
        this.currencyEmpty = false;
      }
    },

    /*USERNAME CHECKER */
    async checkUsername() {
      if (this.username.length > 20) {
        this.usernameAvailability = false;
        Swal.fire({
          icon: "error",
          text: this.translations?.body?.Error_username_too_long ?? "Username too long.",
        });
        return;
      } else if (this.username.length > 0 && this.username.length < 3) {
        this.usernameAvailability = false;
        Swal.fire({
          icon: "error",
          text:
            this.translations?.body?.Error_username_too_short ?? "Username too short.",
        });
        return;
      }
      await this.$store.dispatch("registration/checkNickname", this.username);
      const responseData = this.$store.getters["registration/isNicknameInvalid"];
      if (responseData) {
        this.usernameAvailability = false;
        if (
          this.username != "" &&
          (this.username.length > 4 || this.username.length < 20)
        ) {
          Swal.fire({
            icon: "error",
            text:
              this.translations?.body?.Error_username_already_taken ??
              "Username already taken.",
          });
        } else {
          Swal.fire({
            icon: "error",
            text: this.translations?.body?.Error_invalid_username ?? "Invalid username.",
          });
        }
      } else {
        this.usernameAvailability = true;
      }
    },
    /*PASSWORD CHECKER */
    async checkPasswordComplexity() {
      // const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()])[a-zA-Z\d!@#$%^&*()]{8,}$/;
      /* eslint-disable */
      //  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()?\-])[a-zA-Z\d!@#$%^&*()?\-]{8,}$/;
      /* eslint-disable */
      //  const isValidPassword = passwordRegex.test(this.password);
      this.isPasswordStrong = true;
      //  if (!isValidPassword) {
      const errors = [];

      if (!/(?=.*[a-z])/.test(this.password)) {
        errors.push("Password must contain at least one lowercase letter.");
      }

      if (!/(?=.*[A-Z])/.test(this.password)) {
        errors.push("Password must contain at least one uppercase letter.");
      }

      if (!/(?=.*\d)/.test(this.password)) {
        errors.push("Password must contain at least one number.");
      }

      if (!/(?=.*[!@#$%^&*()])/.test(this.password)) {
        errors.push(
          "Password must contain at least one special character among !@#$%^&*()."
        );
      }

      if (this.password.length < 8) {
        errors.push("Password must be at least 8 characters long.");
      }

      if (errors.length > 0) {
        this.showPasswordError(errors);
        this.isPasswordStrong = false;
      }

      //  } else {
      //    this.isPasswordStrong = true;
      //  }
    },
    showPasswordError(errors) {
      // alert(this.password);
      Swal.fire({
        icon: "error",
        html: errors
          .map(
            (error) => `<p style="margin-bottom:10px; text-align:center;">${error}</p>`
          )
          .join(""),
      });
    },

    /*EMAIL CHECKER */
    async checkEmail() {
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      const isEmailValid = emailRegex.test(this.email);

      if (!isEmailValid) {
        Swal.fire({
          icon: "error",
          text:
            this.translations?.body?.Error_enter_valid_mail ??
            "Please enter valid email.",
        });
        return;
      }

      await this.$store.dispatch("registration/checkEmail", this.email);
      const responseData = this.$store.getters["registration/isEmailInvalid"];
      if (responseData == false) {
        this.emailAvailability = true;
      } else {
        this.emailAvailability = false;
        Swal.fire({
          icon: "error",
          text:
            this.translations?.body?.Error_email_already_used ?? "Email already used.",
        });
      }
    },

    /*PHONE NUMBER CHECKER */
    async checkPhoneNumber() {
      const mobile = this.phoneNumber;
      const prefix = this.selectedPrefix;

      // Verifica se il numero di telefono ha il formato corretto (solo numeri)
      const phoneNumberRegex = /^[0-9]+$/;
      if (!phoneNumberRegex.test(mobile)) {
        Swal.fire({
          icon: "error",
          text:
            this.translations?.body?.Error_number_format_invalid ??
            "Invalid Phone Number format. Please enter only numbers.",
        });
        this.phoneNumberValidity = false;
        return;
      }

      await this.$store
        .dispatch("registration/checkPhoneNumber", {
          prefix,
          mobile,
        })
        .then(() => {
          const responseData = this.isMobileInvalid;
          if (responseData == 1) {
            this.phoneNumberValidity = true;
          } else {
            this.phoneNumberValidity = false;
          }
        });
    },

    /*CHECK IF ACCORDING TO T&C */
    isCheckboxChecked() {
      const checkbox = document.getElementById("checkBox18Age");
      return checkbox.checked;
    },

    isValidName() {
      if (!this.name) {
        Swal.fire({
          icon: "error",
          text:
            this.translations?.body.Error_must_fill_name_field ??
            "You must fill Name field.",
        });
        return false;
      }
      return true;
    },

    isValidSurname() {
      if (!this.surname) {
        Swal.fire({
          icon: "error",
          text:
            this.translations?.body.Error_must_fill_surname_field ??
            "You must fill Surname field.",
        });
        return false;
      }
      return true;
    },

    isUserOldEnough() {
      const currentDate = new Date();

      if (this.dayOfBirth === "" || this.monthOfBirth === "" || this.yearOfBirth === "") {
        Swal.fire({
          icon: "error",
          text:
            this.translations?.body?.Error_must_select_complete_date_of_birthday ??
            "You must select a complete birthday date.",
        });
        return false;
      }

      // Aggiungi uno zero se il mese è inferiore a 10
      const formattedMonth =
        this.monthOfBirth < 10 ? "0" + this.monthOfBirth : this.monthOfBirth;

      // Aggiungi uno zero se il giorno è inferiore a 10
      const formattedDay = this.dayOfBirth < 10 ? "0" + this.dayOfBirth : this.dayOfBirth;

      const birthDate = new Date(`${this.yearOfBirth}-${formattedMonth}-${formattedDay}`);

      let age = currentDate.getFullYear() - this.yearOfBirth;

      if (
        currentDate.getMonth() < birthDate.getMonth() ||
        (currentDate.getMonth() === birthDate.getMonth() &&
          currentDate.getDate() < birthDate.getDate())
      ) {
        age--;
      }

      if (age < 18) {
        Swal.fire({
          icon: "error",
          text:
            this.translations?.body?.Error_must_be_18 ??
            "You must be at least 18 years old.",
        });
        return false;
      }

      return true;
    },
    isValidSelectedGender() {
      if (!this.selectedGender) {
        Swal.fire({
          icon: "error",
          text:
            this.translations?.body?.Error_must_select_gender ??
            "You must select a Gender.",
        });
        return false;
      }
      return true;
    },

    // isValidSelectedCountryBirthday() { //commentato per ora
    //   if (!this.selectedCountryBirthday) {
    //     Swal.fire({
    //       icon: "error",
    //       text:
    //         this.translations?.body?.Error_must_select_birthday_country ??
    //         "You must select a birthday country.",
    //     });
    //     return false;
    //   }
    //   return true;
    // },

    // isValidSelectedCityBirthday() { //commentato per ora
    //   if (!this.selectedCityBirthday) {
    //     Swal.fire({
    //       icon: "error",
    //       text:
    //         this.translations?.body?.Error_must_select_birthday_city ??
    //         "You must select a birthday city.",
    //     });
    //     return false;
    //   }
    //   return true;
    // },

    isValidSelectedCountryResidence() {
      if (!this.selectedCountryResidence) {
        Swal.fire({
          icon: "error",
          text:
            this.translations?.body?.Error_must_insert_residence_country ??
            "You must insert a residence country.",
        });
        return false;
      }
      return true;
    },

    isValidSelectedResidenceCity() {
      if (!this.selectedResidenceCity) {
        Swal.fire({
          icon: "error",
          text:
            this.translations?.body?.Error_must_insert_residence_city ??
            "You must insert a residence city.",
        });
        return false;
      }
      return true;
    },

    isValidAddress() {
      if (!this.address_user) {
        Swal.fire({
          icon: "error",
          text:
            this.translations?.body?.Error_must_insert_address ??
            "You must insert your address.",
        });
        return false;
      }
      return true;
    },

    isValidCivicNumber() {
      if (!this.civicNumberUser) {
        Swal.fire({
          icon: "error",
          text:
            this.translations?.body?.Error_must_insert_street_number ??
            "You must insert your street number.",
        });
        return false;
      }
      return true;
    },

    isValidPostalCode() {
      if (!this.zipCode) {
        Swal.fire({
          icon: "error",
          text:
            this.translations?.body?.Error_must_insert_zip_code ??
            "You must insert your postal code.",
        });
        return false;
      }
      return true;
    },

    async checkDocumentType() {
      if (!this.selectedDocumentType) {
        Swal.fire({
          icon: "error",
          text:
            this.translations?.body?.Error_must_choose_document ??
            "You must select a document.",
        });
        this.isDocumentTypeValid = false;
      } else {
        this.isDocumentTypeValid = true;
      }
    },

    async checkDocumentNumber() {
      if (!this.documentNumber) {
        Swal.fire({
          icon: "error",
          text:
            this.translations?.body?.Error_must_insert_document_number ??
            "You must insert a document number.",
        });
        this.isDocumentNumberValid = false;
      } else {
        this.isDocumentNumberValid = true;
      }
    },

    async checkReleaseDateDoc() {
      if (!this.dayOfRelease || !this.monthOfRelease || !this.yearOfRelease) {
        Swal.fire({
          icon: "error",
          text:
            this.translations?.body?.Error_must_fill_all_fields_release_doc ??
            "You must fill in all fields for the release date of your document.",
        });
        this.isReleaseDateDocValid = false;
      } else {
        this.isReleaseDateDocValid = true;
      }
    },

    async checkExpireDateDoc() {
      if (!this.dayOfExpire || !this.monthOfExpire || !this.yearOfExpire) {
        Swal.fire({
          icon: "error",
          text:
            this.translations?.body?.Error_must_fill_all_fields_expire_doc ??
            "You must fill in all fields for the expire date of your document.",
        });
        this.isExpireDateDocValid = false;
      } else {
        this.isExpireDateDocValid = true;
      }
    },

    async getCampaign(event) {
      let img = event.target.options[event.target.options.selectedIndex].getAttribute(
        "data-img"
      );
      this.campaignTitle = event.target.options[
        event.target.options.selectedIndex
      ].getAttribute("data-title");
      if (img != null) this.campaignImage = img;
      else this.campaignImage = "";

      if (event.target.value == "bonuscode") this.showbonuscode = true;
      else {
        this.showbonuscode = false;
        this.bonus_code_campaign = null;
      }
    },
    async getCountryPromos() {
      const selectedCountry = this.selectedCountryResidence;
      const phonePrefix = this.countries.find(
        (country) => country.code == selectedCountry
      );
      this.phonePrefix = phonePrefix.phone_prefix;
      this.$store
        .dispatch("skinSettings/callCampaigns", {
          country: this.selectedCountryResidence,
          currency: this.selectedCurrency,
        })
        .then(() => {
          if (0 in this.campaigns) {
            let codeWelcome = this.campaigns[0].code;
            this.campaign = codeWelcome;

            if (this.campaigns[0].media_url != null)
              this.campaignImage = this.campaigns[0].media_url;
            else this.campaignImage = "";

            this.campaignTitle = this.campaigns[0].name;
          } else {
            this.campaign = "null";
            this.campaignImage = "";
            this.campaignTitle = "";
          }
        });
    },

    /*check newsLetters*/
    isCheckboxCheckedNewsLetters() {
      const checkbox = document.getElementById("checkBoxNewsLetters");
      const isChecked = checkbox.checked;
      this.isNewsLettersAccepted = isChecked;

      return isChecked;
    },

    /* SUMBIT FORM REGISTRATION */

    async saveRegistration() {
      this.checkUsername();
      this.checkEmail();
      this.checkPhoneNumber();
      const body = {
        acceptance: 1,
        name: this.name + " " + this.surname,
        firstname: this.name,
        lastname: this.surname,
        username: this.username,
        email: this.email,
        password: this.password,
        password_confirmation: this.password,
        mobile: this.selectedPrefix + this.phoneNumber,
        currency_id: this.selectedCurrency, //numero
        promocode: this.campaign,
        referral_code: this.referralCode,
        affiliate_Bonus: this.affiliate_code,
        gender: this.selectedGender,
        dob: `${this.yearOfBirth}-${this.monthOfBirth}-${this.dayOfBirth}`,
        document_Type: this.selectedDocumentType,
        document_Number: this.documentNumber,
        birth_country: this.selectedCountryBirthday ?? "",
        city: this.selectedResidenceCity,
        state: this.selectedCountryResidence,
        newsletter: this.isNewsLettersAccepted,
        terms_and_conditions: true,
        status: "active",
        country: this.selectedCountryResidence,
        birth_city: this.selectedCityBirthday ?? "",
        address_line_1: this.address_user,
        address_line_2: this.civicNumberUser,
        postal_code: this.zipCode,
      };
      // console.log("-----------Report Registrazione-------------");
      // console.log(body);
      // console.log("-----------Report Registrazione-------------");

      this.$store
        .dispatch("registration/saveRegistration", {
          body,
        })
        .then(() => {
          if (this.$store.getters["registration/isLastCheckRegistration"] == 1) {
            localStorage.setItem("prefilled-usr", this.username);
            localStorage.setItem("prefilled-psw", this.password);
            Swal.fire({
              icon: "success",
              text:
                this.translations?.body?.Registration_complete ??
                "Registration complete!Please verify you email to activate your account!",
              timer: 3500,
              showConfirmButton: false,
            }).then(() => {
              if (this.$route.name === "game-page") {
                window.location.href = "/";
              } else {
                this.toggleLoginMenu();
              }
            });
          } else {
            Swal.fire({
              icon: "error",
              text:
                this.translations?.body?.Error_registration_failed ??
                "Registration Failed.Try again!",
              timer: 500,
              showConfirmButton: false,
            }).then(() => {
              window.location.reload();
            });
          }
        });
    },
  },
  computed: {
    filteredCountries() {
      return this.countriesAll.filter((country) =>
        country.name.trim().toLowerCase().includes(this.searchInput.trim().toLowerCase())
      );
    },
    filteredResidenceCountry() {
      return this.countries.filter((residenceCountry) =>
        residenceCountry.name
          .trim()
          .toLowerCase()
          .includes(this.searchInputResidenceCountry.trim().toLowerCase())
      );
    },
    ...mapGetters("openMenuLogin", ["isModalOpen"]),
    ...mapGetters("skinSettings", [
      "currencies",
      "countriesAll",
      "countries",
      "campaigns",
    ]),
    ...mapGetters("translations", ["translations", "isTranslationLoading"]),
    ...mapGetters("registration", ["isMobileInvalid"]),
    sortedCountries() {
      const uniqueCountries = Array.from(
        new Set(this.countries.map((c) => c.phone_prefix))
      );
      return uniqueCountries
        .sort((a, b) =>
          a.localeCompare(b, undefined, {
            numeric: true,
            sensitivity: "base",
          })
        )
        .map((phone_prefix) =>
          this.countries.find((c) => c.phone_prefix === phone_prefix)
        );
    },
  },
  watch: {
    $route() {
      //if not used remove btga when change page
      localStorage.removeItem("btag");
    },
  },
};
</script>

<style scoped>
:disabled {
  opacity: 0.6 !important;
  cursor: not-allowed !important;
}
</style>
