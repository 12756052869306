// import router from "@/router";
// import Swal from 'sweetalert2';
export default {
  namespaced: true,
  state() {
    return {
      path: {
        allGames: "casino/games?",
        games: "casino/games?page=",
        gameSearched: "casino/games?is_provider_visible=1&name=",
        sections: "casino/sections",
        game_section: "casino/games?&section_id=",
        providers: "casino/providers",
        filterdProviders: "casino/games?",
        filterdGameSidebar: "casino/games?",
        gamePageStart: "static/casinoGames/",
      },
      howManyGamesPerPage: 4,
      /*Check device type*/
      isDevice: "",
      isMobileStatus: "",
      isDesktopStatus: "",
      //gamesSWS
      games: [],
      gamesTopWin: [],
      gamesPopularSections: [],
      gamesLiveCasino: [],
      currentGames: [],
      searchResults: [],
      currentProvider: null,
      last_page: null,
      currentPage: 1,
      wordToSearch: null,
      //Skin
      allSkinGames: null,
      allSkinProviders: null,
      allSkinProviderImages: null,
      isAllSkinGamesLoading: true,
      isAllProvidersLoading: true,
      isLoadingCardGames: true,
      isLoadingCardGamesBySections: true,
      allSkinSections: null,
      isChangePage: false,
      game_detail: {},
      //Game in sections
      gamesInThisSection: null,
      fullGameInThisSection: [],
      idFUllGameInthisSection: null,
      defaultSectionFirstLoading: null,
      isFullViewActive: false,
      allSkinSectionLeftMenuCasino: null,
      allSkinSectionLeftMenuLiveCasino: null
    };
  },
  getters: {
    endpoint(_, _1, _2, rootGetters) {
      return rootGetters["api/endpoint"];
    },
    axios(_, _1, _2, rootGetters) {
      return rootGetters["api/axios"];
    },
    auth(_, _1, _2, rootGetters) {
      return rootGetters["api/auth"];
    },
    isDevice(state) {
      return state.isDevice;
    },
    isMobileStatus(state) {
      return state.isMobileStatus;
    },
    isDesktopStatus(state) {
      return state.isDesktopStatus;
    },
    isLoadingCardGames(state) {
      return state.isLoadingCardGames;
    },
    isLoadingCardGamesBySections(state) {
      return state.isLoadingCardGamesBySections;
    },
    currentPage(state) {
      return state.currentPage;
    },
    last_page(state) {
      return state.last_page;
    },
    games(state) {
      return state.games;
    },
    gamesTopWin(state) {
      return state.gamesTopWin;
    },
    gamesPopularSections(state) {
      return state.gamesPopularSections;
    },
    gamesLiveCasino(state) {
      return state.gamesLiveCasino;
    },
    currentProvider(state) {
      return state.currentProvider;
    },
    searchResults(state) {
      return state.searchResults;
    },
    // SELECTED GAMES DEPENDENT ON PAGE
    selectedSection(state) {
      return state.selectedSection;
    },
    sectionsAndGamesInThisPage(state) {
      return state.sectionsAndGamesInThisPage;
    },
    gamesInThisSection(state) {
      return state.gamesInThisSection;
    },
    fullGameInThisSection(state) {
      return state.fullGameInThisSection;
    },
    isFullViewActive(state) {
      return state.isFullViewActive;
    },
    idFUllGameInthisSection(state) {
      return state.idFUllGameInthisSection;
    },
    allSkinProviders(state) {
      return state.allSkinProviders;
    },
    defaultSectionFirstLoading(state) {
      return state.defaultSectionFirstLoading;
    },
    allSkinProviderImages(state) {
      return state.allSkinProviderImages;
    },
    isAllProvidersLoading(state) {
      return state.isAllProvidersLoading;
    },
    // ONLY FOR LAUNCH GAME PAGE
    allSkinSections(state) {
      return state.allSkinSections;
    },
    isAllSkinGamesLoading(state) {
      return state.isAllSkinGamesLoading;
    },
    game_detail(state) {
      return state.game_detail;
    },
    wordToSearch(state) {
      return state.wordToSearch;
    },
    allSkinSectionLeftMenuCasino(state) {
      return state.allSkinSectionLeftMenuCasino;
    },
    allSkinSectionLeftMenuLiveCasino(state) {
      return state.allSkinSectionLeftMenuLiveCasino;
    }
  },
  mutations: {
    setIsDevice(state, value) {
      if (value === "desktop") {
        state.isDevice = "&is_desktop=1";
        state.isDesktopStatus = 1;
        state.isMobileStatus = 0;
      } else if (value === "mobile") {
        state.isDevice = "&is_mobile=1";
        state.isDesktopStatus = 0;
        state.isMobileStatus = 1;
      }
    },
    setIsLoadingCardGames(state, value) {
      state.isLoadingCardGames = value;
    },
    setCurrentPage(state, actualPage) {
      state.currentPage = actualPage;
    },
    setCurrentProvider(state, slug) {
      state.currentProvider = slug;
    },
    setLastPage(state, last_page) {
      state.last_page = last_page;
    },
    setSkinProviders(state, payload) {
      // state.allSkinProviders = payload.data;
      state.allSkinProviders = payload.data;
      // console.log(state.allSkinProviders)
    },
    setAllSkinProviderImages(state, payload) {
      state.allSkinProviderImages = payload.data;
    },
    saveSkinSections(state, payload) {
      state.allSkinSections = payload.data;
      if (Object.keys(state.allSkinSections).length > 0) {
        const firstSectionId = state.allSkinSections[0];
        state.defaultSectionFirstLoading = firstSectionId;
        localStorage.setItem('defaultSection', state.defaultSectionFirstLoading.id);
      } else {
        console.error("Nessuna section available");
      }
    },

    saveSkinSectionsLeftMenuCasino(state, payload) {
      state.allSkinSectionLeftMenuCasino = payload.data;
    },
    saveSkinSectionsLeftMenuLiveCasino(state, payload) {
      state.allSkinSectionLeftMenuLiveCasino = payload.data;
    },

    setLaunchGames(state, payload) {
      state.game_detail = payload;

      state.allSkinGames_loaded = true;
      state.isAllSkinGamesLoading = false;
    },
    saveGamesInThisSection(state, { sectionId, games }) {
      state.gamesInThisSection = {
        ...state.gamesInThisSection,
        [sectionId]: games.data,
      };
      state.isLoadingCardGamesBySections = false;
    },
    saveFullGameInThisSection(state, value) {
      // console.log(state.fullGameInThisSection)
      let mergeResult = state.fullGameInThisSection.concat(value.data);
      state.fullGameInThisSection = mergeResult;
      state.isLoadingCardGames = false
      state.isLoadingCardGamesBySections = false
    },
    clearGame(state, value) {
      state.fullGameInThisSection = value;
      state.gamesInThisSection = value
    },
    setIsFullViewActive(state, value) {
      state.isFullViewActive = value;
    },
    setIdFUllGameInthisSection(state, value) {
      state.idFUllGameInthisSection = value;
    },
    setWordToSearch(state, value) {
      state.wordToSearch = value
    }
  },
  actions: {
    async callAllGamesByProvider({ getters, commit, state }) {
      state.isLoadingCardGames = true
      let defaultSection = localStorage.getItem('defaultSection');
      let activeSection = localStorage.getItem('fullGameSectionID')
      let sectionIdtoPass = defaultSection ? defaultSection : activeSection
      const responseData = await getters.axios
        .get(
          getters.endpoint +
          state.path.allGames +
          '&provider_id=' + state.currentProvider.id +
          '&section_id=' + sectionIdtoPass +
          "&per_page=40" +
          "&page=" +
          state.currentPage +
          state.isDevice,
          getters.auth
        )
        .then((response) => response.data)
        .catch((err) => err);

      // commit("setLastPage", responseData.meta.last_page);
      commit("saveFullGameInThisSection", responseData);
      commit("setLastPage", responseData.meta.last_page);
      commit('setCurrentPage', ++state.currentPage)
    },

    async callSkinProviders({ state, getters, commit }, filters) {
      let defaultSection = localStorage.getItem('defaultSection');
      let activeSection = localStorage.getItem('fullGameSectionID')
      let sectionIdtoPass = defaultSection ? defaultSection : activeSection
      // state.isAllProvidersLoading = true;
      let params = "?";
      if (filters != null) {
        filters.forEach((filter, index) => {
          params += `${filter.label}=${filter.value}`;
          if (index < filters.length - 1) {
            params += "&";
          }
        });
      }
      const responseData = await getters.axios
        .get(getters.endpoint + 'casino/sections/' + sectionIdtoPass + '/providers' + params, getters.auth)
        .then((response) => response.data)
        .catch((err) => err);
      state.isAllProvidersLoading = false;
      commit("setSkinProviders", responseData);
    },

    async callSkinProvidersImages({ state, getters, commit }) {
      state.isAllProvidersLoading = true;
      const responseData = await getters.axios
        .get(getters.endpoint + state.path.providers, getters.auth)
        .then((response) => response.data)
        .catch((err) => err);
      state.isAllProvidersLoading = false;
      commit("setAllSkinProviderImages", responseData);
    },

    async clearFilter({ commit }) {
      // await commit("setIsFullViewActive", false);
      await commit("clearGame", []);
      await commit("setCurrentPage", 1);
      await commit("setCurrentProvider", null);
      await commit('setWordToSearch', null)
    },

    async clearFilterLite({ commit }) {
      await commit("setCurrentProvider", null);
      await commit('setWordToSearch', null)
    },

    async launchGamePage({ state, getters, commit }, { game_id, provider_id }) {
      const endpoint = getters.endpoint;
      const path = state.path.gamePageStart;
      const url = `${endpoint}${path}${game_id}?provider_id=${provider_id}`;
      const responseData = await getters.axios
        .get(url, getters.auth)
        .then((response) => response.data.data)
        .catch((err) => err);
      commit("setLaunchGames", responseData);
    },

    async callSkinSections({ state, getters, commit }, slug = "homepage") {
      const responseData = await getters.axios
        .get(
          getters.endpoint + state.path.sections + "?&language=en&slug=" + slug,
          getters.auth
        )
        .then((response) => response.data)
        .catch((err) => err);

      await commit("saveSkinSections", responseData);
    },

    async callSkinSectionsFromLeftMenu({ state, getters, commit }, slug) {
      const responseData = await getters.axios
        .get(
          getters.endpoint + state.path.sections + "?&language=en&slug=" + slug,
          getters.auth
        )
        .then((response) => response.data)
        .catch((err) => err);

      if (slug == 'homepage') {
        await commit("saveSkinSectionsLeftMenuCasino", responseData);
      } else if (slug == 'live-casino') {
        await commit("saveSkinSectionsLeftMenuLiveCasino", responseData);
      }
    },

    async callGamesBySections({ state, getters, commit }, section) {
      state.isLoadingCardGamesBySections = true;
      let howManyGamesToShow = 10;
      let currentPage = 1;
      let sectionId = section.id;

      const gamesResponse = await getters.axios
        .get(
          `${getters.endpoint}${state.path.game_section}${sectionId}&per_page=${howManyGamesToShow}&page=${currentPage}&sort_by=rank&language=en&${state.isDevice}`,
          getters.auth
        )
        .then((response) => response.data)
        .catch((err) => err);

      await commit("saveGamesInThisSection", { sectionId, games: gamesResponse });
    },
    async callFullGameBySections(
      { state, getters, commit, dispatch },
      { section, currentPage, defaultValue, filters }
    ) {
      let isDefault = defaultValue;
      if (isDefault) {
        state.isLoadingCardGamesBySections = true
      } else {
        state.isLoadingCardGames = true
      }

      // console.log(isDefault)
      state.isLoadingCardGames = true;
      let howManyGamesToShow = 60;
      let actualPage = currentPage;
      let sectionId = section;

      const gamesResponse = await getters.axios
        .get(
          `${getters.endpoint}casino/sections/${sectionId}/gamesAll?per_page=${howManyGamesToShow}&page=${actualPage}&sort_by=rank&language=en${state.isDevice}`,
          getters.auth
        )
        .then((response) => response.data)
        .catch((err) => err);
      // console.log(gamesResponse);
      // console.log(gamesResponse.meta.last_page);
      await commit("setLastPage", gamesResponse.meta.last_page);
      await commit("setCurrentPage", ++actualPage);
      await commit("saveFullGameInThisSection", gamesResponse);
      await dispatch('callSkinProviders', filters)
    },

    async searchGames({ state, getters, commit }, { wordSearched, currentSection, currentProvider }) {
      state.wordToSearch = wordSearched;
      let defaultSection = localStorage.getItem('defaultSection');
      let activeSection = localStorage.getItem('fullGameSectionID')
      let sectionIdtoPass = defaultSection ? defaultSection : activeSection
      state.isLoadingCardGames = true
      commit("clearGame", []);
      commit('setCurrentPage', 1);
      commit('setLastPage', 0)
      commit("setIsFullViewActive", true);
      let providerSelected = currentProvider.id;
      let gametype = '';

      if (currentSection.startsWith('live')) {
        gametype = 'live-casino'
      }

      let endpoint = `${getters.endpoint}${state.path.gameSearched}${state.wordToSearch}`;
      if (gametype !== '') {
        endpoint += `&type=${gametype}`
      }
      if (state.isDevice)
        endpoint += state.isDevice

      if (providerSelected && providerSelected !== '') {
        endpoint += `&provider_id=${providerSelected}`
      }

      const responseData = await getters.axios
        .get(endpoint + '&section_id=' + sectionIdtoPass, getters.auth)
        .then((response) => response.data)
        .catch((err) => err);
      await commit("saveFullGameInThisSection", responseData);
    },
    removeSectionSavedLocalStorage() {
      localStorage.removeItem('fullGameSectionID');
    }
  },
};
