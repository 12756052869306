// import router from "@/router";
import Swal from "sweetalert2";
export default {
  namespaced: true,
  state() {
    return {
      path: {
        recoveryPassword: "auth/password/recovery",
        manyAttempts: "auth/password/manyAttempts",
        checkUsername: "auth/checkusername",
        checkEmail: "auth/checkemail",
        checkMobile: "auth/checkmobile",
        checkFields: "auth/checkfields",
        register: "auth/register",
        static: "static/players/",
        sendEmailAgain: (id) => {
          return `notifications/players/${id}/registration`;
        },
        notification: (service) => {
          return `notifications/players/verify_${service}`;
        },
      },
      // REGISTRATION
      registration_nickname: "",
      registration_password: "",
      isLastCheckRegistration: true,
      isNicknameInvalid: null,
      nicknameErrorMessage: null,
      passwordValidated: false,
      isEmailInvalid: null,
      emailErrorMessage: null,
      emailValidated: false,
      isMobileInvalid: true,
      mobileErrorMessage: null,
      isFieldsInvalid: true,
      fieldsErrorMessage: null,
      isPasswordInvalid: true,
      passwordErrorMessage: null,
      passwordMatchControl: false,
      confirmPasswordFieldError: false,
      verificationPlayerID: "",
      verificationConfirmed: false,
      codeMailCorrect: false,
      //for login
      userInfo: {},
    };
  },
  getters: {
    endpoint(_, _1, _2, rootGetters) {
      return rootGetters["api/endpoint"];
    },
    axios(_, _1, _2, rootGetters) {
      return rootGetters["api/axios"];
    },
    skinId(_, _1, _2, rootGetters) {
      return rootGetters['skinSettings/skinId'];
    },
    translations(_, _1, _2, rootGetters) {
      return rootGetters['translations/translations'];
    },
    auth(_, _1, _2, rootGetters) {
      return rootGetters["api/auth"];
    },
    userInfo(state) {
      return state.userInfo;
    },
    registration_nickname(state) {
      return state.registration_nickname;
    },
    registration_password(state) {
      return state.registration_password;
    },
    isNicknameInvalid(state) {
      return state.isNicknameInvalid;
    },
    nicknameErrorMessage(state) {
      return state.nicknameErrorMessage;
    },
    passwordValidated(state) {
      return state.passwordValidated;
    },
    isEmailInvalid(state) {
      return state.isEmailInvalid;
    },
    emailErrorMessage(state) {
      return state.emailErrorMessage;
    },
    emailValidated(state) {
      return state.emailValidated;
    },
    isMobileInvalid(state) {
      return state.isMobileInvalid;
    },
    mobileErrorMessage(state) {
      return state.mobileErrorMessage;
    },
    isFieldsInvalid(state) {
      return state.isFieldsInvalid;
    },
    fieldsErrorMessage(state) {
      return state.fieldsErrorMessage;
    },
    isPasswordInvalid(state) {
      return state.isPasswordInvalid;
    },
    passwordErrorMessage(state) {
      return state.passwordErrorMessage;
    },
    passwordMatchControl(state) {
      return state.passwordMatchControl;
    },
    confirmPasswordFieldError(state) {
      return state.confirmPasswordFieldError;
    },
    isLastCheckRegistration(state) {
      return state.isLastCheckRegistration;
    },
  },
  mutations: {
    emailValidation(state, payload) {
      let result = !!String(payload)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
      state.emailValidated = !!result;
      // console.log("il result dopo check email è: " + state.emailValidated);
    },
    passwordValidation(state, payload) {
      let result = String(payload).match(
        /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/gm
      );
      state.passwordValidated = !!result;
    },

    setIsNicknameInvalid(state, value) {
      state.isNicknameInvalid = value;
    },

    setIsEmailInvalid(state, value) {
      state.isEmailInvalid = value;
    },

    setIsMobileInvalid(state, value) {
      state.isMobileInvalid = value;
    },
    checkFields(state, { responseData, fieldsAlreadyUsed }) {
      if (responseData.data == 1) {
        state.isFieldsInvalid = false;
      } else if (
        Object.prototype.hasOwnProperty.call(responseData, "response")
      ) {
        state.isFieldsInvalid = true;
        state.fieldsErrorMessage = fieldsAlreadyUsed;
        return false;
      } else {
        console.log(responseData);
      }
    },
    saveRegistration(_, payload) {
      console.log(payload);
    },
    saveUserInfo(state, value) {
      state.userInfo = value;
    },
    setIsLastCheckRegistration(state, value) {
      state.isLastCheckRegistration = value;
    },
    setVerificationCode(_, payload) {
      console.log(payload);
    },
    setContactVerification(state, { responseData, service }) {
      console.log(service)
      if (Object.prototype.hasOwnProperty.call(responseData, 'data')) {
        if (responseData.data.status == 'success') {
          state.verificationConfirmed = true;
          state.codeMailCorrect = true;
          alert('Succes code right')
        }
      } else {
        state.codeMailCorrect = false;
        if (Object.prototype.hasOwnProperty.call(responseData, 'response')) {
          // alert(responseData.response.data.message);
        } else {
          alert('SERVER ERROR');
        }
      }
    },
  },
  actions: {
    async checkNickname({ state, getters, commit }, payload) {
      let body = {
        username: payload,
      };
      const responseData = await getters.axios
        .post(getters.endpoint + state.path.checkUsername, body, getters.auth)
        .then((response) => response)
        .catch((err) => err);
      // console.log(responseData.data + " async CHECK-NICKNAME RESPONSE");
      if (responseData.data == 1) {
        commit("setIsNicknameInvalid", false);
      } else {
        commit("setIsNicknameInvalid", true);
      }
    },

    async checkEmail({ state, getters, commit }, payload) {
      state.isEmailInvalid = true;
      let emailUser = payload;
      console.log("payloadEmail:" + emailUser);
      commit("emailValidation", emailUser);
      if (getters.emailValidated) {
        let body = {
          email: payload,
        };
        const responseData = await getters.axios
          .post(getters.endpoint + state.path.checkEmail, body, getters.auth)
          .then((response) => response)
          .catch((err) => err);
        // console.log("la ResponseData di CHECKEMAIL è:" + responseData.data);
        if (responseData.data != 1) {
          commit("setIsEmailInvalid", true);
          // console.log("A" + "setiIsEmailInvalid = " + state.isEmailInvalid);
        } else {
          commit("setIsEmailInvalid", false);
          // console.log("B");
        }
      } else {
        console.log("error");
      }
    },

    async checkPhoneNumber({ state, getters, commit }, { prefix, mobile }) {
      state.isMobileInvalid = false;
      if (prefix.length < 1) {
        state.isMobileInvalid = true;
        state.mobileErrorMessage = "remove phone prefix";
        commit("setIsMobileInvalid", true);
        return;
      } else {
        try {
          const body = {
            mobile: prefix + mobile,
          };
          state.mobileErrorMessage = "";
          const responseData = await getters.axios
            .post(getters.endpoint + state.path.checkMobile, body, getters.auth)
            .then((response) => response.data);
          await commit("setIsMobileInvalid", responseData);
        } catch (error) {
          commit("setIsMobileInvalid", false);
          Swal.fire({
            icon: "error",
            text:
              getters.translations?.body?.Error_phone_number_already_taken ??
              "Phone Number already taken.",
          });
          console.error("Errore during API Call:", error);
        }
      }
    },

    async saveRegistration({ state, getters, commit }, { body }) {
      const responseData = await getters.axios
        .post(getters.endpoint + state.path.register, body, getters.auth)
        .then((response) => response)
        .catch((err) => err);
      if (responseData.status == 200 || responseData.status == 201) {
        localStorage.removeItem("btag");
        commit("setIsLastCheckRegistration", 1); //all check passed
        commit("saveRegistration", responseData);
        commit("saveUserInfo", body);
      } else {
        commit("setIsLastCheckRegistration", 0); //check field not passed
      }
    },
    async updatePlayer(
      { state, getters, commit, dispatch },
      { body, playerId }
    ) {
      const responseData = await getters.axios
        .put(
          getters.endpoint + state.path.static + playerId,
          body,
          getters.auth
        )
        .then((response) => response)
        .catch((err) => err);

      if (Object.prototype.hasOwnProperty.call(responseData, "status")) {
        if (responseData.status == "200") {
          console.log(responseData, dispatch);
        } else {
          alert("SERVER ERROR");
        }
      } else {
        commit("saveRegistration", responseData);
      }
    },
    // RECOVER PASSWORD
    async recoverPassword({ state, getters }, payload) {
      if (payload) {
        const config = {
          body: { username: payload, expiration_hours: 1 },
          path: state.path.recoveryPassword,
        };

        if (payload.includes("@")) {
          config.body = { email: payload, expiration_hours: 1 };
        }

        try {
          const response = await getters.axios.post(
            getters.endpoint + config.path,
            config.body,
            getters.auth
          );
          const responseData = response.data;

          if (responseData.email) {
            // Se la risposta contiene il campo 'email'
            Swal.fire({
              icon: "error",
              text:
                getters.translations?.body?.Email_doesnt_exist ??
                "This email does not exist!",
            });
          } else if (responseData.token) {
            // Se la risposta contiene il campo 'token'
            Swal.fire({
              icon: "success",
              text:
                getters.translations?.body?.please_follow_instruction ??
                "Please follow the instructions sent via email!",
              timer: 10000,
              didClose: () => {
                window.location.href = localStorage.getItem("language") + "/";
              },
            });
          } else {
            // Se la risposta non contiene né 'email' né 'token'
            Swal.fire({
              icon: "error",
              text:
                getters.translations?.body?.Something_went_wrong ??
                "Something went wrong",
            });
          }
        } catch (error) {
          console.error("Error during password recovery:", error);
          if (error.response && error.response.status === 422) {
            // Se la risposta ha uno status code 422
            Swal.fire({
              icon: "error",
              text:
                getters.translations?.body?.Email_doesnt_exist ??
                "This email does not exist!",
            });
          } else {
            // Altrimenti, se si verifica un altro tipo di errore
            Swal.fire({
              icon: "error",
              text:
                getters.translations?.body?.Something_went_wrong ??
                "Something went wrong",
            });
          }
        }
      }
    },

    async sendVerificationCodeAgain({ state, getters, commit }, payload) {
      const responseData = await getters.axios
        .post(getters.endpoint + state.path.sendEmailAgain(payload))
        .then((response) => response)
        .catch((err) => err);
      commit('setVerificationCode', responseData);
    },

    async contactVerification(
      { state, getters, commit, dispatch },
      { verification, service, playerId = null }
    ) {
      console.log(dispatch)
      let userId = playerId;

      const body = {
        user_id: userId,
        skin_id: getters.skinId,
        verification,
        check_campaign: 1
      };

      if (service == 'email') {
        body.email_verified = 1
      } else {
        body.sms_verified = 1
      }

      console.log(body)

      const responseData = await getters.axios
        .post(getters.endpoint + state.path.notification(service), body)
        .then((response) => response)
        .catch((err) => err);
      commit('setContactVerification', { responseData, service });
      // dispatch(
      //   'alert/topRightNotification',
      //   { responseData, service },
      //   { root: true }
      // );
    },

    verifyMail({ getters }) {
      Swal.fire({
        icon: "success",
        confirmButtonColor: "green",
        denyButtonColor: "red",
        showDenyButton: false,
        title: getters.translations.myprofile.Confirm_mail_popup ?? "Confirm Email",
        text: getters.translations.myprofile.Email_confirmation_code_sent ?? "Check your email address, and follow the link to verify your account",
        timer: 5000, // Auto close after 3 seconds
      })
    },
  },
};
