<template>
  <section
    class="recover-psw-section"
    style="padding: 120px 0px; min-height: 100vh"
  >
    <div class="container pt-5">
      <h3 style="color: white">Recover your Password</h3>
      <div class="form-group">
        <input
          v-model="email"
          type="email"
          id="email"
          placeholder="Email"
          class="form-control my-3"
        />
        <div class="form-group">
          <button
            :class="isEmailInvalid ? 'disabled-recover-psw' : 'recover-psw-btn'"
            @click="recovery"
            id="recover"
          >
            {{ translations?.body?.Recover_password ?? "Recover" }}
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      email: "",
      isEmailInvalid: true,
    };
  },
  computed: {
    ...mapGetters("translations", ["translations", "isTranslationLoading"]),
  },
  watch: {
    email(value) {
      function validationEmail(el) {
        let result = String(el)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
        return result;
      }
      if (validationEmail(value)) {
        this.isEmailInvalid = false;
      } else {
        this.isEmailInvalid = true;
      }
    },
  },
  methods: {
    recovery() {
      this.$store.dispatch("registration/recoverPassword", this.email);
    },
  },
};
</script>
