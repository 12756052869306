<template>
  <div>
    <transition name="route" mode="out-in">
      <PreLoader v-if="isPageLoading" />
    </transition>
    <section class="container" style="padding: 120px 15px 0px 15px">
      <div class="static-page-content">
        <h1 class="ty_h4 text-center my-3">{{ page.name }}</h1>
        <div>
          <div class="body-static-page ty_bd pt-2" v-html="page.content"></div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PreLoader from "@/components/global/PreLoader.vue";

export default {
  name: "StaticPage",
  components: {
    PreLoader,
  },
  computed: {
    ...mapGetters("skinSettings", ["language", "page", "isPageLoading"]),
    ...mapGetters("openMenuLogin", ["isLoginMenuOpen", "isModalOpen"]),
    ...mapGetters("translations", ["translations", "isTranslationLoading"]),
    ...mapGetters("casinoGames", ["isAllProvidersLoading"]),
  },
  beforeMount() {
    this.loadPage().then(() => {
      if (!this.page.id || this.page.id == undefined) {
        this.$router.push({ name: "Page404Error" });
      }
    });
  },
  watch: {
    "$route.params.page": "loadPage",
  },
  methods: {
    async loadPage() {
      let language = localStorage.getItem("language") ?? "en";
      if (language == "ca" || language == "au") {
        language = "en";
      }
      await this.$store.dispatch(
        "skinSettings/callPage",
        this.$route.params.page + "-" + language
      );

      document.title = this.page?.seo?.title ?? "Bet Happy";
      document
        .querySelector('meta[name="description"]')
        .setAttribute("content", this.page?.seo?.description ?? "Bet Happy");
      const keywords = this.page?.seo?.keywords;
      const metaKeywords = document.createElement("meta");
      metaKeywords.setAttribute("name", "keywords");
      metaKeywords.setAttribute("content", keywords);
      document.querySelector("head").appendChild(metaKeywords);
    },
  },
  beforeUnmount() {
    this.$store.commit("skinSettings/clearStaticPage", "");
  },
};
</script>
