<template>
  <div class="container" style="padding-top: 120px">
    <div
      class="verify-mail-section d-flex justify-content-center"
      style="min-height: 90vh"
    >
      <h3
        class="ty_h3"
        :class="{ verifying: isVerifying }"
        style="color: white"
      >
        <span>{{ unsubscribeMessages }} </span>
      </h3>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { mapState } from "vuex";
import Swal from "sweetalert2";

export default {
  name: "unsubscribe-page",
  data() {
    return {
      isVerifying: true,
      unsubscribeMessages: "",
      lang: localStorage.getItem("language") ?? "en",
      reason: "Request from the player to unsubscribe from the newsletters",
      terms_and_conditions: true,
      newsletter: true,
      token: null,
      isLoading: false,
      requestSent: false,
      is_accepted: true,
      newsletterOptOutTranslated: null,
    };
  },
  computed: {
    ...mapGetters("player", ["playerInfo"]),
    ...mapGetters("translations", ["isTranslationLoading", "translations"]),
    ...mapGetters("player", [
      "optOutToken",
      "newsletterOptOut",
      "isTokenInvalidOptOut",
    ]),
    ...mapState("player", ["isCheckingOptOutToken"]),
  },
  created() {
    this.unsubscribeMessages =
      this.translations?.body?.unsubscribe_in_few_moments ??
      "Subscription to the newsletters will be canceled shortly...";

    this.$router.push({ params: { lang: this.lang } });
  },
  mounted() {
    this.checkToken();
  },
  methods: {
    replaceSpacesWithUnderscore(text) {
      return text.toLowerCase().replace(/\s/g, "_");
    },
    async checkToken() {
      const token = this.$route.params.token;
      if (token) {
        this.token = token;
        let body = {
          token: this.token,
        };
        await this.$store.dispatch("player/checkOptOutToken", body).then(() => {
          if (!this.isTokenInvalidOptOut) {
            this.showAlert();
          }
        });
      }
    },
    showAlert() {
      if (this.token) {
        Swal.fire({
          text:
            this.translations?.dashboard_menu?.are_you_sure ??
            "Are you sure you want to unsubscribe from our newsletter?",
          icon: "warning",
          showDenyButton: true,
          denyButtonColor: "#a78300",
          confirmButtonText:
            this.translations?.dashboard_menu?.YesWant ?? "Yes, I Want",
          denyButtonText:
            this.translations?.dashboard_menu?.NoThink ??
            "No, I need to think about it",
          confirmButtonColor: "#149102",
          title: this.translations?.dashboard_menu?.Attention ?? "Attention",
        })
          .then((result) => {
            let body = {
              terms_and_conditions: 0,
              newsletter: 0,
              token: this.token,
              reason: this.reason,
              is_accepted: this.is_accepted, // ---> true --> if you wish for the request to be accepted automatically
            };

            if (result.isConfirmed)
              return this.$store.dispatch(
                "player/newsletterOptOutAction",
                body
              );
          })
          .then(() => {
            this.requestSent = true;
            if (
              this.newsletterOptOut ===
              "You're already unsubscribed from our newsletter"
            ) {
              this.newsletterOptOut =
                "You're already unsubscribed from our newsletter";
            } else if (
              this.newsletterOptOut === "Opt-out request sent successfully"
            ) {
              this.newsletterOptOut =
                "Newsletter opt-out request sent successfully";
            } else if (this.newsletterOptOut === "Failed to send the request") {
              this.newsletterOptOut = "Failed to send the request";
            } else if (
              this.newsletterOptOut === "Token not found or not valid"
            ) {
              this.newsletterOptOut = "Token not found or not valid";
            }

            this.newsletterOptOutTranslated =
              this.translations?.body?.[
                this.replaceSpacesWithUnderscore(this.newsletterOptOut)
              ] ?? this.newsletterOptOut;

            alert(this.newsletterOptOutTranslated);
            window.location.href = "/";
          });
      }
    },
  },
};
</script>

<style>
.verifying .dots {
  animation: verifyingAnimation 1s infinite alternate;
}

@keyframes verifyingAnimation {
  to {
    transform: scale(1.1);
  }
}
</style>
