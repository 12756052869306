<template>
  <transition name="route" mode="out-in">
    <PreLoader
      v-if="
        isLoadingCardGamesBySections ||
        isAllProvidersLoading ||
        isTranslationLoading ||
        isLoadingBanners
      "
    />
  </transition>
  <div class="Main-Container">
    <Carousel :slugSection="slug" />
    <GameSection
      :defaultSection="defaultSectionFirstLoading"
      :games_providers="allSkinProviders"
      :slugSection="slug"
    />
    <!-- <div v-if="!isFullViewActive" class="section-main-wrapper">
      <div
        v-for="(section, index) in allSkinSections"
        :key="section.id"
        id="gamesCategoryFiltered"
      >
        <CategoryGames
          :section="section"
          :isFirstTemplateVisible="index === 0"
          :isLastTemplateVisible="index + 1 === allSkinSections.length"
        />
      </div>
    </div> -->
    <!-- <div v-else class="full-game-shown">
      <FullCategoryGames :id="idFUllGameInthisSection" />
    </div> -->
    <div id="fullGame" class="full-game-shown">
      <FullCategoryGames
        :defaultSection="defaultSectionFirstLoading"
        :id="idFUllGameInthisSection"
        :filters="filters"
      />
    </div>
  </div>
</template>

<script>
import Carousel from "@/components/ui/Carousel.vue";
import GameSection from "@/components/ui/GameSection.vue";
//import CategoryGames from "@/components/ui/casinoPage/CategoryGames.vue";

import { mapGetters } from "vuex";
import { defineAsyncComponent } from "vue";
import PreLoader from "@/components/global/PreLoader.vue";
// import MainLoader from "@/components/global/MainLoader.vue";
const FullCategoryGames = defineAsyncComponent(() =>
  import("@/components/ui/casinoPage/FullCategoryGames.vue")
);
export default {
  name: "HomePage",
  components: {
    Carousel,
    GameSection,
    //CategoryGames,
    FullCategoryGames,
    PreLoader,
    // MainLoader,
  },
  data() {
    return {
      lang: localStorage.getItem("language"),
      allIsLoading: true,
      slug: "homepage",
      isLogged: localStorage.getItem("X19fYWNjZXNzX19f") ?? false,
    };
  },
  computed: {
    ...mapGetters("casinoGames", [
      "isLoadingCardGames",
      "allSkinSections",
      "allSkinProviders",
      "isFullViewActive",
      "idFUllGameInthisSection",
      "isAllProvidersLoading",
      "isLoadingCardGamesBySections",
      "defaultSectionFirstLoading",
      "currentPage",
    ]),
    ...mapGetters("translations", ["translations", "isTranslationLoading"]),
    ...mapGetters("skinBanners", ["isLoadingBanners", "bannerToDisplay"]),
    ...mapGetters("player", ["playerInfo"]),
  },
  created() {
    let filters = new Array();
    let filter = {};
    filter["label"] = "is_live";
    filter["value"] = 0;
    filters.push(filter);
    this.$router.push({ params: { lang: this.lang } });
    this.$store.dispatch("casinoGames/callSkinSections", this.slug).then(() => {
      this.$store.dispatch("casinoGames/callFullGameBySections", {
        section: localStorage.getItem("defaultSection"),
        currentPage: 1,
        defaultValue: true,
        filters: filters,
      });
    });
    if (
      (this.playerInfo?.id == null || this.playerInfo?.id == undefined) &&
      this.isLogged
    ) {
      this.$store.dispatch("player/callUserInformation").then(() => {
        this.$store.dispatch("skinBanners/callBanners", {
          slug: this.slug,
          playerID: this.playerInfo?.id,
          tag: "home",
        });
      });
    } else if (this.isLogged && this.playerInfo && this.playerInfo?.id) {
      this.$store.dispatch("skinBanners/callBanners", {
        slug: this.slug,
        playerID: this.playerInfo?.id,
        tag: "home",
      });
    } else if (!this.isLogged) {
      this.$store.dispatch("skinBanners/callBanners", {
        slug: this.slug,
        playerID: "",
        tag: "home",
      });
    }

    // this.$store.dispatch("casinoGames/callSkinProviders", filters);
  },
  mounted() {},
  beforeUnmount() {
    localStorage.removeItem("fullGameSectionID");
    this.$store.dispatch("casinoGames/clearFilter");
  },
  methods: {},
};
</script>
