<template>
  <transition name="route" mode="out-in">
    <PreLoader v-if="isPromotionsLoading || isTranslationLoading || isLoadingBanners" />
  </transition>
  <div>
    <Carousel :slugSection="slug" />
    <PromotionSections />
  </div>
</template>

<script>
import PreLoader from "@/components/global/PreLoader.vue";
import Carousel from "@/components/ui/Carousel.vue";
import PromotionSections from "@/components/ui/PromotionSections.vue";
import { mapGetters } from "vuex";
export default {
  name: "PromotionPage",
  components: {
    Carousel,
    PromotionSections,
    PreLoader,
  },
  data() {
    return {
      lang: localStorage.getItem("language"),
      allIsLoading: true,
      slug: "promotion",
      isLogged: localStorage.getItem("X19fYWNjZXNzX19f") ?? false,
    };
  },
  created() {
    this.$router.push({ params: { lang: this.lang } });
    // this.$store.dispatch("skinBanners/callBanners", this.slug);
    if (
      (this.playerInfo?.id == null || this.playerInfo?.id == undefined) &&
      this.isLogged
    ) {
      this.$store.dispatch("player/callUserInformation").then(() => {
        this.$store.dispatch("skinBanners/callBanners", {
          slug: this.slug,
          playerID: this.playerInfo?.id,
          tag: "promotions",
        });
      });
    } else if (this.isLogged && this.playerInfo && this.playerInfo?.id) {
      this.$store.dispatch("skinBanners/callBanners", {
        slug: this.slug,
        playerID: this.playerInfo?.id,
        tag: "promotions",
      });
    } else if (!this.isLogged) {
      this.$store.dispatch("skinBanners/callBanners", {
        slug: this.slug,
        playerID: "",
        tag: "promotions",
      });
    }
    // this.$store.dispatch("promotion/callSkinPromotions").then(() => {
    //   // this.promotions = this.promotionsList;
    // });
  },
  computed: {
    ...mapGetters("promotion", [
      "promotionsList",
      "promotion",
      "isPromotionsLoading",
      "isPromoIdLoading",
    ]),
    ...mapGetters("translations", ["translations", "isTranslationLoading"]),
    ...mapGetters("skinBanners", ["isLoadingBanners", "defaultBanner_mobile"]),
    ...mapGetters("player", ["playerInfo"]),
  },
};
</script>
