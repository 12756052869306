<template>
  <TheHeader
    v-if="this.$route.name !== 'game-page' && this.$route.name !== 'Page404Error'"
  />
  <ExitAdminModeButton v-if="isAdminMode" />
  <div>
    <router-view v-slot="slotProps">
      <component :is="slotProps.Component"></component>
    </router-view>
  </div>
  <TheFooter
    v-if="this.$route.name !== 'game-page' && this.$route.name !== 'Page404Error'"
  />
</template>

<script>
import TheHeader from "./components/global/TheHeader.vue";
import TheFooter from "./components/global/TheFooter.vue";
import ExitAdminModeButton from "./components/global/ExitAdminModeButton.vue";
import { mapGetters } from "vuex";
export default {
  name: "App",
  components: {
    TheHeader,
    TheFooter,
    ExitAdminModeButton,
  },
  data() {
    return {
      checkDeviceType: "",
      showScript: false,
      isAdminMode: false,
    };
  },
  computed: {
    ...mapGetters("player", ["isPlayerLoading", "playerInfo"]),
    ...mapGetters("translations", ["isTranslationLoading", "translations"]),
    ...mapGetters("casinoGames", [
      "isLoadingCardGames",
      "gamesInThisSection",
      "allSkinSections",
      "currentPage",
      "isFullViewActive",
      "isLoadingCardGamesBySections",
    ]),
  },
  async created() {
    let languagesSkins = ["de", "en", "pt", "tr", "es", "fr", "ar", "au", "ca"];
    if (languagesSkins.includes(this.$route.params.lang))
      localStorage.setItem("language", this.$route.params.lang);
    //SET API KEY
    this.$store.dispatch("api/createAuth");
    // CALL CLIENT IP
    this.$store.dispatch("api/getClientIp");
    // CHECK IF TOKEN EXIST
    await this.$store.dispatch("player/setTokenOnLoad").then(() => {
      if (this.playerInfo) {
        let enableSocket = process.env.VUE_APP_WEBSOCKET_ENABLE ?? false;
        if (enableSocket === "true") {
          this.$store.dispatch("player/websocketLogin"); // Connect to the websocket only when the player data is fetched
        }
      }
    });
    // CALL SKIN TRANSLATIONS
    await this.$store.dispatch("translations/getSkinLanguages").then(() => {
      this.setMetaDescription();
    });
    //CALL PROVIDER FOR IMAGE
    this.$store.dispatch("casinoGames/callSkinProvidersImages");
    // CALL SKIN SETTINGS
    this.$store.dispatch("skinSettings/callSkinSettings");
    // this.isDeskOrMob();
    // this.startChat();
    document.body.style.maxWidth = "1920px";
    document.body.style.margin = "0 auto";
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.checkAdminMode();
    this.handleResize();
  },

  methods: {
    setMetaDescription() {
      const metaDescriptionContent =
        this.translations?.body?.meta_description ??
        "Play at Bet Happy casino for online casino games. Deposit & Withdraw using BTC, ETH, LTC, BCH, DASH";

      let metaDescriptionTag = document.querySelector('meta[name="description"]');

      metaDescriptionTag = document.createElement("meta");
      metaDescriptionTag.name = "description";
      document.head.appendChild(metaDescriptionTag);

      metaDescriptionTag.content = metaDescriptionContent;
    },
    checkAdminMode() {
      const adminNickname = sessionStorage.getItem("adminNickname");
      const adminPassword = sessionStorage.getItem("adminPassword");
      const vanillaTranslations = sessionStorage.getItem("vanilla-translations");
      this.isAdminMode = adminNickname && adminPassword && vanillaTranslations;
    },
    handleResize() {
      if (window.innerWidth <= 600) {
        this.checkDeviceType = "mobile";
        this.showScript = false;
      } else {
        this.checkDeviceType = "desktop";
        this.showScript = true;
      }
      this.$store.commit("casinoGames/setIsDevice", this.checkDeviceType);
      this.startChat();
    },

    startChat() {
      const scriptIdPrefix = "b_fo9o";

      if (this.$route.name !== "game-page" && this.showScript) {
        const scriptId = "liveAgentScript";
        if (!document.getElementById(scriptId)) {
          const script = document.createElement("script");
          script.type = "text/javascript";
          script.id = scriptId;
          script.text = `
                (function(d, src, c) {
                    var t = d.scripts[d.scripts.length - 1],
                        s = d.createElement('script');
                    s.id = 'la_x2s6df8d';
                    s.defer = true;
                    s.src = src;
                    s.onload = s.onreadystatechange = function() {
                        var rs = this.readyState;
                        if (rs && (rs != 'complete') && (rs != 'loaded')) {
                            return;
                        }
                        c(this);
                    };
                    t.parentElement.insertBefore(s, t.nextSibling);
                })(document,
                'https://bethappy.ladesk.com/scripts/track.js',
                function(e){ LiveAgent.createButton('fo9o0mxz', e); });
            `;
          document.body.appendChild(script);
        }

        const elementsToHide = document.querySelectorAll(`[id^="${scriptIdPrefix}"]`);
        elementsToHide.forEach((element) => {
          element.style.display = "block";
        });
      } else {
        const script = document.getElementById("liveAgentScript");
        if (script) {
          script.style.display = "none";
        }
        const elementsToHide = document.querySelectorAll(`[id^="${scriptIdPrefix}"]`);
        elementsToHide.forEach((element) => {
          element.style.display = "none";
        });
      }
    },
  },
};
</script>

<style>
/* VUE TRANSITION - START*/
.route-enter {
  opacity: 0;
  transform: translateY(-20px);
}

.route-enter-active {
  transition: all 0.5s ease-in-out;
}

.route-leave-active {
  transition: all 0.5s ease-in-out;
}

.route-enter-to {
  opacity: 1;
  transform: translateX(0px);
}

.route-leave {
  opacity: 1;
  transform: translateX(0px);
}

.route-leave-to {
  opacity: 0;
  transform: translateX(20px);
}

/* Width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  transition: 0.4s all;
  background-color: #132437 !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: padding-box content-box;
  border-radius: 10px;
  transition: 0.4s all;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #3d5d20 !important;
  border-radius: 10px;
}

:root {
  scrollbar-color: #3d5d20 !important;
  scrollbar-width: thin !important;
  border-radius: 10px !important;
  transition: 0.35 all !important;
}
/* Track (Firefox) */
::-moz-scrollbar-track {
  background: padding-box content-box;
  border-radius: 10px;
}

/* Track (Edge) */
::-ms-scrollbar {
  width: 5px;
}

::-ms-scrollbar-track {
  background: padding-box content-box;
  border-radius: 10px;
}

/* Handle (Edge) */
::-ms-scrollbar-thumb {
  background: #3d5d20;
  border-radius: 10px;
  min-height: 20px;
}

.all {
  user-select: none !important;
  -webkit-user-drag: none;
  -moz-window-dragging: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

*,
::before,
::after {
  background-repeat: no-repeat;
  box-sizing: inherit;
  scrollbar-width: thin !important;
  user-select: none !important;
}
</style>
<style lang="css">
@import "bootstrap/dist/css/bootstrap.css";
</style>

<style lang="scss">
@import "@/assets/scss/app.scss";
</style>
