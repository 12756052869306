module.exports = {
  transpileDependencies: true,
  outputDir: process.env.VUE_OUTPUT_DIR,
  devServer: {
    proxy: process.env.VUE_APP_PROXY,
    webSocketServer: false,
  },
  css: {
    loaderOptions: {
      sass: {
        additionalData: `
          @import "@/assets/scss/app.scss";
        `,
      },
    },
  },
};
