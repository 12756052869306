<template>
  <div class="footer-wrapper">
    <footer class="footer-section">
      <div class="custom-container">
        <div class="top w-100 flex-wrap flex-column mb-1">
          <p class="ty_bd mb-1" v-if="0">
            {{ translations?.footer?.categoriesFooter ?? "Categories" }}
          </p>
          <ul class="navigation_links-footer justify-content-center">
            
            <!--In caso di separazione togliere il justify content center -->
            <li v-for="(link, index) in footerLinks" :key="index">
              <a :href="link.url">
                {{
                  translations?.footer?.[replaceSpacesWithUnderscore(link.label)] ??
                  link.label
                }}
              </a>
            </li>
            <li
              class="text-center"
              v-for="documentslink in documentsLinkFooter"
              :key="documentslink.label"
            >
              <router-link
                :to="{
                  name: 'static-page',
                  params: {
                    lang: `${lang}`,
                    page: documentslink.url,
                  },
                }"
                @click="
                  $router.push({
                    name: 'static-page',
                    params: {
                      lang: `${lang}`,
                      page: documentslink.url,
                    },
                  })
                "
              >
                {{
                  translations?.footer?.[
                    replaceSpacesWithUnderscore(documentslink.label)
                  ] ?? documentslink.label
                }}
              </router-link>
            </li>
          </ul>
        </div>

        <div class="row-social my-2">
          <a href="https://x.com/BetHappyCasino" target="_blank">
            <img
              src="../../../public/images/x.png"
              title="X"
              class="img-social-scale"
              alt="X"
            />
          </a>
          <a
            href="https://www.facebook.com/profile.php?id=61561041413324"
            target="_blank"
          >
            <img
              title="Facebook"
              src="../../../public/images/facebook.png"
              class="img-social"
              alt="Facebook"
            />
          </a>
          <a href="https://www.instagram.com/bethappycasino/" target="_blank">
            <img
              title="Instagram"
              src="../../../public/images/instagram.png"
              class="img-social"
              alt="Instagram"
            />
          </a>
        </div>

        <div class="row mb-3" id="company">
          <div class="certifications">
            <div
              v-for="method in logoPaymentMethods"
              :key="method.id"
              class="logo-method"
            >
              <img
                :src="`/images/paymentMethods/${method.name}.png`"
                :alt="method.name"
              />
            </div>
          </div>
          <div class="supplier-container">
            <div
              v-for="provider_thumb in allSkinProviderImages"
              :key="provider_thumb.id"
              class="logo-provider-footer position-relative"
              :data-provider-name="provider_thumb.name"
            >
              <img :src="provider_thumb.image" :alt="provider_thumb.name" />
            </div>
          </div>
        </div>

        <div class="spin-grin-lg-text w-100 text-center" v-if="0">
          <div class="spin-grin">
            {{ translations?.footer?.spin_grin ?? "Spin & Grin." }}
          </div>
        </div>
        <div class="d-flex flex-wrap w-100 justify-content-center align-items-center">
          <img
            style="max-width: 50px; max-height: 50px; object-fit: contain"
            src="/images/u18.png"
            alt=""
          />
          <a
            href="https://verify.antelliphone.com/9490785921340471"
            target="_blank"
            class="ml-3"
          >
            <img
              style="max-width: 100px; max-height: 50px; object-fit: contain"
              src="/images/gc-logo.png"
              alt="Gaming Curacao"
            />
          </a>
          <a href="https://www.gamstop.co.uk/" target="_blank" class="ml-3">
            <img
              style="max-width: 100px; max-height: 50px; object-fit: contain"
              src="/images/gamStop.png"
              alt="GamStop"
            />
          </a>
          <a href="https://www.gambleaware.org/" target="_blank" class="ml-3">
            <img
              style="max-width: 100px; max-height: 50px; object-fit: contain"
              src="/images/gambleAware.png"
              alt="Gamble Aware"
            />
          </a>
        </div>
        <div class="container my-3">
          <h5 class="ty_bs">
            {{
              translations?.footer?.license_one ??
              "Bet Happy is operated by Beyond Yonder Services on the basis of License.No.8048/JAZ of the international regulator Curaçao eGaming (Curacao)."
            }}
          </h5>
          <h5 class="ty_bs">
            {{
              translations?.footer?.license_two ??
              "Under 18s are strictly forbidden from gambling on this website."
            }}
          </h5>
          <h5 class="ty_bs">
            {{
              translations?.footer?.license_three ?? "Underage gambling is an offence."
            }}
          </h5>
          <h5 class="ty_bs">
            {{
              translations?.footer?.license_four ??
              "Gambling can be addictive, please play responsibly"
            }}
          </h5>
        </div>
        <!--SPIN&GRIN -->
        <div class="container text-center my-3">
          <h5 class="ty_bs">
            {{
              translations?.footer?.license_five ??
              "©Bet Happy, 2024, All rights reserved"
            }}
          </h5>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import SupportedCurrencies from "../ui/SupportedCurrencies.vue";
// import OurProviderCarouselFooter from "../ui/OurProviderCarouselFooter.vue";
export default {
  components: {
    // SupportedCurrencies,
    // OurProviderCarouselFooter,
  },
  data() {
    return {
      lang: localStorage.getItem("language") ?? "en",
      logoPaymentMethods: [
        { id: 1, name: "astropay1" },
        { id: 2, name: "bitcoin1" },
        { id: 3, name: "etherum1" },
        { id: 4, name: "evoucher1" },
        { id: 5, name: "mastercard1" },
        { id: 6, name: "flexepin1" },
        { id: 7, name: "muchbetter1" },
        { id: 8, name: "neosurf1" },
        { id: 9, name: "paysafe1" },
        { id: 10, name: "t1" },
        { id: 11, name: "visa1" },
      ],
      footerLinks: [
        {
          label: "Banking",
          url: `/${localStorage.getItem("language")}/banking`,
          slug: "banking",
        },
        {
          label: "Support",
          url: `/${localStorage.getItem("language")}/support`,
          slug: "support",
        },
      ],
      documentsLinkFooter: [
        // {
        //   label: "Affiliates",
        //   url: "affiliates",
        //   slug: "affiliates",
        // },
        {
          label: "AML",
          url: "aml",
          slug: "aml",
        },
        {
          label: "Deposit Policy",
          url: "deposit-policy",
          slug: "deposit-policy",
        },
        {
          label: "FAQ",
          url: "faq",
          slug: "faq",
        },
        {
          label: "GDPR",
          url: "gdpr",
          slug: "gdpr",
        },
        {
          label: "Payments",
          url: "payments",
          slug: "payments",
        },
        {
          label: "Privacy policy",
          url: "privacypolicy",
          slug: "privacypolicy",
        },
        {
          label: "Refund policy",
          url: "refund-policy",
          slug: "refund-policy",
        },
        {
          label: "Responsable Gaming",
          url: "responsible-gaming",
          slug: "responsible-gaming",
        },
        {
          label: "Terms And Conditions",
          url: "terms-and-conditions",
          slug: "terms-and-conditions",
        },
        {
          label: "Withdrawal Policy",
          url: "withdrawals-policy",
          slug: "withdrawals-policy",
        },
        {
          label: "Regulatory",
          url: "regulatory",
          slug: "regulatory",
        },
      ],
    };
  },
  created() {
    let lang = localStorage.getItem("language") ?? "en";
    this.lang = lang;
  },
  mounted() {},
  methods: {
    sendMail() {
      window.location.href = "mailto:info@bethappy.com";
    },
    replaceSpacesWithUnderscore(text) {
      return text.toLowerCase().replace(/\s/g, "_");
    },
  },
  computed: {
    ...mapGetters("translations", ["translations", "isTranslationLoading"]),
    ...mapGetters("casinoGames", ["allSkinProviderImages"]),
  },
  watch: {},
};
</script>

<style scoped>
.spin-grin {
  color: black;
  display: block;
  width: 100%;
  text-align: center;
  font-size: clamp(16px, 8vw, 270px);
}

.logo-provider-footer[data-provider-name="Pragmatic Play Live"]:after {
  content: "Live";
  display: block;
  position: absolute;
  top: 52px;
  right: 45px;
  font-weight: 500;
  color: white;
  font-size: 10px;
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
  transition: 0.3s all;
  padding: 1px;
}

.logo-provider-footer[data-provider-name="Pragmatic Play Live"]:hover:after {
  transform: scale(1.1);
}

@media (max-width: 768px) {
  .logo-provider-footer[data-provider-name="Pragmatic Play Live"]:after {
    content: "Live";
    display: block;
    position: absolute;
    top: 49px;
    right: 27px;
    font-weight: 500;
    color: white;
    font-size: 7px;
    font-family: "Poppins", sans-serif;
    text-transform: uppercase;
    transition: 0.3s all;
    padding: 1px;
  }
}
</style>

<!-- <div class="row my-5" id="company">
          <p class="payments-text ty_h4 mb-0" v-if="0">
            {{ translations?.footer?.JoinTheComunity ?? "Join the community" }}
          </p>
          <div class="socials justiy-content-center">
            <img
              src="/images/svg-sticky-nav/whatsapp.svg"
              alt="whatsapp"
              class="m-1"
              width="44"
              height="44"
              loading="lazy"
            />
            <img
              src="/images/svg-sticky-nav/facebook.svg"
              alt="facebook"
              class="m-1"
              width="44"
              height="44"
              loading="lazy"
            />
            <img
              src="/images/svg-sticky-nav/instagram.svg"
              alt="instagram"
              class="m-1"
              width="44"
              height="44"
              loading="lazy"
            />
            <img
              src="/images/svg-sticky-nav/linkedin.svg"
              alt="linkedin"
              class="m-1"
              width="44"
              height="44"
              loading="lazy"
            />
            <img
              src="/images/svg-sticky-nav/telegram.svg"
              alt="telegram"
              class="m-1"
              width="44"
              height="44"
              loading="lazy"
            />
            <img
              @click="sendMail"
              src="/images/svg-sticky-nav/email.png"
              alt="email"
              class="m-1"
              width="44"
              height="44"
              loading="lazy"
              style="
                object-fit: contain;
                background-color: black;
                padding: 9px;
                border-radius: 10px;
                cursor: pointer;
              "
            />
          </div>
        </div> -->
<!-- <div class="top w-100 flex-wrap flex-column">
          <p class="ty_bd mb-1" v-if="0">
            {{ translations?.footer?.DocumentsFooter ?? "Documents" }}
          </p>
          <ul class="navigation_links-footer justify-content-center">
            <li
              class="text-center"
              v-for="documentslink in documentsLinkFooter"
              :key="documentslink.label"
            >
              <router-link
                :to="{
                  name: 'static-page',
                  params: {
                    lang: `${lang}`,
                    page: documentslink.url,
                  },
                }"
                @click="
                  $router.push({
                    name: 'static-page',
                    params: {
                      lang: `${lang}`,
                      page: documentslink.url,
                    },
                  })
                "
              >
                {{
                  translations?.footer?.[
                    replaceSpacesWithUnderscore(documentslink.label)
                  ] ?? documentslink.label
                }}
              </router-link>
            </li>
          </ul>
        </div> -->
<!--SPIN&GRIN -->
<!-- <div class="company-information-footer">
        <div class="container">
          <div class="logo-footer" v-if="0">
            <img src="/images/logo.png" alt="" loading="eager" />
          </div>
          <div class="company-information">
            <p class="text-center company-legal_information ty_bd">
              {{
                translations?.footer?.descriptionFooter ??
                "No Purchase Necessary. Experience a completely free gaming experience. Play with both Gold Coins and Moon Coins immediately without any payment! You can claim your free Moon Coins via our Customer Benefits, Mail a Request, or receive a Free Moon Coins Bonus when you purchase any Gold Coins package."
              }}
            </p>
          </div>
          <div class="box-need-help">
            <p class="ty_bd">
              {{ translations?.footer?.support_email ?? "Support Email" }}:
              <a href="mailto:info@bethappy.com">info@bethappy.com</a>
            </p>

            <button class="need-help-button" @click="sendMail">
              {{ translations?.footer?.need_a_help ?? "Need a help?" }}
            </button>
          </div>
        </div>
      </div> -->
<!-- <div class="company-information my-3">
            <p class="text-center company-legal_information ty_bd">
              {{
                translations?.footer?.descriptionFooter ??
                "No Purchase Necessary. Experience a completely free gaming experience. Play with both Gold Coins and Moon Coins immediately without any payment! You can claim your free Moon Coins via our Customer Benefits, Mail a Request, or receive a Free Moon Coins Bonus when you purchase any Gold Coins package."
              }}
            </p>
          </div> -->
