<template>
  <div v-if="!isLoadingCardGamesBySections">
    <div class="container-wrapper">
      <div
        :class="{
          'bg-wrapper-first-template': isFirstTemplateVisible,
          'transparent-bg': !isFirstTemplateVisible,
        }"
      >
        <div
          :class="{
            'header-carousel-game': !isFirstTemplateVisible,
            'header-first-template': isFirstTemplateVisible,
          }"
          v-if="!isLastTemplateVisible"
        >
          <h4
            v-if="!isFirstTemplateVisible"
            class="game-section-text background-effect-section"
          >
            {{
              translations?.casino?.[replaceSpaces(section.button_text)] ??
              section.button_text
            }}
          </h4>
          <button
            class="show-all-games"
            @click="showGamesSectionSelected(section)"
          >
            {{ translations?.buttons?.ShowAll }}
          </button>
        </div>
        <div
          class="wrapper_game"
          :class="{ 'first-template': isFirstTemplateVisible }"
        >
          <carousel
            :items-to-show="carouselItemsToShow"
            :items-to-scroll="carouselItemsToShow"
            :transition="300"
            :pause-autoplay-on-hover="true"
            class="Carousel-HomePage"
            v-if="!isLastTemplateVisible"
          >
            <!-- Box "square Game" -->
            <slide
              v-if="isFirstTemplateVisible"
              class="slider-wrapper_game slider-game-box"
            >
              <div class="inner_game square-game-box">
                <div class="CategorySlug position-absolute w-100">
                  <h3 class="ty_h3 large-text-slug">
                    {{
                      translations?.casino?.[
                        replaceSpaces(section.button_text)
                      ] ?? section.button_text
                    }}
                  </h3>
                  <p class="ty_bd mb-1 counter-games-box">Top 10 Games</p>
                </div>
              </div>
            </slide>
            <!-- Slide -->
            <slide
              v-for="game in games"
              :key="game.id"
              class="slider-wrapper_game"
              @click="launchGame(game.game_id, game.provider_id)"
              :class="{ 'inset-padding': isFirstTemplateVisible }"
            >
              <!-- Slide Content -->
              <div class="inner_game">
                <img :src="game.image" :alt="game.name" />
              </div>
              <div class="game-information">
                <p class="game-name" style="margin-bottom: 0px">
                  {{ game.name }}
                </p>
                <img
                  class="right-arrow-launchGame"
                  src="/images/rightArrow.svg"
                  style="width: 20px; height: 20px; object-fit: contain"
                />
              </div>
            </slide>

            <!-- Addons (Pagination and Navigation) -->
            <template #addons>
              <pagination v-if="0" class="carousel__pagination" />
              <navigation />
            </template>
          </carousel>
          <!--Last template -->
          <div
            class="bg-wrapper-last-template"
            v-if="isLastTemplateVisible && allSkinSections.length > 1"
          >
            <!--Header last template -->
            <div class="header-last-template d-flex flex-column">
              <button
                class="show-all-games-last-template align-self-end"
                @click="showGamesSectionSelected(section)"
              >
                {{ translations?.buttons?.ShowAll }}
              </button>
              <h4 class="text-large-last-template ty_h1 text-center w-100">
                {{
                  translations?.casino?.[replaceSpaces(section.button_text)] ??
                  section.button_text
                }}
              </h4>
            </div>
            <!--Table Game for last template -->
            <div class="container-game-full-view">
              <div
                class="row"
                :style="{
                  'justify-content':
                    games.length % 5 === 0 ? 'space-around' : 'left',
                }"
                v-if="games.length > 0"
              >
                <div
                  v-for="game in games"
                  :key="game.id"
                  class="game-col"
                  @click="launchGame(game.game_id, game.provider_id)"
                >
                  <div class="card-game">
                    <img
                      class="game-thumb"
                      :src="game.image"
                      :alt="game.name"
                    />
                  </div>
                  <div class="game-information position-realtive">
                    <p class="game-name" style="margin-bottom: 0px">
                      {{ game.name }}
                    </p>
                    <img
                      class="right-arrow-launchGame"
                      src="/images/rightArrow.svg"
                      style="width: 20px; height: 20px; object-fit: contain"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Svg with latest win for type -->
      <div class="svg-item-container" v-if="isFirstTemplateVisible">
        <div class="win-information-container">
          <div class="last-big-win">
            <p class="ty_bs mb-1">
              {{ translations?.casino?.last_big_win ?? "Last Big Win" }}
            </p>
            <small class="fw-bold">$845,458</small>
          </div>
          <div class="last-lucky-win">
            <p class="ty_bs mb-1">
              {{ translations?.casino?.last_lucky_win ?? "Last Lucky Win" }}
            </p>
            <small class="fw-bold">$845,458</small>
          </div>
          <div class="last-diamond-win">
            <p class="ty_bs mb-1">
              {{ translations?.casino?.last_diamond_win ?? "Last Diamond Win" }}
            </p>
            <small class="fw-bold">$845,458</small>
          </div>
        </div>
        <svg
          v-if="isFirstTemplateVisible"
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="133"
          viewBox="0 0 1331 113"
          fill="none"
          class="svg-item"
        >
          <path
            d="M0 0H1331L1000.19 108.983C992.116 111.644 983.666 113 975.162 113H373.228C365.123 113 357.065 111.768 349.33 109.347L0 0Z"
            fill="#121212"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import { mapGetters } from "vuex";

export default {
  name: "CategoryGames",
  components: { Carousel, Slide, Pagination, Navigation },
  props: {
    section: {
      type: Array,
    },
    isFirstTemplateVisible: {
      type: Boolean,
      default: false,
    },
    isLastTemplateVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      carouselItemsToShow: "",
      games: [],
      isMobile: window.innerWidth <= 600,
      isLogged: localStorage.getItem("X19fYWNjZXNzX19f") ?? false,
      selectedSection: null,
      gameIndex: 0,
    };
  },
  computed: {
    ...mapGetters("casinoGames", [
      "isLoadingCardGames",
      "gamesInThisSection",
      "allSkinSections",
      "currentPage",
      "isFullViewActive",
      "isLoadingCardGamesBySections",
    ]),
    ...mapGetters("translations", ["translations", "isTranslationLoading"]),
  },
  watch: {
    gamesInThisSection(value) {
      this.games = value[this.section.id] ?? [];
    },
  },
  methods: {
    replaceSpaces(text) {
      return text.replace(/\s/g, "").toLowerCase();
    },
    handleWindowResize() {
      if (window.innerWidth >= 992) {
        if (this.isFirstTemplateVisible) {
          return (this.carouselItemsToShow = 4.5);
        } else {
          return (this.carouselItemsToShow = 5);
        }
        // Desktop
      } else if (window.innerWidth >= 600) {
        return (this.carouselItemsToShow = 3); // Tablet
      } else {
        return (this.carouselItemsToShow = 1.5); // Smartphone
      }
    },
    handleResize() {
      this.isMobile = window.innerWidth <= 600;
    },
    play(id, providerId) {
      this.$router.push(`/game/play/${id}/${providerId}`);
    },
    launchGame(id, providerId) {
      if (this.isMobile) {
        const origin = window.location.origin;
        window.open(`${origin}/game/play/${id}/${providerId}`, "_blank");
      } else {
        this.play(id, providerId);
      }
    },
    async showGamesSectionSelected(section) {
      // await this.scrollToTop();
      this.$store.commit("casinoGames/setIsFullViewActive", true);
      localStorage.setItem("fullGameSectionID", section.id);
      await this.$store
        .dispatch("casinoGames/callFullGameBySections", {
          section: localStorage.getItem("fullGameSectionID"),
          currentPage: this.currentPage,
          defaultValue: false,
        })
        .then(() => {});
    },
    // scrollToTop() {
    //   return new Promise((resolve) => {
    //     window.scrollTo({
    //       top: 0,
    //       left: 0,
    //       behavior: "smooth",
    //     });
    //     setTimeout(() => {
    //       resolve();
    //     }, 1000);
    //   });
    // },
  },
  created() {
    this.$store.dispatch("casinoGames/callGamesBySections", this.section);
    this.handleWindowResize();
  },
  mounted() {
    window.addEventListener("resize", this.handleWindowResize);
    window.addEventListener("resize", this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleWindowResize);
  },
};
</script>

<style scoped lang="scss">
.first-template {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  height: 100%;
  position: relative;
  align-items: center;
}

.bg-wrapper-last-template {
  padding: 10px;
  border-radius: 40px;
  background: linear-gradient(180deg, #203D5D 0%, rgba(63, 63, 63, 0) 100%);
}

.last-template {
  display: flex;
  width: 100%;
  height: auto;
  justify-content: space-around;
  height: 100%;
  position: relative;
  align-items: center;
  flex-wrap: wrap;

  @media (min-width: 1201px) {
    .all-card {
      flex-basis: 18%;
    }
  }

  @media (min-width: 992px) and (max-width: 1200px) {
    .all-card {
      flex-basis: 22%;
    }
  }

  @media (min-width: 601px) and (max-width: 991px) {
    .all-card {
      flex-basis: 30%;
    }
  }

  @media (max-width: 600px) {
    .all-card {
      flex-basis: 46%;
    }
  }
  .all-card {
    transition: 0.3s all;
    &:hover {
      transform: scale(0.99);
      filter: drop-shadow(-1px -1px 2px rgba(255, 255, 255, 0.681));
    }
  }

  .inner_game {
    width: 100%;
    height: 100%;
    position: relative;

    img {
      position: relative;
      top: 0;
      left: 0;
      width: 100%;
      min-height: 100%;
      max-width: 320px;
      aspect-ratio: 4 / 3;
      border-radius: 24px;
      -o-object-fit: cover;
      object-fit: cover;
      -o-object-position: top;
      object-position: top;
      transition: 0.3s all;
      cursor: pointer;
    }
  }
  .game-information {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    width: 100%;
    height: 50px;
    position: relative;
    left: 0px;
    transition: 0.3s all;
    z-index: 3;
    background: $button-grey-color;
    border-radius: 24px;
    padding: 0px 10px;
    margin-top: 10px;

    .game-provider,
    .game-name {
      color: linen;
      font-weight: 700;
      text-align: left;
      font-size: 14px;
      width: 80%;
      margin: 0;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      -webkit-line-clamp: 1;
      filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5));
    }
  }
}

.header-first-template {
  justify-content: flex-end !important;
  align-items: center;
  display: flex;

  .show-all-games {
    min-width: 80px;
    border-radius: 10px;
    color: $primary-color-1;
    font-size: clamp(10px, 4vw, 14px);
    font-weight: 600;
    transition: 0.3s all;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 0 !important;
    background-color: transparent !important;
  }
}

.header-last-template {
  .text-large-last-template {
    color: white;
    margin: 40px 0px;
    font-size: clamp(14px, 8vw, 40px);
  }

  .show-all-games-last-template {
    min-width: 80px;
    border-radius: 10px;
    color: $primary-color-1;
    font-size: clamp(10px, 4vw, 14px);
    font-weight: 600;
    transition: 0.3s all;
    position: relative;
    top: 20px;
    right: 15px;
    padding: 0 !important;
    background-color: transparent !important;
  }
}
.show-all-games {
  min-width: 80px;
  border-radius: 10px;
  color: $primary-color-1;
  font-size: clamp(10px, 4vw, 14px);
  font-weight: 600;
  transition: 0.3s all;
  padding: 5px 5px;
  background-color: $button-grey-color;

  &:hover {
    background-color: $button-grey-color;
    color: $primary-color-1;
    transition: 0.3s all;
  }
}

@media (max-width: 600px) {
  .slider-game-box {
    max-width: 260px !important;
    height: 100% !important;
  }
}

.square-game-box {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #203D5D;
  border-radius: 24px;
  height: 95%;
}

.CategorySlug {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: white;
}

.large-text-slug {
  font-size: clamp(14px, 8vw, 40px);
  margin: 5px 0px;
}

.inset-padding {
  padding: 25px 0px;
}

.bg-wrapper-first-template {
  background: #232323;
  padding: 20px 5px;
  position: relative;
  border-radius: 16px;
}

.transparent-bg {
  background: transparent;
}

.svg-item-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  position: relative;
}

.svg-item {
  max-width: 100%;
  height: auto;
  padding: 0px 10px;
  position: relative;
}

.win-information-container {
  display: flex;
  justify-content: center;
  max-width: 100%;
  align-items: center;
  text-align: center;
  position: absolute;
  z-index: 30;
}

.last-big-win,
.last-lucky-win,
.last-diamond-win {
  margin: 0px 10px;
  color: white;
}

.counter-games-box {
  color: lightgray;
}

@media (max-width: 767px) {
  .svg-item-container {
    display: none;
  }
}
</style>

<style>
.carousel {
  width: 100%;
}

.carousel__viewport {
  overflow: hidden;
  display: flex;
}
</style>
<!--// showGamesSectionSelected(section) { //
this.$store.commit("casinoGames/setIsFullViewActive", true); //
localStorage.setItem("fullGameSectionID", section.id); //
this.$store.dispatch("casinoGames/callFullGameBySections", { // section:
localStorage.getItem("fullGameSectionID"), // currentPage: this.currentPage, //
}); // }, // scrollToTop() { // window.scrollTo({ // top: 0, // behavior:
"smooth", // }); // },-->
