import router from "@/router";
import Swal from "sweetalert2";
export default {
  namespaced: true,
  state() {
    return {
      path: {
        casino: "casino/play/",
      },
      default_demo_currency: "EUR",
      currentGameName: null,
      currentGameId: null,
      currentProviderId: null,
      currentGamePath: null,
      isGameLoading: true,
      isForReal: true,
      demoNotAvailable: false,
      demoNotAvailableMessage: "",
      isBalanceInsufficient: false,
      // isKYCError: false,
      isSelfExclusionError: false,
    };
  },
  mutations: {
    launchGame(state, payload) {
      state.currentGameName = payload.name;
      state.currentGameId = payload.game_id;
      state.currentProviderId = payload.provider_id;
      state.isGameLoading = false;

      // IF PLAY FOR FUN && PLAY FOR REAL IS NULL
      if (payload.responseData == null) {
        state.demoNotAvailable = true;
        state.demoNotAvailableMessage = "GAME NOT AVAILABLE";
        return;
      }

      // IF BALANCE IS INSUFFICIENT
      if (
        Object.prototype.hasOwnProperty.call(payload.responseData, "response")
      ) {
        state.demoNotAvailable = true;
        state.isBalanceInsufficient = true;
        state.demoNotAvailableMessage = "You must recharge your account";
        // alert('entro qui')
        return;
      }

      // IF PLAY FOR FUN IS NOT AVAILABLE
      if (payload.responseData.includes("is not available")) {
        state.demoNotAvailable = true;
        state.demoNotAvailableMessage = payload.responseData;
        return;
      }

      state.currentGamePath = payload.responseData;
    },
  },

  actions: {
    play({ state, getters, dispatch }, payload) {
      let modeSelectedToPlay = localStorage.getItem("game-mode");

      state.currentGameName = null;
      state.currentGameId = null;
      state.currentProviderId = null;
      state.currentGamePath = null;
      state.demoNotAvailable = false;
      state.demoNotAvailableMessage = "";
      state.isGameLoading = true;
      if (getters.isAuthenticated && modeSelectedToPlay == "real") {
        state.isForReal = true;
        dispatch("play_real", payload);
      } else if (getters.isAuthenticated && modeSelectedToPlay == "forfun") {
        state.isForReal = false;
        dispatch("play_demo", payload);
      } else if (getters.isAuthenticated) {
        state.isForReal = true;
        dispatch("play_real", payload);
      } else {
        state.isForReal = false;
        dispatch("play_demo", payload);
      }
    },

    async play_real(
      { state, getters, commit },
      { provider_slug, provider_id, game_id, name }
    ) {

      // CHECKING SELF EXCLUSION
      const playerExclusionNull = getters.playerInfo?.status_expires_at;
      const today = new Date().toISOString().slice(0, 10);
      if (playerExclusionNull != null) {
        const playerExclusion = getters.playerInfo.status_expires_at.slice(
          0,
          10
        );
        if (playerExclusion > today) {
          state.isSelfExclusionError = true;
          Swal.fire({
            title:
              getters.translations?.body?.Error_account_disabled ??
              "Your player account is disabled",
            text:
              getters.translations?.body?.Error_account_disabled_until ??
              "Your account will remain disabled until " + playerExclusion,
            icon: "warning",
            confirmButtonColor: "#149102",
            confirmButtonText: "OK",
            allowOutsideClic: false,
          }).then(() => {
            router.replace("/");
          });
        }
      }
      state.currentGameId = game_id;
      state.currentProviderId = provider_id;

      let body = {
        game_id: game_id,
        provider_name: provider_slug,
        provider_id: provider_id,
        is_mobile: getters.isMobileStatus,
        is_desktop: getters.isDesktopStatus,
        lobby_url: "https://www.bethappy.com/",
        skin_id: getters.refId,
        ip_address: getters.clientIp ?? "0.0.0.0",
        user_rank: 0,
        bonus_type: 0,
        bonus_info: 0,
        bonus_target: 0,
        bonus_counter: 0,
        user_id: getters.playerInfo.id,
        // user_language: getters.language_selected,
        user_language: "en",
        user_currency: getters.playerInfo?.currency?.code,
      };
      const responseData = await getters.axios
        .post(
          getters.endpoint + state.path.casino + provider_slug,
          body,
          getters.auth
        )
        .then((response) => response.data.data.play_for_real)
        .catch((err) => err);
      commit("launchGame", { responseData, name });
    },
    async play_demo(
      { state, getters, commit },
      { provider_slug, provider_id, game_id, name }
    ) {
      let body = {
        is_demo: "true",
        game_id: game_id,
        provider_id: provider_id,
        provider_name: provider_slug,
        lobby_url: "https://www.bethappy.com/",
        // user_language: getters.language_selected,
        user_language: "en",
        user_currency: state.default_demo_currency,
      };

      const responseData = await getters.axios
        .post(
          getters.endpoint + state.path.casino + provider_slug,
          body,
          getters.auth
        )
        .then((response) => response.data.data.play_for_fun)
        .catch((err) => err);
      commit("launchGame", { responseData, name });
    },
    async realityCheck({ state, getters }) {
      let body = {
        // language: getters.language_selected,
        language: "en",
      };
      let url = getters.endpoint + state.path.realityCheck;
      const responseData = await getters.axios
        .post(url, body, getters.tokenAuth)
        .then((response) => response)
        .catch((err) => err);
      console.log(responseData);
    },
    stopGame({ state }) {
      state.currentGameName = null;
      state.currentGamePath = null;
    },
  },
  getters: {
    endpoint(_, _1, _2, rootGetters) {
      return rootGetters["api/endpoint"];
    },
    axios(_, _1, _2, rootGetters) {
      return rootGetters["api/axios"];
    },
    clientIp(_, _1, _2, rootGetters) {
      return rootGetters["api/clientIp"];
    },
    auth(_, _1, _2, rootGetters) {
      return rootGetters["api/auth"];
    },
    playerInfo(_, _1, _2, rootGetters) {
      return rootGetters["player/playerInfo"];
    },
    isAuthenticated(_, _1, _2, rootGetters) {
      return rootGetters["player/isAuthenticated"];
    },
    translations(_, _1, _2, rootGetters) {
      return rootGetters["translations/translations"];
    },
    basicKYCLevel(_, _1, _2, rootGetters) {
      return rootGetters["player/basicKYCLevel"];
    },
    selfieKYCLevel(_, _1, _2, rootGetters) {
      return rootGetters["player/selfieKYCLevel"];
    },
    tokenAuth(_, _1, _2, rootGetters) {
      return rootGetters["player/tokenAuth"];
    },
    isMobileStatus(_, _1, _2, rootGetters) {
      return rootGetters["casinoGames/isMobileStatus"];
    },
    isDesktopStatus(_, _1, _2, rootGetters) {
      return rootGetters["casinoGames/isDesktopStatus"];
    },
    refId(_, _1, _2, rootGetters) {
      return rootGetters["skinSettings/refId"];
    },
    currentGameName(state) {
      return state.currentGameName;
    },
    currentGameId(state) {
      return state.currentGameId;
    },
    currentProviderId(state) {
      return state.currentProviderId;
    },
    currentGamePath(state) {
      return state.currentGamePath;
    },
    isGameLoading(state) {
      return state.isGameLoading;
    },
    isForReal(state) {
      return state.isForReal;
    },
    demoNotAvailable(state) {
      return state.demoNotAvailable;
    },
    demoNotAvailableMessage(state) {
      return state.demoNotAvailableMessage;
    },
    isBalanceInsufficient(state) {
      return state.isBalanceInsufficient;
    },
    isKYCError(state) {
      return state.isKYCError;
    },
    isSelfExclusionError(state) {
      return state.isSelfExclusionError;
    },
  },
};
