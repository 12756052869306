<template>
  <div>
    <PreLoader v-if="isCheckingTransaction" />
    <section v-if="!isCheckingTransaction" class="transaction-page-pt">
      <div
        v-if="!!transactionInfo"
        class="container alert mt-5"
        :class="type"
        role="alert"
      >
        <div class="alert-content">
          <h4 class="alert-heading ty_h4 color-primary-bd">
            Transaction {{ transactionInfo.status }}!
          </h4>
          <p class="ty_bd color-primary-bd">
            Transaction <strong>{{ transactionInfo.id }}</strong> has been
            {{ transactionInfo.status }}
          </p>
          <hr />
          <p class="ty_bd color-primary-bd">
            {{ translations.reports.Check_your_email }}
          </p>
        </div>
      </div>
      <div v-else class="container mt-5">
        <div class="transaction-not-found">
          <h2 class="text-danger text-center">
            {{
              translations.reports.transaction_not_found ??
              "Transaction not found"
            }}
          </h2>
          <button class="comeBackHome-btn-transaction" @click="comeBackTo('/')">
            Come back to Home
          </button>
        </div>
      </div>

      <div v-if="!!transactionInfo" class="row">
        <button class="comeBackHome-btn-transaction" @click="comeBackTo('/account-balance?tab=deposit')">Go back to deposit page</button>
      </div>
    </section>
  </div>
</template>

<script>
import PreLoader from "@/components/global/PreLoader.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    PreLoader,
  },
  computed: {
    ...mapGetters("reports", ["transactionInfo", "isCheckingTransaction"]),
    playerInfo() {
      return this.$store.getters["player/playerInfo"];
    },
    translations() {
      return this.$store.getters["translations/translations"];
    },
    type() {
      const typeClass = {
        "alert-success": this.transactionInfo.status == "authorized",
        "alert-danger": this.transactionInfo.status == "declined",
        "alert-primary": this.transactionInfo.status == "initialized",
        "alert-warning": this.transactionInfo.status == "pending",
      };
      return typeClass;
    },
  },
  methods: {
    checkTransactionId() {
      const id = this.$route.params.transactionId;
      this.$store.dispatch("reports/getTransactionStatus", id);
    },
    comeBackTo(path) {
      let uri = path.startsWith('/') ? path : `/${path}`;
      window.location.href = `${window.location.origin}/${localStorage.getItem('language') + uri}`;
    },
  },
  created() {
    this.checkTransactionId();
  },
};
</script>

<style scoped>
section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 80vh;
}

.transaction-page-pt {
  padding-top: 120px;
}

.container {
  max-width: 900px;
}

.alert-content {
  text-align: center;
}

.transaction-not-found {
  text-align: center;
  margin-top: 5rem;
}

.comeBackHome-btn-transaction {
  margin-top: 1.5rem;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.color-primary-bd {
  color: white;
}
</style>
