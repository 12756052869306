<template>
  <div>
    <DashBoard />
    <div class="container">
      <div class="row mx-auto justify-content-center">
        <div class="col-12 col-lg-10">
          <div class="dashboard-all">
            <h2 class="dashboard-title">Reports test</h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DashBoard from "./DashBoard.vue";
export default {
  name: "ReportsPage",
  components: {
    DashBoard,
  },
  data() {
    return {
      checkDeviceType: "",
    };
  },
  methods: {},
  created() {},
  mounted() {},
};
</script>

<style></style>
