<template>
  <div class="right-menu-login" :class="{ active: isLoginMenuOpen }">
    <div class="cross-container">
      <div class="close-left-menu" @click="toggleMenuLogin">
        <img src="/images/left-menu_img/close.svg" alt="" />
      </div>
    </div>
    <div class="login-form">
      <div class="top-part">
        <h6>{{ translations?.login?.LoginToPlay }}</h6>
        <form class="login-fill">
          <div class="input">
            <i aria-hidden="true" class="fa fa-user"></i>
            <input
              class="w-100"
              type="text"
              name=""
              :placeholder="translations?.registration?.UsernamePlaceHolder"
              v-model="nicknameLogin"
              @keydown.enter.prevent="loginPlayer"
            />
          </div>
          <div class="input">
            <i aria-hidden="true" class="fa fa-unlock-alt"></i>
            <input
              class="w-100"
              type="password"
              name=""
              :placeholder="translations?.registration?.PasswordPlaceHolder"
              v-model="passwordLogin"
              @keydown.enter.prevent="loginPlayer"
            />
          </div>
          <div class="custom-checkbox">
            <input
              id="rem-usr-checkbox"
              :checked="rememberUserName !== ''"
              type="checkbox"
              class="custom-control-input customControlInline"
            />
            <label for="customControlInline" class="custom-control-label">
              {{ translations?.buttons?.RememberUsername }}</label
            >
          </div>
          <div class="hs-button">
            <input
              type="button"
              id="loginBTNslide"
              name="Login"
              :value="translations?.buttons?.Login"
              @click="loginPlayer"
            />
            <a href="#" class="fp" @click.prevent="forgottenPasswordRecovery">
              {{ translations?.buttons?.ForgottenPassword }}</a
            >
          </div>
        </form>
      </div>
      <div class="bottom-part">
        <a href="#" class="new-player"> {{ translations?.buttons?.NewPlayer }}</a>
        <a href="#" class="signUp" @click="toggleModalRegistration">
          {{ translations?.buttons?.SignUp }}</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
export default {
  name: "Login_Menu",
  components: {},
  data() {
    return {
      nicknameLogin: "",
      passwordLogin: "",
      rememberUserName: sessionStorage.getItem("sav-usr") ?? "",
      intervalId: null,
      language_selected: localStorage.getItem("language"),
    };
  },
  computed: {
    ...mapGetters("openMenuLogin", ["isLoginMenuOpen", "isModalOpen"]),
    ...mapGetters("translations", ["translations", "isTranslationLoading"]),
  },
  mounted() {
    this.checkSavedPathrestricted();
  },
  async created() {
    await this.checkSavedUsrSession();
  },
  beforeUnmount() {
    clearInterval(this.intervalId);
  },
  watch: {
    isLoginMenuOpen(newVal) {
      if (newVal) {
        this.checkPreffiledCredential();
        for (let i = 0; i < 3; i++) {
          this.checkPreffiledCredential();
        }
      }
    },
  },
  methods: {
    checkSavedUsrSession() {
      if (this.rememberUserName !== "") {
        let parsedUsr = JSON.parse(this.rememberUserName);
        const savedTimestamp = Date.parse(parsedUsr.timestamp);
        const currentTimestamp = new Date().getTime();
        const thirtyDaysInMilliseconds = 30 * 24 * 60 * 60 * 1000;

        if (currentTimestamp - savedTimestamp > thirtyDaysInMilliseconds) {
          sessionStorage.removeItem("sav-usr");
        }
      }
    },
    checkSavedPathrestricted() {
      let isSavedPathOnStore = sessionStorage.getItem("savedPath");
      if (isSavedPathOnStore) {
        this.toggleMenuLogin();
      }
    },
    checkPreffiledCredential() {
      if (this.rememberUserName !== "") {
        let parsedUsr = JSON.parse(this.rememberUserName);
        this.nicknameLogin = parsedUsr.nickname;
      } else if (
        localStorage.getItem("prefilled-usr") &&
        localStorage.getItem("prefilled-psw")
      ) {
        this.nicknameLogin = localStorage.getItem("prefilled-usr");
        this.passwordLogin = localStorage.getItem("prefilled-psw");
      }
    },
    handleDepositClick() {
      const url = `/${this.language_selected}/account-balance?&tab=deposit`;
      window.location.href = url;
    },
    toggleMenuLogin() {
      this.$store.dispatch(
        "openMenuLogin/setStateLoginMenu",
        !this.$store.getters["openMenuLogin/isLoginMenuOpen"]
      );
      if (
        window.scrollY === 0 &&
        !this.$store.getters["openMenuLogin/isLoginMenuOpen"] &&
        this.$route.name === "HomePage"
      ) {
        this.setTransparentBackground();
      }
    },
    toggleModalRegistration() {
      this.$store
        .dispatch(
          "openMenuLogin/setStateLoginMenu",
          !this.$store.getters["openMenuLogin/isLoginMenuOpen"]
        )
        .then(() => {
          this.$store.dispatch(
            "openMenuLogin/setStateModalRegistration",
            !this.$store.getters["openMenuLogin/isModalOpen"]
          );
          if (this.isModalOpen && window.innerWidth >= 768) {
            // Se la modale è appena stata aperta, disabilita lo scroll
            document.documentElement.style.overflow = "hidden";
            document.documentElement.style.paddingRight = "11px";
          } else {
            // Se la modale è stata chiusa, riabilita lo scroll
            document.documentElement.style.overflow = "";
            document.documentElement.style.paddingRight = "0px";
          }
        });
    },
    //---LOGIN PLAYER METHODS---//
    loginPlayer() {
      this.$store
        .dispatch("player/login", {
          username: this.nicknameLogin,
          password: this.passwordLogin,
        })
        .then(() => {
          const ErrorLogin = this.$store.getters["player/errorLogin"];
          if (ErrorLogin) {
            Swal.fire({
              icon: "error",
              text:
                this.translations?.body?.Error_credential_does_not_match_any_account ??
                "These credentials don't match any account!",
            });
          } else {
            localStorage.removeItem("prefilled-usr");
            localStorage.removeItem("prefilled-psw");

            const rememberUsernameCheckbox = document.getElementById("rem-usr-checkbox");
            if (rememberUsernameCheckbox.checked) {
              const currentDate = new Date().toISOString();
              const userData = {
                nickname: this.nicknameLogin,
                timestamp: currentDate,
              };
              sessionStorage.setItem("sav-usr", JSON.stringify(userData));
            }
            const savedPath = sessionStorage.getItem("savedPath");

            if (savedPath) {
              const pathSegments = savedPath.split("/");
              // divido in lang e page
              const page = pathSegments[2];
              window.location.href = page;
              sessionStorage.removeItem("savedPath");
            } else {
              this.handleDepositClick();
            }
          }
        })
        .catch(() => {});
    },
    /*FORGOTTEN PASSWORD RECOVER */
    forgottenPasswordRecovery() {
      this.$router.push({
        name: "recover-password",
        params: { lang: this.lang },
      });
      this.toggleMenuLogin();
    },
  },
};
</script>
