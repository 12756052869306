<template>
  <PreLoader v-if="isLoading" />
  <div id="notfound">
    <div class="notfound">
      <div class="notfound-404 m-3 text-center">
        <h1 class="ty_h1" style="font-size: clamp(16px, 100vw, 100px)">404</h1>
      </div>
      <div class="m-3" style="margin-top: 25px !important">
        <h2 class="ty_h3 text-center">
          {{
            translations?.body?.we_are_sorry_no_page_404 ??
            " We are sorry, but the page you requested was not found"
          }}
        </h2>
      </div>
      <div class="w-100 d-flex justify-content-center align-items-center pt-3">
        <button @click="goBackHome" class="home-btn ty_bd text-center">
          {{ translations?.body?.go_home_from_404 ?? " Go Home" }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import PreLoader from "@/components/global/PreLoader.vue";
import { mapGetters } from "vuex";
export default {
  name: "Page404Error",
  components: {
    PreLoader,
  },
  data() {
    return {
      isLoading: true,
    };
  },
  methods: {
    goBackHome() {
      window.location.href = "/";
    },
    setTimerLoader() {
      setTimeout(() => {
        this.isLoading = false;
      }, 2000);
    },
  },
  created() {
    this.setTimerLoader();
  },
  computed: {
    ...mapGetters("translations", ["translations", "isTranslationLoading"]),
  },
};
</script>

<style>
#notfound {
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.home-btn {
  border-radius: 24px;
  padding: 10px 16px;
  background-color: white;
  color: black;
  text-decoration: none;
  transition: 0.3s all;
}

.home-btn :hover {
  background-color: rgb(164, 158, 158);
  cursor: pointer;
}
</style>
