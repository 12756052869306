<template>
  <div class="container" style="padding-top: 120px">
    <div
      class="verify-mail-section d-flex justify-content-center"
      style="min-height: 90vh"
    >
      <h3
        class="ty_h3"
        :class="{ verifying: isVerifying }"
        style="color: white"
      >
        <span>{{ verificationMessage }} </span>
      </h3>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Swal from "sweetalert2";

export default {
  name: "verifyEmail",
  data() {
    return {
      isVerifying: true,
      verificationMessage: "",
      lang: localStorage.getItem("language") ?? "en",
    };
  },
  computed: {
    ...mapGetters("player", [
      "isPlayerLoading",
      "playerInfo",
      "tokenChekResponse",
    ]),
    ...mapGetters("translations", ["isTranslationLoading", "translations"]),
  },
  created() {
    this.verificationMessage =
      this.translations?.body?.verification_mail_in_progress ??
      "Verification in progress...";

    this.$router.push({ params: { lang: this.lang } });
  },
  async mounted() {
    const token = this.$route.params.token;

    if (token) {
      await this.$store
        .dispatch("player/checkTokenToValidateEMail", token)
        .then(() => {
          const response = this.tokenChekResponse;

          if (
            response &&
            response.status === "success" &&
            response.is_valid === true &&
            response.message === "token is valid"
          ) {
            let body = {
              email_verified_at: response?.created_at,
              status: "active",
            };
            let playerId = response?.tokenable_id;
            this.$store
              .dispatch("registration/updatePlayer", {
                body,
                playerId,
              })
              .then(() => {
                this.isVerifying = false;
                this.verificationMessage =
                  this.translations?.body?.verification_complete ??
                  "Verification completed";
                Swal.fire({
                  icon: "success",
                  title:
                    this.translations?.body?.verification_mail_success ??
                    "Email verified successfully!",
                }).then(() => {
                  window.location.href = "/";
                });
              });
          } else {
            this.isVerifying = false;
            this.verificationMessage =
              this.translations?.body?.error_during_verification ??
              "Error during the verification phase.";
            Swal.fire({
              icon: "error",
              title:
                this.translations?.body
                  ?.error_something_went_wrong_during_verification ??
                "Something went wrong!",
              text:
                this.translations?.body?.error_check_if_token_is_still_valid ??
                "Check if your link is still valid.",
            }).then(() => {
              window.location.href = "/";
            });
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      this.verificationMessage =
        this.translations?.body?.error_invalid_url_to_verify ??
        "Invalid link to verify Email";
    }
  },
  methods: {},
};
</script>

<style>
.verifying .dots {
  animation: verifyingAnimation 1s infinite alternate;
}

@keyframes verifyingAnimation {
  to {
    transform: scale(1.1);
  }
}
</style>
