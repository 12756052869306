<template>
  <div>
    <transition name="route" mode="out-in">
      <div v-if="isCheckingToken || isTranslationLoading">
        <PreLoader />
      </div>
    </transition>
    <section
      class="reset-psw-section"
      style="padding-top: 120px; min-height: 100vh"
    >
      <div class="container pt-5">
        <h3 class="ty_h3 text-center" style="color: white">
          {{
            translations?.body?.Welcome_To_Reset_Page ??
            "Welcome to reset Password page"
          }}
        </h3>
        <div v-if="!isCheckingToken">
          <div v-if="isTempTokenValid">
            <div class="container">
              <div class="d-flex justify-content-center">
                <form class="w-100" @submit.prevent>
                  <div
                    class="form-group mt-4 mb-5 d-flex flex-column flex-md-row align-items-md-start"
                  >
                    <div class="col-md-6 pr-md-3 mb-2">
                      <label
                        class="form-label field-name ty_bs"
                        for="new_password"
                      >
                        {{
                          translations?.body?.New_Password_Reset ??
                          " New Password"
                        }}:
                      </label>
                      <input
                        id="new_password"
                        type="password"
                        class="form-control"
                        v-model.trim="new_pwd"
                        @keyup="enablingPasswordButton"
                      />
                      <transition name="error-message">
                        <p
                          v-if="new_pwd_error"
                          class="warning field-name pt-2 pb-2 ty_bs"
                        >
                          {{ new_pwd_error_message }}
                        </p>
                      </transition>
                    </div>
                    <div class="col-md-6 pl-md-3 mb-2">
                      <label
                        class="form-label field-name ty_bs"
                        for="confirm_password"
                      >
                        {{
                          translations?.body?.Confirm_password ??
                          "Confirm password"
                        }}:
                      </label>
                      <input
                        id="confirm_password"
                        type="password"
                        class="form-control"
                        v-model.trim="confirm_pwd"
                        @keyup="enablingPasswordButton"
                      />
                      <transition name="error-message">
                        <p v-if="confirm_pwd_error" class="warning ty_bs">
                          {{ confirm_pwd_error_message }}
                        </p>
                      </transition>
                    </div>
                  </div>
                  <div
                    class="col-12 d-flex justify-content-center align-items-center mt-3 pt-3"
                  >
                    <label class="form-label">&nbsp;</label>
                    <button
                      class="button-go-home-from-resetPage ty_bs"
                      @click="changePlayerPassword"
                    >
                      {{
                        translations?.body?.Change_password ?? "Change Password"
                      }}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="container mt-5">
              <div class="d-flex justify-content-center">
                <div class="col-12 col-md-6">
                  <div class="text-error text-center ty_bs">
                    {{
                      translations?.body?.Link_expired ??
                      "Your link has expired, please make again the process to reset the password!"
                    }}
                  </div>
                  <div
                    class="btn-container-go-Home d-flex justify-content-center align-items-center pt-5"
                  >
                    <button
                      class="button-go-home-from-resetPage ty_bs"
                      @click="$router.replace('/')"
                    >
                      {{ translations?.body?.Go_home_btn ?? "Go Home" }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PreLoader from "@/components/global/PreLoader.vue";
export default {
  components: {
    PreLoader,
  },
  data() {
    return {
      email: "",
      new_pwd: null,
      new_pwd_invalid: false,
      new_pwd_error: false,
      new_pwd_error_message: "",
      confirm_pwd: null,
      confirm_pwd_invalid: false,
      confirm_pwd_error: false,
      confirm_pwd_error_message: "The password doesn't match",
      passwordButtonDisabled: true,
      lang: localStorage.getItem("language"),
    };
  },
  computed: {
    isTempTokenValid() {
      return this.$store.getters["player/isTempTokenValid"];
    },
    isCheckingToken() {
      return this.$store.getters["player/isCheckingToken"];
    },
    translations() {
      return this.$store.getters["translations/translations"];
    },
    ...mapGetters("translations", ["isTranslationLoading", "translations"]),
  },
  watch: {
    confirm_pwd(value) {
      this.confirm_pwd_error = false;
      this.confirm_pwd_invalid = true;
      if (value.length < 5) {
        return false;
      } else {
        if (this.new_pwd != value) {
          this.confirm_pwd_error = true;
        } else {
          this.confirm_pwd_error = false;
          this.confirm_pwd_invalid = false;
        }
      }
    },
    new_pwd(value) {
      this.new_pwd_invalid = true;
      this.new_pwd_error = false;
      this.new_pwd_error_message = "";
      if (value.length == 0) {
        return false;
      } else if (value.length != 0) {
        if (this.validationPassword(value)) {
          this.new_pwd_invalid = false;
          this.new_pwd_error = false;
        } else {
          this.new_pwd_invalid = true;
          this.new_pwd_error = true;
          this.new_pwd_error_message =
            this.translations?.body?.password_error_during_reset ??
            "Your password should include a minimum of one uppercase letter, one lowercase letter, one number, and one special character.";
        }
      }
    },
    email(value) {
      function validationEmail(el) {
        let result = String(el)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
        return result;
      }
      if (validationEmail(value)) {
        this.isEmailInvalid = false;
      } else {
        this.isEmailInvalid = true;
      }
    },
  },
  methods: {
    getTokenFromUrl() {
      const token = this.$route.params.temp_token;
      this.$store.dispatch("player/checkTempToken", token);
    },
    enablingPasswordButton() {
      if (
        this.new_pwd === this.confirm_pwd &&
        !this.new_pwd_invalid &&
        !this.confirm_pwd_invalid &&
        !this.isEmailInvalid
      ) {
        this.passwordButtonDisabled = false;
      } else {
        this.passwordButtonDisabled = true;
      }
    },
    changePlayerPassword() {
      if (!this.passwordButtonDisabled) {
        this.$store.dispatch("player/changeForgotPassword", {
          email: this.email,
          new: this.new_pwd,
          confirm: this.confirm_pwd,
        });
      }
    },
    validationPassword(value) {
      let result = String(value).match(
        /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/gm
      );
      return result;
    },
  },
  created() {
    this.getTokenFromUrl();
    this.lang = localStorage.getItem("language") ?? "en";
  },
};
</script>

<style scoped>
p.warning {
  margin-top: 0;
}
</style>
