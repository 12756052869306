// Menu JS
export default {
    namespaced: true,
    state() {
        return {
            isLoginMenuOpen: false,
            isModalOpen: false,
        };
    },
    getters: {
        isLoginMenuOpen(state) {
            return state.isLoginMenuOpen;
        },
        isModalOpen(state) {
            return state.isModalOpen;
        }
    },
    mutations: {
        setLoginMenu(state, value) {
            state.isLoginMenuOpen = value;
        },
        setModalRegistration(state, value) {
            state.isModalOpen = value;
        }
    },
    actions: {
        setStateLoginMenu({ commit }, value) {
            commit("setLoginMenu", value);
        },
        setStateModalRegistration({ commit }, value) {
            commit("setModalRegistration", value);
        }
    },
};
