<template>
  <aside id="popUp" class="popup" :class="{ 'popup-active': showBalance }">
    <div v-if="showBalance" class="popUpContainer">
      <header class="header-popup-summary">
        <h4 class="ty_bd">Player Information</h4>
      </header>
      <article>
        <p class="ty_bs mb-1 text-left">
          <b> {{ translations?.myprofile?.Player_ID ?? "Player ID" }}</b
          >: {{ playerInfo?.id }}
        </p>
        <p class="ty_bs mb-1 text-left" v-if="playerInfo">
          <b>{{ translations?.myprofile?.Total_Balance ?? "Total Balance" }}</b
          >: {{ playerInfo?.currency?.symbol ?? "" }}
          {{ playerInfo?.balance?.total?.toFixed(2) }}
        </p>
        <p class="ty_bs mb-1 text-left" v-if="playerInfo">
          <b>{{
            translations?.myprofile?.Withdrawable_Balance ?? "Withdrawable Balance"
          }}</b
          >: {{ playerInfo?.currency?.symbol ?? "" }}
          {{ playerInfo?.balance?.available?.toFixed(2) }}
        </p>
        <p class="ty_bs mb-1 text-left" v-if="playerInfo">
          <b>{{
            translations?.account_balance?.NonWithdrawableBalance ??
            "Balance not Withdrawable"
          }}</b
          >: {{ playerInfo?.currency?.symbol ?? "" }}
          {{ playerInfo?.balance?.deposited?.toFixed(2) }}
        </p>
        <p class="ty_bs mb-3 text-left" v-if="playerInfo">
          <b>{{ translations?.myprofile?.Bonus_Balance ?? "Bonus Balance" }}</b
          >: {{ playerInfo?.currency?.symbol ?? "" }}
          {{ playerInfo?.balance?.bonus?.toFixed(2) }}
        </p>
        <div class="button-wrapper-summary">
          <button class="btn deposit-button-summary-popup" @click="handleDepositClick">
            {{ translations?.buttons?.deposit_button ?? "Deposit" }}
          </button>
          <button
            @click="handleDashboardClick"
            class="btn dashboard-button-summary-popup"
          >
            {{ translations?.buttons?.dashboard_button ?? "Dashboard" }}
          </button>
        </div>
        <div class="logout-btn-container" v-if="!isMobile">
          <button @click="logoutPlayer" class="button-logout-player-summary-popup">
            <svg
              class="logout-player-summary-button-icon"
              viewBox="-4 2 28 28"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>logout</title>
              <path
                d="M0 9.875v12.219c0 1.125 0.469 2.125 1.219 2.906 0.75 0.75 1.719 1.156 2.844 1.156h6.125v-2.531h-6.125c-0.844 0-1.5-0.688-1.5-1.531v-12.219c0-0.844 0.656-1.5 1.5-1.5h6.125v-2.563h-6.125c-1.125 0-2.094 0.438-2.844 1.188-0.75 0.781-1.219 1.75-1.219 2.875zM6.719 13.563v4.875c0 0.563 0.5 1.031 1.063 1.031h5.656v3.844c0 0.344 0.188 0.625 0.5 0.781 0.125 0.031 0.25 0.031 0.313 0.031 0.219 0 0.406-0.063 0.563-0.219l7.344-7.344c0.344-0.281 0.313-0.844 0-1.156l-7.344-7.313c-0.438-0.469-1.375-0.188-1.375 0.563v3.875h-5.656c-0.563 0-1.063 0.469-1.063 1.031z"
              ></path>
            </svg>
            <span class="ml-1">{{ translations?.buttons?.logout ?? "Logout" }}</span>
          </button>
        </div>
      </article>
    </div>
  </aside>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "PlayerSummary",
  components: {},
  data() {
    return {
      isLogged: localStorage.getItem("X19fYWNjZXNzX19f") ?? false,
      showBalance: localStorage.getItem("showBalance"),
      language_selected: localStorage.getItem("language") ?? "en",
      isMobile: false,
    };
  },
  computed: {
    ...mapGetters("player", ["playerInfoLoading", "playerInfo", "playerBalance"]),
    ...mapGetters("translations", ["translations", "isTranslationLoading"]),
  },
  mounted() {
    window.addEventListener("resize", this.checkIsMobile);
  },
  created() {},
  methods: {
    handleDepositClick() {
      localStorage.removeItem("showBalance");
      this.$router.push({
        name: "account-balance",
        params: { lang: this.language_selected },
        query: { tab: "deposit" },
      });
    },
    handleDashboardClick() {
      localStorage.removeItem("showBalance");
      this.$router.push({
        name: "profile",
        params: { lang: this.language_selected },
      });
    },
    checkIsMobile() {
      this.isMobile = window.innerWidth <= 992;
    },
    async logoutPlayer() {
      this.$store.dispatch("player/logout");
    },
  },
};
</script>
<style scoped>
.header-popup-summary {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
