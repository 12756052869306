<template>
  <PreLoader
    v-if="isAllProvidersLoading || isTranslationLoading || !allInformationIsLoading"
  />
  <div>
    <DashBoard />
    <div class="container">
      <div class="row mx-auto justify-content-center">
        <div class="col-12 col-lg-10">
          <div class="dashboard-all">
            <h2 class="dashboard-title ty_h3">
              {{ translations?.account_balance?.Cashier ?? "Cashier" }}
            </h2>
            <div class="btn-wrapper" v-if="contentViewButtons.length > 0">
              <button
                v-for="button in contentViewButtons"
                :key="button.value"
                @click="setContentView(button.value)"
                :disabled="contentView === button.value"
                :class="{ active: contentView === button.value }"
                class="dashboard-button-cashier ty_bs"
              >
                {{ translations?.account_balance?.[button.label] ?? button.label }}
              </button>
            </div>
            <hr class="dashboard-line" />
            <div v-if="contentView === 'overview'">
              <h5 class="dashboard-title ty_h3 mb-5">
                {{ translations?.account_balance?.YourBalances ?? "Your balances" }}
              </h5>
              <div
                class="row flex-row justify-content-start align-items-center mx-0"
                v-if="playerInfo"
              >
                <div
                  v-for="(card, index) in balanceCards"
                  :key="index"
                  class="col-12 col-md-6 col-lg-4 mb-3"
                >
                  <div :class="['dashboard-card', card.class]">
                    <svg
                      :class="['dashboard-icon', card.icon]"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        :d="card.iconPath"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <div class="d-flex flex-column align-items-end">
                      <h4 class="ty_h4">
                        {{ playerInfo?.balance?.[card.label] }}
                        {{ playerInfo?.currency?.symbol ?? "" }}
                      </h4>
                      <span class="responsive-text ty_text100-medium">{{
                        translations?.account_balance?.[card.textCard] ?? card.textCard
                      }}</span>
                    </div>
                  </div>
                </div>
                <h5 class="dashboard-title ty_h3 mt-2 mb-4">
                  {{ translations?.account_balance?.your_limits ?? "Your Limits" }}
                </h5>
                <div
                  class="limits-player-container d-flex align-items-center justify-content-between flex-wrap w-100"
                >
                  <div class="d-flex flex-column" :class="isMobile ? 'w-100' : 'w-32'">
                    <label class="mb-2 ty_bd" for="maxDailyDeposit"
                      >{{
                        translations?.myprofile?.max_daily_deposit ?? "Max Daily Deposit"
                      }}:</label
                    >
                    <input
                      type="number"
                      class="input"
                      id="maxDailyDeposit"
                      v-model="max_daily_deposit_new"
                      name="maxDailyDeposit"
                      :placeholder="
                        max_daily_deposit_default !== null ||
                        max_daily_deposit_default !== ''
                          ? max_daily_deposit_default
                          : translations?.myprofile?.no_limits_setted ??
                            'No limits setted'
                      "
                    />
                  </div>
                  <div class="d-flex flex-column" :class="isMobile ? 'w-100' : 'w-32'">
                    <label class="mb-2 ty_bd" for="maxWeeklyDeposit"
                      >{{
                        translations?.myprofile?.max_weekly_deposit ??
                        "Max Weekly Deposit"
                      }}:</label
                    >
                    <input
                      type="number"
                      class="input"
                      id="maxWeeklyDeposit"
                      v-model="max_weekly_deposit_new"
                      name="maxWeeklyDeposit"
                      :placeholder="
                        max_weekly_deposit_default !== null ||
                        max_weekly_deposit_default !== ''
                          ? max_weekly_deposit_default
                          : translations?.myprofile?.no_limits_setted ??
                            'No limits setted'
                      "
                    />
                  </div>
                  <div class="d-flex flex-column" :class="isMobile ? 'w-100' : 'w-32'">
                    <label class="mb-2 ty_bd" for="maxMonthlyDeposit"
                      >{{
                        translations?.myprofile?.max_monthly_deposit ??
                        "Max Monthly Deposit"
                      }}:</label
                    >
                    <input
                      type="number"
                      class="input"
                      id="maxMonthlyDeposit"
                      v-model="max_monthly_deposit_new"
                      name="maxMonthlyDeposit"
                      :placeholder="
                        max_monthly_deposit_default !== null ||
                        max_monthly_deposit_default !== ''
                          ? max_monthly_deposit_default
                          : translations?.myprofile?.no_limits_setted ??
                            'No limits setted'
                      "
                    />
                  </div>
                </div>
                <div class="d-flex justify-content-center align-items-center w-100">
                  <button class="btn modify-limits-btn" @click="callModifyLimits">
                    {{ translations?.buttons?.modify_limits ?? "Modify Limits" }}
                  </button>
                </div>
                <div
                  class="self-exclusion-section mt-3 d-flex flex-column w-100 align-items-start justify-content-start"
                >
                  <h5 class="dashboard-title ty_h3 mt-2 mb-4">
                    {{
                      translations?.account_balance?.self_exclusion ?? "Self Exclusion"
                    }}
                  </h5>

                  <div class="d-flex justify-content-start align-items-start w-100">
                    <!-- Select per scegliere i giorni self-exclusion -->
                    <select
                      id="selfExclusionDays"
                      v-model="selectedDaysExclusion"
                      @change="updateExclusionDate"
                      class="mb-0 mr-3"
                    >
                      <option :value="null" disabled>
                        {{
                          translations?.account_balance?.choose_periods ?? "Choose Period"
                        }}
                      </option>
                      <option
                        v-for="day in daysSelfExclusionOptions"
                        :key="day"
                        :value="day"
                      >
                        {{ day }}
                      </option>
                    </select>
                    <button
                      :class="exclusionDate == null ? 'disabled' : ''"
                      @click="callSelfExclusion"
                      class="btn btn-danger button-self-exclusion"
                    >
                      {{
                        translations?.account_balance?.set_exclusion ?? "Set Exclusion"
                      }}
                    </button>
                  </div>
                  <div
                    class="d-flex justify-content-start align-items-start w-100 flex-column pt-4"
                  >
                    <small
                      class="ty_bd"
                      style="font-size: 12px; color: red"
                      v-if="playerInfo?.status_expires_at"
                      >{{
                        translations?.account_balance?.you_are_already_excluse ??
                        "You are self-excluse until"
                      }}
                      {{ playerInfo?.status_expires_at }}</small
                    >
                    <p class="ty_bd mr-2 mb-0" v-if="exclusionDate !== null">
                      {{
                        translations?.account_balance?.date_of_self_Exclusion ??
                        "Date of Self exclusion"
                      }}:
                    </p>
                    <p class="ty_bd mb-0" v-if="exclusionDate !== null">
                      {{ formattedExclusionDate ? formattedExclusionDate : "" }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="position-relative d-flex flex-column"
              v-else-if="contentView == 'deposit'"
            >
              <div v-if="isMethodLoaded">
                <h5 class="dashboard-title ty_h3 mb-5">
                  {{ translations?.account_balance?.Deposit ?? "Deposit" }}
                </h5>
                <p v-if="depositPaymentMethodsFiltered">
                  {{ translations?.account_balance?.Methods ?? "Methods" }}
                </p>
                <p v-if="!playerInfo.email_verified_at">
                  {{
                    translations?.registration?.nodeposit ??
                    "In order to deposit, you must verify your email address via the email that arrived in your inbox."
                  }}
                </p>
                <div
                  v-if="playerInfo.email_verified_at"
                  class="row w-100 align-items-center flex-row mx-0"
                >
                  <!-- eslint-disable -->
                  <div
                    v-if="depositPaymentMethodsFiltered"
                    class="col-12 col-lg-4 col-md-4 col-sm-6"
                    v-for="method in depositPaymentMethodsFiltered"
                    :key="method.id"
                  >
                    <div
                      @click="selectPaymentMethod(method)"
                      :id="method.id"
                      class="dashboard-card py-4 flex-column mt-2 mb-2"
                      :class="{
                        activeDashboardCardDeposit:
                          selectedPaymentMethodId === method.id && isDepositFormOpen,
                      }"
                    >
                      <h5 class="ty_h5 mb-5 mb-lg-3">
                        {{
                          translations?.account_balance?.[
                            replaceMethodName(method?.name)
                          ] ?? method?.name
                        }}
                      </h5>
                      <div
                        class="method-limits mb-2"
                        v-if="
                          minDepositable &&
                          maxDepositable &&
                          isDepositFormOpen &&
                          selectedPaymentMethodId === method.id
                        "
                      >
                        <span class="mr-4 ty_text100-regular"
                          ><b>{{ translations?.account_balance?.Min ?? "Min" }}</b>
                          {{ minDepositable }}€</span
                        >
                        <span class="ty_text100-regular"
                          ><b>{{ translations?.account_balance?.Max ?? "Max" }}</b>
                          {{ maxDepositable }}€</span
                        >
                      </div>
                      <small class="ty_text100-regular">{{
                        translations?.account_balance?.Transaction_without_costs ??
                        "Transaction_without_costs"
                      }}</small>
                    </div>
                  </div>
                  <div v-else>
                    <p>
                      {{
                        translations?.account_balance?.no_method_available ??
                        "No deposit methods available."
                      }}
                    </p>
                  </div>
                  <!-- eslint-disable -->
                  <form class="mt-5" v-if="account_id_deposit == 14 && isDepositFormOpen">
                    <div class="form-group mb-3">
                      <label class="mb-2" for="Bank">{{
                        translations?.account_balance?.Bank ?? "Bank"
                      }}</label>
                      <input
                        type="text"
                        class="form-control"
                        id="Bank"
                        aria-describedby="Bank"
                        :placeholder="translations?.account_balance?.Bank ?? 'Bank'"
                        required
                        v-model="bank"
                      />
                    </div>
                    <div class="form-group mb-3">
                      <label class="mb-2" for="Iban">{{
                        translations?.account_balance?.Iban ?? "Iban"
                      }}</label>
                      <input
                        type="text"
                        class="form-control"
                        id="Iban"
                        :placeholder="translations?.account_balance?.Iban ?? 'Iban'"
                        minlength="5"
                        maxlength="34"
                        required
                        v-model="iban"
                      />
                    </div>
                    <div class="form-group mb-3">
                      <label class="mb-2" for="AccountNumber">{{
                        translations?.account_balance?.Account_number ?? "Account Number"
                      }}</label>
                      <input
                        type="text"
                        class="form-control"
                        id="AccountNumber"
                        :placeholder="
                          translations?.account_balance?.Account_number ??
                          'Account Number'
                        "
                        required
                        v-model="accountNumber"
                      />
                    </div>
                  </form>
                  <form
                    class="mt-5"
                    v-else-if="
                      getProviderPaymentMethods('globank') &&
                      getProviderPaymentMethods('globank').includes(account_id_deposit) &&
                      isDepositFormOpen &&
                      !showAlreadyUsedCard
                    "
                  >
                    <div class="row align-items-center mx-0">
                      <div class="col-12 col-md-12" style="padding-left: 0px !important">
                        <div class="form-group mb-3">
                          <label class="mb-2" for="card_number">{{
                            translations?.account_balance?.Card_number ?? "Card Number"
                          }}</label>
                          <input
                            type="tel"
                            inputmode="numeric"
                            pattern="[0-9\s]{13,19}"
                            class="form-control"
                            id="card_number"
                            @input="formatCardNumberValue"
                            aria-describedby="card_number"
                            :placeholder="
                              translations?.account_balance?.Card_number ?? 'Card Number'
                            "
                            required
                            v-model="card_number"
                          />
                        </div>
                      </div>
                      <div class="col-12 col-md-12" style="padding-left: 0px !important">
                        <div class="form-group mb-3">
                          <label class="mb-2" for="cvv">{{
                            translations?.account_balance?.Cvv ?? "CVV"
                          }}</label>
                          <input
                            type="text"
                            class="form-control"
                            id="cvv"
                            inputmode="numeric"
                            pattern="[0-9\s]{13,19}"
                            :placeholder="translations?.account_balance?.Cvv ?? 'CVV'"
                            maxlength="4"
                            required
                            v-model="cvv"
                          />
                        </div>
                      </div>
                      <div class="col-12 col-md-12" style="padding-left: 0px !important">
                        <label class="mb-2" for="expiry_month">{{
                          translations?.account_balance?.Expiry_date ?? "Expiry Date"
                        }}</label>
                        <div class="form-group d-flex justify-content-between mb-3">
                          <input
                            type="text"
                            class="form-control d-inline mr-1 mr-md-3"
                            id="expiry_month"
                            maxlength="2"
                            inputmode="numeric"
                            pattern="[0-9\s]{13,19}"
                            :placeholder="
                              translations?.account_balance?.Expiry_month ??
                              'Expiry Month'
                            "
                            required
                            v-model="expiry_date.month"
                          />
                          <input
                            type="text"
                            class="form-control d-inline ml-1 ml-md-3"
                            id="expiry_year"
                            maxlength="2"
                            inputmode="numeric"
                            pattern="[0-9\s]{13,19}"
                            :placeholder="
                              translations?.account_balance?.Expiry_year ?? 'Expiry Year'
                            "
                            required
                            v-model="expiry_date.year"
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                  <div
                    v-else-if="
                      getProviderPaymentMethods('globank') &&
                      getProviderPaymentMethods('globank').includes(account_id_deposit) &&
                      isDepositFormOpen &&
                      showAlreadyUsedCard
                    "
                    class="row justify-content-start mt-4 mx-0"
                  >
                    <div class="col-12 col-lg-5">
                      <div
                        class="dashboard-card card-details inverse flex-direction-column position-relative"
                      >
                        <p class="mb-0">{{ card_details.card.type }}</p>
                        <p class="mb-0 fw-bold">
                          *********{{ card_details.card.last_digits }}
                        </p>
                        <div
                          @click="removeAlreadyUsedCard"
                          class="position-absolute dashboard-remove"
                        >
                          {{ translations?.account_balance?.Remove ?? "Remove" }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="wrapper-deposit-option mt-5 flex-wrap"
                    v-if="isDepositFormOpen && !showIframe"
                  >
                    <div class="w-90 mr-md-3">
                      <label class="d-block" for="amountDeposit">{{
                        translations?.account_balance?.Amount ?? "Amount"
                      }}</label>
                      <input
                        class="dashboard-input-deposit"
                        type="number"
                        name="amountDeposit"
                        id="amountDeposit"
                        v-model="amountDeposited"
                      />
                    </div>
                    <div class="w-90 mr-md-3">
                      <label class="d-block" for="bonusCode">{{
                        translations?.account_balance?.BonusCode ?? "Bonus Code"
                      }}</label>
                      <input
                        class="dashboard-input-deposit"
                        type="text"
                        name="codeBonus"
                        id="codeBonus"
                        v-model="codeBonus"
                        :placeholder="
                          translations?.promotion?.if_you_have_bonus ?? 'If you have it'
                        "
                      />
                    </div>
                    <div>
                      <label style="display: block; opacity: 0" for="#">#</label>
                      <button @click="makeDeposit" class="dashboard-button-deposit mr-1">
                        {{ translations?.account_balance?.Deposit ?? "Deposit" }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="pt-3 d-flex align-items-center justify-content-center"
                v-else-if="!isMethodLoaded && contentView == 'deposit'"
              >
                <MainLoader />
              </div>
              <div
                class="pt-3 d-flex w-100 flex-column align-items-center justify-content-center"
                v-if="showIframe == true"
              >
                <div class="frame-outer-box col-lg-10 col-md-10 col-sm-12 col-12">
                  <iframe :src="iframeSrc" class="frame-container"></iframe>
                </div>

                <button
                  class="cancelDepositButton position-relative pt-2"
                  style="bottom: 0px; right: 0px"
                  @click="comeBackToDepositPage"
                >
                  {{ translations?.buttons?.returnDeposit ?? "Return to deposit" }}
                </button>
              </div>
            </div>
            <div v-else-if="contentView == 'withdraw'">
              <div v-if="isMethodLoaded">
                <h5 class="dashboard-title ty_h3 mb-5">
                  {{ translations?.account_balance?.Withdraw ?? "Withdraw" }}
                </h5>
                <div
                  class="d-flex align-items-center w-100 flex-wrap"
                  v-if="playerInfo.is_verified"
                >
                  <div class="d-flex flex-column group-option">
                    <label for="amount_withdraw">
                      {{ translations?.account_balance?.Amount ?? "Amount" }}</label
                    >
                    <input
                      type="text"
                      class="dashboard-input-withdraw"
                      id="amountWithdraw"
                      aria-describedby="amountWithdraw"
                      :placeholder="
                        translations?.account_balance?.EnterAmountPlaceHolder ??
                        'Enter amount'
                      "
                      required
                      v-model="amountWithdraw"
                    />
                  </div>
                  <div class="d-flex flex-column group-option">
                    <label for="methos_withdraw">
                      {{ translations?.account_balance?.Methods ?? "Methods" }}</label
                    >
                    <select
                      class="dashboard-input-withdraw"
                      name="account_id_withdraw"
                      id="account_id_withdraw"
                      v-model="account_id_withdraw"
                    >
                      <option value="" disabled selected>
                        {{
                          translations?.account_balance?.SelectMethod ?? "Select Methods"
                        }}
                      </option>
                      <option
                        v-for="method in withdrawPaymentMethods"
                        :key="method.id"
                        :value="method.id"
                      >
                        {{ method.name }}
                      </option>
                    </select>
                  </div>
                  <div
                    class="banner-limits-withdraw"
                    v-if="account_id_withdraw !== '' && !showIframeWithdraw"
                  >
                    <div class="container-shown-limits-max-min">
                      <div class="limits-item ty_text100-regular">
                        {{
                          translations?.account_balance?.MinWithdrawable ??
                          "Min Withdrawable"
                        }}:
                        {{ playerInfo?.currency?.symbol ?? "" }}
                        {{ minWithdrawable_for_method }}
                      </div>
                      <div class="limits-item ty_text100-regular">
                        {{
                          translations?.account_balance?.MaxWithdrawable ??
                          "Max Withdrawable"
                        }}
                        {{ playerInfo?.currency?.symbol ?? "" }}
                        {{ maxWithdrawable_for_method }}
                      </div>
                      <div class="limits-item ty_text100-regular">
                        {{
                          translations?.account_balance?.WithdrawableBalance ??
                          "Withdrawable Balance"
                        }}:
                        {{ playerInfo?.currency?.symbol ?? "" }}
                        {{ maxWithdrawable }}
                      </div>
                    </div>
                    <div class="dashboard-button-makeWithdraw" @click="withdrawCall">
                      {{ translations?.account_balance?.Withdraw ?? "Withdraw" }}
                    </div>
                  </div>
                  <div
                    class="pt-3 d-flex w-100 flex-column align-items-center justify-content-center"
                    v-if="showIframeWithdraw == true"
                  >
                    <div
                      class="frame-outer-box-withdraw col-lg-10 col-md-10 col-sm-12 col-12"
                    >
                      <iframe :src="iframeSrcWithdraw" class="frame-container"></iframe>
                    </div>

                    <button
                      class="cancelDepositButton position-relative pt-2"
                      style="bottom: 0px; right: 0px"
                      @click="comeBackToWithdrawPage"
                    >
                      {{
                        translations?.buttons?.returnToWithdraw ?? "Return to withdraw"
                      }}
                    </button>
                  </div>
                </div>
                <!--Message if not verified-->
                <div v-else class="not-verified d-flex align-items-center">
                  <svg
                    class="icon me-2"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0" />
                    <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <g id="SVGRepo_iconCarrier">
                      <path
                        d="M6.30928 9C8.59494 5 9.96832 3 12 3C14.3107 3 15.7699 5.58716 18.6883 10.7615L19.0519 11.4063C21.4771 15.7061 22.6897 17.856 21.5937 19.428C20.4978 21 17.7864 21 12.3637 21H11.6363C6.21356 21 3.50217 21 2.40626 19.428C1.45498 18.0635 2.24306 16.2635 4.05373 13"
                        stroke-width="1.5"
                        stroke-linecap="round"
                      />
                      <path d="M12 8V13" stroke-width="1.5" stroke-linecap="round" />
                      <circle cx="12" cy="16" r="1" />
                    </g>
                  </svg>
                  <span>{{
                    translations?.account_balance?.Account_not_yet_verified ??
                    "Account not yet verified"
                  }}</span>
                </div>
              </div>
              <div
                class="pt-3 d-flex align-items-center justify-content-center"
                v-else-if="!isMethodLoaded && contentView == 'withdraw'"
              >
                <MainLoader />
              </div>
            </div>
            <!--Account statement-->
            <div v-else-if="contentView == 'account-statement'">
              <h5 class="dashboard-title ty_h3 mb-5">
                {{
                  translations?.account_balance?.Account_statement ?? "Account Statement"
                }}
              </h5>
              <div class="container py-1">
                <div class="date-field py-3 d-flex justify-content-center flex-wrap">
                  <div class="col-6 date-option-acc-statement">
                    <label class="form-label" for="from_date">{{
                      translations?.reports?.From ?? "From"
                    }}</label>
                    <div
                      style="
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                      "
                      class="mb-2"
                    >
                      <div class="mr-1" style="flex: 1">
                        <select v-model="dayOption_From" id="day" class="input">
                          <option value="" disabled selected>
                            {{ translations?.registration?.Day }}
                          </option>
                          <option
                            v-for="dayOption_From in days_From"
                            :key="dayOption_From"
                            :value="dayOption_From"
                          >
                            {{
                              dayOption_From
                                ? dayOption_From
                                : translations?.registration?.Day
                            }}
                          </option>
                        </select>
                      </div>
                      <div class="mr-1" style="flex: 1">
                        <select v-model="monthOption_From" id="month" class="input">
                          <option value="" disabled selected>
                            {{ translations?.registration?.Month }}
                          </option>
                          <option
                            v-for="monthOption_From in months_From"
                            :key="monthOption_From"
                            :value="monthOption_From"
                          >
                            {{
                              monthOption_From
                                ? monthOption_From
                                : translations?.registration?.Month
                            }}
                          </option>
                        </select>
                      </div>
                      <div class="mr-1" style="flex: 1">
                        <select v-model="yearOption_From" id="year" class="input">
                          <option value="" disabled selected>
                            {{ translations?.registration?.Year }}
                          </option>
                          <option
                            v-for="yearOption_From in years_From"
                            :key="yearOption_From"
                            :value="yearOption_From"
                          >
                            {{
                              yearOption_From
                                ? yearOption_From
                                : translations?.registration?.Year
                            }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 date-option-acc-statement">
                    <label class="form-label" for="from_date">{{
                      translations?.reports?.To ?? "To"
                    }}</label>
                    <div
                      style="
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                      "
                      class="mb-2"
                    >
                      <div class="mr-1" style="flex: 1">
                        <select v-model="dayOption_To" id="day" class="input">
                          <option value="" disabled selected>
                            {{ translations?.registration?.Day }}
                          </option>
                          <option
                            v-for="dayOption_To in days_To"
                            :key="dayOption_To"
                            :value="dayOption_To"
                          >
                            {{
                              dayOption_To
                                ? dayOption_To
                                : translations?.registration?.Day
                            }}
                          </option>
                        </select>
                      </div>
                      <div class="mr-1" style="flex: 1">
                        <select v-model="monthOption_To" id="month" class="input">
                          <option value="" disabled selected>
                            {{ translations?.registration?.Month }}
                          </option>
                          <option
                            v-for="monthOption_To in months_To"
                            :key="monthOption_To"
                            :value="monthOption_To"
                          >
                            {{
                              monthOption_To
                                ? monthOption_To
                                : translations?.registration?.Month
                            }}
                          </option>
                        </select>
                      </div>
                      <div class="mr-1" style="flex: 1">
                        <select v-model="yearOption_To" id="year" class="input">
                          <option value="" disabled selected>
                            {{ translations?.registration?.Year }}
                          </option>
                          <option
                            v-for="yearOption_To in years_To"
                            :key="yearOption_To"
                            :value="yearOption_To"
                          >
                            {{
                              yearOption_To
                                ? yearOption_To
                                : translations?.registration?.Year
                            }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="my-3 d-flex justify-content-center">
                  <div class="col-6">
                    <div class="form-group mr-1">
                      <label class="form-label" for="type">{{
                        translations?.reports?.Type ?? "Type"
                      }}</label>
                      <select id="type" class="form-control" v-model="type">
                        <option value="" selected>
                          {{ translations?.reports?.All ?? "All" }}
                        </option>
                        <option value="1">
                          {{
                            translations?.account_balance?.CasinoDeposit ??
                            "Casino Deposit "
                          }}
                        </option>
                        <option value="2">
                          {{
                            translations?.account_balance?.CasinoWithdraw ??
                            "Casino Withdraw"
                          }}
                        </option>
                        <option value="4">
                          {{ translations?.account_balance?.CasinoBet ?? "Casino Bet" }}
                        </option>
                        <option value="5">
                          {{ translations?.account_balance?.CasinoWin ?? "Casino Win" }}
                        </option>
                        <option value="6">
                          {{
                            translations?.account_balance?.CasinoRefund ?? "Casino Refund"
                          }}
                        </option>
                        <option value="7">
                          {{
                            translations?.account_balance?.CasinoBonusBet ??
                            "Casino Bonus Bet"
                          }}
                        </option>
                        <option value="8">
                          {{
                            translations?.account_balance?.CasinoBonusWin ??
                            "Casino Bonus WIn"
                          }}
                        </option>
                        <option value="10">
                          {{
                            translations?.account_balance?.BonusAssigned ??
                            "Bonus Assigned"
                          }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group ml-1">
                      <label class="form-label" for="status">{{
                        translations?.reports?.Status ?? "Status"
                      }}</label>
                      <select id="type" class="form-control" v-model="status">
                        <option value="authorized">
                          {{ translations?.account_balance?.Authorized ?? "Authorized" }}
                        </option>
                        <option value="declined">
                          {{ translations?.account_balance?.Declined ?? "Declined" }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="my-3">
                  <div class="col-12 d-flex justify-content-end">
                    <div
                      v-if="isSearchActive"
                      class="d-flex justify-content-start align-items-end downloader-btn-pdf mr-3"
                    >
                      <img
                        class="icon-downloader"
                        src="../../../public/images/csv.png"
                        alt=""
                        @click="exportToCSV"
                      />
                      <img
                        class="icon-downloader"
                        src="../../../public/images/pdf.png"
                        alt=""
                        @click="exportToPDF"
                      />
                    </div>
                    <button class="dashboard-button-searchTransactions" @click="search">
                      {{ translations?.reports?.Search ?? "Search" }}
                    </button>
                  </div>
                </div>
              </div>
              <!--Start Info transactions-->
              <div
                class="container py-1 px-3"
                v-if="
                  isSearchActive &&
                  transactionsList?.length != 0 &&
                  !isTransactionsLoading
                "
              >
                <div class="table-container">
                  <table
                    id="transactionTable"
                    class="table table-striped table-bordered table-hover"
                  >
                    <thead class="thead-dark" style="border: 2px solid black">
                      <tr>
                        <th>{{ translations?.reports?.ID ?? "ID" }}</th>
                        <th>
                          {{ translations?.reports?.DateTime ?? "Date Time" }}
                        </th>
                        <th>
                          {{ translations?.reports?.Description ?? "Description" }}
                        </th>
                        <th>{{ translations?.reports?.Status ?? "Status" }}</th>
                        <th>{{ translations?.reports?.Amount ?? "Amount" }}</th>
                        <th>
                          {{ translations?.reports?.Balance ?? "Balance" }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in transactionsList" :key="index">
                        <td>{{ item?.id }}</td>
                        <td>{{ formatCreatedAt(item?.created_at) }}</td>
                        <td>{{ item?.type?.description }}</td>
                        <td>{{ item?.status }}</td>
                        <td>
                          {{ item?.currency?.symbol }}
                          {{ formatCurrency(item?.amount) }}
                        </td>
                        <td>
                          {{ item?.currency?.symbol }}
                          {{ formatCurrency(item?.balance) }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div
                class="w-100 d-flex align-items-center justify-content-center pt-2"
                v-else-if="isTransactionsLoading && isSearchActive"
              >
                <MainLoader />
              </div>
              <div
                class="pt-2 pb-2 text-center"
                v-else-if="
                  !isTransactionsLoading &&
                  transactionsList?.length == 0 &&
                  isSearchActive
                "
              >
                {{ translations?.reports?.NoResult ?? "No Result" }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Papa from "papaparse";
import html2pdf from "html2pdf.js";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import PreLoader from "@/components/global/PreLoader.vue";
import DashBoard from "./DashBoard.vue";
import MainLoader from "@/components/global/MainLoader.vue";
export default {
  name: "AccountBalancePage",
  components: {
    DashBoard,
    MainLoader,
    PreLoader,
  },
  data() {
    const currentDate = new Date();
    const currentDay = currentDate.getDate();
    const currentYear = new Date().getFullYear();
    let today = new Date();
    let sevenDaysAgoDate = new Date();
    sevenDaysAgoDate.setDate(today.getDate() - 7);

    return {
      contentView: null,
      isMobile: window.innerWidth <= 767,
      //Overview
      balanceCards: [
        {
          class: "",
          icon: "wallet",
          iconPath:
            "M18 8V7.2C18 6.0799 18 5.51984 17.782 5.09202C17.5903 4.71569 17.2843 4.40973 16.908 4.21799C16.4802 4 15.9201 4 14.8 4H6.2C5.07989 4 4.51984 4 4.09202 4.21799C3.71569 4.40973 3.40973 4.71569 3.21799 5.09202C3 5.51984 3 6.0799 3 7.2V8M21 12H19C17.8954 12 17 12.8954 17 14C17 15.1046 17.8954 16 19 16H21M3 8V16.8C3 17.9201 3 18.4802 3.21799 18.908C3.40973 19.2843 3.71569 19.5903 4.09202 19.782C4.51984 20 5.07989 20 6.2 20H17.8C18.9201 20 19.4802 20 19.908 19.782C20.2843 19.5903 20.5903 19.2843 20.782 18.908C21 18.4802 21 17.9201 21 16.8V11.2C21 10.0799 21 9.51984 20.782 9.09202C20.5903 8.71569 20.2843 8.40973 19.908 8.21799C19.4802 8 18.9201 8 17.8 8H3Z",
          label: "total",
          textCard: "TotalBalance",
        },
        {
          class: "",
          icon: "cash",
          iconPath:
            "M10.2322 1.05711C9.8417 0.66659 9.20854 0.66659 8.81802 1.05711L1.03984 8.83529C0.649317 9.22581 0.649317 9.85898 1.03984 10.2495L1.74698 10.9566L13.7678 22.9774C14.1583 23.3679 14.7915 23.3679 15.182 22.9774L15.8811 22.2783L15.8891 22.2703L15.8972 22.2622L22.2456 15.9138L22.2531 15.9064L22.2605 15.8989L22.9601 15.1992C23.3507 14.8087 23.3507 14.1756 22.9601 13.785L22.2603 13.0852L22.2531 13.0779L22.2458 13.0707L10.9454 1.77027C10.9434 1.76825 10.9414 1.76623 10.9394 1.76422C10.9373 1.76221 10.9353 1.7602 10.9333 1.7582L10.2322 1.05711ZM8.11092 4.59264L4.57539 8.12817L4.57541 8.12818C5.35646 8.90923 5.35646 10.1756 4.57541 10.9566L4.57539 10.9566L8.81804 15.1992L13.0607 19.4419C13.8417 18.6608 15.1081 18.6608 15.8891 19.4419L19.4247 15.9064C18.6436 15.1253 18.6436 13.859 19.4247 13.0779L15.1894 8.84272C16.8413 10.5038 16.4772 13.197 14.8284 14.8457C13.1773 16.4969 10.4785 16.8597 8.81804 15.1992C7.1576 13.5388 7.52042 10.84 9.17159 9.18884C10.8203 7.54013 13.5135 7.17594 15.1746 8.82788L10.9393 4.59266C10.1583 5.3737 8.89198 5.37369 8.11093 4.59265C8.11093 4.59264 8.11093 4.59264 8.11092 4.59264ZM10.5858 10.6031C11.6683 9.52055 13.0851 9.56679 13.7678 10.2495C14.4505 10.9322 14.4967 12.349 13.4142 13.4315C12.3317 14.514 10.915 14.4677 10.2322 13.785C9.54954 13.1023 9.5033 11.6856 10.5858 10.6031Z",
          label: "available",
          textCard: "WithdrawableBalance",
        },
        {
          class: "",
          icon: "cash",
          iconPath:
            "M10.2322 1.05711C9.8417 0.66659 9.20854 0.66659 8.81802 1.05711L1.03984 8.83529C0.649317 9.22581 0.649317 9.85898 1.03984 10.2495L1.74698 10.9566L13.7678 22.9774C14.1583 23.3679 14.7915 23.3679 15.182 22.9774L15.8811 22.2783L15.8891 22.2703L15.8972 22.2622L22.2456 15.9138L22.2531 15.9064L22.2605 15.8989L22.9601 15.1992C23.3507 14.8087 23.3507 14.1756 22.9601 13.785L22.2603 13.0852L22.2531 13.0779L22.2458 13.0707L10.9454 1.77027C10.9434 1.76825 10.9414 1.76623 10.9394 1.76422C10.9373 1.76221 10.9353 1.7602 10.9333 1.7582L10.2322 1.05711ZM8.11092 4.59264L4.57539 8.12817L4.57541 8.12818C5.35646 8.90923 5.35646 10.1756 4.57541 10.9566L4.57539 10.9566L8.81804 15.1992L13.0607 19.4419C13.8417 18.6608 15.1081 18.6608 15.8891 19.4419L19.4247 15.9064C18.6436 15.1253 18.6436 13.859 19.4247 13.0779L15.1894 8.84272C16.8413 10.5038 16.4772 13.197 14.8284 14.8457C13.1773 16.4969 10.4785 16.8597 8.81804 15.1992C7.1576 13.5388 7.52042 10.84 9.17159 9.18884C10.8203 7.54013 13.5135 7.17594 15.1746 8.82788L10.9393 4.59266C10.1583 5.3737 8.89198 5.37369 8.11093 4.59265C8.11093 4.59264 8.11093 4.59264 8.11092 4.59264ZM10.5858 10.6031C11.6683 9.52055 13.0851 9.56679 13.7678 10.2495C14.4505 10.9322 14.4967 12.349 13.4142 13.4315C12.3317 14.514 10.915 14.4677 10.2322 13.785C9.54954 13.1023 9.5033 11.6856 10.5858 10.6031Z",
          label: "deposited",
          textCard: "NonWithdrawableBalance",
        },
        {
          class: "inverse",
          icon: "gift",
          iconPath:
            "M3 17H21M12 8L10 12M12 8L14 12M12 8H7.5C6.83696 8 6.20107 7.73661 5.73223 7.26777C5.26339 6.79893 5 6.16304 5 5.5C5 4.83696 5.26339 4.20107 5.73223 3.73223C6.20107 3.26339 6.83696 3 7.5 3C11 3 12 8 12 8ZM12 8H16.5C17.163 8 17.7989 7.73661 18.2678 7.26777C18.7366 6.79893 19 6.16304 19 5.5C19 4.83696 18.7366 4.20107 18.2678 3.73223C17.7989 3.26339 17.163 3 16.5 3C13 3 12 8 12 8ZM6.2 21H17.8C18.9201 21 19.4802 21 19.908 20.782C20.2843 20.5903 20.5903 20.2843 20.782 19.908C21 19.4802 21 18.9201 21 17.8V11.2C21 10.0799 21 9.51984 20.782 9.09202C20.5903 8.71569 20.2843 8.40973 19.908 8.21799C19.4802 8 18.9201 8 17.8 8H6.2C5.0799 8 4.51984 8 4.09202 8.21799C3.71569 8.40973 3.40973 8.71569 3.21799 9.09202C3 9.51984 3 10.0799 3 11.2V17.8C3 18.9201 3 19.4802 3.21799 19.908C3.40973 20.2843 3.71569 20.5903 4.09202 20.782C4.51984 21 5.07989 21 6.2 21Z",
          label: "bonus",
          textCard: "BonusBalance",
        },
      ],
      contentViewButtons: [
        { label: "Overview", value: "overview" },
        { label: "Deposit", value: "deposit" },
        { label: "Withdraw", value: "withdraw" },
        { label: "AccountStatement", value: "account-statement" },
      ],
      isMethodLoaded: false,
      //value setted by player in bo
      max_daily_deposit_default: null,
      max_weekly_deposit_default: null,
      max_monthly_deposit_default: null,
      max_daily_bet: null,
      max_daily_loss: null,
      //new val set by player in dashboard
      max_daily_deposit_new: null,
      max_weekly_deposit_new: null,
      max_monthly_deposit_new: null,
      //self-exclusion
      daysSelfExclusionOptions: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        14,
        30,
        60,
        90,
        180,
        365,
        "Forever",
      ],
      selectedDaysExclusion: null,
      exclusionDate: null,
      /*DEPOSIT SECTION */
      selectedPaymentMethodId: null,
      isDepositFormOpen: false,
      account_id_deposit: null,
      amountDeposited: null,
      codeBonus: "",
      iban: "",
      accountNumber: "",
      bank: "",
      card_number: "",
      cvv: "",
      expiry_date: {
        month: "",
        year: "",
      },
      showAlreadyUsedCard: false,
      firstSearch: true,
      card_details: null /* Used for globank payment method, if set, it holds the players's previously used card's type, last digits and token */,
      /*Withdraw */
      account_id_withdraw: "",
      amountWithdraw: "",
      /*Account statement*/
      /*Date_to*/
      yearOption_To: currentYear,
      monthOption_To: String(today.getMonth() + 1).padStart(2, "0"),
      dayOption_To: String(today.getDate()).padStart(2, "0"),
      days_To: Array.from({ length: currentDay }, (_, index) =>
        (index + 1).toString().padStart(2, "0")
      ),
      months_To: Array.from({ length: 12 }, (_, index) =>
        (index + 1).toString().padStart(2, "0")
      ),
      years_To: Array.from({ length: 6 }, (_, index) => currentYear - index),
      /*Date_from*/
      yearOption_From: sevenDaysAgoDate.getFullYear(),
      monthOption_From: String(sevenDaysAgoDate.getMonth() + 1).padStart(2, "0"),
      dayOption_From: String(sevenDaysAgoDate.getDate()).padStart(2, "0"),
      days_From: Array.from({ length: 31 }, (_, index) =>
        (index + 1).toString().padStart(2, "0")
      ),
      months_From: Array.from({ length: 12 }, (_, index) =>
        (index + 1).toString().padStart(2, "0")
      ),
      years_From: Array.from({ length: 6 }, (_, index) => currentYear - index),
      //*//
      from_date: "",
      to_date: "",
      status: "authorized",
      type: "",
      responseLength: null,
      isSearchActive: false,
      //show Iframe for deposit
      showIframe: false,
      iframeSrc: "",
      showIframeWithdraw: false,
      iframeSrcWithdraw: "",
    };
  },
  computed: {
    ...mapGetters("api", ["refId"]),
    ...mapGetters("skinSettings", ["allInformationIsLoading"]),
    ...mapGetters("player", ["playerInfo", "balance", "playerSurvey"]),
    ...mapGetters("casinoGames", ["allSkinProviders", "isAllProvidersLoading"]),
    ...mapGetters("reports", [
      "isTransactionsLoading",
      "isFirstLoadTransactions",
      "transactionsList",
      "transactionsWithdraw",
      "transactionsDeposit",
      "transactionsTotal",
    ]),
    ...mapGetters("translations", ["isTranslationLoading", "translations"]),
    ...mapGetters("payments", [
      "depositResponse",
      "depositPaymentMethods",
      "withdrawResponse",
      "withdrawalPaymentMethods",
      "withdrawPaymentMethods",
      "gbLastUsedCardResponse",
    ]),
    ...mapGetters("payments", ["depositPaymentMethodsFiltered"]),
    ...mapGetters("api", ["clientIp"]),
    /* Check Withdraw */
    methodName() {
      const name = () => {
        let selected = this.account_id_withdraw;
        let selectedMethod = this.withdrawPaymentMethods?.find(
          (method) => method.id == selected
        );
        if (selected != null) {
          return selectedMethod.name;
        }
      };
      return name();
    },
    maxWithdrawable() {
      const maximum = () => {
        let playerBalanceAvailable = this.balance.available;
        let selected = this.account_id_withdraw;
        if (selected != null) {
          return playerBalanceAvailable;
        }
      };
      return maximum();
    },
    maxWithdrawable_for_method() {
      const maximum_for_method = () => {
        let selected = this.account_id_withdraw;

        const selectedMethod = this.withdrawPaymentMethods?.find(
          (method) => method.id === selected
        );
        return selectedMethod?.withdrawal?.maximum || null;
      };
      return maximum_for_method();
    },
    minWithdrawable_for_method() {
      const minimum_for_method = () => {
        let selected = this.account_id_withdraw;

        const selectedMethod = this.withdrawPaymentMethods?.find(
          (method) => method.id === selected
        );
        return selectedMethod?.withdrawal?.minimum || null;
      };
      return minimum_for_method();
    },
    /* Check Withdraw */
    /*------------------------------------------------------- */
    //**Deposit Check  */
    maxDepositable() {
      const maximum = () => {
        let selected = this.account_id_deposit;

        let selectedMethod = this.depositPaymentMethods?.find(
          (method) => method.id == selected
        );
        if (selected != null) {
          return selectedMethod.data.maximum;
        }
      };
      return maximum();
    },
    minDepositable() {
      const minimum = () => {
        let selected = this.account_id_deposit;
        let selectedMethod = this.depositPaymentMethods?.find(
          (method) => method.id == selected
        );
        if (selected != null) {
          return selectedMethod.data.minimum;
        }
      };
      return minimum();
    },
    //**Deposit Check  **/
    //*----------------------------------------------------- */
    /*Reports */
    skinName() {
      return this.$store.getters["skinSettings/skinName"];
    },
    //self exclusion
    formattedExclusionDate() {
      if (this.selectedDaysExclusion === "Forever") {
        return "2999-12-31";
      }

      if (this.selectedDaysExclusion && typeof this.selectedDaysExclusion === "number") {
        const currentDate = new Date();
        const exclusionDate = new Date(
          currentDate.setDate(currentDate.getDate() + this.selectedDaysExclusion)
        );

        const day = exclusionDate.getDate().toString().padStart(2, "0");
        const month = (exclusionDate.getMonth() + 1).toString().padStart(2, "0");
        const year = exclusionDate.getFullYear();
        return `${year}-${month}-${day}`;
      }

      return "";
    },
  },
  mounted() {
    this.checkIsMobile();
    this.contentView = this.$route.query.tab || "overview";
    if (this.contentView == "deposit") {
      let urlParams = new URLSearchParams(window.location.search);
      if (urlParams.has("code")) this.codeBonus = urlParams.get("code");
    }
    this.updateTabParam();
    this.handleContentViewCall();
    window.addEventListener("resize", this.checkIsMobile);
    setTimeout(() => {
      this.setDefaultPlayerMethods();
    }, 2000);
  },
  beforeUnmount() {
    this.codeBonus = "";
  },
  methods: {
    replaceMethodName(text) {
      return text.toLowerCase().replace(/\s/g, "");
    },
    exportToCSV() {
      const fields = [
        "id",
        "created_at",
        "type.description",
        "status",
        "amount",
        "balance",
      ];

      const visibleRows = this.transactionsList.map((item) => ({
        id: item.id,
        created_at: this.formatCreatedAt(item.created_at),
        description: item?.type?.description,
        status: item?.status,
        amount: `${this.playerInfo?.currency?.symbol} ${this.formatCurrency(
          item.amount
        )}`,
        balance: `${this.playerInfo?.currency?.symbol} ${this.formatCurrency(
          item.balance
        )}`,
      }));

      const opts = {
        fields,
      };

      const csv = Papa.unparse(visibleRows, opts);
      const blob = new Blob([csv], { type: "text/csv" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "transactions.csv";
      link.click();
    },

    exportToPDF() {
      html2pdf(document.getElementById("transactionTable"), {
        margin: 0,
        filename: "transactionTable.pdf",
      });
    },

    checkIsMobile() {
      this.isMobile = window.innerWidth <= 767;
    },
    updateExclusionDate() {
      if (this.selectedDaysExclusion === "Forever") {
        this.exclusionDate = new Date("2999-12-31");
      } else if (typeof this.selectedDaysExclusion === "number") {
        const currentDate = new Date();
        this.exclusionDate = new Date(
          currentDate.setDate(currentDate.getDate() + this.selectedDaysExclusion)
        );
      } else {
        this.exclusionDate = null;
      }
    },
    // supportNotification(content_body = null, subject = null) {
    //   let ref_id = this.refId;
    //   let emails = ["nm@bethappy.com"];

    //   this.$store
    //     .dispatch("player/supportNotification", {
    //       emails: emails,
    //       content_body: content_body,
    //       subject: subject,
    //       ref_id: ref_id,
    //     })
    //     .then(() => {
    //       return;
    //       location.reload();
    //     });
    // },
    callSelfExclusion() {
      let activated = true;
      if (activated) {
        Swal.fire({
          title: this.translations?.dashboard_menu?.Attention ?? "Attention",
          html:
            this.translations?.dashboard_menu?.SelfExclusionReason ??
            "Are you sure you want to self-exclude yourself from the game? In order for us to approve the requested reduction in your self-exclusion, we are required to ask for the reason. Please enter the reason below:",
          input: "textarea",
          confirmButtonColor: "#149102",
          denyButtonColor: "#a78300",
          showCancelButton: true,
          allowOutsideClick: false,
          confirmButtonText: this.translations?.dashboard_menu?.YesWant ?? "Yes, I Want",
          cancelButtonText:
            this.translations?.dashboard_menu?.NoThink ?? "No, I need to think about it",
          preConfirm: (value) => {
            if (!value) {
              Swal.showValidationMessage(
                '<i class="fa fa-info-circle"></i> ' +
                  (this.translations?.dashboard_menu?.reasonReq ??
                    "You must insert the reason")
              );
            }
          },
        }).then((result) => {
          if (result.isConfirmed) {
            this.$store
              .dispatch("player/playerSelfExclusion", {
                time: this.formattedExclusionDate,
                activated: activated,
                status_reason: result.value,
              })
              .then(() => {
                let playerId = this.playerInfo?.id;
                let content_body =
                  (this.translations?.dashboard_menu?.the_user ?? "The user") +
                  "<b>" +
                  playerId +
                  "</b>" +
                  (this.translations?.dashboard_menu?.would_to_change ??
                    " would like to change the self exclusion date to ") +
                  this.formattedExclusionDate +
                  (this.translations?.dashboard_menu?.for_the_following_reason ??
                    " for the following reason:") +
                  "<br>" +
                  result.value +
                  "<br>" +
                  process.env.VUE_APP_URLBO +
                  "resources/players/" +
                  playerId +
                  "?tab=statistic";
                console.log(content_body);
                window.location.href = "/";
                // this.supportNotification( //per ora commento
                //   content_body,
                //   "Self exclusion date change request - Player " +
                //     this.playerInfo?.id
                // );
              });
          }
        });
      } else {
        Swal.fire({
          title: this.translations?.dashboard_menu?.Attention ?? "Attention",
          html:
            this.translations?.dashboard_menu?.SelfExclusion ??
            "Are you sure you want to self-exclude yourself from the game?",
          icon: "warning",
          type: "input",
          showCancelButton: true,
          allowOutsideClick: false,
          confirmButtonText: this.translations?.dashboard_menu?.YesWant ?? "Yes, I Want",
          cancelButtonText:
            this.translations?.dashboard_menu?.NoThink ?? "No, I need to think about it",
        }).then((result) => {
          if (result.isConfirmed) {
            this.$store
              .dispatch("player/playerSelfExclusion", {
                time: this.formattedExclusionDate,
                activated: activated,
              })
              .then(() => {
                location.reload();
              });
          }
        });
      }
    },
    setContentView(value) {
      if (this.contentView !== value) {
        this.contentView = value;
        this.removeCodeQueryParam();
        this.resetDepositFields();
        this.resetWithdrawFields();
        this.updateTabParam();
        this.handleContentViewCall();
      }
    },
    removeCodeQueryParam() {
      this.codeBonus = "";
      let currentUrl = window.location.href;
      let urlObject = new URL(currentUrl);
      urlObject.searchParams.delete("code");
      history.replaceState({}, document.title, urlObject.toString());
    },
    callModifyLimits() {
      if (this.max_daily_deposit_new == null) {
        this.max_daily_deposit_new = this.max_daily_deposit_default;
      }

      if (this.max_weekly_deposit_new == null) {
        this.max_weekly_deposit_new = this.max_weekly_deposit_default;
      }

      if (this.max_monthly_deposit_new == null) {
        this.max_monthly_deposit_new = this.max_monthly_deposit_default;
      }

      if (
        this.max_weekly_deposit_new < this.max_daily_deposit_new ||
        this.max_monthly_deposit_new < this.max_weekly_deposit_new ||
        this.max_monthly_deposit_new < this.max_daily_deposit_new
      ) {
        Swal.fire({
          text:
            this.translations?.body?.error_alert_limits ??
            "Please note:The weekly limit must be higher than or equal to the daily limit and lower than or equal to the monthly limit. The monthly limit must be higher than or equal to both the daily and weekly limits. ",
          icon: "warning",
          button: this.translations?.body?.error_alert_limits_ok ?? "OK",
        });
        return;
      }

      this.$store
        .dispatch("player/editPlayerLimits", {
          max_daily_deposit: +this.max_daily_deposit_new,
          max_monthly_deposit: +this.max_monthly_deposit_new,
          max_weekly_deposit: +this.max_weekly_deposit_new,
          max_daily_bet: +this.max_daily_bet,
          max_daily_loss: +this.max_daily_loss,
        })
        .then(() => {
          location.reload();
        });
    },
    setDefaultPlayerMethods() {
      if (
        this.playerInfo &&
        this.playerInfo?.limits &&
        this.playerInfo?.limits?.max_daily_deposit != null
      ) {
        this.max_daily_deposit_default = this.playerInfo?.limits?.max_daily_deposit;
      }
      if (
        this.playerInfo &&
        this.playerInfo?.limits &&
        this.playerInfo?.limits?.max_weekly_deposit != null
      ) {
        this.max_weekly_deposit_default = this.playerInfo?.limits?.max_weekly_deposit;
      }
      if (
        this.playerInfo &&
        this.playerInfo?.limits &&
        this.playerInfo?.limits?.max_monthly_deposit != null
      ) {
        this.max_monthly_deposit_default = this.playerInfo?.limits?.max_monthly_deposit;
      }
    },
    findMethodIdByCode(code) {
      let method = this.depositPaymentMethodsFiltered.find((el) => el.code == code);

      if (method != null) return method.id;
    },
    /**
     * Check between the payment methods codes by provider name
     * and returns an array with all methods IDs.
     * N.B. The payment method's code must include the provider's name (e.g. globank_can)
     *
     * @param {String} slug
     */
    getProviderPaymentMethods(slug) {
      return (
        this.depositPaymentMethodsFiltered
          .filter((el) => el.code.includes(slug))
          .map((method) => method.id) ?? []
      );
    },
    /**
     * Format card_number value everytime it changes
     */
    formatCardNumberValue() {
      // Remove non-digit characters
      let cleanedInput = this.card_number.replace(/\D/g, "");

      // Format the input as "xxxx xxxx xxxx xxxx"
      this.card_number = cleanedInput.replace(/(\d{4})/g, "$1 ").trim();
    },
    removeAlreadyUsedCard() {
      this.showAlreadyUsedCard = false;
    },
    handleContentViewCall() {
      switch (this.contentView) {
        case "overview":
          //niente
          break;
        case "deposit":
          this.isMethodLoaded = false;
          this.$store.dispatch("payments/callPaymentMethods");
          this.$store
            .dispatch("payments/getDepositPaymentMethods")
            .then(() => {
              this.isMethodLoaded = true;
            })
            .catch((error) => {
              console.error("Error during getDepositPaymentMethods:", error);
            });
          break;
        case "withdraw":
          this.isMethodLoaded = false;
          this.$store.dispatch("payments/callPaymentMethods");
          this.$store
            .dispatch("payments/getWithdrawPaymentMethods")
            .then(() => {
              this.isMethodLoaded = true;
            })
            .catch((error) => {
              console.error("Error during getWithdrawPaymentMethods:", error);
            });
          break;
        case "account-statement":
          if (this.firstSearch) {
            this.firstSearch = false;
            this.search();
          }
          break;
        default:
          break;
      }
    },
    updateTabParam() {
      const url = new URL(window.location.href);
      url.searchParams.set("tab", this.contentView);
      window.history.replaceState(null, null, url.toString());
    },
    selectPaymentMethod(method) {
      if (
        (this.isDepositFormOpen &&
          this.account_id_deposit &&
          this.account_id_deposit == method.id) ||
        !this.isDepositFormOpen
      )
        this.isDepositFormOpen = !this.isDepositFormOpen;

      this.account_id_deposit = this.selectedPaymentMethodId = method.id;
    },
    makeDeposit() {
      if (
        (this.account_id_deposit == this.findMethodIdByCode("BANKTRANSFER") &&
          (this.bank == "" || this.iban == "" || this.accountNumber == "")) ||
        this.amountDeposited == "" ||
        (this.getProviderPaymentMethods("globank").includes(this.account_id_deposit) &&
          (this.card_number == "" ||
            this.cvv == "" ||
            this.expiry_date.year == "" ||
            this.expiry_date.month == ""))
      ) {
        Swal.fire({
          icon: "error",
          text:
            this.translations?.body?.Error_must_fill_all_fields ??
            "Must Fill all fields!",
        });
        return;
      } else if (
        this.account_id_deposit == this.findMethodIdByCode("BANKTRANSFER") &&
        (this.iban.length < 5 || this.iban.length > 34)
      ) {
        Swal.fire({
          icon: "error",
          text:
            this.translations?.body?.Error_Iban_lenght ??
            "Iban must be at least 5 number and maximum 34!",
        });
        return;
      } else if (this.amountDeposited < this.minDepositable) {
        Swal.fire({
          icon: "error",
          text:
            (this.translations?.body?.Error_deposit_less_than_minimm ??
              "You must deposit at least") +
            " " +
            this.minDepositable,
        });
        return;
      } else if (this.amountDeposited > this.maxDepositable) {
        Swal.fire({
          icon: "error",
          text:
            (this.translations?.body.Error_deposit_high_than_maximum_pt1 ??
              "You must deposit") +
            " " +
            this.maxDepositable +
            " " +
            (this.translations?.body?.Error_deposit_high_than_maximum_pt2 ?? "or less."),
        });
        return;
      } else {
        let fullUrl = location.protocol + "//" + location.host;
        let body = {
          amount: +this.amountDeposited,
          payment_method_id: this.account_id_deposit,
          bonus_code: this.codeBonus,
          cancel_url: "/deposit",
          bank: this.bank,
          // noframe: 1,
          iban: this.iban, //min5-max34
          accountnumber: this.accountNumber,
          redirect_success: fullUrl + "/transactions/{transaction_id}",
        };

        if (this.getProviderPaymentMethods("globank").includes(this.account_id_deposit)) {
          if (!(this.card_details && this.showAlreadyUsedCard)) {
            body.ip_address = this.clientIp;
            body.card_number = this.card_number.replace(/\s/g, "");
            body.cvv = this.cvv;
            (body.expiry_month = this.expiry_date.month),
              (body.expiry_year = this.expiry_date.year);
          } else {
            body.token = this.card_details.token;
          }
        }

        this.$store.dispatch("payments/makeDeposit", body);
      }
    },
    resetDepositFields() {
      this.bank = "";
      this.iban = "";
      this.accountNumber = "";
      this.isDepositFormOpen = false;
      this.cvv = "";
      this.expiry_date = {
        month: "",
        year: "",
      };
      this.showAlreadyUsedCard = false;
      this.card_number = "";

      this.isDepositFormOpen = false;
    },
    resetWithdrawFields() {
      this.amountWithdraw = "";
      this.account_id_withdraw = "";
    },
    comeBackToDepositPage() {
      location.reload();
    },
    comeBackToWithdrawPage() {
      location.reload();
    },
    withdrawCall() {
      if (this.amountWithdraw == "" || this.account_id_withdraw == "") {
        Swal.fire({
          icon: "error",
          text:
            this.translations?.body.Error_must_fill_all_fields ?? "Must fill all fields",
        });
        return;
      } else if (
        parseFloat(this.amountWithdraw) > parseFloat(this.maxWithdrawable) ||
        parseFloat(this.amountWithdraw) < parseFloat(this.minWithdrawable_for_method) ||
        parseFloat(this.amountWithdraw) > parseFloat(this.maxWithdrawable_for_method)
      ) {
        // console.log("Amount to withdraw", this.amountWithdraw);
        // console.log("Max to withdraw", this.maxWithdrawable);
        // console.log("Min to withdraw", this.minWithdrawable_for_method);
        // console.log("Max to withdraw", this.maxWithdrawable_for_method);
        Swal.fire({
          icon: "error",
          text:
            this.translations?.body.Error_invalid_amount_chosen ??
            "Invalid amount chosen!",
        });
        return;
      } else {
        let fullUrl = location.protocol + "//" + location.host;
        let body = {
          amount: +this.amountWithdraw,
          //account_id: this.account_id_withdraw,
          account_id: "bambora",
          cancel_url: "/withdraw",
          redirect_success: fullUrl + "/transactions/{transaction_id}",
        };
        this.$store.dispatch("payments/makeWithdraw", body);
      }
    },
    /*Account statement methods */
    formatCreatedAt(created_at) {
      if (!created_at || isNaN(new Date(created_at).getTime())) {
        return "";
      }
      const date = new Date(created_at);
      const formattedDate = date.toISOString().slice(0, 19).replace("T", " ");
      return formattedDate;
    },
    formatCurrency(value) {
      if (!isNaN(value)) {
        return parseFloat(value).toFixed(2);
      }
      return value;
    },
    requestTransactionList() {
      this.from_date = `${this.yearOption_From}-${this.monthOption_From}-${this.dayOption_From}`;
      this.to_date = `${this.yearOption_To}-${this.monthOption_To}-${this.dayOption_To}`;
      const params = {
        from: this.from_date,
        to: this.to_date,
        type: this.type,
        status: this.status,
      };
      this.$store.dispatch("reports/getTransactionsReports", params);

      const paramsDep = {
        from: this.from_date,
        to: this.to_date,
        type: 1,
        status: this.status,
        total: 1,
      };
      //total reports
      this.$store.dispatch("reports/getTransactionsReports", paramsDep);

      const paramsWith = {
        from: this.from_date,
        to: this.to_date,
        type: 2,
        status: this.status,
        total: 1,
      };
      //total reports
      this.$store.dispatch("reports/getTransactionsReports", paramsWith);
    },
    search() {
      if (
        !this.yearOption_From ||
        !this.monthOption_From ||
        !this.dayOption_From ||
        !this.yearOption_To ||
        !this.monthOption_To ||
        !this.dayOption_To
      ) {
        Swal.fire({
          icon: "error",
          text:
            this.translations?.body.Error_miss_date_time_range ??
            "Please enter a time range to perform the search.",
        });
        return;
      }

      const fromDate = new Date(
        this.yearOption_From,
        this.monthOption_From - 1, // Mese inizia da 0
        this.dayOption_From
      );

      const toDate = new Date(
        this.yearOption_To,
        this.monthOption_To - 1,
        this.dayOption_To
      );

      if (fromDate > toDate) {
        Swal.fire({
          icon: "error",
          text:
            this.translations?.body?.Error_date_from_higher_than_to_date ??
            "The 'From' date cannot be greater than the 'To' date.",
        });
        return;
      }

      this.isSearchActive = true;
      this.from_date = `${this.yearOption_From}-${this.monthOption_From}-${this.dayOption_From}`;
      this.to_date = `${this.yearOption_To}-${this.monthOption_To}-${this.dayOption_To}`;
      this.requestTransactionList(this.from_date, this.to_date, this.type, this.status);
    },
    callAlertCardUsed() {
      Swal.fire({
        titleText: `Use the ${this.card_details.card.type} that ends with *****${this.card_details.card.last_digits}?`,
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: this.translations?.body?.Alert_yes_i_want ?? "Yes, I want",
        cancelButtonText:
          this.translations?.body?.Alert_no_i_want_to_use_another_card ??
          "No, I want to use another card",
      }).then((response) => {
        if (response.isConfirmed) this.showAlreadyUsedCard = true;
      });
    },
  },
  watch: {
    depositResponse(value) {
      if (Object.prototype.hasOwnProperty.call(value, "message")) {
        let depositErrorMessage1 = value.message;
        let showMessage = true;
        //error pending transaction
        if (showMessage) {
          Swal.fire({
            icon: "error",
            text: depositErrorMessage1,
          }).then(() => {
            this.resetDepositFields();
          });
        }
      } else if (
        Object.prototype.hasOwnProperty.call(value, "redirect_url") &&
        Object.prototype.hasOwnProperty.call(value, "bambora")
      ) {
        window.location.replace(value.redirect_url);
      } else if (Object.prototype.hasOwnProperty.call(value, "amount")) {
        let amount = value.amount[0];
        let message = amount.charAt(0).toUpperCase() + amount.slice(1);
        let depositErrorMessage2 = message;
        let showMessage = true;
        if (showMessage) {
          Swal.fire({
            icon: "error",
            text: depositErrorMessage2,
          });
        }
      } else if (Object.prototype.hasOwnProperty.call(value, "redirect_url")) {
        window.location.replace(value.redirect_url);
      } else {
        if (value.includes("<form")) {
          //this.depositForm = value;
          document.body.innerHTML = value;
          let apco = document.getElementById("submitapco");
          if (apco)
            setTimeout(function () {
              apco.submit();
            }, 2000);
        } else {
          let startFromSrc = value.slice(value.search("src")).slice(5);
          let src = startFromSrc.slice(0, startFromSrc.search('"'));
          src = src.replace(/&amp;/g, "&");
          // alert(1);
          this.showIframe = true;
          this.iframeSrc = src;
          // return;
          // window.open(src, "_blank");
        }
        if (this.depositResponse.status && this.depositResponse.status === "success") {
          Swal.fire({
            icon: "success",
            text:
              (this.translations?.body?.Alert_pt1_Transaction_ID ?? "Transaction ID:") +
              " " +
              this.depositResponse.transaction.id +
              " " +
              (this.translations?.body?.Alert_pt2_created_with_status ??
                "created with status:") +
              " " +
              this.depositResponse.transaction.status +
              (this.translations?.body?.Alert_pt3_your_request_will_be_evalueted ??
                ".Your request will be evaluated"),
          }).then(() => {
            window.location.reload();
          });
        }
      }
    },
    withdrawResponse(value) {
      if (Object.prototype.hasOwnProperty.call(value, "message")) {
        let withdrawErrorMessage1 = value.message;
        let showMessage = true;
        if (showMessage) {
          Swal.fire({
            icon: "error",
            text: withdrawErrorMessage1,
          });
        }
      } else if (Object.prototype.hasOwnProperty.call(value, "amount")) {
        let amount = value.amount[0];
        let message = amount.charAt(0).toUpperCase() + amount.slice(1);
        let withdrawErrorMessage2 = message;
        let showMessage = true;
        if (showMessage) {
          Swal.fire({
            icon: "error",
            text: withdrawErrorMessage2,
          });
        }
      } else if (value.includes("<iframe")) {
        // console.log("Iframe_trovato");
        let startFromSrc = value.slice(value.search("src")).slice(5);
        let src = startFromSrc.slice(0, startFromSrc.search('"'));
        src = src.replace(/&amp;/g, "&");
        // alert(1);
        this.showIframeWithdraw = true;
        this.iframeSrcWithdraw = src;
      } else {
        if (this.withdrawResponse.status && this.withdrawResponse.status === "success") {
          Swal.fire({
            icon: "success",
            text:
              (this.translations?.body?.Alert_pt1_Transaction_ID ?? "Transaction ID:") +
              " " +
              this.withdrawResponse.transaction.id +
              (this.translations?.body?.Alert_pt2_created_with_status ??
                "created with status:") +
              " " +
              this.withdrawResponse.transaction.status +
              (this.translations?.body?.Alert_pt3_your_request_will_be_evalueted ??
                ".Your request will be evaluated"),
          }).then(() => {
            window.location.reload();
          });
        }
      }
    },
    account_id_deposit(value) {
      let response = this.gbLastUsedCardResponse;
      if (!response && this.getProviderPaymentMethods("globank").includes(value))
        this.$store.dispatch("payments/callGlobankLastUsedCard");
      else if (
        this.getProviderPaymentMethods("globank").includes(value) &&
        response &&
        Object.prototype.hasOwnProperty.call(response, "status") &&
        response.status == "success"
      )
        this.callAlertCardUsed();
      else this.showAlreadyUsedCard = false;
    },
    gbLastUsedCardResponse(value) {
      if (
        Object.prototype.hasOwnProperty.call(value, "status") &&
        value.status == "success"
      ) {
        this.card_details = value.data;
        this.callAlertCardUsed();
      }
    },
    /*ACCOUNT STATEMENT */
    transactionsList(value) {
      if (value != null) {
        this.responseLength = value.length;
      }
    },
    transactionsTotList(value) {
      if (value != null) {
        this.responseLengthTot = value.length;
      }
    },
    "expiry_date.month": {
      handler: function (value) {
        if (value.length == 2) document.querySelector("#expiry_year").focus();
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style scoped>
.disabled {
  opacity: 0.6;
}

h5 {
  font-size: 22px !important;
}

.w-32 {
  width: 32%;
}

.icon-downloader {
  max-width: 40px;
  max-height: 35px;
  -o-object-fit: contain;
  object-fit: contain;
  cursor: pointer;
}
</style>

<!--OLD CREATED
    // this.$store
    //   .dispatch("payments/getDepositPaymentMethods")
    //   .then(() => {
    //     this.isMethodLoaded = true;
    //   })
    //   .catch((error) => {
    //     console.error("Error during getDepositPaymentMethods:", error);
    //   });
    // this.$store.dispatch("payments/callPaymentMethods");
    // this.$store
    //   .dispatch("payments/getWithdrawPaymentMethods")
    //   .then(() => {
    //     this.isMethodLoaded = true;
    //   })
    //   .catch((error) => {
    //     console.error("Error during getWithdrawPaymentMethods:", error);
    //   });
    -->
