<template>
  <section id="CasinoCategoryMenu" class="CasinoCategoryMenu">
    <div class="container-max">
      <div class="search-box">
        <input
          id="game_name"
          type="text"
          :placeholder="translations?.buttons?.SearchButton"
          required
          v-model="searchTerm"
          @keydown.enter.prevent="searchGames"
        />
        <div
          class="cross-delete-search"
          :class="{ 'active-cross-delete-search': searchTerm.length !== 0 }"
        >
          <img
            @click="resetSearchBox"
            class="cross-categories"
            src="/images/crossSection.svg"
            alt=""
          />
        </div>
      </div>
      <div class="menu" v-if="allSkinSections && allSkinSections?.length > 1">
        <a
          v-for="(section, index) in allSkinSections"
          :key="index"
          class="item"
          :class="{
            'selected-section': section.id == storedSection,
          }"
          :id="section.id"
          :data-section-id="section.id"
          @click="showGamesSectionSelected(section)"
        >
          <span
            class="d-flex justify-content-center align-items-center flex-column"
            style="height: 70px"
          >
            <img class="thumb-section" :key="translations?.casino?.[replaceSpaces(section.button_text)] ?? section.button_text" :src="section.thumb" />
            <div class="btn-text-section">
              {{
                translations?.casino?.[replaceSpaces(section.button_text)] ??
                section.button_text
              }}
            </div>
          </span>
        </a>
      </div>
      <div class="select-provider-dropdown" v-click-outside="closeDropDownProvider">
        <div class="dropdown">
          <div class="input-box" @click="toggleDropdown" :class="{ open: isOpen }">
            {{
              currentProvider
                ? currentProvider.name
                : translations?.casino?.show_all_btn_provider_menu
            }}
          </div>
          <div
            class="extended"
            ref="list"
            :style="{ maxHeight: listMaxHeight, boxShadow: listBoxShadow }"
            style="height: 150px"
          >
            <div class="list" style="max-height: 150px; overflow-y: auto !important">
              <li
                class="providers-item"
                @click="resetProvider"
                v-if="currentProvider != null"
              >
                <label for="null" @click="closeDropdown">
                  <span class="name text-center">{{
                    translations?.casino?.show_all_btn_provider_menu ?? "Show All"
                  }}</span>
                </label>
              </li>
              <li
                v-for="(provider, index) in filteredProviders"
                :key="index"
                :id="provider.id"
                class="providers-item"
                @click="selectProvider(provider)"
              >
                <label
                  :for="provider.id"
                  @click="closeDropdown"
                  :style="{ fontSize: provider.id === 169 ? '10px' : '' }"
                >
                  <span class="name text-center">{{ provider.name }}</span>
                </label>
              </li>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
export default {
  name: "GameSections",
  props: ["games_providers", "section", "slugSection", "defaultSection", "filters"],
  components: {},
  data() {
    return {
      isOpen: false,
      isTop: false,
      isBottom: false,
      selectedProvider: "",
      searchTerm: "",
      storedSection: null,
      topPosition: window.innerWidth,
      isMobile: window.innerWidth <= 767,
    };
  },
  computed: {
    filteredProviders() {
      return this.games_providers?.filter(
        (provider) => provider?.id !== this.currentProvider?.id
      );
    },
    listMaxHeight() {
      return this.isOpen ? this.$refs.list.scrollHeight + "px" : "0";
    },
    listBoxShadow() {
      return this.isOpen
        ? "0 1px 2px 0 rgba(0, 0, 0, 0.15),0 1px 3px 1px rgba(0, 0, 0, 0.1)"
        : null;
    },
    ...mapGetters("casinoGames", [
      "allSkinSections",
      "fullGameInThisSection",
      "currentPage",
      "last_page",
      "isFullViewActive",
      "currentProvider",
      "defaultSectionFirstLoading",
      "isLoadingCardGames",
      "isLoadingCardGamesBySections",
      "idFUllGameInthisSection",
    ]),
    ...mapGetters("translations", ["translations", "isTranslationLoading"]),
  },
  watch: {
    currentProvider(newProviderId) {
      if (newProviderId === null) {
        this.selectedProvider = this.translations?.casino?.show_all_btn_provider_menu;
      } else {
        const provider = this.games_providers.find(
          (provider) => provider.id === newProviderId
        );
        this.selectedProvider = provider
          ? provider.name
          : this.translations?.casino?.show_all_btn_provider_menu;
      }
    },
  },
  methods: {
    replaceSpaces(text) {
      return text.replace(/\s/g, "").toLowerCase();
    },
    scrollToTop() {
      let fullGame = document.getElementById("fullGame");
      if (fullGame) {
        let positionfull = fullGame.getBoundingClientRect();
        window.scrollTo(positionfull.left, positionfull.top + window.scrollY - 250);
      }
    },
    async showGamesSectionSelected(section) {
      await this.$store.dispatch("casinoGames/clearFilterLite");
      localStorage.removeItem("defaultSection");
      this.storedSection = section.id;
      this.scrollToTop();
      if (this.searchTerm.length !== 0) {
        this.resetSearchBox();
      }
      const activeSectionId = localStorage.getItem("fullGameSectionID");
      // Check per evitare doppio click in caso di sezione gia attiva
      if (this.isFullViewActive && section.id.toString() === activeSectionId) {
        return;
      }
      this.$store.commit("casinoGames/setIsFullViewActive", true);
      if (this.currentPage !== 1) {
        this.$store.commit("casinoGames/setCurrentPage", 1);
      }

      const localStorageKey = "fullGameSectionID";
      localStorage.setItem(localStorageKey, section.id);
      this.$store.commit("casinoGames/clearGame", []);
      this.$store.dispatch("casinoGames/callFullGameBySections", {
        section: localStorage.getItem(localStorageKey),
        currentPage: this.currentPage,
        defaultValue: false,
        filters: this.filters,
      });
    },
    async showAllCategories() {
      this.scrollToTop();
      //resetto tutti i filtri
      await this.$store.dispatch("casinoGames/clearFilter");
    },
    selectProvider(provider) {
      this.selectedProvider = provider.name;
      //pulisco giochi e setto paginazione a 1
      this.$store.commit("casinoGames/clearGame", []);
      this.$store.commit("casinoGames/setCurrentPage", 1);
      //salvo provider selezionato e attivo vista full
      this.$store.commit("casinoGames/setCurrentProvider", provider);
      this.$store.commit("casinoGames/setIsFullViewActive", true);
      this.scrollToTop();
      //chiamo giochi x provider
      this.$store.dispatch("casinoGames/callAllGamesByProvider");
    },
    resetProvider() {
      if (this.currentProvider != null) {
        this.$store.dispatch("casinoGames/clearFilterLite");
        this.$store.commit("casinoGames/clearGame", []);
        this.$store.commit("casinoGames/setCurrentPage", 1);
        this.$store.dispatch("casinoGames/callFullGameBySections", {
          section:
            localStorage.getItem("fullGameSectionID") ??
            localStorage.getItem("defaultSection"),
          currentPage: this.currentPage,
          defaultValue: false,
          filters: this.filters,
        });
      } else {
        return;
      }
    },
    searchGames() {
      if (this.searchTerm.length >= 3) {
        this.scrollToTop();
        const searchData = {
          wordSearched: this.searchTerm.toLowerCase(),
          currentSection: this.slugSection,
          currentProvider: this.currentProvider || "", // Assegna il valore vuoto se currentProvider è null
        };
        this.$store.dispatch("casinoGames/searchGames", searchData);
        this.searchTerm = "";
      } else {
        if (this.searchTerm.length > 0) {
          Swal.fire({
            icon: "error",
            text:
              this.translations?.body?.Error_must_Insert_3_Character ??
              "You must insert at least 3 characters.",
          });
        } else {
          this.$store.dispatch("casinoGames/clearFilterLite");
          this.$store.commit("casinoGames/clearGame", []);
          this.$store.commit("casinoGames/setCurrentPage", 1);
          this.$store.dispatch("casinoGames/callFullGameBySections", {
            section:
              localStorage.getItem("fullGameSectionID") ??
              localStorage.getItem("defaultSection"),
            currentPage: this.currentPage,
            defaultValue: false,
            filters: this.filters,
          });
        }
      }
    },
    resetSearchBox() {
      this.searchTerm = "";
    },
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },
    closeDropDownProvider() {
      if (this.isOpen) {
        this.toggleDropdown();
      }
    },
    closeDropdown() {
      this.isOpen = false;
    },
    windowScroll() {
      if (window.scrollY >= 300) {
        this.isTop = false;
      } else {
        this.isTop = true;
      }
    },
    checkPagePosition() {
      if (window.scrollY >= 301) {
        this.isBottom = true;
      } else {
        this.isBottom = false;
      }
    },
    checkIsMobile() {
      this.isMobile = window.innerWidth <= 767;
    },
    checkDropdownPosition() {
      if (this.isMobile) {
        return;
      } else {
        this.windowScroll();
        this.checkPagePosition();
        const dropdown = this.$refs.list;
        if (dropdown) {
          const dropdownRect = dropdown.getBoundingClientRect();
          if (this.isTop && dropdownRect) {
            dropdown.style.top = `-${dropdownRect.height + 10}px`;
          } else if (this.isBottom && dropdownRect) {
            dropdown.style.top = null;
          }
        } else {
          return;
        }
      }
    },
    setActiveBtn() {
      this.storedSection = localStorage.getItem("defaultSection")
        ? localStorage.getItem("defaultSection")
        : localStorage.getItem("fullGameSectionID");
    },
  },
  mounted() {
    this.topPosition = window.innerWidth;
    let counter = 0;
    const intervalId = setInterval(() => {
      this.setActiveBtn();
      counter++;
      if (counter === 15) {
        clearInterval(intervalId);
      }
    }, 300);
    window.addEventListener("scroll", () => {
      this.checkIsMobile();
      this.checkDropdownPosition();
    });
    window.addEventListener("resize", () => {
      this.checkIsMobile();
      this.topPosition = window.innerWidth;
    });
  },
};
</script>

<style scoped>
.thumb-section {
  max-width: 30px;
  height: auto;
  aspect-ratio: 1/1;
  -o-object-fit: contain;
  object-fit: contain;
  padding: 0px 0px;
  margin-bottom: 2px;
}
</style>

<!-- <div
          v-if="isFullViewActive"
          class="close-filter"
          @click="showAllCategories"
        >
          <img class="cross-categories" src="/images/crossSection.svg" alt="" />
          <span class="mt-1">
            {{ translations?.buttons?.CloseButtonSection }}
          </span>
        </div> -->
<!--const selectedButton = document.querySelector(
`.item[data-section-id="${this.storedSection}"]` ); if (selectedButton) {
selectedButton.classList.add("selected-section"); }-->
