import axios from "axios";
export default {
    namespaced: true,
    state() {
        return {
            path: {
                sendForm: 'notifications/messages/custom_email'
            },
        };
    },
    getters: {
        endpoint(_, _1, _2, rootGetters) {
            return rootGetters['api/endpoint'];
        },
        axios(_, _1, _2, rootGetters) {
            return rootGetters['api/axios'];
        },
        auth(_, _1, _2, rootGetters) {
            return rootGetters['api/auth'];
        },
        language(_, _1, _2, rootGetters) {
            return rootGetters['translations/language_selected'];
        },
    },
    mutations: {
    },
    actions: {
        //sales@theearplatform.com
        async sendFormContact({ getters, state, commit }, { name, email, telephone, message_body }) {
            let all_information = `
            <p style="font-size: 16px; color: #333; line-height: 1.6; text-align: left; font-weight: 500;">
                Dear Customer, a request arrived from our website <b>'Bet Happy'</b>. Below is a report of the data sent:
            </p>
            <ul style="list-style-type: none; padding: 0 !important; margin-top: 10px; text-align: left;">
                <li style="margin-bottom: 10px; margin-left: 0px; font-weight: 500;"> <b>Name</b>: ${name}</li>
                <li style="margin-bottom: 10px; margin-left: 0px; font-weight: 500;"> <b>Email</b>: ${email}</li>
                <li style="margin-bottom: 10px; margin-left: 0px; font-weight: 500;"> <b>Telephone-Number</b>: ${telephone}</li>
                <li style="margin-bottom: 10px; margin-left: 0px; font-weight: 500;"> <b>Message</b>:<br /> ${message_body}</li>
            </ul>
            <p style="font-size: 14px; color: #333; line-height: 1.6; text-align: left; font-weight: 500; margin-top: 20px;">
                We will contact you as soon as possible. Best regards, Bet Happy staff.
            </p>
            <br />
`;

            let bodyrequest = {
                emails: ["support@bethappy.com"],
                body: all_information,
                subject: "Contact Form",
                ref_id: process.env.VUE_APP_REFID,
            };
       
            await axios
                .post(
                    getters.endpoint + state.path.sendForm, bodyrequest,
                )
                .then((response) => {
                    return response.data;
                })
                .catch((err) => {
                    console.log(commit);
                    console.log(err);
                    return;
                });
        }
    }
}
