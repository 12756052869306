export default {
	namespaced: true,
	state() {
		return {
			path: {
				transactions: 'me/transactions',
				delete_transaction: (provider, id) => {
					return `payments/providers/${provider}/decline/${id}`;
				},
				casino: 'casino/analytics',
				transactionStatus: 'static/transactions/',
			},
			transactionsList: null,
			transactionsDeposit: { 'amount': 0 },
			transactionsWithdraw: { 'amount': 0 },
			casinoList: null,
			isReportsLoading: false,
			isTransactionsLoading: false,
			isFirstLoadCasino: true,
			isFirstLoadTransactions: true,
			pendingWithdraw: null,
			isCheckingPendingWithdraw: false,
			isCheckingTransaction: false,
			transactionInfo: null,
			type: null
		};
	},
	getters: {
		endpoint(_, _1, _2, rootGetters) {
			return rootGetters['api/endpoint'];
		},
		translations(_, _1, _2, rootGetters) {
			return rootGetters['translations/translations'];
		},
		axios(_, _1, _2, rootGetters) {
			return rootGetters['api/axios'];
		},
		auth(_, _1, _2, rootGetters) {
			return rootGetters['api/auth'];
		},
		tokenAuth(_, _1, _2, rootGetters) {
			return rootGetters['player/tokenAuth'];
		},
		playerInfo(_, _1, _2, rootGetters) {
			return rootGetters['player/playerInfo'];
		},
		transactionsList(state) {
			return state.transactionsList;
		},
		transactionsDeposit(state) {
			return state.transactionsDeposit;
		},
		transactionsWithdraw(state) {
			return state.transactionsWithdraw;
		},

		casinoList(state) {
			return state.casinoList;
		},
		isReportsLoading(state) {
			return state.isReportsLoading;
		},
		isTransactionsLoading(state) {
			return state.isTransactionsLoading;
		},
		type(state) {
			return state.type;
		},
		pendingWithdraw(state) {
			return state.pendingWithdraw;
		},
		isCheckingPendingWithdraw(state) {
			return state.isCheckingPendingWithdraw;
		},
		isFirstLoadCasino(state) {
			return state.isFirstLoadCasino;
		},
		isFirstLoadTransactions(state) {
			return state.isFirstLoadTransactions;
		},
		amountTotalRequest(state) {
			const reducer = (accumulator, currentValue) => accumulator + currentValue;
			const amountRequestArray = state.transactionsList
				.map((transaction) => transaction.amount)
				.map(Number)
				.reduce(reducer);
			return amountRequestArray;
		},
		isCheckingTransaction(state) {
			return state.isCheckingTransaction;
		},
		transactionInfo(state) {
			return state.transactionInfo;
		},
	},
	mutations: {
		getTransactionsReports(state, payload) {
			state.isFirstLoadTransactions = false;
			state.isTransactionsLoading = false;
			state.transactionsList = payload;
			// console.log('---------------')
			// console.log(state.transactionsList)
			// console.log('---------------')
		},
		getTransactionsReportsDeposit(state, payload) {
			if (state.transactionsDeposit && state.transactionsDeposit.amount !== undefined) {
				state.transactionsDeposit.amount = 0;
			}
			if (payload.length !== 0) {
				state.transactionsDeposit = payload[0];
			}
		},

		getTransactionsReportsWithdraw(state, payload) {
			if (state.transactionsWithdraw && state.transactionsWithdraw.amount !== undefined) {
				state.transactionsWithdraw.amount = 0;
			}
			if (payload.length !== 0) {
				state.transactionsWithdraw = payload[0];
			}
		},
		checkPendingWithdraw(state, payload) {
			state.pendingWithdraw = payload;
			state.isCheckingPendingWithdraw = false;
		},
		deleteTransaction(state, payload) {
			console.log(payload);
			console.log(state);
		},
		getCasinoReports(state, payload) {
			state.isFirstLoadCasino = false;
			state.isReportsLoading = false;
			state.casinoList = payload;
		},
		resetFirstLoad(state) {
			state.isFirstLoad = true;
		},
		setTransactionStatus(state, payload) {
			state.transactionInfo = payload;
			state.isCheckingTransaction = false;
		},
	},
	actions: {
		async getTransactionsReports({ state, getters, commit }, payload) {
			state.isTransactionsLoading = true;
			const from = '?date_from=' + payload.from + ' 00:00:00';
			const to = '&date_to=' + payload.to + ' 23:59:59';
			let type = '';
			let status = '';
			let total = "";
			if (payload.total != null)
				total = "&total=" + payload.total;

			if (payload.type != null) {
				type = '&type_id=' + payload.type;
			}
			if (payload.status) {
				status = '&status=' + payload.status;
			}
			state.type = payload.type;
			const responseData = await getters.axios
				.get(
					getters.endpoint +
					state.path.transactions +
					from +
					to +
					type +
					status +
					total,
					getters.tokenAuth
				)
				.then((response) => response.data.data)
				.catch((err) => err);

			if (payload.total != null && payload.type == 1) //deposit 
				commit('getTransactionsReportsDeposit', responseData);
			else if (payload.total != null && payload.type == 2) //withdraw 
				commit('getTransactionsReportsWithdraw', responseData);
			else
				commit('getTransactionsReports', responseData);
		},
		async checkPendingWithdraw({ state, getters, commit }, payload) {
			state.isCheckingPendingWithdraw = true;
			const responseData = await getters.axios
				.get(
					getters.endpoint + state.path.transactions + payload,
					getters.tokenAuth
				)
				.then((response) => response.data.data)
				.catch((err) => err);
			commit('checkPendingWithdraw', responseData);
		},
		async deleteTransaction({ state, getters, commit }, payload) {
			const responseData = await getters.axios
				.get(
					getters.endpoint +
					state.path.delete_transaction(
						payload.provider,
						payload.transaction
					),
					getters.tokenAuth
				)
				.then((response) => {
					if (response.data.includes("<form")) {
						document.body.innerHTML = response.data;
					}

					return response;
				})
				.catch((err) => err);
			commit('deleteTransaction', responseData);
			if (!responseData.data.includes("<form"))
				if (responseData.status == 200) {
					// commit(
					// 	'alert/simple_alert',
					// 	{
					// 		message: getters.translations.account_balance.transaction_deleted,
					// 	},
					// 	{ root: true }
					// );
					alert('transaction deleted')
				} else {
					// commit(
					// 	'alert/simple_alert',
					// 	{
					// 		message: getters.translations.account_balance.Something_went_wrong,
					// 	},
					// 	{ root: true }
					// );
					alert('something went wrong!')
				}
		},
		async getCasinoReports({ state, getters, commit }, payload) {
			state.isReportsLoading = true;
			const from = '?from=' + payload.from + ' 00:00:00';
			const to = '&to=' + payload.to + ' 23:59:59';
			let type = '';
			let provider = '';
			if (payload.type != null) {
				type = '&money_type=' + payload.type;
			}
			if (payload.provider) {
				provider = '&provider_id=' + payload.provider;
			}
			const responseData = await getters.axios
				.get(
					getters.endpoint + state.path.casino + from + to + type + provider,
					getters.tokenAuth
				)
				.then((response) => response.data)
				.catch((err) => err);
			commit('getCasinoReports', responseData);
		},
		async getTransactionStatus({ state, getters, commit }, id) {
			state.isCheckingTransaction = true;
			const responseData = await getters.axios
				.get(
					getters.endpoint +
					state.path.transactionStatus +
					id,
					getters.tokenAuth
				)
				.then((response) => response)
				.catch((err) => err);
			if (Object.prototype.hasOwnProperty.call(responseData, 'response')) {
				commit('setTransactionStatus', null);
			} else {
				commit('setTransactionStatus', responseData.data);
			}
		},

	},
};
